import {Image, StyleSheet, Text, View} from "react-native";
import {Card} from "../Card/Card";
import {useSelector} from "react-redux";
import {Link} from "@react-navigation/native";

const styles = StyleSheet.create({
    cardContainer: {
        paddingVertical: 32,
        paddingHorizontal: 32,
        flexDirection: 'row',
        alignItems: 'center'
    },
    icon: {
        alignSelf: 'stretch',
        maxWidth: 300,
        flex: 2,
        resizeMode: 'contain'
    },
    textContainer: {
        flex: 3,
        marginLeft: 32
    },
    title: {
        marginBottom: 16,
    },
    text: {
        marginBottom: 32,
    },
    button: {
        paddingVertical: 8,
        paddingHorizontal: 20,
        fontFamily: "BebasNeue_400Regular",
        fontSize: 23,
        textAlign: 'center',
        marginRight: 'auto'
    }
});

export default function ChatBotPreview() {
    const theme = useSelector(state => state.theme);
    const overwriteStyles = StyleSheet.create({
        title: {
            ...theme.text.bold,
        },
        text: {
            ...theme.text.paragraph,
        },
        button: {
            backgroundColor: theme.colors.primary,
            color: theme.colors.text_light,

        }
    });

    return <Card title={'Toni ist immer für dich da!'}>
        <View style={styles.cardContainer}>
            <Image
                style={styles.icon}
                source={require('../../../assets/images/KiKxxl_Toni.png')}/>
            <View style={styles.textContainer}>
                <Text style={[styles.title, overwriteStyles.title]}>
                    Toni hilft dir weiter solltest du Fragen haben
                </Text>
                <Text style={[styles.text, overwriteStyles.text]}>
                    Bei allen Fragen zu KiKxxl ist Toni der richtige Ansprechpartner. Und sollte dir Toni mal nicht
                    weiterhelfen können, kennt er zumindest jemanden der es kann!
                </Text>
                <Link to={{screen: 'ChatBot'}}>
                    <View style={[styles.button, overwriteStyles.button]}>
                        <Text>Chat starten</Text>
                    </View>
                </Link>
            </View>
        </View>
    </Card>
}
