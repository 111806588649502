import {Card} from "../Card/Card";
import {Image, StyleSheet, Text, View} from "react-native";
import {useSelector} from "react-redux";
import useCanteenMenu from "../../utils/hooks/useCanteenMenu";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

const styles = StyleSheet.create({
    cardContainer: {
        paddingVertical: 16,
        paddingHorizontal: 32,
    },
    dishContainer: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'flex-start',
        alignItems: "center",
        paddingVertical: 16,
        borderTopWidth: 1,
        borderTopColor: '#000',
    },
    dishContainer_first: {
        borderTopWidth: 0,
    },
    dishTextContainer: {
        flex: 1,
        alignItems: 'flex-start',
        marginRight: 12,
    },
    dishPriceContainer: {
        flexGrow: 0,
        flexShrink: 1,
        alignItems: 'flex-end'
    },
    dishPrice: {
        marginLeft: 12,
        flexShrink: 0,
    },
    dishIconContainer: {
        width: 32,
        marginRight: 12,
    },
    dishIcon: {
        width: 32,
        height: 32,
        resizeMode: 'contain',
    },
});

export default function CanteenMenuPreview(props) {
    const {dishesByDate, isLoading} = useCanteenMenu({renderDays: 1});

    const theme = useSelector(state => state.theme);
    const overwriteStyles = StyleSheet.create({
        cardContainer: {
            paddingHorizontal: theme.padding
        },
        dishContainer: {
            borderTopColor: theme.colors.border
        },
        dishName: {
            ...theme.text.paragraph,
        },
        dishPrice: {
            ...theme.text.bold,
        }
    });

    return <Card title={'Speiseplan für Osnabrück'}>
        <View style={styles.cardContainer}>
            {isLoading
                ? <LoadingIndicator/>
                : dishesByDate?.[0]?.dishes?.map((dish, idx) => (
                    <View
                        key={idx}
                        style={[styles.dishContainer, overwriteStyles.dishContainer, (idx === 0 && styles.dishContainer_first)]}>
                        <View style={styles.dishIconContainer}>
                            {(dish.vegetarian || dish.contains_pork) && <Image
                                style={styles.dishIcon}
                                source={dish.vegetarian
                                    ? require("../../../assets/images/vegi.png")
                                    : require("../../../assets/images/schwein.png")}/>}
                        </View>
                        <View style={styles.dishTextContainer}>
                            <Text style={overwriteStyles.dishName}>{dish.title}</Text>
                            <Text style={overwriteStyles.dishName}>{dish.side_dish}</Text>
                        </View>
                        <View style={styles.dishPriceContainer}>
                            <Text style={[styles.dishPrice, overwriteStyles.dishPrice]}>{dish.employee_price} €</Text>
                        </View>
                    </View>
                ))}
        </View>
    </Card>
}
