import {View} from "react-native";
import ImageSlider from "../ImageSlider/ImageSlider";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import {useFocusEffect, useLinkTo} from "@react-navigation/native";
import {useValidLocations} from "../../utils/location";
import {isDevelopment} from "../../constants/booleanValues";
import {useSelector} from "react-redux";
import {CONTENT_TYPE} from "../../constants/strings";

/**
 * This part appears on the top most part of the home page
 * TopNews tag must be selected in the global admin for this news to show up.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function NewsFeatured(props) {
    const [activeArticle, setActiveArticle] = useState(null);
    const locations = useValidLocations()
    const {route} = props;
    const params = {};
    if (locations) params.locations = locations;
    const isRefreshed = useRef(false);
    const {data, error, isLoading, refetch} = endpoints.getFeaturedNews.useQuery(params);

    const cache = useSelector(state => state.cache);
    const cachedNews = cache?.cacheTags.find(item => item.type === CONTENT_TYPE.NEWS);

    /**
     * after navigating home, the cache is reset, so we refetch
     */
    useEffect(() => {
        if (!isLoading && !cachedNews) {
            // isDevelopment && console.log("NewsFeatured useEffect", {data, error, isLoading}, cachedNews);
            refetch();
        }
    }, [cachedNews])

    const linkTo = useLinkTo();
    const navigateToArticle = (item) => {
        linkTo({
            screen: 'NewsArticle', params: {
                id: item.id,
            }
        });
    };

    return <View>
        <ImageSlider
            isSwipeEnabled={false}/* performance issues on internal browsers/old hardware */
            onImageChanged={(idx) => setActiveArticle(data[idx])}
            featuredNews={data}
            onPress={navigateToArticle}
            images={data?.map(item => ({
                imageUrl: item.imageUrl,
                previewUrl: item.preview
            }))}/>
    </View>;
}
