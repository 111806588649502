import {Text, TouchableOpacity, View} from 'react-native';
import {useSelector} from "react-redux";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import React from "react";
import {FontelloIcon} from "../Icon/FontelloIcon";
import {Image} from "react-native-expo-image-cache";

function CaptionBanner(props) {
    const theme = useSelector(state => state.theme);
    let {
        config = {
            options: {
                icon: null,
                imageIconUrl: null,
            }
        }
    } = props;
    let icon = null;
    if (config?.options?.icon) {
        icon = <TouchableOpacity disabled={config?.options?.icon?.onPress ? false : true}
                                 onPress={() => config?.options?.icon?.onPress ? config?.options?.icon?.onPress() : null}>
            {
                config?.options?.icon.type === "fontello_custom" ?
                    <FontelloIcon name={config?.options?.icon?.name} style={{marginRight: 10}} color={"#fff"}
                                  size={65}/> :

                    <FontAwesome5
                        color={"#ffffff"}
                        style={{marginRight: theme.wrapperPadding}}
                        size={config?.options?.icon?.size || 45}
                        name={config?.options?.icon?.name}/>}
        </TouchableOpacity>
    } else if (config?.options?.imageIconUrl) {
        icon = <Image
            style={{
                height: 80,
                width: 80,
            }}
            resizeMode={"contain"}
            uri={config?.options?.imageIconUrl}
        />
    } else if (config.options?.renderButton) {
        //render our own stuff
        icon = config.options?.renderButton()
    }
    return <View style={{
        width: "100%",
        backgroundColor: config?.options?.backgroundColor || theme.colors.primary,
        alignItems: config?.options?.alignItems || 'center',
        imageIconUrl: null,
        //flex: 1,
        alignContent: "stretch",
        // flexShrink: 1,
        paddingTop: 9,
        paddingBottom: 9,
        paddingLeft: theme.wrapperPadding,
        paddingRight: theme.wrapperPadding,
        flexDirection: 'row',
        justifyContent: "space-between",
    }}>
        <Text
            adjustsFontSizeToFit={true}
            minimumFontScale={0.5}
            numberOfLines={config.options?.text?.numberOfLines || 2}
            style={{
                color: config?.options?.color || "white",
                fontFamily: "BebasNeue_400Regular",
                marginRight: theme.wrapperPadding,
                fontSize: config?.options?.text?.fontSize || 65
            }}>
            {config?.options?.text?.value}
        </Text>
        {icon}

    </View>
}

export default CaptionBanner
