import {Modal, Platform, Pressable, StyleSheet, Text, View} from "react-native";
import React from "react";
import {useSelector} from "react-redux";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {Image} from "react-native-expo-image-cache";
// import {Image} from "expo-image"; //doesn't work on native apps, crashes


const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 80,
        backgroundColor: 'rgba(0, 0, 0, .9)',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        flex: 1,
    },
    btn: {
        position: 'absolute',
        width: 80,
        height: 80,
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 999,
        // borderColor: "#707070",
        // borderWidth: 1,
    },
    btn_right: {
        right: 0,
    },
    btn_left: {
        left: 0,
    },
    btn_close: {
        top: 0,
        right: 0,
        zIndex: 999,
    },
    image: {
        flex: 1,
        width: "100%",
        // height: "100%",
        // resizeMode: 'center',
        // resizeMode: 'cover',
        resizeMode: "contain",
    }
});

export default function LightboxModal(props) {
    const {
        visible,
        setVisible,
        index,
        setIndex,
        imageUrls,
        remoteTotal,
        remotePage,
        remoteSize,
        loadMore,
        usePagination = false
    } = props;

    const theme = useSelector(state => state.theme);
    const overwriteStyle = StyleSheet.create({
        imageContainer: {
            position: 'absolute',
            ...(Platform.select({
                native: {
                    left: 0,
                    right: 0,
                },
                default: {//web
                    left: 80,
                    right: 80,
                }

            })),
            bottom: 80,
            top: 80,
            // alignItems: 'stretch',
        },
        text: {
            position: 'absolute',
            ...(Platform.select({
                native: {
                    bottom: 0,
                    paddingBottom: 46,
                },
                default: {//web
                    top: 0,
                    paddingTop: 32,
                }

            })),
            ...theme.typo.p_sm,
            color: theme.colors.text_light,
            zIndex: 999,
        },

        btn: {
            // alignItems: Platform.OS === "web" ? 'center' : 'flex-end',
            bottom: 0,
            marginBottom: 16,
        }
    });

    const Arrow = ({dir}) => <Pressable
        style={[styles.btn, styles[`btn_${dir}`], Platform.OS === "web" ? null : overwriteStyle.btn]}
        onPress={() => {
            if (dir === 'left' && index === 0) {
                setIndex(imageUrls.length - 1);
                //TODO: InfiniteScrollHook kann nur in eine richtung neue Datensätze laden, muss für diesen fall warscheinlich erweitert werden
            } else if (dir === 'right' && index === imageUrls.length - 1) {
                if (usePagination) {
                    if (index + 1 === (remotePage * remoteSize) && (remotePage * remoteSize < remoteTotal)) {
                        setIndex(index + 1);
                        // console.log("loading more.")
                        loadMore()
                    } else {
                        setIndex(0);
                    }
                } else setIndex(0);
            } else setIndex((prev) => prev + (dir === 'left' ? -1 : 1));
        }}>
        <FontAwesome5
            color={theme.colors.text_light}
            size={30}
            name={`chevron-${dir}`}/>
    </Pressable>;
    return <Modal
        animationType="fade"
        transparent={true}
        visible={visible}
        onRequestClose={() => setVisible(false)}
    >
        <Pressable
            style={[styles.overlay]}
            onPress={() => setVisible(false)}>
            <View style={styles.modalContainer}>


                <Text style={overwriteStyle.text}>
                    {index + 1} / {remoteTotal ?? imageUrls.length}
                </Text>

                {/* buttons */}
                <Arrow dir={'left'}/>
                <Arrow dir={'right'}/>

                <Pressable
                    /*IOS has some active controls on top, so top: 0 */
                    style={[styles.btn, styles.btn_close, Platform.OS === "ios" ? {top: 15} : null]}
                    onPress={() => setVisible(false)}>
                    <FontAwesome5
                        color={theme.colors.text_light}
                        size={30}
                        name={'times'}/>
                </Pressable>

                <View style={overwriteStyle.imageContainer}>
                    {/*Image has to be imported from react-native-expo-image-cache !!!*/}
                    <Image
                        preview={{uri: imageUrls[index]?.url}}
                        uri={imageUrls[index]?.url}
                        // contentFit={'contain'}
                        // contentPosition={'center'}
                        resizeMode={'contain'}
                        style={[styles.image]}
                    />

                    {/*this one has to be from expo-image package!! but it has bugs with react-native yet to be fixed :D*/}
                    {/*<Image*/}
                    {/*    source={imageUrls[index]?.url}*/}
                    {/*    preview={{uri: imageUrls[index]?.url}}*/}
                    {/*    uri={imageUrls[index]?.url}*/}
                    {/*    contentFit={'contain'}*/}
                    {/*    contentPosition={'center'}*/}
                    {/*    style={[styles.image]}*/}
                    {/*/>*/}
                </View>
            </View>
        </Pressable>
    </Modal>;
}
