import React, {useState} from "react";
import useGrid from "../../utils/useGrid";
import {useSelector} from "react-redux";
import {FlatList, Platform, StyleSheet, View} from "react-native";
// import {Image} from "react-native-expo-image-cache";

import LightboxModal from "../Modals/LightboxModal/LightboxModal";
import GalleryViewItem from "../Fotogalerien/GalleryViewItem";

/**
 * Shows a 2x2 grid of article images
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const GalleryView = (props) => {
    const {
        route, navigation,
        data = [],
        maxTiles = 2,
        minTileWidth = Platform.select({native: 100, default: 230}),
        marginWidth = Platform.select({native: 12, default: 24}),
    } = props;

    // ref is used for web and onLayout is used for native
    const {ref, tiles, tileDim, onLayout} = useGrid(minTileWidth, marginWidth, maxTiles);
    const theme = useSelector(state => state.theme);
    const tileHeight = tileDim / 4 * 3;//single tile height;
    const containerHeight = Math.ceil(data.length / 2) * (tileHeight + marginWidth); //all container height
    // const {height} = useWindowDimensions();
    // console.log("GalleryView", data?.images_data?.length > 0, /*data,*/ Math.ceil(data.length / 2),
    //     {tiles, tileDim, tileHeight, containerHeight});


    const [modalVisible, setModalVisible] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);

    const styles = StyleSheet.create({
        flatList: {
            flex: 1,
            // marginBottom: marginWidth,
        },
        imgListRowNative: {
            display: "flex",
            flexDirection: "row",
            marginBottom: marginWidth,
        },
        container: {
            ...(Platform.OS === "web" ? {
                height: containerHeight,
            } : {
                // marginBottom: marginWidth,
            }),
        }
    });

    const selectImg = (idx) => {
        setModalVisible(true)
        setCurrentImage(idx)
    }

    /**
     * Returns image FlatList for web only
     * @returns {JSX.Element}
     * @constructor
     */
    const ImgListWeb = () => {
        return <FlatList
            data={data}
            style={[styles.flatList]}
            contentContainerStyle={{
                paddingBottom: 40,
                // height: height - 460 //TODO: calculate real components height and substract
                height: containerHeight - 40,
            }}
            numColumns={tiles}
            key={tiles} // forced refresh on column-change
            // Performance settings
            removeClippedSubviews={true} // Unmount components when outside of window
            initialNumToRender={4} // Reduce initial render amount
            // maxToRenderPerBatch={4} // Reduce number in each render batch
            // updateCellsBatchingPeriod={100} // Increase time between renders
            // windowSize={7} // Reduce the window size
            // onEndReachedThreshold={0.1}
            renderItem={({item, index}) =>
                GalleryViewItem({item, index, tiles, tileWidth: tileDim, tileHeight, selectImg})}
        />
    }

    /**
     * Image gallery for native without scrolling (nested scrolling on native brings warnings)
     * @constructor
     */
    const ImgListNative = () => {

        const renderImgs = () => {
            let imgContainers = [];
            for (let i = 0; i < data.length / 2; i++) {
                imgContainers.push(<View key={i} style={styles.imgListRowNative}>
                    <GalleryViewItem key={i * 2} margin={12} {
                        ...{
                            item: data[i * 2], index: i * 2, tiles,
                            tileWidth: tileDim, tileHeight,
                            selectImg,
                        }
                    }/>
                    {(i * 2 + 1 < data.length)
                        ? <GalleryViewItem key={i * 2 + 1} margin={12} {
                            ...{
                                item: data[i * 2 + 1], index: i * 2 + 1, tiles,
                                tileWidth: tileDim, tileHeight,
                                selectImg,
                            }
                        }/>
                        : null
                    }
                </View>)
            }
            return imgContainers;
        }

        return data.length > 0
            ? renderImgs()
            : null;
    }

    return data.length > 0
        ? <View
            onLayout={onLayout}
            style={styles.container}
            ref={ref}
        >
            {Platform.OS === "web" ? <ImgListWeb/> : <ImgListNative/>}

            <LightboxModal
                visible={modalVisible}
                setVisible={setModalVisible}
                index={currentImage}
                setIndex={setCurrentImage}
                // remoteSize={remoteSize}
                // usePagination={true}
                // loadMore={readMore}
                // remotePage={remotePage}
                // remoteTotal={remoteTotal}//news_big
                imageUrls={data?.map(item => ({url: item?.news_big?.url}))}/>
        </View>
        : null
};


export default GalleryView;