import React, {useState} from "react";
import {Pressable, StyleSheet, Text, View,} from "react-native";
import CaptionBanner from "../CaptionBanner/CaptionBanner";
import BackNavigationBanner from "../BackNavigationBanner/BackNavigationBanner";
import NotificationCounter from "../NotificationCounter/NotificationCounter";
import {useSelector} from "react-redux";
import RelativeTimeFormat from "relative-time-format"
import {endpoints} from "../../app/services/api/globalAdminAPI";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import {getDaysDifference} from "../../utils/timeFunctions";
import useInfiniteScroll from "../../utils/hooks/useInfiniteScroll";
import CreateForumThreadModal from "../Modals/CreateForumThreadModal/CreateForumThreadModal";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import ErrorText from "../Error/ErrorText";
import {POLLING_INTERVALL_LIKES_COMMENTS} from "../../constants/numbers";
import LoadMoreButton from "../Button/LoadMoreButton";
import useTitle from "../../utils/hooks/useTitle";

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    listContainer: {
        flex: 1,
    },
    categoryContainer: {
        borderWidth: 1,
        borderRadius: 100,
        padding: 30,
        display: 'flex',
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    categoryLabel: {
        borderRightWidth: 1,
        paddingRight: 40,
        marginRight: 40,
        flex: 1,
    },
    categoryLabel_bpMargin: {
        paddingRight: 20,
        marginRight: 20,
    },
    createThreadPressable: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 32,
        paddingBottom: 8
    },
    createThreadText: {},
    createThreadBtn: {
        borderWidth: 1,
        borderRadius: 30,
        width: 44,
        height: 44,
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 16
    }
});

const PAGE_SIZE = 20;
export default function ForumCategory(props) {
    const rtf1 = new RelativeTimeFormat("de", {numeric: "auto"});
    const {route, navigation, breakpoint} = props;
    const bp_margin = breakpoint < 4;
    const {id, title} = route.params;
    const [modalVisible, setModalVisible] = useState(false);

    const {
        refetch: refetchForum, data: forumData, error: forumError, isLoading: forumIsLoading
    } = endpoints.getForum.useQuery(id, {notifyOnNetworkStatusChange: true});
    const {
        combinedData: data, readMore, refresh, isLoading, isFetching, refetch, canFetchMore
    } = useInfiniteScroll(endpoints.getThreadsPaginated.useQuery,
        {
            size: PAGE_SIZE,
            threadIdList: forumData?.entries[0]?.threads_data?.map(item => item.id),
            notifyOnNetworkStatusChange: true,
            skip: forumIsLoading
        });
    // const {data:likeData, error, isLoading:likesAndCommentsLoading, isFetching :likesAndCommentsFetching,isUninitialized:likesAndCommentsIsUninitialized} = endpoints.getLikesAndCommentsCountForNewsArticles.useQuery([id],{skip: !forumData?.threadsData ,pollingInterval: POLLING_INTERVALL_LIKES_COMMENTS});
    // console.log({data},"skip? ",!data)

    useTitle(navigation, isLoading, route?.params?.title);


    const theme = useSelector(state => state.theme);
    if (forumError) return <ErrorText error={error}/>;

    const threadsMapped = data.map(thread => {
        // const messageCount = thread.comments;
        const messageCount = thread.comments_info.reduce((prev, current) => {
            return prev + 1 + (current.children?.length ?? 0);
        }, 0);
        return {
            id: thread.id,
            title: thread.title,
            comments: thread.comments_info,
            notificationsAmount: messageCount,
            lastEntryDate: thread.last_user_info?.last_update ? rtf1.format(getDaysDifference(new Date(thread.last_user_info?.last_update), new Date()), 'day') : "Kein Eintrag",
            lastEntry: thread.last_user_info?.last_update ? thread.last_user_info?.last_user?.full_name : "Kein Eintrag",
            threads: thread.threads_data
        }
    }) || []

    const overwriteStyle = StyleSheet.create({
        categoryContainer: {
            backgroundColor: theme.colors.background_light,
            borderColor: theme.colors.border,
            padding: theme.padding
        },
        categoryLabel: {
            ...theme.typo.p_sm_bold,
            color: theme.colors.text_dark,
            borderRightColor: theme.colors.border,
        },
        infoLabel: {
            ...theme.typo.p_sm,
            color: theme.colors.text,
            borderRightColor: theme.colors.border
        },
        createThreadText: {
            ...theme.typo.p_md,
            color: theme.colors.text_dark
        },
        createThreadBtn: {
            backgroundColor: theme.colors.background_light,
            borderColor: theme.colors.text_dark,
        }
    });

    const onAdd = () => {
        refetchForum();
    }

    // if (forumIsLoading) return <LoadingIndicator/>;
    return <View style={styles.container}>
        <CreateForumThreadModal {...props} forumId={id} visible={modalVisible} setVisible={setModalVisible} onAdd={onAdd}/>
        <BackNavigationBanner
            {...props}
            onPress={() => navigation.navigate('Forum')}
            link={"ForumOverview"}
            label={"Forum"}
            iconBefore={"chevron-left"}
        />
        <CaptionBanner config={{
            headline: title,
            customComponent: (
                <Pressable
                    style={styles.createThreadPressable}
                    onPress={() => setModalVisible(true)}>
                    <Text style={[styles.createThreadText, overwriteStyle.createThreadText]}>
                        Neuen Thread erstellen</Text>
                    <View style={[styles.createThreadBtn, overwriteStyle.createThreadBtn]}>
                        <FontAwesome5 name={'plus'} size={20} color={theme.colors.text_dark}/>
                    </View>
                </Pressable>
            )
        }}/>
        {forumIsLoading
            ? <LoadingIndicator/>
            : <View style={styles.listContainer}>
                {threadsMapped.map((thread, idx) => (
                    <Pressable
                        key={idx}
                        style={[styles.categoryContainer, overwriteStyle.categoryContainer, (idx !== 0 && {marginTop: 8})]}
                        onPress={() => navigation.navigate("ForumPage", {
                            id: thread.id,
                            title: thread.title
                            // parent: route.params
                        })}
                    >
                        <Text
                            style={[styles.categoryLabel, (bp_margin && styles.categoryLabel_bpMargin), overwriteStyle.categoryLabel]}>{thread.title}</Text>
                        <Text
                            style={[styles.categoryLabel, (bp_margin && styles.categoryLabel_bpMargin), overwriteStyle.infoLabel]}>{`Letzer Eintrag: ${thread.lastEntryDate}`}</Text>
                        <Text
                            style={[styles.categoryLabel, (bp_margin && styles.categoryLabel_bpMargin), overwriteStyle.infoLabel]}>{`von: ${thread.lastEntry}`}</Text>
                        <NotificationCounter value={thread.notificationsAmount}/>
                    </Pressable>
                ))}
                <LoadMoreButton onPress={readMore} disabled={!canFetchMore}/>
            </View>}
    </View>
}
