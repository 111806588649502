/**
 * Transforms form data from the portal into the GA-readable FormRequest definition
 *
 * @param {object} data
 * @param {object} formConfiguration
 *
 * @returns {object}
 */
export function transformFormDataToRequestDefinition(data, formConfiguration) {
    let requestDefinition = [];
    let i = 0;

    Object.values(formConfiguration.pages).forEach((page) => {
        // Iterate over form fieldsets
        let j = 0;
        Object.values(page.fieldsets ).forEach((fieldset) => {
            // Iterate over form rows
            requestDefinition[i] = requestDefinition[i] || {};
            requestDefinition[i].fieldsets = requestDefinition[i].fieldsets || [];
            requestDefinition[i].fieldsets[j] = requestDefinition[i].fieldsets[j] || {};
            requestDefinition[i].fieldsets[j].label = fieldset.label || '';

            let k = 0;
            Object.values(fieldset.rows ).forEach((row) => {
                // Iterate over form fields
                requestDefinition[i].fieldsets[j].rows = requestDefinition[i].fieldsets[j].rows || [];
                requestDefinition[i].fieldsets[j].rows[k] = requestDefinition[i].fieldsets[j].rows[k] || {};
                requestDefinition[i].fieldsets[j].rows[k].label = row.label || '';

                Object.values(row.fields).forEach((field) => {
                    if (!field.name) {
                        return;
                    }
                    requestDefinition[i].fieldsets[j].rows[k].fields = requestDefinition[i].fieldsets[j].rows[k].fields || {};
                    requestDefinition[i].fieldsets[j].rows[k].fields[field.name] = {
                        label: field.label || '',
                        value: data[field.name] || null,
                    };

                    if (field.choices && field.choices[data[field.name]]) {
                        requestDefinition[i].fieldsets[j].rows[k].fields[field.name].choice_label = field.choices[data[field.name]];
                    }
                });

                k++;
            });

            j++;
        });

        i++;
    });

    return requestDefinition;
}
