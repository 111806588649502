import {useSelector} from "react-redux";
import {useEffect, useMemo, useState} from "react";

/**
 * Gets locations from the user authentication and returns them
 * @returns {*[]}
 */
export function useValidLocations() {
    const auth = useSelector(state => state.auth);
    const locations = [];
    if (auth.locationId) locations.push(auth.locationId);
    if (Array.isArray(auth.locations) && auth.locations.length > 0) locations.push(...auth.locations)
    // console.log("useValidLocations", locations.length);
    return locations;
}
