import React from 'react';
import {Image, StyleSheet, View} from 'react-native';
import RenderHtml from 'react-native-render-html';
import CaptionBanner from "../CaptionBanner/CaptionBanner";
import {SystemFontKeys, SystemFonts} from "../../constants/SystemFonts";
import {useSelector} from "react-redux";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import DynamicFormWrapper from "../Form/DynamicFormWrapper";
import {parseHTMLIntoSeperatedArray} from "../../utils/strings";
import BackNavigationBanner from "../BackNavigationBanner/BackNavigationBanner";

export const InformationDetail = (props) => {
    const {config, route, navigation} = props;
    const {params} = route;
    //console.log({params})
    const {data, refetch, error, isLoading} = endpoints.getInformationDetail.useQuery(params.id);
    //console.log("got data ? ",{data})
    const theme = useSelector(state => state.theme);
    const htmlStyles = React.useMemo(
        () => ({
            ...theme.typo.p_md_light,
            ...theme.typo.web,
            color: theme.colors.text_dark,
        }),
        [theme],
    );
    const overwriteStyles = StyleSheet.create({});
    navigation.setOptions({title: data?.title ?? params?.title})
    const resultArr = data?.description ? parseHTMLIntoSeperatedArray(data?.description) : [];
    return <View>
        {isLoading
            ? <LoadingIndicator/>
            : <>
                <BackNavigationBanner
                    {...props}
                    onPress={() => props.navigation.navigate('Info')}
                    label={"Informationen"}
                    iconBefore={"chevron-left"}
                />
                <CaptionBanner config={{
                    headline: data?.title ?? params?.title
                }}/>
                {(data?.picture_data?.reference?.url)
                    ? <Image
                        source={data?.picture_data?.reference?.url}
                        style={styles.newsImage}/>
                    : <Image resizeMode={"cover"}
                             style={styles.newsImage}
                             defaultSource={require("../../../assets/images/placeholder-web.jpg")}
                             // source={require("../../../assets/images/placeholder-web.jpg")}
                    />
                }
                <View style={styles.articleContainer}>
                    {
                        resultArr.map((item, index) => {
                            if (typeof item === "object" && item.type === "form" && item.id) {
                                return <DynamicFormWrapper key={index} id={item.id}/>
                            } else if (typeof item === "string" && item !== "") {
                                return <RenderHtml
                                    key={index}
                                    tagsStyles={{
                                        a: {
                                            color: theme.colors.primary,
                                            textDecoration: "underline",
                                            textDecorationColor: theme.colors.primary
                                        }
                                    }}
                                    contentWidth={500}
                                    baseStyle={htmlStyles}
                                    source={{
                                        html: item
                                    }}
                                    systemFonts={SystemFontKeys}
                                />
                            }
                        })
                    }
                </View>
            </>}
    </View>;
}
const styles = StyleSheet.create({
    articleContainer: {},
    newsImage: {
        borderRadius: 30,
        marginBottom: 32,
        height: 480,
        resizeMode: "cover",
    },
});
