import MealPlan from "../MealPlan/MealPlan.native";
import {ScrollView, StyleSheet, Text} from "react-native";
import CaptionBanner from "../../components/CaptionBanner/CaptionBanner.native";
import React from "react";
import {useSelector} from "react-redux";

/**
 * Wrapper for MealPlan that will swithc to using public route for a meal plan
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function MealPlanPublic(props) {
    const theme = useSelector(state => state.theme);

    const overwriteStyle = StyleSheet.create({
        subHeadline: {
            ...theme.typo.subHeadline,
            fontSize: 16,
            margin: 16,
        },
    });

    return <ScrollView style={{flex: 1, flexGrow: 1}} contentContainerStyle={{flexGrow: 1}}>
        <CaptionBanner config={{
            options: {
                text: {
                    numberOfLines: 2,
                    value: 'freizeichen – die KiKxxl-Kantine',
                    // fontSize: 20
                }
            }
        }}
        />
        <Text style={[styles.subHeadline, overwriteStyle.subHeadline]}>Das “freizeichen” am Standort Osnabrück öffnet
            täglich von 07:45 – 13:30 Uhr, warme Speisen werden zwischen 11:45 – 13:30 Uhr serviert. Außerhalb der
            Mittagskarte erwartet die Gäste eine Auswahl belegter Brötchen, Süßspeisen und Getränke.</Text>
        <MealPlan {...props} isPublic={true}/>
    </ScrollView>
}

const styles = StyleSheet.create({
    subHeadline: {
        maxWidth: 1000,
        width: "90%",
    }
});