import React, {useEffect, useState} from "react";
import {StyleSheet, Text, View} from "react-native";
import CaptionBanner from "../CaptionBanner/CaptionBanner";
import BackNavigationBanner from "../BackNavigationBanner/BackNavigationBanner";
import Comment from "../Comment/Comment";
import {
    useLikeCommentMutation,
    useUnlikeCommentMutation
} from "../../app/services/api/globalAdminAPI";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";
import {mapComments} from "src/components/Comment/commentsHelpers";
import CommentSection from "../Comment/CommentSection";
import useTitle from "../../utils/hooks/useTitle";
import {isDevelopment} from "../../constants/booleanValues";
import useCommentSubmit from "../../utils/hooks/useCommentSubmit";

export default function ForumPage(props) {
    const {route, navigation, scrollViewRef} = props;
    const {id, commentId: providedCommentId} = route.params;

    console.log("ForumPage scrollViewRef(b):", !!scrollViewRef.current, {providedCommentId})

    const [comments, setComments] = useState([]);
    const [originalPost, setOriginalPost] = useState(null);
    const [highlightedComment, setHighlightedComment] = useState(undefined);

    const [likeComment, {error: likeCommentError, isLoading: likeCommentIsLoading}] = useLikeCommentMutation();
    const [unlikeComment, {error: unlikeCommentError, isLoading: unlikeCommentIsLoading}] = useUnlikeCommentMutation();

    const {
        refetch, threadData, threadDataError, threadDataIsLoading,
        replyToCommentError,
        onCommentSubmit,
        setPosition,
        commentId,
        // highlightedCommentId,
        highlightPosition,
        setScrollView,
    } = useCommentSubmit({id, commentId: providedCommentId, scrollView: scrollViewRef}); //actually our child will report a scrollView afterwards

    const forum = threadData?.forums[0];

    // const { combinedData : threadData, readMore, refresh,isLoading : threadDataIsLoading, isFetching,refetch,canFetchMore } = useInfiniteScroll(
    //     endpoints.getThreadDetailPaginated.useQuery,
    //     {threadId: id}
    // );
    useTitle(navigation, threadDataIsLoading, threadData?.title ?? route?.params?.title);

    useEffect(() => {
        if (highlightedComment !== commentId) {
            isDevelopment && console.log("ForumPage useEffect", commentId);
            setHighlightedComment(commentId)
        }
    }, [commentId]);


    // console.log("ForumPage", {id, commentId, highlightedComment});

    if (threadDataIsLoading)
        return <LoadingIndicator/>;
    // if (threadDataError) return <Text>{JSON.stringify(route.params)}</Text>
    else {
        const mappedData = mapComments(threadData?.comments_info)
        if (JSON.stringify(mappedData) !== JSON.stringify(comments)) {
            setComments(mappedData)
        }
    }

    //also handle original post
    const threadPost = mapComments([threadData?.comments_op])[0];
    if (JSON.stringify(originalPost) !== JSON.stringify(threadPost))
        setOriginalPost(threadPost);

    if (!originalPost)
        return <View><Text>Fehler: Thread hat keinen Original Post</Text></View>

    return <View
        style={styles.container}
        contentContainerStyle={{alignItems: "center"}}>
        {forum && <BackNavigationBanner
            {...props}
            onPress={() => navigation.navigate('ForumCategory', {id: forum?.id, title: forum?.title})}
            label={forum.title ?? ""}
            iconBefore={"chevron-left"}/>}
        <CaptionBanner config={{
            headline: threadData?.title ?? "",
        }}/>
        {threadDataIsLoading ? <LoadingIndicator/> : <>
            <View style={styles.threadContainer}>
                {/* THIS IS THE OP , counts as FIRST COMMENT */}
                <Comment
                    {...props}
                    id={originalPost?.id}
                    padding={0}
                    isReplyTo={originalPost.parentId}
                    likesAmount={originalPost.likesAmount}
                    highlighted={highlightedComment && Number(originalPost.id) === Number(highlightedComment)}
                    canLike={true}
                    likeLoading={likeCommentIsLoading || unlikeCommentIsLoading}
                    onLike={() => originalPost.youLikeIt
                        ? unlikeComment({
                            commentId: originalPost.id,
                            threadId: id
                        })
                        : likeComment({commentId: originalPost.id, threadId: id})
                    }
                    likedByUser={originalPost.youLikeIt}
                    canReply={false}
                    user={originalPost.user}
                    createdAt={global.moment(originalPost.createdAt).format("DD.MM.YY - LT [Uhr]")}
                    comment={{content: originalPost.content}}
                />
            </View>
            <CommentSection
                // comments={comments?.flat() ?? []}
                comments={comments ?? []}
                // comments={comments?.sort((a, b) => new Date(a?.createdAt) < new Date(b?.createdAt)).flat() ?? []} //sorting is done in mapComments
                commentId={highlightedComment}
                setHighlightPosition={setPosition}
                headline={'Antworten'}
                number_of_comments={comments?.length}
                onCommentSubmit={onCommentSubmit}
            />
        </>}
    </View>;
}


const styles = StyleSheet.create({
    threadContainer: {
        marginBottom: 64,
        maxWidth: 835,
    },
});
