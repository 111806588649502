import {Image, StyleSheet, Text, View} from 'react-native';
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import {groupBy} from "../../utils/arrayUtils";
import {getDefaultImage} from "../../utils/avatar";
import {Card} from "../Card/Card";
import {CONTENT_TYPE} from "../../constants/strings";
import {isDevelopment} from "../../constants/booleanValues";

const dateOptions = {weekday: 'short', year: 'numeric', month: '2-digit', day: '2-digit'};
/**
 * fetch birthdays that are next in birthdayShownTimeDays time
 * @type {number}
 */
const birthdayShownTimeDays = 363;

const styles = StyleSheet.create({
    container: {
        padding: 32,
    },
    birthdayContainer: {
        flex: 1,
        flexWrap: 'wrap',
        flexDirection: "row",
        marginTop: 16,
    },
    birthdayContainer_first: {
        marginTop: 0,
    },
    image: {
        height: 64,
        width: 64,
        borderRadius: '50%',
        resizeMode: 'cover'
    },
    nameText: {},
    labelText: {
        marginBottom: 10
    },
    textContainer: {
        flex: 1,
        justifyContent: "center",
        paddingLeft: 10
    },
    noBirthdayText: {}
});

export default function BirthDays(props) {
    const {refetch, data, error, isLoading} = endpoints.getUpcomingBirthDays.useQuery(birthdayShownTimeDays);
    const {cache, theme} = useSelector(state => ({theme: state.theme, cache: state.cache}));
    const cachedBirthdays = cache?.cacheTags.find(item => item.type === CONTENT_TYPE.BIRTHDAYS);

    /**
     * after navigating home, the cache is reset, so we refetch
     */
    useEffect(() => {
        if (!isLoading && !cachedBirthdays) {
            // isDevelopment && console.log("BirthDays useEffect", {data, error, isLoading}, cachedBirthdays);
            refetch();
        }
    }, [cachedBirthdays]);

    const overwriteStyles = StyleSheet.create({
        container: {
            padding: theme.padding,
        },
        nameText: {
            ...theme.typo.p_md_bold,
        },
        labelText: {
            ...theme.typo.p_md_light,
        },
        noBirthdayText: {
            ...theme.typo.p_sm,
        }
    });

    /**
     * groups birthdays by "x_y" where x is day and y is month (x from 1, y from 0)
     * @type {Map<*, *>|*}
     */
    const groupedBirthDays = groupBy(data?.birthdays, (item) => {
        return new Date(item.birthday).getDate() + "_" + new Date(item.birthday).getMonth()
    });

    /**
     * builds todays index
     * @returns {`${number}_${number}`}
     */
        const getTodaysIndex = () => `${new Date().getDate()}_${new Date().getMonth()}`;
    // const getTodaysIndex = () => "19_8";//debug only


    const todaysBirthdays = groupedBirthDays?.get(getTodaysIndex());

    // console.log("groupedBirthDays", {groupedBirthDays, todaysIndex: getTodaysIndex(), todaysBirthdays});


    return <Card title={`Geburtstage (${todaysBirthdays?.length ?? 0})`}>
        <View style={[styles.container, overwriteStyles.container]}>
            {isLoading ? <LoadingIndicator/> : <>
                {todaysBirthdays?.length > 0
                    ? todaysBirthdays?.map((item, idx) => (
                        <View key={idx}
                              style={[styles.birthdayContainer, (idx === 0 && styles.birthdayContainer_first)]}>
                            {item.picture
                                ? <Image
                                    style={styles.image}
                                    source={item.picture?.default_preview.url}/>
                                : getDefaultImage(item.gender, styles.image)
                            }
                            <View style={styles.textContainer}>
                                <Text style={[styles.nameText, overwriteStyles.nameText]}>
                                    {`${item.full_name} ${(new Date().getFullYear() - new Date(item.birthday).getFullYear())}`}
                                </Text>
                                <Text style={[styles.labelText, overwriteStyles.labelText]}>
                                    {`${item.city || ""} - ${item.job || ""} - ${item.area_of_work || ""}`}
                                </Text>
                            </View>
                        </View>
                    ))
                    : <Text
                        style={[styles.noBirthdayText, overwriteStyles.noBirthdayText]}>{`Heute hat niemand Geburtstag.`}</Text>}
            </>}
        </View>
    </Card>
}
