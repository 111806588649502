import {useSelector} from "react-redux";
import {StyleSheet, Text, View} from 'react-native';

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#ffffff',
        borderBottomRightRadius: 20,
        borderBottomLeftRadius: 20,
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: .1,
        shadowRadius: 6,
        elevation: 1,
        overflow: 'hidden',
    },
    headerContainer: {
        flexDirection: 'row',
        paddingVertical: 9,
        paddingHorizontal: 26,
    },
    headerText: {
        fontFamily: "BebasNeue_400Regular",
        fontSize: 43
    },
    headerChildren: {
        flex: 1,
        justifyContent: 'center',
    },
    childrenContainer: {}
});

export const Card = (props) => {
    const theme = useSelector(state => state.theme);
    const overwriteStyles = StyleSheet.create({
        headerContainer: {
            backgroundColor: theme.colors.primary,
            paddingHorizontal: theme.padding
        },
        headerChildren: {},
        headerText: {
            color: theme.colors.text_light
        }
    });

    return <View style={[styles.container, props.style]}>
        <View style={[styles.headerContainer, overwriteStyles.headerContainer]}>
            {props.title && <Text style={[styles.headerText, overwriteStyles.headerText]}>
                {props.title}
            </Text>}
            {props.headerChildren && <View style={[styles.headerChildren, (props.title && {marginLeft: 24})]}>
                {props.headerChildren}
            </View>}
        </View>
        <View style={[styles.childrenContainer]}>
            {props.children}
        </View>
    </View>
}
