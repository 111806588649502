import * as Device from "expo-device";
import * as Notifications from "expo-notifications";
import {Platform} from "react-native";
import {
    NOTIFICATION_TYPE_CALENDAR_EVENT_CHANGED,
    NOTIFICATION_TYPE_CALENDAR_EVENT_NEW,
    NOTIFICATION_TYPE_FORUM_NEW_REPLY_TO_OWN_COMMENT,
    NOTIFICATION_TYPE_FORUM_NEW_REPLY_TO_OWN_THREAD,
    NOTIFICATION_TYPE_NEW_GALLERY,
    NOTIFICATION_TYPE_NEWS_NEW_ENTRY,
    NOTIFICATION_TYPE_NEWS_NEW_NOTICE
} from "../Notifications/notificationTypes";
import {routeToForumComment, routeToThread} from "../Forum/forumUtil";
import {routeToCalendarEvent} from "../Calendar/calendarUtils";
import {routeToNewsComment} from "../../components/NewsArticle/newsUtil";
import {isDevelopment} from "../../constants/booleanValues";

interface ICreatePush {
    token: string;
    title: string;
    body: string;
    data?: any;
}

Notifications.setNotificationHandler({
    /**
     * Sets settings of incoming notifications
     * @param notification
     */
    handleNotification: async (notification) => {
        const silent = notification?.request?.content?.data?.silent === 1;
        const bg = notification?.request?.content?.data?.background === 1;
        return {
            shouldShowAlert: !bg,
            shouldPlaySound: !bg && !silent,
            shouldSetBadge: !bg,
        }
    },
});
const sendPushNotification = async (pushData: ICreatePush) => {
    const message = {
        to: pushData.token,
        title: pushData.title,
        body: pushData.body,
        data: pushData.data,
    };

    await fetch("https://exp.host/--/api/v2/push/send", {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Accept-encoding": "gzip, deflate",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(message),
    });
}
export const NotificationHandler = {
    // init,
    sendPushNotification,
};
export type {
    ICreatePush,
};

export async function registerForPushNotificationsAsync() {
    let token;
    //IT'S A LIE!!! we can use emulators!!!
    // if (Device.isDevice) {
    try {
        const {status: existingStatus} = await Notifications.getPermissionsAsync();
        let finalStatus = existingStatus;
        if (existingStatus !== 'granted') {
            const {status} = await Notifications.requestPermissionsAsync();
            finalStatus = status;
        }
        if (finalStatus !== 'granted') {
            console.log('Failed to get permission for push notification!');
        } else {
            token = await Notifications.getExpoPushTokenAsync();
        }
    } catch (e) {
        console.error(e)
    }
    // } else {
    //     console.warn('Must use physical device for Push Notifications');
    // }

    if (Platform.OS === 'android') {
        Notifications.setNotificationChannelAsync('default', {
            name: 'default',
            importance: Notifications.AndroidImportance.MAX,
            vibrationPattern: [0, 250, 250, 250],
            lightColor: '#FF231F7C',
        });
    }

    return token;
}

export function handleNotificationClick(notificationObj, linkTo) {
    //first one is when we click on it, second one is when we come from device event it self
    const data = notificationObj?.request?.content?.data ?? notificationObj?.notification?.request?.content?.data
    const dataID = data?.id ?? data?.thread_id ?? data?.news_id;
    console.log("handleNotificationClick", /*notificationObj, "data?.type:", data?.type,*/ JSON.stringify(data));
    switch (data?.type) {
        case NOTIFICATION_TYPE_CALENDAR_EVENT_NEW:
        case NOTIFICATION_TYPE_CALENDAR_EVENT_CHANGED:
            routeToCalendarEvent({id: dataID})
            break;
        case NOTIFICATION_TYPE_FORUM_NEW_REPLY_TO_OWN_THREAD:
        //why to route just to thread, when we can route and highlight the exact comment?
        //reusing NOTIFICATION_TYPE_FORUM_NEW_REPLY_TO_OWN_COMMENT
        // routeToThread(dataID, linkTo)
        // break;
        case NOTIFICATION_TYPE_FORUM_NEW_REPLY_TO_OWN_COMMENT:
            const isNews = data?.hasOwnProperty("news_id");
            isDevelopment && console.log("routing to", isNews ? "news" : "forum", {
                id: dataID,
                anchorId: data.anchor_id
            });
            if (isNews)
                routeToNewsComment(dataID, data.anchor_id, linkTo)
            else
                routeToForumComment(dataID, data.anchor_id, linkTo)
            break;
        case NOTIFICATION_TYPE_NEW_GALLERY:
            linkTo({
                screen: 'FotogalerienDetail', params: {
                    galleryId: dataID
                }
            });
            break;
        case NOTIFICATION_TYPE_NEWS_NEW_ENTRY:
            linkTo({
                screen: 'NewsArticle', params: {
                    id: dataID,
                }
            });
            break;
        case NOTIFICATION_TYPE_NEWS_NEW_NOTICE:
            linkTo({
                screen: 'ShortNewsArticle', params: {
                    id: dataID,
                }
            });
            break;
        default:
            break;
    }
}


//TODO: Remove, only for Test for marcel.
// setTimeout(() => {
//     //console.log("Schedule test notifications...",notifications)
//     if(notifications.length  < 1){
//         Notifications.scheduleNotificationAsync({
//             content: {
//                 sound:"default",
//                 title: "NOTIFICATION_TYPE_CALENDAR_EVENT_CHANGED",
//                 body: 'Here is the notification body',
//                 data: {
//                     type: NOTIFICATION_TYPE_CALENDAR_EVENT_CHANGED,
//                 },
//             },
//             trigger: { seconds: 2 },
//         }).then(r => console.log("push!")).catch((e) => console.error(e))
//         Notifications.scheduleNotificationAsync({
//             content: {
//                 sound:"default",
//                 title: "NOTIFICATION_TYPE_CALENDAR_EVENT_NEW",
//                 body: 'Here is the notification body',
//                 data: {
//                     type: NOTIFICATION_TYPE_CALENDAR_EVENT_NEW,
//                 },
//             },
//             trigger: { seconds: 2 },
//         }).then(r => console.log("push!")).catch((e) => console.error(e))
//         Notifications.scheduleNotificationAsync({
//             content: {
//                 sound:"default",
//                 title: "NOTIFICATION_TYPE_FORUM_NEW_REPLY_TO_OWN_THREAD",
//                 body: 'Here is the notification body',
//                 data: {
//                     type: NOTIFICATION_TYPE_FORUM_NEW_REPLY_TO_OWN_THREAD,
//                     id: 1,
//                     title:  "test",
//
//                 },
//             },
//             trigger: { seconds: 2 },
//         }).then(r => console.log("push!")).catch((e) => console.error(e))
//         Notifications.scheduleNotificationAsync({
//             content: {
//                 sound:"default",
//                 title: "NOTIFICATION_TYPE_FORUM_NEW_REPLY_TO_OWN_COMMENT",
//                 body: 'Here is the notification body',
//                 data: {
//                     type: NOTIFICATION_TYPE_FORUM_NEW_REPLY_TO_OWN_COMMENT,
//                 },
//             },
//             trigger: { seconds: 2 },
//         }).then(r => console.log("push!")).catch((e) => console.error(e))
//         Notifications.scheduleNotificationAsync({
//             content: {
//                 sound:"default",
//                 title: "NOTIFICATION_TYPE_NEW_GALLERY",
//                 body: 'Here is the notification body',
//                 data: {
//                     type: NOTIFICATION_TYPE_NEW_GALLERY,
//                 },
//             },
//             trigger: { seconds: 2 },
//         }).then(r => console.log("push!")).catch((e) => console.error(e))
//         Notifications.scheduleNotificationAsync({
//             content: {
//                 sound:"default",
//                 title: "NOTIFICATION_TYPE_NEWS_NEW_ENTRY",
//                 body: 'Here is the notification body',
//                 data: {
//                     type: NOTIFICATION_TYPE_NEWS_NEW_ENTRY,
//                 },
//             },
//             trigger: { seconds: 2 },
//         }).then(r => console.log("push!")).catch((e) => console.error(e))
//     }
// }, 0)
