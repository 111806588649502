import React from "react";
import {useSelector} from "react-redux";
import {StyleSheet, Text, View,} from "react-native";
import Skeleton from "../Skeleton/Skeleton";


// todo: link article pages (to={'/'})
export default function NewsArticlePreviewSkeleton(props) {
    const {
        isSmall,
        useImage = true,

    } = props;
    const theme = useSelector(state => state.theme);

    return <View style={[styles.container, (isSmall && styles.container_sm)]}>
        <View
            style={[{flex: 1}, (isSmall && {flex: null})]}>
            {useImage && <Skeleton  style={[styles.newsImage, (isSmall && styles.newsImage_sm)]} height={300} width={300} />
            }
        </View>
        <View style={[styles.textContainer, (isSmall && styles.textContainer_sm)]}>
            <Skeleton style={[styles.newsTitle, theme.typo.p_lg]}/>
            <Skeleton style={[styles.newsDate, theme.typo.date]}/>
            <Text style={[styles.newsParagraph, theme.typo.p_sm]} numberOfLines={3}>
                <Skeleton/>
            </Text>

            {/* interaction */}
            <View style={styles.interactionContainer}>
                {/* read more */}
                <View
                    style={[styles.button_readMore,]}>
                    <Skeleton/>
                </View>

                {/* likes */}
                <View style={styles.interactionGroup}>
                    <Skeleton width={40} height={40}/>
                </View>

                {/* comments */}
                <View style={styles.interactionGroup}>
                    <Skeleton width={40} height={40}/>
                </View>
            </View>

        </View>
    </View>
}

const styles = StyleSheet.create({
    container: {
        marginTop: 32,
        flexDirection: 'row',
        height: 280,
        alignItems: 'stretch'
    },
    container_sm: {
        height: 'unset',
        flexDirection: 'column',
    },
    newsImage: {
        flex: 1,
        borderRadius: 10,
    },
    newsImage_sm: {
        flex: null,
        minHeight: 300,
    },
    textContainer: {
        flex: 1,
        marginLeft: 32,
        minWidth: 300,
        justifyContent: 'center'
    },
    textContainer_sm: {
        flex: null,
        marginLeft: 0,
        marginTop: 16,
    },
    newsTitle: {
        marginBottom: 4,
    },
    newsDate: {
        marginBottom: 16,
    },
    newsParagraph: {
        flexShrink: 1
    },
    interactionContainer: {
        alignItems: "center",
        flexDirection: "row",
        marginTop: 16
    },
    interactionGroup: {
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 32
    },
    interactionCount: {
        marginLeft: 8
    },
    button_readMore: {
        height: 40,
        width: 120,
        alignItems: "center",
        justifyContent: "center"
    }
});

