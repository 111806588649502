import React, {useEffect, useState} from "react";
import {Modal, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {useSelector} from "react-redux";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {endpoints} from '../../app/services/api/globalAdminAPI'
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import {Card} from "../Card/Card";
import {SystemFontKeys, SystemFonts} from "../../constants/SystemFonts";
import RenderHtml from "react-native-render-html";
import {useValidLocations} from "../../utils/location";
import {CONTENT_TYPE} from "../../constants/strings";
import {isDevelopment} from "../../constants/booleanValues";
import {NOTIFICATION_TYPE_NEWS_NEW_NOTICE} from "../../components/Notifications/notificationTypes";

const styles = StyleSheet.create({
    container: {},
    verticalLine: {
        marginTop: 20,
        marginBottom: 20,
        borderBottomColor: 'white',
    },
    shortNewsContainer: {
        flex: 1,
        paddingVertical: 16,
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        borderTopWidth: 1,
        borderTopColor: '#000',
    },
    shortNewsContainer_first: {
        borderTopWidth: 0,
    },
    shortNewsTouchable: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'nowrap',
        overflow: 'hidden'
    },
    textContainer: {
        flexDirection: 'row',
        flexShrink: 1,
        overflow: 'hidden',
    },
    textContainer_col: {
        flexDirection: 'column',
    },
    iconContainer: {
        flexShrink: 0,
        marginLeft: 12,
    },
    date: {
        marginRight: 12,
        flexShrink: 0,
    },
    title: {
        flexShrink: 1,
    },
    cardContainer: {
        paddingVertical: 16,
        paddingHorizontal: 32,
    },
    modalContainer: {
        backgroundColor: 'rgba(0, 0, 0, .6)',
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 80,
    },
    modalPopup: {
        backgroundColor: '#fff',
        padding: 32,
        borderRadius: 40,
        width: '100%',
        maxWidth: 800,
    },
    modalTouchableBg: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
    modalTextContainer: {
        justifyContent: "flex-start",
        alignSelf: 'stretch',
        alignItems: 'flex-start'
    },
    modalTitle: {
        overflow: "hidden"
    },
    modalSubtitle: {},
    modalText: {},
    modalCloseButton: {},
    modalHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 16,
    }
});

/**
 * ShortNews aka Kurznachrichten is the news list without icons, just with link arrows
 * In globaladmin it's under "News/Blog -> notice"
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ShortNews(props) {
    let {navigation, bottomNavigation, drawerNavigation, breakpoint, route} = props;
    const {notificationType, id} = route?.params ?? {};
    // console.log("ShortNews", notificationType, id)
    const [modalVisible, setModalVisible] = useState(null);

    const locations = useValidLocations()
    const params = {};
    if (locations) params.locations = locations;
    const {data, error, isLoading, refetch} = endpoints.getShortNews.useQuery(params);
    //if we're comming from notification - we load notification data
    const {
        data: articleData,
        error: articleError,
        isLoading: isLoadingArticle,
        refetch: refetchArticle
    } = endpoints.getShortNewsArticle.useQuery(id, {skip: !id && notificationType?.toString() === NOTIFICATION_TYPE_NEWS_NEW_NOTICE.toString()});


    // isDevelopment && console.log(articleData, id, !id, notificationType?.toString(), NOTIFICATION_TYPE_NEWS_NEW_NOTICE.toString());
    const {cache, theme} = useSelector(state => ({theme: state.theme, cache: state.cache}));
    const cachedNews = cache?.cacheTags.find(item => item.type === CONTENT_TYPE.SHORTNEWS);

    //if we're coming from notification - we should get a short news index to be displayed
    const shouldFindIndex = notificationType?.toString() === NOTIFICATION_TYPE_NEWS_NEW_NOTICE.toString() && !!id && !isLoadingArticle;
    const indexInShortNews = shouldFindIndex
        ? data?.entries?.findIndex(n => {
        return n.id.toString() === id?.toString()
    }) ?? -1
        : -1;

    /**
     * after navigating home, the cache is reset, so we refetch
     */
    useEffect(() => {
        if (!isLoading && !cachedNews) {
            // isDevelopment && console.log("ShortNews useEffect", {data, error, isLoading}, cachedNews);
            refetch();
        }
    }, [cachedNews]);

    /**
     * making a notification pop up visible of the short news
     * going to ?id=14&notificationType=7 would make id and notificationType as string,
     * while coming from notification would render those as numbers. So to make life easier - both convert to string.
     * ideally all of notification logic should have been documented initially.
     */
    useEffect(() => {
        // console.log({notificationType, id}, notificationType.toString() === NOTIFICATION_TYPE_NEWS_NEW_NOTICE.toString(), !!id)

        //looking for notifications in the first three loaded notifications
        if (shouldFindIndex) {
            //if we found it - we display it
            if (indexInShortNews !== -1) {
                setModalVisible(indexInShortNews);
                // console.log("ShortNews useEffect, found idx", indexInShortNews);
            } else {
                //if we didn't find it - we have it preloaded and separately added as 4th option
                setModalVisible("extraNews");
            }
        }
    }, [data?.entries, isLoadingArticle, articleData])

    // console.log("ShortNews", articleData)

    const overwriteStyles = StyleSheet.create({
        verticalLine: {
            borderBottomWidth: StyleSheet.hairlineWidth,
        },
        shortNewsContainer: {
            borderTopColor: theme.colors.border,
        },
        cardContainer: {
            paddingHorizontal: theme.padding
        },
        date: {
            ...theme.text.bold,
        },
        title: {
            ...theme.text.paragraph,
        },
        modalPopup: {
            backgroundColor: theme.colors.primary
        },
        modalTitle: {
            ...theme.text.bold,
            color: theme.colors.text_light,
        },
        modalText: {
            ...theme.text.paragraph,
            color: theme.colors.text_light
        },
    });
    const htmlStyles = React.useMemo(
        () => ({
            ...styles.modalText,
            ...overwriteStyles.modalText
        }),
        [theme],
    );

    const generateShortNews = (news, idx) => {
        return <View
            key={idx}
            style={[styles.shortNewsContainer, overwriteStyles.shortNewsContainer, (idx === 0 && styles.shortNewsContainer_first)]}>
            <TouchableOpacity style={styles.shortNewsTouchable} onPress={() => setModalVisible(idx)}>
                <Modal
                    animationType="fade"
                    transparent={true}
                    visible={modalVisible === idx}
                    onRequestClose={() => setModalVisible(false)}
                >
                    <View style={styles.modalContainer}>
                        <TouchableOpacity style={styles.modalTouchableBg}
                                          onPress={() => setModalVisible(null)}/>
                        <View style={[styles.modalPopup, overwriteStyles.modalPopup]}>
                            <View style={[styles.modalHeader]}>
                                <Text
                                    style={[styles.modalTitle, overwriteStyles.modalTitle]}>{news?.title}</Text>
                                <TouchableOpacity
                                    style={styles.modalCloseButton}
                                    onPress={() => setModalVisible(null)}
                                >
                                    <FontAwesome5
                                        size={25}
                                        color={theme.colors.text_light}
                                        name="times"
                                    />
                                </TouchableOpacity>
                            </View>
                            <Text
                                style={[styles.modalText, overwriteStyles.modalText]}>{global.moment(news?.updated_at).format('dddd,  DD. MMMM YYYY')}</Text>
                            <View
                                style={[
                                    overwriteStyles.verticalLine,
                                    styles.verticalLine
                                ]}
                            />
                            <Text style={[styles.modalText, overwriteStyles.modalText]}>
                                <RenderHtml
                                    tagsStyles={{
                                        a: {
                                            color: "white",
                                            textDecoration: "underline",
                                            textDecorationColor: "white",
                                            fontStyle: "italic"
                                        }
                                    }}
                                    baseStyle={htmlStyles}
                                    source={{html: news?.description}}
                                    systemFonts={SystemFontKeys}
                                />
                            </Text>
                        </View>
                    </View>
                </Modal>
                <View style={[styles.textContainer, (breakpoint < 5 && styles.textContainer_col)]}>
                    <Text style={[styles.date, overwriteStyles.date]}>{
                        global.moment(news?.updated_at).format('DD.MM.YY')
                    }</Text>
                    <Text style={[styles.title, overwriteStyles.title]}>{news?.title}</Text>
                </View>
                <View style={styles.iconContainer}>
                    <FontAwesome5
                        color={theme.colors.primary}
                        size={30}
                        solid
                        name="arrow-right"
                    />
                </View>
            </TouchableOpacity>
        </View>;
    }

    return <View style={styles.container}>
        <Card title={"Kurznachrichten"}>
            {isLoading
                ? <LoadingIndicator/>
                : <View style={[styles.cardContainer, overwriteStyles.cardContainer]}>
                    {(data?.entries ?? []).map((news, idx) => generateShortNews(news, idx))}
                    {shouldFindIndex && indexInShortNews === -1 && generateShortNews(articleData, "extraNews")}
                </View>}
        </Card>
    </View>
};
