import {useEffect} from "react";

/**
 * update title from within any page
 * @param navigation navigation used to update the title
 * @param isLoading when loading gets false title is updated
 * @param title if title is defined it gets updated
 */
const useTitle = (navigation, isLoading = true, title) => {

    useEffect(() => {
        if (!isLoading && title) {
            navigation.setOptions({title});
        }
    }, [isLoading, title]);

}

export default useTitle;