import {Pressable, StyleSheet, Text} from 'react-native';
import {useSelector} from "react-redux";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";

const styles = StyleSheet.create({
    container: {
        width: "100%",
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingBottom: 16,
    },
    text: {},
    icon: {
        marginRight: 16,
        marginTop: 2
    }
});

function BackNavigationBanner(props) {
    const {navigation} = props;
    const theme = useSelector(state => state.theme);
    let {config, link, label, iconBefore, iconAfter, style, onPress} = props;

    const overwriteStyles = StyleSheet.create({
        container: {},
        text: {
            ...theme.typo.subHeadline,
            ...config?.options?.text.style,
        },
        icon: {}
    });

    return <Pressable
        onPress={(!!onPress ? onPress : () => navigation.goBack())}
        style={[styles.container, overwriteStyles.container]}>
        {iconBefore ? <FontAwesome5
            color={theme.colors.text_dark}
            style={[styles.icon, overwriteStyles.icon]}
            size={style?.iconBefore?.fontSize || 20}
            name={iconBefore}/> : null}
        <Text style={[styles.text, overwriteStyles.text]}>
            {label}
        </Text>
    </Pressable>
}

export default BackNavigationBanner
