import React, {useEffect} from "react";

export default function usePullToRefresh(props, refetchFunc) {
    //console.warn("usePullToRefresh", props.onRefresh.current)
    useEffect(() => {
        if (props.onRefresh && Array.isArray(props.onRefresh.current)) {
            if (Array.isArray(refetchFunc)) {
                props.onRefresh.current.push(...refetchFunc);
            } else {
                props.onRefresh.current.push(refetchFunc)
            }
        } else {
            console.warn("shouldn't happen: pull to refresh props not defined")
        }
    }, [])
}
