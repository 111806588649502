import * as React from 'react';
import {View, useWindowDimensions, Text, StyleSheet, TouchableOpacity} from 'react-native';
import { TabView as _TabView, SceneMap,SceneComponent } from 'react-native-tab-view';
import {ComponentResolver} from "../../constants/constants";
import {useSelector} from "react-redux";

export default function TabView(props = {}) {
    const { config } = props;
    const layout = useWindowDimensions();
    const [index, setIndex] = React.useState(0);
    const theme = useSelector(state => state.theme);
    const notifications = useSelector(state => state.notifications);
    const [routes] = React.useState(config.options?.tabs.map(item=>({
        title: item.title,
        key: item.component,
        navigation: props.navigation,
        isNotificationRoute: item.isNotificationRoute
    })) || []);
    const renderScene =  ({ route, jumpTo }) => {
        return  React.createElement(ComponentResolver()[route.key], {...props,jumpTo});
    };
    //SceneMap({components});
    const overwriteStyles = StyleSheet.create({
        tabContainer: {
            backgroundColor: theme.colors.newContent,
        },
        tabBarButton:{
            backgroundColor: theme.colors.newContent,
            color: theme.colors.primary
        },
        tabBarButtonActive: {
            backgroundColor: theme.colors.primary,
        },
        tabBarLabel: {
            fontFamily:"BebasNeue_400Regular",
            color: theme.colors.primary,
            fontSize: 23
        },
        tabBarLabelActive:{
            fontFamily:"BebasNeue_400Regular",
            color: theme.colors.text_light,
            fontSize: 23
        }
    });
    const _renderTabBar = (props) => {
        return (
            <View style={styles.tabBar}>
                {props.navigationState?.routes.map((route, i) => {
                    const tabStyle = i===index ? overwriteStyles.tabBarButtonActive : overwriteStyles.tabBarButton;
                    const isNotificationRoute = route.isNotificationRoute || false;
                    return (
                        <TouchableOpacity
                            key={i}
                            style={[styles.tabItem,tabStyle]}
                            onPress={() => setIndex(i)}>
                            <View>
                                {
                                    isNotificationRoute && !notifications?.seen &&  <View style={{
                                        zIndex: 999,
                                        backgroundColor: "red",
                                        height: 12,
                                        width: 12,
                                        position: "absolute",
                                        top: -12,
                                        right: -12,
                                        borderRadius: 20
                                    }}/>
                                }
                                <Text allowFontScaling={true} style={i===index ? overwriteStyles.tabBarLabelActive : overwriteStyles.tabBarLabel}>{route.title}</Text>
                            </View>
                        </TouchableOpacity>
                    );
                })}
            </View>
        );
    }
    return (
        <_TabView
            navigationState={{ index, routes }}
            renderScene={renderScene}
            onIndexChange={setIndex}
            initialLayout={{ width: layout.width }}
            renderTabBar={_renderTabBar}
            swipeEnabled={false} //has issues with "BENACHRICHTIGUNGEN" tab if it's enabled. The items in that tab are also swipeable
        />
    );
}
const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    tabBar: {
        flexDirection: 'row',
    },
    tabItem: {
        flex: 1,
        alignItems: 'center',
        padding: 16,
    },
});
