import {removeInvalidatedCacheTags} from "../state/cache";
import {CACHE_ID_NEW_CONTENT} from "../../../constants/numbers";
import {Platform} from "react-native";

/**
 * This file defines utility functions for queries / working with API endpoints
 */


/**
 * variable for our Redux-store
 */
let store
/**
 * Used to prevent require cycle, will set redux-store object
 * @param store
 */
export const injectStore = _store => {
    store = _store
}
/**
 * Variable for rtk-query API object.
 */
let globalAdminApi
/**
 * Used to prevent require cycle, will set API object
 * @param _globalAdminApi
 */
export const injectApiStoreIntoHelper = _globalAdminApi => {
    globalAdminApi = _globalAdminApi
}
/**
 * filters soft deleted data from the api endpoint
 * @type {string[]}
 */
export const QUERY_PARAM_NOT_DELETED = ["deletedAt", "isNull", ""];

/**
 * filter some queries based platform
 * @type {string[]}
 */
export const QUERY_PARAM_PLATFORM = ["platforms", "like", "%" + (Platform.OS === "web" ? "web" : "app") + "%"]

/**
 * Utility function that builds "where" param for queries like the globalAdmin API expects.
 * Only implemented AND because i didn't need or yet.
 * @param arg
 * @returns {string}
 */
export function buildWhereParams(arg) {
    const i = 0;
    let result = {};
    //only AND supported atm
    if (typeof arg === "object" && arg["and"] && Array.isArray(arg["and"])) {
        result[i] = {
            and: {
                ...arg.and.map((andItem) => {
                    return andItem
                })
            }
        };
    }
    return `where=${JSON.stringify(result)}`
}

/**
 * returns the TagType for given content group based on ids received from the backend.
 *
 * @param type Content Type
 * @param result result of the query function used
 * @returns Array with {type: contentType, id: id from resultSet}
 */
export function provideTagsWithTypeAndId(type, result) {
    let _result = null;
    if (Array.isArray(result)) _result = result;
    else if (Array.isArray(result?.entries)) _result = result?.entries;
    else {
        return [];
    }
    if (Array.isArray(_result)) {
        const tags = _result.map(resultItem => {
            return {
                type: type,
                id: resultItem.id
            }
        })
        //push custom tag "new" into the array - used to invalidate the cache for this endpoint when new content is added.
        tags.push({
            type: type,
            id: CACHE_ID_NEW_CONTENT
        })
        return tags
    } else if (typeof _result === "function") {
        console.warn("provideTagsWithTypeAndId got a function: ", type, _result());
        return [];
    } else {
        console.warn("provideTagsWithTypeAndId EMPTY: ", type, result?.entries);
        return [];
    }
}

/**
 * Waits for a query to finish and dispatches a function that removes our saved cache ID's from the 'cache' slice.
 * @param arg
 * @param dispatch
 * @param queryFulfilled
 * @param type content type from enum
 * @returns {Promise<void>}
 */
export async function onQueryFinishedRemoveInvalidatedTagsForType(arg, {dispatch, queryFulfilled}, type) {
    try {
        //remove the current invalidatedTags for this type, since we are re-fetching/ getting data.
        const result = await queryFulfilled;
        dispatch(removeInvalidatedCacheTags(type));
    } catch (e) {
        console.error("onQueryFinishedRemoveInvalidatedTagsForType encountered error: ", e, arg)
    }
}

/**
 *
 * @param type
 * @param id
 * @param dispatch
 * @param queryFulfilled
 * @returns {Promise<void>}
 */
export async function invalidateTagsFromSyncResponse(type, id, {dispatch, queryFulfilled}) {
    try {
        const {data} = await queryFulfilled;
        let updatedTags = data.map(updatedId => {
            return {
                type: type,
                id: updatedId
            }
        });
        dispatch(globalAdminApi.util.invalidateTags(updatedTags))
    } catch (err) {
        console.error(err);
    }
}

/**
 * will return the string used to query modified/added dataIds for specific contentType.
 * route will return the modified/added dataIds since last Sync time.
 * @param type content Type
 * @param url url to get modified/new dataIds for contentType
 * @returns {string} api route
 */
export function querySyncForType(type, url) {
    const lastSyncedTimeStamp = store.getState().cache.cacheTags?.find(item => item.type === type)?.lastSynced
    let lastSyncDateStr = null;
    if (!lastSyncedTimeStamp) {
        lastSyncDateStr = global.moment().format()//new Date("2023-02-07T18:00:00+01:00")
    } else {
        lastSyncDateStr = global.moment(lastSyncedTimeStamp).format();
    }
    return `${url}?syncedAt=${lastSyncDateStr}` + (lastSyncDateStr ? "" : "&firstSync");
}
