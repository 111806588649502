import {ScrollView, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {useSelector} from "react-redux";
import {Link} from "@react-navigation/native";
import React, {useEffect, useRef, useState} from "react";
import WebIcon from "../../components/Icon/WebIcon";


const style = StyleSheet.create({
    container: {
        maxWidth: 320,
        paddingHorizontal: 32,
        overflow: 'hidden',
    },
    container_bpMargin: {
        paddingHorizontal: 12,
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingVertical: 16,
        borderBottomWidth: 1,
    },
    body: {
        flexGrow: 1,
        flexShrink: 1,
    },
    scroll: {
        height: '100%',
    },
    footer: {
        paddingVertical: 16,
        borderTopWidth: 1,
    },
    headerText: {
        marginRight: 16,
        fontSize: 15
    },
    collapseIcon: {
        width: 48,
        height: 48,
        justifyContent: 'center',
        alignItems: 'center',
    },
    collapseIcon_disabled: {
        opacity: .4
    },
    linkGroup: {
        paddingVertical: 24
    },
    link: {
        paddingVertical: 8,
    },
    linkInner: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    linkText: {
        marginLeft: 12,
        fontSize: 15
    },
    linkIcon: {
        width: 32,
        height: 32,
        margin: 8,
        justifyContent: 'center',
        alignItems: 'center',
    },
});

const WebDrawer = (props) => {
    const {config, setDrawerWidth, breakpoint} = props;
    const settingsRoute = config?.routes?.find((route) => route.name === 'Einstellungen');
    const ref = useRef(null);
    const [routeGroups, setRouteGroups] = useState([]);
    const [collapsed, setCollapsed] = useState(false);
    const collapse = () => setCollapsed(!collapsed);
    const bp_forceCollapse = breakpoint < 3;
    const bp_margin = breakpoint < 2;

    useEffect(() => {
        if (bp_forceCollapse && !collapsed) setCollapsed(true);
    }, [bp_forceCollapse]);

    const theme = useSelector(state => state.theme);
    const overwriteStyle = StyleSheet.create({
        container: {
            backgroundColor: theme.colors.primary,
        }
    });

    // width
    useEffect(() => {
        const el = ref?.current;
        if (!el) return;
        const ro = new ResizeObserver(() => {
            setDrawerWidth(el.getBoundingClientRect()?.width ?? 0);
        });
        ro.observe(el);
        return () => ro.disconnect();
    }, []);

    // sort routes by drawerGroup
    useEffect(() => {
        const groupCount = config.routes.reduce((a, b) => Math.max(a, b.drawerGroup), 0);
        let _routeGroups = [];
        for (let i = 0; i <= groupCount; i++) {
            let routeGroup = config.routes.filter((route) => (route.showInDrawer && route.drawerGroup === i));
            if (routeGroup.length >= 0) _routeGroups.push(routeGroup);
        }
        setRouteGroups(_routeGroups);
    }, []);

    return <View ref={ref}
                 style={[style.container, (bp_margin && style.container_bpMargin), overwriteStyle.container, (collapsed ? {width: null} : null)]}>
        <View style={[style.header, {borderBottomColor: theme.colors.primary_light}]}>
            {!collapsed && <Link to={{screen: 'Home'}}>
                <Text style={[style.headerText, {color: theme.colors.text_light}]}>KiKxxl Mitarbeiter*innenportal</Text>
            </Link>}
            <TouchableOpacity
                disabled={bp_forceCollapse}
                onPress={collapse}>
                <View style={[style.collapseIcon, (bp_forceCollapse && style.collapseIcon_disabled)]}>
                    <WebIcon
                        icon={(collapsed ? 'chevron-circle-right' : 'chevron-circle-left')}
                        regular
                        size={24}
                        color={theme.colors.text_light}/>
                </View>
            </TouchableOpacity>
        </View>

        <View style={style.body}>
            <ScrollView style={style.scroll} showsVerticalScrollIndicator={false}>
                {routeGroups.map((group, i) => (
                    <View
                        key={i}
                        style={[style.linkGroup,
                            (i !== 0 && {borderTopWidth: 1, borderTopColor: 'rgba(255, 255, 255, .3)'})]}>
                        {group.map((route, j) => (
                            <Link to={{screen: route.name}} key={j}>
                                <View style={style.link}>
                                    <View style={style.linkInner}>
                                        <View style={style.linkIcon}>
                                            {route.icon ? <WebIcon
                                                icon={route.icon}
                                                color={theme.colors.text_light}
                                                size={24}/> : null}
                                        </View>
                                        {!collapsed && <Text
                                            style={[style.linkText, {color: theme.colors.text_light}]}>{route.title}</Text>}
                                    </View>
                                </View>
                            </Link>
                        ))}
                    </View>
                ))}
            </ScrollView>
        </View>

        {settingsRoute ? <View style={[style.footer, {borderTopColor: theme.colors.primary_light}]}>
            <Link to={{screen: 'Einstellungen'}}>
                <View style={style.linkInner}>
                    <View style={style.linkIcon}>
                        {settingsRoute?.icon ? <WebIcon
                            icon={settingsRoute.icon}
                            color={theme.colors.text_light}
                            size={24}/> : null}
                    </View>
                    {!collapsed && <Text
                        style={[style.linkText, {color: theme.colors.text_light}]}>Einstellungen</Text>}
                </View>
            </Link>
        </View> : null}
    </View>;
};

export default WebDrawer;
