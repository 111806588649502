import {Image} from "react-native";
import React from "react";

/**
 * Render Default Image for specific gender
 * @param gender
 * @param styles
 * @returns {JSX.Element}
 */
export function getDefaultImage(gender, styles) {
    return <Image style={styles ?? {}} source={require("../../assets/images/avatar_default.jpg")}/>
    // todo: remove img_avatar* files in future once all feels satisfiedwith avatar_default.jpg
    // if (gender === "d") {
    //     return <Image style={styles ?? {}} source={require("../../assets/images/img_avatar_m.png")}/>
    // } else if (gender === "f") {
    //     return <Image style={styles ?? {}} source={require("../../assets/images/img_avatar_f.png")}/>
    // } else if (gender === "m") {
    //     return <Image style={styles ?? {}} source={require("../../assets/images/img_avatar_m.png")}/>
    // } else {
    //     return <Image style={styles ?? {}} source={require("../../assets/images/img_avatar_m.png")}/>
    // }
}
