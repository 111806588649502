import {StyleSheet, Text, View} from "react-native";
import React from "react";

/**
 * To replace bullet point list
 * @param children
 * @returns {JSX.Element}
 * @constructor
 */
const ViewBullet = ({children, listContainer = {}, theDot = {}, childrenStyle = {}}) => {
    const styles = StyleSheet.create({
        listContainer: {
            display: "flex",
            flexDirection: "row",
            marginBottom: 15,
            alignItems: "flex-start",
            ...listContainer
        },
        theDot: {
            width: 6,
            height: 6,
            borderRadius: 50,
            backgroundColor: "#000000",
            // left: 5,
            // top: 7,
            ...theDot,
        },
        childrenStyle: {
            marginLeft: 15,
            ...childrenStyle
        },
    });

    return <View style={styles.listContainer}>
        <View style={styles.theDot}/>
        <Text style={styles.childrenStyle}>{children}</Text>
    </View>
}

export default ViewBullet