import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import React from "react";
import {useSelector} from "react-redux";

const styles = StyleSheet.create({
    loadMoreContainer: {
        flex: 1,
        alignItems: "center",
        paddingBottom: 24,
        paddingTop: 24,
    },
    loadMoreButton: {
        height: 40,
        width: 140,
        alignItems: "center",
        justifyContent: "center"
    },
    loadMoreButtonDisabled: {
        height: 40,
        width: 140,
        backgroundColor: "grey",
        alignItems: "center",
        justifyContent: "center"
    }
})
export default function LoadMoreButton(props) {
    const theme = useSelector(state => state.theme);
    const {disabled, onPress, style} = props;
    if(disabled) return null;
    return <View style={[styles.loadMoreContainer]}>
        <TouchableOpacity
            disabled={disabled}
            style={disabled ? [styles.loadMoreButtonDisabled, styles.button] : [styles.loadMoreButton, {backgroundColor: theme.colors.primary,}, styles.button]}
            onPress={() => onPress()}>
            <Text style={{...theme.text.header, fontSize: 25}}>
                Mehr laden
            </Text>
        </TouchableOpacity>
    </View>
}
