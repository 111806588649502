import React, {useEffect, useState} from "react";
import {Pressable, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {useSelector} from "react-redux";
import {FontAwesome5} from "@expo/vector-icons";
import {
    useRegisterToCalendarEventMutation,
    useUnregisterToCalendarEventMutation
} from "../../../app/services/api/globalAdminAPI";
import {renderToastFromQueryResult} from "../../../utils/renderToast";
import ModalContainer from "../../Modals/ModalContainer";

const style = StyleSheet.create({
    dayContainer: {
        flex: 1,
        justifyContent: 'center',
        alignSelf: 'stretch',
        alignItems: 'stretch',
    },
    dateContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
    },
    personalTitle: {
        fontFamily: "OpenSans_400Regular",
        fontSize: 16,
        textAlign: 'center',
    },
    eventContainer: {
        // flex: 1,
        height: 40,
        justifyContent: 'center',
    },
    eventPressable: {
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        width: "100%",
        borderRadius: 20,
        padding: 10
    },
    eventText: {
        fontFamily: "OpenSans_700Bold",
        overflow: "hidden",
        color: '#fff',
        fontSize: 13,
    },
    modalTextContainer: {
        justifyContent: "flex-start",
        alignSelf: 'stretch',
        alignItems: 'flex-start'
    },
    modalTitle: {
        fontFamily: "OpenSans_700Bold",
        fontSize: 16,
        textAlign: 'left',
        marginBottom: 8,
    },
    modalDate: {
        fontFamily: "OpenSans_300Light_Italic",
        fontSize: 13,
        marginBottom: 16,
        textAlign: "left"
    },
    modalText: {
        fontFamily: "OpenSans_300Light",
        fontSize: 13,
        marginBottom: 16,
        textAlign: "left"
    },
    buttonRegister: {
        borderWidth: 1,
        borderRadius: 100,
        paddingHorizontal: 20,
        paddingVertical: 12,
    },
    buttonRegisterText: {
        color: "#fff",
    },
    buttonClose: {
        position: "absolute",
        right: 20,
        top: 18,
    },
});

const CalendarEventModal = (props) => {
    const {eventDay, eventText, pressDisabled, dateState, date, visible, setVisibleEvent} = props;
    const theme = useSelector(state => state.theme);
    const [modalVisible, setModalVisible] = useState(visible);
    const [registerToCalendarEvent, {register_isLoading, register_error}] = useRegisterToCalendarEventMutation();
    const [unregisterToCalendarEvent, {
        unregister_isLoading,
        unregister_error
    }] = useUnregisterToCalendarEventMutation();

        // console.log("CalendarEventModal", {visible, modalVisible, pressDisabled, date})

    // useEffect(()=>{
    //     if (visible !== modalVisible)
    //         setModalVisible(visible);
    // }, [visible])

    const overwriteStyle = StyleSheet.create({
        buttonRegister: {
            backgroundColor: theme.colors.primary,
            borderColor: theme.colors.primary,
        },
        modalTitle: {
            color: theme.colors.text,
        },
        modalText: {
            color: theme.colors.text,
        },
        modalDate: {
            color: theme.colors.text,
        },
        dateText: {
            color: theme.colors.text
        },
        eventPressable: {
            backgroundColor: theme.colors.primary,
        },
        overlay: {}
    });
    //console.log(Platform.OS,Constants.statusBarHeight)
    return (
        <View style={style.dayContainer}>
            <View style={style.dateContainer}>
                <Text style={[style.dateText, overwriteStyle.dateText, (dateState === "disabled" && {opacity: .3})]}>
                    {eventDay}
                </Text>
            </View>
            <View style={style.eventContainer}>
                {Array.isArray(date) && date.map((item, idx) => (
                    <Pressable key={idx} style={[style.eventPressable, overwriteStyle.eventPressable]}
                               onPress={pressDisabled === true
                                   ? undefined
                                   : () => {
                                       setModalVisible(item.id)
                                   }}>
                        <View style={{flexDirection: "row", alignItems: "center"}}>
                            <Text style={style.eventText}>
                                {item.short_name ?? item.title}
                            </Text>
                            {
                                item.isRegistered &&
                                <FontAwesome5
                                    color={theme.colors.background_light}
                                    style={{marginLeft: 5}}
                                    size={10}
                                    regular
                                    name="check"
                                />
                            }
                        </View>
                        {/* modal */}
                        <ModalContainer
                            setEventIdVisible={setVisibleEvent}
                            visible={modalVisible?.toString() === item.id.toString()}
                            setVisible={setModalVisible}>
                            <View style={style.modalTextContainer}>
                                <Text style={[style.modalTitle, overwriteStyle.modalTitle]}>{item.title}</Text>
                                <Text style={[style.modalDate, overwriteStyle.modalDate]}>
                                    {item.eventDurationString}</Text>
                                <Text style={style.modalText}>{item.description}</Text>
                                <TouchableOpacity
                                    style={[style.buttonRegister, overwriteStyle.buttonRegister]}
                                    onPress={async () => {
                                        const result = item.isRegistered
                                            ? await unregisterToCalendarEvent({eventId: item.id})
                                            : await registerToCalendarEvent({eventId: item.id});
                                        renderToastFromQueryResult(result, `Erfolgreich ${item.isRegistered ? "ab" : "an"}gemeldet`);
                                        setModalVisible(false)
                                        setVisibleEvent(null);
                                    }}>
                                    <Text style={[style.buttonRegisterText]}>
                                        {item.isRegistered ? "Ab" : "An"}melden
                                    </Text>
                                </TouchableOpacity>
                            </View>

                        </ModalContainer>
                    </Pressable>
                ))}
            </View>
        </View>
    );
};
export default CalendarEventModal;
