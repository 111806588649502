import {Pressable, StyleSheet, Text, TextInput, View} from "react-native";
import ModalContainer from "../ModalContainer";
import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {Controller, useForm} from "react-hook-form";

const styles = StyleSheet.create({
    container: {
        width: '100%',
        alignItems: 'flex-start'
    },
    title: {
        marginBottom: 16,
    },
    inputGroup: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        marginTop: 8,
    },
    inputGroup_first: {
        marginTop: 0,
    },
    input: {
        flex: 2,
        borderWidth: 1,
        padding: 8,
    },
    input_multiline: {
        height: 160
    },
    label: {
        width: 140,
        marginBottom: 4
    },
    createBtn: {
        paddingHorizontal: 20,
        paddingVertical: 12,
        marginTop: 24,
        borderRadius: 100,
    },
    errText: {
        marginTop: 4,
    }
});

export default function EditProfileModal(props) {
    const {userObj, visible, setVisible, updateProfile} = props;
    const defaultValues = {
        city: userObj.city ?? '',
        hobbies: userObj.hobbies ?? '',
        music: userObj.music ?? '',
        movies: userObj.movies ?? '',
        quote: userObj.quote ?? '',
        about_me: userObj.about_me ?? ''
    };

    const {getValues, control, handleSubmit, formState: {errors}, reset} = useForm({
        //defaultValues was before, but navigating from another users form would persist the values, but values seems buggy so we useMemo in profile
        defaultValues,
        // values: defaultValues
    });

    // console.log("EditProfileModal", getValues())
    useEffect(() => {
        reset(defaultValues);
    }, [userObj])

    const onSubmit = handleSubmit((data) => {
        updateProfile({userId: userObj.id, formData: data});
        setVisible(false);
    });

    const theme = useSelector(state => state.theme);

    const overwriteStyles = StyleSheet.create({
        title: {
            ...theme.typo.p_md_bold,
            color: theme.typo.text_dark
        },
        input: {
            borderColor: theme.colors.border
        },
        label: {
            ...theme.typo.p_sm,
        },
        createBtn: {
            backgroundColor: theme.colors.primary,
        },
        createBtnText: {
            ...theme.typo.p_sm,
            color: theme.colors.text_light,
        },
        errText: {
            ...theme.typo.p_sm,
            color: theme.colors.error
        }
    });

    const InputGroup = ({label, propName, numberOfLines, customInput}) => {
        return <Controller
            control={control}
            name={propName}
            // defaultValue={defaultValues?.[propName] ?? ''}
            render={({field: {onChange, onBlur, value}}) => (
                <View style={[styles.inputGroup]}>
                    <Text style={[styles.label, overwriteStyles.label]}>{label}</Text>
                    {customInput
                        ? customInput({value, onChange})
                        : <TextInput
                            defaultValue={value}
                            onChangeText={onChange}
                            style={[styles.input, overwriteStyles.input]}
                            multiline={!!numberOfLines}
                            numberOfLines={numberOfLines}
                        />}
                </View>
            )}
        />;
    };

    return <ModalContainer
        visible={visible}
        setVisible={setVisible}
    >
        <View style={styles.container}>
            <Text style={[styles.title, overwriteStyles.title]}>Profil bearbeiten</Text>
            <InputGroup label={'Wohnort'} propName={'city'} defaultValues={defaultValues}/>
            <InputGroup label={'Interessen'} propName={'hobbies'}/>
            <InputGroup label={'Musik'} propName={'music'}/>
            <InputGroup label={'Filme'} propName={'movies'}/>
            <InputGroup label={'Zitate'} propName={'quote'}/>
            <InputGroup label={'Über mich'} propName={'about_me'} numberOfLines={4}/>
            <Pressable
                style={[styles.createBtn, overwriteStyles.createBtn]}
                onPress={onSubmit}>
                <Text style={overwriteStyles.createBtnText}>Speichern</Text>
            </Pressable>
        </View>
    </ModalContainer>;
}
