import {Platform, StyleSheet, Text, TouchableOpacity, useWindowDimensions, View} from "react-native";
import {useDispatch, useSelector} from "react-redux";
import {globalAdminApi} from "../../app/services/api/globalAdminAPI";
import {removeInvalidatedCacheTags} from "../../app/services/state/cache";
import {CACHE_ID_NEW_CONTENT, HEADERHEIGHT} from "../../constants/numbers";
import useNewContentNotification from "./useNewContentNotification";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";


export default function NewContentAvailableNotification(props = {}) {
    const {types = []} = props;
    const {cache, theme, api} = useSelector(state => state);
    // const cache = useSelector(state => state.cache);
    // const theme = useSelector(state => state.theme);
    // const state = useSelector(state => state);
    const {visible} = useNewContentNotification({types})
    const {width, height} = useWindowDimensions();
    const dispatch = useDispatch();

    const touchableStyle = Platform.select({
        native: {width: width},
        default: {}
    })

    if (!Array.isArray(types)) throw new Error("Types must be array")

    return !visible
        ? null
        : <>
            <TouchableOpacity style={[styles.item, styles.itemOut, touchableStyle]} onPress={() => {
                types.forEach(typeSpec => {
                    let containsNew = false
                    const ids = cache?.cacheTags.find(item => item.type === typeSpec)?.ids
                    if (Array.isArray(ids) && ids.length > 0) {
                        const cacheTags = ids.map(id => {
                            return {
                                id: id,
                                type: typeSpec
                            }
                        })

                        ids.forEach(id => {
                            if (!Object.keys(api?.provided[typeSpec]).includes(id + "")) {
                                containsNew = true
                            }
                        })
                        if (containsNew === true) {
                            cacheTags.push({
                                type: typeSpec,
                                id: CACHE_ID_NEW_CONTENT
                            })
                        }
                        dispatch(globalAdminApi.util.invalidateTags(cacheTags));
                        dispatch(removeInvalidatedCacheTags(typeSpec));
                    }
                })
            }}>
                {Platform.select({

                    native: <View style={[styles.balloon, {
                        backgroundColor: theme.colors.newContent,
                        flexDirection: "row",
                        alignItems: "center",
                        height: 34,
                        justifyContent: "center"
                    }]}>
                        <View style={{marginRight: 6}}>
                            <FontAwesome5
                                color={theme.colors.newContentText}
                                size={16}
                                solid
                                name="redo"
                            />
                        </View>
                        <Text style={{
                            fontFamily: "OpenSans_400Regular",
                            fontSize: 15,
                            color: theme.colors.newContentText
                        }}>
                            Neue Inhalte verfügbar
                        </Text>
                    </View>,

                    default: <View style={[styles.balloon, {backgroundColor: theme.colors.newContent}]}>
                        <View style={{marginRight: 7}}>
                            <FontAwesome5
                                color={theme.colors.newContentText}
                                size={19}
                                solid
                                name="redo"
                            />
                        </View>
                        <View>
                            <Text style={{color: theme.colors.newContentText}}>
                                Neue Inhalte verfügbar!
                            </Text>
                            <Text style={{color: theme.colors.newContentText}}>
                                Hier klicken oder Seite neu laden
                            </Text>
                        </View>
                        <View
                            style={[
                                styles.arrowContainer,
                                styles.arrowRightContainer,
                            ]}
                        >
                        </View>
                    </View>
                })}
            </TouchableOpacity>
        </>
};

const styles = StyleSheet.create(Platform.select({
    native: {},
    web: {
        item: {
            position: "absolute",
            top: 20,
            left: 20,
            zIndex: 999,
            marginVertical: 7,
            flexDirection: 'row'
        },
        itemIn: {
            marginLeft: 20
        },
        itemOut: {
            // alignSelf: 'flex-end',
            // marginRight: 20
        },
        balloon: {
            maxWidth: 250,
            position: "fixed",
            right: 24 + 20,//scroollbarWidth,
            top: HEADERHEIGHT,
            paddingHorizontal: 10,
            paddingVertical: 14,
            flexDirection: "row",
            alignItems: "center",
            //borderRadius: 20,
            height: 61
        },
        arrowContainer: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: -1,
            flex: 1
        },
        arrowLeftContainer: {
            justifyContent: 'flex-end',
            alignItems: 'flex-start'
        },

        arrowRightContainer: {
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
        },

        arrowLeft: {
            left: -6,
        },

        arrowRight: {
            right: -6,
        }
    }
}))
