import {Platform} from "react-native";

/**
 * Text that is shown when no data exist and if data is filtered by location
 * @type {string}
 */
export const NO_DATA_MESSAGE_WITH_LOCATION = "Es sind momentan kein Datensatz für Ihren Standort verfügbar.";
/**
 * Text that is shown when no data exist
 * @type {string}
 */
export const NO_DATA_MESAGE = "Keine Daten gefunden";
/**
 * Default Error to show if nothing fits
 * @type {string}
 */
export const DEFAULT_ERROR = "Es ist ein Fehler beim Laden der Daten aufgetreten."
export const FETCH_ERROR = "FETCH_ERROR"
/**
 * Errortext that shows when a user is offline
 * @type {string}
 */
export const ERROR_TEXT_USER_OFFLINE = "Sie sind momentan nicht mit dem Internet verbunden."

/**
 * Types of Content the app tracks by requesting changed/added data ids with a defined intervall
 * @type {{BENEFIT: string, THREAD_COMMENTS_LIKES: string, INFORMATION: string, NEWS_COMMENTS_LIKES: string, NEWS: string, CALENDAREVENT: string, GALLERY: string, QUICKLINK: string, SHORTNEWS: string, THREAD: string, COMMENT: string, BIRTHDAYS: string, MENU_PREVIEW: string}}
 */
export const CONTENT_TYPE = {
    BENEFIT: "BENEFIT",
    CALENDAREVENT: 'CALENDAREVENT',
    COMMENT: "COMMENT",
    GALLERY: "GALLERY",
    INFORMATION: "INFORMATION",
    NEWS: 'NEWS',
    NEWS_COMMENTS_LIKES: "NEWS_COMMENTS_LIKES",
    QUICKLINK: "QUICKLINK",
    SHORTNEWS: 'SHORTNEWS',
    THREAD: "THREAD",
    THREAD_COMMENTS_LIKES: "THREAD_COMMENTS_LIKES",
    BIRTHDAYS: "BIRTHDAYS",
    MENU_PREVIEW: "MENU_PREVIEW",
};

/**
 * Chat bot url
 * previous ones:
 * initial: https://main-botconsole.production.e-bot7.de/embed/6267feb3eb1d98a8eddd4b71/
 * till 2023-04-25: https://chat.kikxxl.botario.com/?iid=63cf9e1b82e9ae0a48dd0269
 * @type {string}
 */
export const CHAT_BOT_URL = "https://kikcontent.kikxxl.de/toni/index_chat_full.htm";

export const USERNAME_LABEL_TAG = "KiKxxl E-Mail";

/**
 * Our loaded configuration versions so far
 * @type {{NATIVE_BEFORE_LOGIN_DEFAULT: string, NATIVE_AFTER_LOGIN_DEFAULT: string, WEB: string}}
 */
export const CONFIGURATION_NAMES = {
    NATIVE_BEFORE_LOGIN_DEFAULT: "NATIVE_BEFORE_LOGIN_DEFAULT",
    NATIVE_AFTER_LOGIN_DEFAULT: "NATIVE_AFTER_LOGIN_DEFAULT",
    WEB_DEFAULT: "WEB_DEFAULT",
}

export const LINKS = {
    FACEBOOK: "https://www.facebook.com/KiKxxl",
    INSTAGRAM: "https://www.instagram.com/kikxxl/?hl=de",
}

/**
 * google maps api key. Used in android and ios only
 * https://medium.com/@samil.mehdiyev/integrating-google-maps-into-react-native-app-on-android-40c984c0e4f2
 * @type {string}
 */
export const GOOGLE_MAPS_API_KEY = Platform.select({
    "android": "AIzaSyAl5i-wxi-LN5tVOPGpLMDT46nu3ZHpz5U",
    "ios": "AIzaSyC5YyVRgnxBiUpK-rWnKBul0B--YWa7ki0",
});