import usePullToRefresh from "../../utils/usePullToRefresh";
import {useSelector} from "react-redux";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import {ScrollView, StyleSheet, useWindowDimensions, View} from "react-native";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import RenderHtml from "react-native-render-html";
import {SystemFontKeys, SystemFonts} from "../../constants/SystemFonts";
import React from "react";
import NoDataText from "../Text/NoDataText";
import ErrorText from "../Error/ErrorText";
import {customHTMLElementModels} from "../../utils/fontElementModel";
import {ParagraphRenderer} from "../../utils/customHtmlRenderers";

export default function PublicText(props) {
    const {apiCall = "getImprint", viewStyle = {}, tagsStyles = {}} = props;
    const theme = useSelector(state => state.theme);
    const {width} = useWindowDimensions();
    const {data, error, isLoading, refetch} = endpoints?.[apiCall].useQuery();
    usePullToRefresh(props, refetch);
    if (isLoading) return <LoadingIndicator/>
    if (error) return <ErrorText error={error}/>

    // console.log("PublicText", {apiCall, data})

    return <ScrollView>
        <View style={[{padding: theme.wrapperPadding}, viewStyle]}>
            {
                data?.description ? <RenderHtml
                    baseStyle={style.text}
                    tagsStyles={{
                        a: {
                            color: theme.colors.primary,
                            textDecoration: "underline",
                            textDecorationColor: theme.colors.primary
                        },
                        ...tagsStyles
                    }}
                    contentWidth={width}
                    source={{
                        html: data?.description
                    }}
                    systemFonts={SystemFontKeys}
                    customHTMLElementModels={customHTMLElementModels}//to handle <font> tag
                    //for limiting our paragraph to 3 lines with ellipsis
                    // renderers={{
                    //     p: ParagraphRenderer
                    // }}
                /> : <NoDataText/>
            }
        </View>
    </ScrollView>
}
const style = StyleSheet.create({
    title: {
        fontFamily: "OpenSans_700Bold",
        fontSize: 16,
    },
    text: {
        fontFamily: "OpenSans_300Light",
        fontSize: 15,
    },
});
