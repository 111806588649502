import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {useSelector} from "react-redux";

export default function InternalLink(props) {
    const {navigation} = props;
    const theme = useSelector(state => state.theme);
    const {routeTo = "Home", label = "HelloWorld", routeParams} = props.config?.options;
    return <View>
        <TouchableOpacity onPress={() => {
            navigation ? navigation.navigate(routeTo, routeParams) : global.drawerNavigation.navigate(routeTo, routeParams)
        }}>
            <Text style={[theme.text.link, styles.link]}>{label}</Text>
        </TouchableOpacity>
    </View>
}
const styles = StyleSheet.create({})
