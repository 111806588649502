import React, {useRef, useState} from "react";
import {
    ActivityIndicator,
    Keyboard, KeyboardAvoidingView,
    Linking, Platform,
    StyleSheet,
    Text,
    TextInput,
    TouchableWithoutFeedback,
    useWindowDimensions,
    View
} from "react-native";
import {baseUrl, useLoginMutation, useUpdateExpoTokenMutation} from '../../app/services/api/globalAdminAPI'
import {useDispatch, useSelector} from "react-redux";
import {registerForPushNotificationsAsync} from "../Notifications/NotificationHandler";
import {setExpoToken, setIsFirstLogin} from "../../app/services/state/auth";
import Button from "../Button/Button";
import {useKeyboard} from "@react-native-community/hooks";
import {HEADERHEIGHT} from "../../constants/numbers";
import {USERNAME_LABEL_TAG} from "../../constants/strings";
import {isDevelopment} from "../../constants/booleanValues";
import getUuid from "../../utils/deviceTools";

export default function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const {keyboardShown, keyboardHeight} = useKeyboard()
    const {height, width} = useWindowDimensions()
    // const {keyboardShown, keyboardHeight} = useKeyboard()

    const containerHeight = height - 50;//
    const [login, {isLoading}] = useLoginMutation();
    const [updateExpoToken, {isLoading: updateTokenIsLoading}] = useUpdateExpoTokenMutation();
    const dispatch = useDispatch()
    const passwordInput = useRef(null)
    const [errorState, setErrorState] = useState(null);
    const theme = useSelector(state => state.theme);
    const factor = ((height / 812) > 1) ? 1 : (height / 812);

    // const distanceFactor = useMemo(() => {
    //     const myHeight = keyboardShown ? height - keyboardHeight : height;
    //     const factor = ((myHeight / 812) > 1) ? 1 : (myHeight / 812);
    //     console.log({height, keyboardHeight, myHeight, factor})
    //     return factor;
    // }, [height, keyboardShown, keyboardHeight]);

    const styles = StyleSheet.create({
        mainContainer: {
            backgroundColor: "white",
            height: "100%",
            display: "flex",
            justifyContent: "space-evenly"
        },
        descriptionText: {
            fontSize: 16 * factor,
            textAlign: "center",
            fontFamily: "OpenSans_300Light",
            color: theme.colors.primary,
            paddingTop: 19 * factor, //distanceFactor,
            maxWidth: 400
        },
        container: {
            textAlign: "center",
            // flex: 1,
            alignItems: "center",
            backgroundColor: "#fff",
            justifyContent: "space-evenly",
            maxHeight: 800 * factor,

        },
        inputContainer: {
            // flex: 0.8,
            width: width,
            // backgroundColor: "white", borderWidth: 3, borderColor: "magenta",
            justifyContent: "center",

        },
        headerContainer: {
            // flex: 0.5,
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: 10,
            // backgroundColor: "white", borderWidth: 3, borderColor: "blue",
        },
        buttonContainer: {
            // flex: 1,
            paddingTop: 10,
            paddingBottom: 30,
            width: width,
            alignItems: "center",
            justifyContent: "center",
            // backgroundColor: "white", borderWidth: 3, borderColor: "green",

        },
        infoTextHeader: {
            fontFamily: "BebasNeue_400Regular",
            color: theme.colors.primary,
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: "center",
            fontSize: 33 * factor,
            paddingTop: 10,
        },
        infoText: {
            fontFamily: "OpenSans_300Light",
            color: theme.colors.text,
            fontSize: 22 * factor,
            textAlign: "center",
            justifyContent: 'center',
            alignItems: 'center',
            paddingTop: 35 * factor, //distanceFactor,,
            paddingBottom: 35 * factor, //distanceFactor,
        },
        loginErrorText: {
            alignItems: "center",
            fontFamily: "OpenSans_700Bold",
            fontSize: 18 * factor,
        },
        image: {
            height: 200,
            width: 200,
        },
        inputView: {
            paddingBottom: keyboardShown ? 10 : 30,
            alignItems: "center"
        },
        TextInput: {
            borderBottomWidth: 1,
            maxWidth: 400,
            width: "74%",
            textAlign: "center",
            height: 30 * factor,
        },

        forgot_button: {
            height: 30 * factor,
            marginBottom: 30 * factor, //distanceFactor,,
        },

        loginBtn: {
            width: "80%",
            borderRadius: 25 * factor,
            height: 50 * factor,

            alignItems: "center",
            justifyContent: "center",
            marginTop: 40 * factor,
            backgroundColor: "#0093D2",
        },
        loginText: {
            color: "#fff",
            fontFamily: "OpenSans_300Light",
            fontSize: 18 * factor,
        },

        forgotPasswordButton: {
            button: {
                borderRadius: 60 * factor,
                height: 60 * factor,
                width: "74%",
                maxWidth: 400,
                borderColor: theme.colors.primary,
                backgroundColor: "white",
                borderWidth: 1
            },
            text: {
                color: theme.colors.primary,
                fontFamily: "OpenSans_400Regular",
                fontSize: 18 * factor
            }
        },
        loginButton: {
            button: {
                borderRadius: 60 * factor,
                height: 60 * factor,
                maxWidth: 400,
                width: "74%",
                marginBottom: (keyboardShown ? 5 : 10) * factor, //distanceFactor,
            },
            text: {fontFamily: "OpenSans_400Regular", fontSize: 18 * factor, color: "white"}
        },
        inputDescription: {
            paddingBottom: (keyboardShown ? 5 : 10) * factor, //distanceFactor,
            fontFamily: "OpenSans_400Regular",
            color: theme.colors.text,
            fontSize: 18 * factor,
        }
    });
    const loginIntoApp = () => {
        login({username, password}).unwrap().then((res) => {
            // console.log("login response ", res);
            //geez! todo: make something less awful and more intuitive
            res?.logins >= 1 ? dispatch(setIsFirstLogin(false)) : dispatch(setIsFirstLogin(true));
            registerForPushNotificationsAsync().then(token => {
                getUuid().then(uniqueId => {
                    isDevelopment && console.log("got push token: ", token, ", uniqueId", uniqueId);
                    updateExpoToken({
                        userId: res.id,
                        token: token?.data?.replace("ExponentPushToken[", "")?.replace("]", ""),
                        device_id: uniqueId,
                    }).then(res => dispatch(setExpoToken(token))).catch(e => console.error(e));
                });
            }).catch(e => console.error("got error: ", e))
        }).catch(e => {
            setErrorState(e?.data?.message)
        })
    }
    if (isLoading === true) {
        return <View style={{
            flex: 1,
            backgroundColor: "#fff",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
        }}>
            <ActivityIndicator size="large" color={theme.colors.primary}/>
        </View>
    }
    // else
    return (
        <TouchableWithoutFeedback onPress={Keyboard.dismiss} accessible={false}>

            <KeyboardAvoidingView style={styles.mainContainer}
                                  behavior={Platform.OS === "ios" ? "padding" : "height"}
                                  contentContainerStyle={styles.mainContainer}
                                  keyboardVerticalOffset={Platform.select({
                                      ios: HEADERHEIGHT + 10,
                                      //     ? (HEADERHEIGHT + 30)
                                      //     : (HEADERHEIGHT + 30),
                                      android: HEADERHEIGHT,
                                      // android: (HEADERHEIGHT + 30),
                                      // default: 0,
                                  })}
                // contentContainerStyle={{flexGrow: 1}}
                // enableOnAndroid={true}
                // contentContainerStyle={[styles.container,{height: containerHeight,paddingLeft: theme.wrapperPadding,paddingRight: theme.wrapperPadding}]}
            >
                <View style={styles.headerContainer}>
                    <Text style={styles.infoTextHeader}>
                        LOGIN
                    </Text>
                    <Text style={styles.descriptionText}>
                        Um weitere Inhalte zu sehen,
                        melde dich bitte mit deinen
                        persönlichen Zugangsdaten an.
                    </Text>
                </View>
                <View style={styles.inputContainer} onClick={() => {
                    Keyboard.dismiss()
                }}>
                    <View style={styles.inputView}>
                        <Text style={styles.inputDescription}>{USERNAME_LABEL_TAG}:</Text>
                        <TextInput
                            textContentType={"username"}
                            autoCapitalize={'none'}
                            autoCorrect={false}
                            style={styles.TextInput}
                            value={username}
                            placeholderTextColor="#003f5c"
                            onChangeText={(email) => setUsername(email)}
                            returnKeyType="next"
                            onSubmitEditing={() => passwordInput.current.focus()}
                        />
                    </View>
                    <View style={{alignItems: "center"}}>
                        <Text style={styles.inputDescription}>Passwort:</Text>
                        <TextInput
                            textContentType={"password"}
                            autoCapitalize={'none'}
                            autoCorrect={false}
                            ref={passwordInput}
                            style={styles.TextInput}
                            placeholderTextColor="#003f5c"
                            secureTextEntry={true}
                            value={password}
                            onChangeText={(password) => setPassword(password)}
                            returnKeyType="send"
                            onSubmitEditing={loginIntoApp}
                        />
                    </View>

                    {
                        errorState && <View style={{alignItems: "center"}}><Text
                            style={[styles.loginErrorText, {color: theme.colors.error}]}>{errorState}</Text></View>
                    }
                </View>
                <View style={styles.buttonContainer}>
                    <Button text={"Anmelden"}
                            onClick={loginIntoApp}
                            style={styles.loginButton}
                    />
                    <Button text={"Passwort vergessen"}
                            onClick={async () => {
                                const link = `${baseUrl}resetting/request`//'https://globaladmin-dev.de/resetting/request';
                                const supported = await Linking.canOpenURL(link);
                                if (supported) await Linking.openURL(link)
                                else console.error("not supported link", link)
                            }}
                            style={styles.forgotPasswordButton}
                    />
                </View>
            </KeyboardAvoidingView>
        </TouchableWithoutFeedback>

    );
}
