import {StyleSheet, Text, TouchableOpacity} from "react-native";
import React from "react";
import {useSelector} from "react-redux";

export default function Button(props) {
    const {text, onClick, style, disabled} = props;
    const theme = useSelector(state => state.theme);
    const overwriteStyles = StyleSheet.create({
        button: {
            backgroundColor: theme.colors.primary,
        },
        button_disabled: {
            backgroundColor: theme.colors.text_unimportant,
        },
        text: {
            color: theme.colors.text_light
        }
    });
    const buttonStyles = {
        ...styles.button,
        ...overwriteStyles.button,
        ...(disabled && overwriteStyles.button_disabled),
        ...style?.button
    }
    return <TouchableOpacity
        disabled={disabled}
        style={buttonStyles}
        onPress={onClick}
    >
        <Text style={[styles.text, overwriteStyles.text, style?.text]}>{text}</Text>
    </TouchableOpacity>
}

const styles = StyleSheet.create({
    button: {
        height: 40,
        borderRadius: 10,
        //margin: 5,
        alignItems: "center",
        justifyContent: "center"
    },
    text: {
        textAlign: "center",
        textAlignVertical: "center",
        color: '#fff',
        padding: 10
    }
});
