import {useSelector} from "react-redux";
import React, {createRef} from "react";
import Login from "../Login/Login";
import {SafeAreaView, StyleSheet, TouchableOpacity} from "react-native";
import {StatusBar} from "expo-status-bar";
import {NavigationContainer} from "@react-navigation/native";
import "../../utils/initIconLibrary"
import {enableFreeze, enableScreens} from 'react-native-screens';
import Toast from 'react-native-toast-message';
import {toastConfig} from "../../utils/renderToast";
import moment from 'moment';
import 'moment/locale/de';

// linking
import * as Linking from 'expo-linking';
import RelativeTimeFormat from "relative-time-format"
import de from "relative-time-format/locale/de"
import WebNavigator from "../../navigation/WebNavigator/WebNavigator";
import ShowBirthdayModal from "../Modals/ShowBirthdayModal/ShowBirthdayModal";
import {buildProfile} from "../../constants/booleanValues";

const prefix = Linking.createURL('/');

global.moment = moment;
global.moment.locale('de');

RelativeTimeFormat.addLocale(de);

/*
Optimization for react-navigation library
 */
enableScreens(true);
enableFreeze(true);

export const EntryPoint = (props) => {
    const configV2 = useSelector((state) => state.webConfig);

    TouchableOpacity.defaultProps = TouchableOpacity.defaultProps || {};
    TouchableOpacity.defaultProps.delayPressIn = 0;
    const auth = useSelector(state => state.auth);
    // console.log("Auth reload with state", auth)
    console.log("Build profile", buildProfile);
    const navigationRef = createRef()
    const nav = () => navigationRef.current;

    // configure special URL-handling here
    // https://reactnavigation.org/docs/configuring-links/
    let screens = {};
    configV2.routes.forEach((route) => {
        screens[route.name] = {path: route.path ?? route.name};
    });
    const linking = {
        prefixes: [], // unused in web
        config: {screens}
    };
    if (!auth.token) return <Login/>
    return <SafeAreaView style={styles.safeArea}>
        <StatusBar style="dark"/>
        <NavigationContainer ref={navigationRef} linking={linking}>
            <WebNavigator {...props} config={configV2}/>
        </NavigationContainer>
        <Toast config={toastConfig}/>
    </SafeAreaView>
}
const styles = StyleSheet.create({
    safeArea: {
        flex: 1,
        overflow: 'hidden',
    },
});
