import {useSelector} from "react-redux";
import {StyleSheet, Text, View} from "react-native";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import usePullToRefresh from "../../utils/usePullToRefresh";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import React from "react";
import ErrorText from "../Error/ErrorText";
import NewsArticlePreviewHeader from "../NewsArticlePreview/NewsArticlePreviewHeader";
import NewsArticlePreviewText from "src/components/NewsArticlePreview/NewsArticlePreviewText";
import NewsArticlePreviewReadMore from "../NewsArticlePreview/NewsArticlePreviewReadMore";
import NewsArticlePreviewDate from "../NewsArticlePreview/NewsArticlePreviewDate";

export default function Blog(props) {
    const {navigation} = props;

    const theme = useSelector(state => state.theme);
    const {data, error, isLoading, refetch, isFetching} = endpoints.getPublicNews.useQuery();
    usePullToRefresh(props, refetch)

    const style = StyleSheet.create({
        subHeadline: {
            ...theme.typo.subHeadline,
            fontSize: 16,
            margin: 16,
        },
        container: {
            marginBottom: 20
            // height: Platform.OS === 'ios' ? 250 : undefined,
        },
    });

    /**
     * When article header (image or title) or "read more" is clicked
     * we head to the article details
     */
    const onArticlePress = (news) => {
        navigation.navigate("BlogDetails", {news});
    }

    if (isLoading === true)
        return <LoadingIndicator/>;

    if (error)
        return <ErrorText error={error}/>

    if (!data)
        return <Text>Keine Daten vorhanden.</Text>

    // console.log("Blog", data)
    /**
     * builds news previews for each available news
     * @returns {*}
     */
    const getNews = () => {
        return data?.map((news, idx) => {
            return <View style={style.container} key={idx}>
                <NewsArticlePreviewHeader onArticlePress={() => onArticlePress(news)} uri={news.picture}
                                          isImageEnabled={true}
                                          title={news.title}/>
                <View>
                    <NewsArticlePreviewDate date={news?.datetime ?? news?.created_at}/>
                    <NewsArticlePreviewText id={idx} text={news.teaser}/>
                    <NewsArticlePreviewReadMore onArticlePress={() => onArticlePress(news)}/>
                </View>
            </View>
        });
    }

    return <View style={{flex: 1}}>
        {getNews()}
    </View>


}