import {Pressable, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import Button from "../Button/Button";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import UserImage from "../UserImage/UserImage";
import {isDevelopment} from "../../constants/booleanValues";
import {useFocusEffect} from "@react-navigation/native";

export default function Comment(props) {
    const {
        likeLoading,
        backgroundColor,
        onLike,
        likesAmount,
        user,
        id,
        highlighted = false,
        replies,
        comment,
        createdAt,
        likedByUser,
        canLike = true,
        canReply = true,
        triggerInputFocus,
        onReply,
        isReplyTo,
        setHighlightPosition,
        setTemporaryHighLightPosition,
        replyLoadCount = 0,

        /* web only */
        padding = undefined,
        /* other unused */
        // stackNavigation,
        // scrollViewRef,
        // replyMode = false
    } = props;
    const replyCount = replies?.length ?? 0;


    const theme = useSelector(state => state.theme);
    const containerRef = useRef(undefined);

    const [isFocused, setIsFocused] = useState(false)
    /**
     * Since React Navigation doesn't unmount our components, this focusEffect is a workaround for this issue.
     * When Component is not in view - we skip all data loading hooks.
     */
    useFocusEffect(
        useCallback(() => {
            setIsFocused(true);
            return () => {
                setIsFocused(false);
            };
        }, [])
    );

    /**
     * scrolls down to the page
     */
    useEffect(() => {
        containerRef.current.scrollIntoView()
    }, []);

    const [replyLoadSize, setReplyLoadSize] = useState(replyLoadCount)
    const overwriteStyles = StyleSheet.create({
        container: {
            // paddingBottom: (replies?.length > 0 && replies?.length > replyLoadSize) ? 4 : 32,
            paddingLeft: (isReplyTo ? 88 : 0),
            paddingTop: Number.isInteger(padding) ? padding : (isReplyTo ? 15 : 38),
            backgroundColor: backgroundColor, // borderBottomWidth: replyMode ? 0 : 1,
            // borderBottomColor:replyMode ?  "transparent" :  theme.colors.border
        }, commentContainer: {
            borderColor: theme.colors.border,
            borderRadius: theme.borderRadius,
            padding: theme.padding,
            backgroundColor: highlighted === true ? theme.colors.comment_highlight : theme.colors.background_light,
        }, userNameText: {
            ...theme.typo.p_sm_bold,
            color: theme.colors.text_dark
        }, dateLabel: {
            ...theme.typo.p_sm,
            fontFamily: 'OpenSans_400Regular_Italic'
        }, commentText: {
            ...theme.typo.p_sm,
            ...theme.typo.web,
            color: theme.colors.text_dark
        },
        replyText: {
            ...theme.typo.p_sm_bold,
            color: theme.colors.primary
        },
        likeText: {
            ...theme.typo.p_sm_bold,
            color: theme.colors.primary
        }
    });

    return <>
        <View
            style={[styles.container, overwriteStyles.container]}
            ref={containerRef}
            onLayout={(event) => {
                isDevelopment && isFocused && setHighlightPosition && console.log("onLayout set: id:", id, comment?.content?.slice(0, comment?.content.length > 10 ? 10 : comment?.content.length),
                    " - highlighted:", highlighted, !!setHighlightPosition, setTemporaryHighLightPosition, "replyLoadSize: ", replyLoadSize, ", replyLoadCount: ", replyLoadCount, event);
                if (isFocused && setHighlightPosition) {
                    setHighlightPosition(event.nativeEvent.layout, replyCount > 0);
                }
                if (isFocused && setTemporaryHighLightPosition) {
                    isDevelopment && console.log("onLayout setTemporaryHighLightPosition", id, event.nativeEvent.layout);
                    setHighlightPosition(event.nativeEvent.layout, replyCount > 0);
                }
            }}
        >
            <View style={styles.userImageContainer}>
                <UserImage
                    {...props}
                    imageUrl={user?.imageUrl} uri={user.imageUrl} user={user} style={styles.userImage}/>
            </View>
            <View style={[styles.commentContainer, overwriteStyles.commentContainer]}>
                <View style={[styles.commentHeader]}>
                    <Text style={[styles.userNameText, overwriteStyles.userNameText]}>{user?.name}</Text>
                    <Text style={[styles.dateLabel, overwriteStyles.dateLabel]}>{createdAt}</Text>
                </View>
                <Text style={[styles.commentText, overwriteStyles.commentText]}>
                    {comment?.content.replace(/<[^>]*>?/gm, '')}</Text>
                {(canLike || canReply) && <View style={[styles.commentFunctionContainer]}>
                    {canLike && <View style={[styles.likeContainer]}>
                        {likeLoading
                            ? <LoadingIndicator/>
                            : <TouchableOpacity
                                style={{marginRight: 8,}}
                                onPress={() => onLike && onLike()}>
                                <FontAwesome5
                                    color={theme.colors.primary}
                                    size={20}
                                    // regular
                                    solid={!!likedByUser}
                                    name="thumbs-up"
                                />
                            </TouchableOpacity>}
                        <Text
                            style={[styles.likeText, overwriteStyles.likeText]}>{`${likesAmount} Personen gefällt das!`}</Text>
                    </View>}
                    {canReply && <Pressable style={{
                        marginRight: 8, flexDirection: "row", alignItems: "center"
                    }} onPress={() => {
                        //TODO
                        if (typeof triggerInputFocus === 'function') triggerInputFocus()
                        if (typeof onReply === 'function') onReply(id)
                        // setTimeout(()=>{
                        //     global.scrollView.current.scrollToEnd();
                        // },200)
                    }}>
                        <FontAwesome5
                            color={theme.colors.primary}
                            size={20}
                            regular
                            name="reply"
                        />
                        <Text style={[styles.replyText, overwriteStyles.replyText]}>Antworten</Text>
                    </Pressable>}
                </View>}
            </View>
        </View>

        {(replies?.length > 0 && replies?.length > replyLoadSize) ? <View style={{marginLeft: 64}}>
            <Button
                disabled={replies?.length <= replyLoadSize}
                style={{
                    button: {backgroundColor: "transparent"},
                    text: {
                        alignSelf: "flex-start",
                        fontFamily: "OpenSans_700Bold",
                        color: theme.colors.text,
                        fontSize: 15,
                        padding: 0,
                    }
                }}
                text={`${replies?.length} Antworten anzeigen`}
                onClick={() => setReplyLoadSize(replyLoadSize + replies?.length)}/>
        </View> : null}

        {/*{replies?.flatMap((item, idx) => {*/}
        {/*    if ((idx + 1) > replyLoadSize) return []*/}
        {/*    return <React.Fragment key={idx}>*/}
        {/*        {item}*/}
        {/*    </React.Fragment>;*/}
        {/*})}*/}
    </>
}

const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        paddingTop: 32,
        width: "100%",
    }, commentContainer: {
        // flexShrink: 1,   // to stretch comments
        flex: 1,            // to condense comments
        padding: 30,
        backgroundColor: '#fff',
        borderWidth: 1,
        borderRadius: 40,
        // minWidth: 500,
    }, commentHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    }, likeContainer: {
        marginRight: 32,
        flexDirection: "row",
        alignItems: "center"
    }, userImageContainer: {
        marginRight: 15,
        justifyContent: "center"
    }, userImage: {
        height: 50,
        width: 50,
        borderRadius: 50
    }, commentFunctionContainer: {
        flexDirection: "row",
        alignItems: "center",
        paddingTop: 16,
    }, commentFunctionIcon: {
        marginLeft: 32,
        marginRight: 8,
    }, dateLabel: {
        fontFamily: "OpenSans_300Light_Italic",
    }, userNameText: {
        paddingBottom: 10,
        marginRight: 32
    }, commentText: {
        flex: 1,
        flexWrap: 'wrap',
        flexShrink: 0
    },
    replyText: {
        marginLeft: 8
    },
    likeText: {
        // marginLeft: 8
    }
});
