import {Image, Image as ImageReact, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import CaptionBanner from "../CaptionBanner/CaptionBanner";
import React, {useMemo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import RenderHtml from 'react-native-render-html';
import {
    endpoints,
    useLikeArticleMutation,
    usePostNewsCommentMutation,
    useReplyToCommentMutation,
    useUnlikeArticleMutation
} from "../../app/services/api/globalAdminAPI";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import {mapComments} from "src/components/Comment/commentsHelpers";
import CommentSection from "../Comment/CommentSection";
import {SystemFontKeys, SystemFonts} from "../../constants/SystemFonts";
import {renderToast} from "../../utils/renderToast";
import {DATA_POLLING_INTERVAL} from "../../constants/numbers";
import useTitle from "../../utils/hooks/useTitle";
import {isDevelopment} from "../../constants/booleanValues";
import {Player} from 'video-react';
import "../../../node_modules/video-react/dist/video-react.css"; // import css
import "./newsArticle.style.css";
import GalleryView from "../Fotogalerien/GalleryView";
//import css changes

//TODO: Rename to NewsArticleDetail
export default function NewsArticle(props) {
    const {route, stackNavigation: propStackNavigation, navigation} = props;
    const {
        id,
        imageUrl,
        commentsAmount,
        likesAmount,
        content,
        stackNavigation: routeParamStackNavigation
    } = route.params;
    const stackNavigation = routeParamStackNavigation || propStackNavigation;
    const theme = useSelector(state => state.theme);
    const textRef = useRef(null);

    let messageSubmitRef = useRef();
    const [postNewsComment, {error, isLoading}] = usePostNewsCommentMutation();
    const [likeArticle] = useLikeArticleMutation();
    const [unLikeArticle] = useUnlikeArticleMutation();
    const [liked, setLiked] = useState(false);//refactor
    const [numberOfLikes, setNumberOfLikes] = useState(0)
    const [replyToComment, {
        error: replyToCommentError,
        isLoading: replyToCommentIsLoading
    }] = useReplyToCommentMutation();
    const {
        refetch,
        data: articleDetailData,
        error: onArticleDetailError,
        isLoading: articleDetailIsLoading
    } = endpoints.getArticleDetail.useQuery({articleId: id}, {pollingInterval: DATA_POLLING_INTERVAL});
    const [comments, setComments] = useState([]);

    isDevelopment && console.log({articleDetailData}, imageUrl || articleDetailData?.picture_data?.reference?.url);

    const htmlStyles = React.useMemo(
        () => ({
            ...theme.typo.p_md_light,
            ...theme.typo.web,
            color: theme.colors.text_dark,
        }),
        [theme],
    );

    useTitle(navigation, articleDetailIsLoading, articleDetailData?.title ?? 'Fehler');

    /**
     * Shows a 2x2 grid of article images
     * @returns {JSX.Element|null}
     */
    const addImages = useMemo(() => {
        if (articleDetailData?.images_data?.length > 0) {
            return <GalleryView data={articleDetailData.images_data}/>
        }
        return null;
    }, [articleDetailData?.images_data])


    if (articleDetailIsLoading)
        return <LoadingIndicator/>
    else {
        const mappedData = mapComments(articleDetailData?.comments)
        if (JSON.stringify(mappedData) !== JSON.stringify(comments)) {
            // console.log({mappedData, articleDetailData: articleDetailData?.comments})
            setComments(mappedData)
        }
    }
    const onCommentSubmit = (comment) => {
        const formData = new FormData();
        formData.append("comment", comment?.content);
        if (comment.replyOptions?.commentId) {
            replyToComment({commentId: comment.replyOptions?.commentId, formData}).unwrap().then((res) => {
                refetch({articleId: id});
                if ('error' in res) {
                    renderToast("Kommenar nicht gesendet!", "error");
                } else {
                    renderToast("Kommentar erfolgreich gesendet!");
                }
            }).catch((error) => {
                console.error('rejected replyToComment', error);
                renderToast("Kommentar nicht gesendet!", "error");
            });
        } else {
            postNewsComment({articleId: id, formData}).then(res => {
                refetch({articleId: id});
                if ('error' in res) {
                    renderToast("Kommenar nicht gesendet!", "error");
                } else {
                    renderToast("Kommentar erfolgreich gesendet!");
                }
            }).catch(e => {
                console.error(e);
                renderToast("Kommentar nicht gesendet!", "error");
            })
        }
    };

    const overwriteStyles = StyleSheet.create({
        text: {
            ...theme.typo.p_md_light,
            ...theme.typo.web,
            color: theme.colors.text_dark,
        }
    })

    /**
     * Renders video in the article details
     * @returns {JSX.Element|null}
     */
    const addVideos = () => {
        if (articleDetailData.video_data?.length > 0) {
            return <View style={styles.videoContainer}>
                {
                    articleDetailData.video_data.map((video, index) => {
                        // https://video-react.js.org/
                        return <Player key={index}
                                       playsInline
                                       src={video.url}
                                       poster={video.thumbnail?.url ?? ""}
                        />
                    })
                }
            </View>
        }

        return null;
    }

    return <View style={styles.container}>
        <CaptionBanner config={{
            headline: articleDetailData?.title ?? 'Fehler',
            subHeadline: articleDetailData?.datetime || articleDetailData?.created_at
                ? global.moment(articleDetailData?.datetime ?? articleDetailData?.created_at)?.format('DD.MM.YY')
                // ? global.moment(articleDetailData?.datetime ?? articleDetailData?.created_at)?.format('DD.MM.YY - LT [Uhr]')
                : '-',
            subHeadlineStyle: {...theme.typo.p_md, fontFamily: "OpenSans_400Regular_Italic"},
            numberOfLines: 2,
        }}/>
        {(imageUrl || articleDetailData?.picture_data?.reference?.url) ? <Image
                source={imageUrl ?? articleDetailData?.picture_data?.reference?.url}
                style={styles.newsImage}/>
            : <ImageReact resizeMode={"cover"} style={styles.newsImage}
                          source={require("../../../assets/images/placeholder-web.jpg")}/>
        }
        <View style={styles.articleContainer}>
            <Text
                ref={textRef}
                style={[styles.text, overwriteStyles.text]}>
                <RenderHtml
                    baseStyle={htmlStyles}
                    tagsStyles={{
                        a: {
                            color: theme.colors.primary,
                            textDecoration: "underline",
                            textDecorationColor: theme.colors.primary
                        }
                    }}
                    contentWidth={textRef?.current?.getBoundingClientRect().width ?? 1000}
                    source={{html: articleDetailData?.description ?? 'Dieser Artikel existiert nicht.'}}
                    systemFonts={SystemFontKeys}
                />
            </Text>

            {addVideos()}
            {addImages}

            {articleDetailData ? <TouchableOpacity
                style={styles.likesContainer}
                onPress={() => {
                    if (articleDetailData?.you_like_it === true) unLikeArticle({articleId: id}).then(refetch);
                    else likeArticle({articleId: id}).then(refetch);
                }}>
                <FontAwesome5
                    style={styles.likeIcon}
                    color={theme.colors.primary}
                    size={30}
                    solid={!!articleDetailData?.you_like_it}
                    name={"thumbs-up"}/>
                <Text style={theme.typo.p_md}>
                    {`${articleDetailData?.number_of_likes} Personen gefällt das!`}</Text>
            </TouchableOpacity> : null}
        </View>
        {articleDetailData ? <CommentSection
            comments={comments}
            number_of_comments={articleDetailData?.number_of_comments}
            onCommentSubmit={onCommentSubmit}
        /> : null}
    </View>
};

const styles = StyleSheet.create({
    text: {
        marginBottom: 20,
        flex: 1,
        flexWrap: "wrap",
        flexShrink: 1
    },
    likeIcon: {
        marginRight: 8
    },
    articleContainer: {
        marginBottom: 64
    },
    newsImage: {
        borderRadius: 30,
        marginBottom: 32,
        height: 480,
        resizeMode: "cover",
    },
    likesContainer: {
        flexDirection: "row",
        alignItems: "center",
    },
    videoContainer: {
        marginBottom: 30
    }
});
