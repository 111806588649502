import {
    FIELD_TYPE_CHECKBOX,
    FIELD_TYPE_DATE,
    FIELD_TYPE_EMAIL,
    FIELD_TYPE_RADIO, FIELD_TYPE_RADIO_INLINE,
    FIELD_TYPE_SELECT, FIELD_TYPE_STATIC_TEXT,
    FIELD_TYPE_TEXT,
    FIELD_TYPE_TEXTAREA
} from "../fieldTypes";
import {Platform, StyleSheet, Text, TextInput, useWindowDimensions, View} from "react-native";
import SelectBox from "../../Selectbox/SelectBox.native";
import ValidationErrorText from "../../Error/ValidationErrorText";
import Button from "../../Button/Button";
import DateTimePicker, {DateTimePickerAndroid} from "@react-native-community/datetimepicker";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import React from "react";
import RadioButtonGroup from "./RadioButtonGroup";
const style = StyleSheet.create({
    textArea: {
        height: 80,
        padding: 10,
        textAlignVertical: 'top'
    },
    buttonContainer: {
        marginTop: 10

    },
    container: {
        backgroundColor:"grey"
    },
    selectBoxContainer: {
        minWidth: 250,
    },
    valueContainer: {
        paddingTop: 7.5,
        paddingBottom: 7.5,
        flexDirection:"column"
    },
    rowFieldHelpText: {
        fontSize: 14,
        fontFamily: "OpenSans_300Light_Italic",
    },
    personalFieldHeaderText: {
        fontSize: 18,
        marginBottom: 10,
        fontFamily: "OpenSans_700Bold",
    },
    select: {
        dropdown:{
            borderWidth: 0,
            color: '#000',
            fontFamily: 'OpenSans_400Regular',
            fontWeight: '300',
            fontSize: 16,
            minWidth: 300,
            paddingVertical: 12,
            paddingHorizontal: 20,
            backgroundColor: '#fff',
        }
    },
    input: {
        backgroundColor: "#fff",
        color: "#000",
        border: "none",
        fontFamily: "OpenSans_400Regular",
        height: 40,
        width: 450,
        borderWidth: 1,
        padding: 10,
        borderColor: "rgba(0,0,0,0.3)",
        // borderRadius: 6,
        fontSize: 13,
        // textAlign: 'center',
    },
    rowFieldContainer: {
        margin: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexDirection: 'row',
    },
    rowFieldContainerSmall: {
        marginVertical: 5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
    },
    rowLabel: {
        fontFamily: "OpenSans_600SemiBold",
        fontSize: 14,
    },
    rowLabelSmall: {
        fontFamily: "OpenSans_600SemiBold",
        fontSize: 14,
        marginBottom: 5
    }
});
export const renderRowFieldType = ({theme,rowFieldType,rowFieldLabel,rowField, onChange,errors,getValues,value,setError}) => {
    let rowFieldRender = undefined;
    const isRequired = rowField.validators?.hasOwnProperty("required");
    const {width,height} = useWindowDimensions();
    if(rowFieldLabel) rowFieldLabel += isRequired ? "*" : "";
    const hasError = !!errors[rowField.id]
    const error = errors[rowField.id];
    //Media query replacement
    const rowFieldContainerStyles = width <= 720  ? style.rowFieldContainerSmall : style.rowFieldContainer
    const rowLabelStyles =  width <= 720 ? style.rowLabelSmall : style.rowLabel
    const editorWidth = width <= 720 ? "100%" : 450
    switch (rowFieldType) {
        case FIELD_TYPE_EMAIL:
            rowFieldRender = <View style={style.valueContainer}>
                <View style={rowFieldContainerStyles}>
                    {!!rowFieldLabel&&<Text style={rowLabelStyles}>{rowFieldLabel}:</Text>}
                    <TextInput style={{...style.input,width: editorWidth}} onChangeText={onChange} defaultValue={undefined}/>
                </View>
                 <ValidationErrorText error={error}/>
            </View>
            break;
        case FIELD_TYPE_TEXTAREA:
            rowFieldRender =  <View style={style.valueContainer}>
                <View style={rowFieldContainerStyles}>
                    {!!rowFieldLabel&&<Text style={rowLabelStyles}>{rowFieldLabel}:</Text>}
                    <TextInput style={[{...style.input,width: editorWidth}, style.textArea]}
                               onChangeText={onChange}
                               multiline={true}
                               numberOfLines={4}
                    />
                </View>
                {!!hasError && <ValidationErrorText error={error}/>}
            </View>
            break;
        case FIELD_TYPE_RADIO_INLINE:
        case FIELD_TYPE_RADIO:
            const styles = {
                // alignItems:"flex-start",
                // borderColor: theme.colors.primary,
                // color :theme.colors.primary,
                // containerStyle: {
                //     marginHorizontal: 0
                // }
            }
            const choices = rowField.choices ? Object.entries(rowField.choices).map(item=>(  {
                id: item, // acts as primary key, should be unique and non-empty string
                label: item[1],
                value: item[0],
                ...styles
            })) : [];
            rowFieldRender = <View style={{alignItems:"flex-start"}}>
                {!!rowFieldLabel&&<Text style={[rowLabelStyles]}>{rowFieldLabel}:</Text>}
                <RadioButtonGroup onPress={
                    (args)=> {
                        onChange(args?.filter(item=>item.selected === true)[0]?.value)
                    }
                } layout={"column"} containerStyle={{margin:0}} options={ choices }/>
                {!!hasError && <ValidationErrorText error={error}/>}
            </View>
            break;
        case FIELD_TYPE_SELECT:
            rowFieldRender = <View style={style.valueContainer}>
                <View style={rowFieldContainerStyles}>
                    {!!rowFieldLabel&&<Text style={rowLabelStyles}>{rowFieldLabel}:</Text>}
                    <View style={style.selectBoxContainer}>
                        <SelectBox
                            style={style.select}
                            onValueChanged={(arg)=>{
                                onChange(arg.value)
                            }}
                            useClearIcon={getValues(rowField.id) !== undefined}
                            onClear={()=>onChange(undefined)}
                            labelField={"label"}
                            valueField={"value"}
                            value={getValues(rowField.id)}
                            items={rowField.choices ? Object.entries(rowField.choices).map(item=>{
                                return {
                                    label: item[1],
                                    value: item[0]
                                }
                            }) : []}
                        />
                    </View>
                </View>
                {!!hasError && <ValidationErrorText error={error}/>}
            </View>
            break;
        case FIELD_TYPE_TEXT:
            rowFieldRender = <View style={style.valueContainer}>
                <View style={rowFieldContainerStyles}>
                    {!!rowFieldLabel&&<Text style={rowLabelStyles}>{rowFieldLabel}:</Text>}
                    <TextInput style={{...style.input,width: editorWidth}} onChangeText={onChange} defaultValue={undefined}/>
                </View>
                {!!hasError && <ValidationErrorText error={error}/>}
            </View>
            break;
        case FIELD_TYPE_STATIC_TEXT:
            rowFieldRender = <View style={[style.valueContainer,{flexDirection: "row"}]}>
                {!!rowFieldLabel&&<Text style={rowLabelStyles}>{rowFieldLabel}:</Text>}
                {!!rowField.help&&<Text style={[rowLabelStyles, {paddingLeft: 10}]}>{rowField.help}</Text>}
            </View>
            break;
        case FIELD_TYPE_DATE:
            rowFieldRender = <View style={{flexDirection:"column" }}>
                <View style={rowFieldContainerStyles}>
                    <Text style={[style.rowLabel,style.valueContainer]}>{rowField.label}:</Text>
                    {
                        Platform.select({
                            android:<Button text={global.moment(new Date(getValues(rowField.id))).format('dd, DD.MM.YYYY')} onClick={
                                ()=> {
                                    DateTimePickerAndroid.open({
                                        value: new Date(getValues(rowField.id)),
                                        mode: "date",
                                        onChange: (args) => {
                                            onChange(new Date(args.nativeEvent?.timestamp))
                                        }
                                    })
                                }
                            }/>,
                            ios: <DateTimePicker
                                mode={"date"}
                                style={{alignSelf:"flex-start"}}
                                value={new Date(getValues(rowField.id))}
                                is24Hour={true}
                                onChange={(args)=>{
                                    onChange(new Date(args.nativeEvent?.timestamp))
                                }}
                            />,
                            default: React.createElement('input', {
                                type: 'date',
                                style: {...style.input,width: editorWidth - 20},
                                value: global.moment(value).format('YYYY-MM-DD'),
                                onChange: (e) => onChange(new Date(e.target.value))
                            })

                        })
                    }
                </View>
                {!!hasError && <ValidationErrorText error={error}/>}
            </View>
            break;
        case FIELD_TYPE_CHECKBOX:
            rowFieldRender = <View>
                <BouncyCheckbox
                    style={style.checkbox}
                    size={25}
                    isChecked={value}
                    fillColor={theme.colors?.primary}
                    unfillColor="transparent"
                    text={rowFieldLabel}
                    iconStyle={{borderColor: theme.colors?.primary}}
                    innerIconStyle={{borderWidth: 2}}
                    textContainerStyle={{textDecoration: "none"}}
                    textStyle={{fontFamily: "OpenSans_400Regular", textDecorationLine: "none"}}
                    onPress={(val) => {
                        onChange(rowField.id, val);
                    }}
                />
                {!!hasError && <ValidationErrorText error={error}/>}
            </View>
            break;
        default:
            console.warn("DEFAULT CASE renderRowFieldType",rowFieldType)
            break;
    }
    return rowFieldRender;
}
