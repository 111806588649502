import React from "react";
import {Image as ImageReact, useWindowDimensions} from "react-native";

export default function ImageBlock(props) {
    //TODO: Konfigurierbar machen für Baukasten
    const {imageUrl} = props;
    const {width} = useWindowDimensions();
    return <ImageReact
        defaultSource={require("../../../assets/images/placeholder.jpg")}
        style={{
            height: 250,
            width: width,
        }}
        source={{uri: imageUrl}}
        resizeMode={"cover"}
        uri={imageUrl}
    >
    </ImageReact>
}
