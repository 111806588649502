import CaptionBanner from "../CaptionBanner/CaptionBanner";
import React from "react";
import {StyleSheet, Text, View} from 'react-native';
import {useSelector} from "react-redux";
import {FontAwesome5} from "@expo/vector-icons";
import {A} from '@expo/html-elements';

export default function ContactDetail(props) {
    const {route} = props;
    const {
        name,
        location,
        id,
        contact
    } = route.params;
    const theme = useSelector(state => state.theme);
    //console.log(route)
    return <View>
        <CaptionBanner config={{
            options: {
                text: {
                    color: "white",
                    value: name,
                    fontSize: 40
                }
            }
        }}/>
        <View style={{paddingLeft: theme.wrapperPadding, paddingRight: theme.wrapperPadding}}>
            {/*<View style={{paddingTop: theme.containerSpacing}}>*/}
            {/*    <Text style={[styles.headerText, {color: theme.colors.primary}]}>Anschrift</Text>*/}
            {/*    <View style={styles.valueContainer}>*/}
            {/*        <Text style={[styles.valueText, {color: theme.colors.text_dark}]}>{contact?.street}</Text>*/}
            {/*        <Text style={[styles.valueText, {color: theme.colors.text_dark}]}>{contact?.zip}</Text>*/}
            {/*        <Text style={[styles.valueText, {color: theme.colors.text_dark}]}>{contact?.city}</Text>*/}
            {/*    </View>*/}
            {/*</View>*/}
            <View style={{paddingTop: theme.containerSpacing}}>
                <Text style={[styles.headerText, {color: theme.colors.primary}]}>Telefon</Text>
                <View style={styles.valueContainer}>
                    <View style={styles.iconContainer}>
                        <FontAwesome5
                            style={{paddingRight: 5}}
                            color={theme.colors.text_dark}
                            size={27}
                            name={"phone"}
                        />
                        <A style={[styles.valueText, {color: theme.colors.text_dark}]}
                           href={`tel:${contact.tel}`}>{contact?.tel}</A>
                    </View>
                </View>
            </View>
            <View style={{paddingTop: theme.containerSpacing}}>
                <Text style={[styles.headerText, {color: theme.colors.primary}]}>E-Mail</Text>
                <View style={styles.valueContainer}>
                    <View style={styles.iconContainer}>
                        <FontAwesome5
                            style={{paddingRight: 5}}
                            color={theme.colors.text_dark}
                            size={27}
                            name={"envelope"}
                        />
                        <A style={[styles.valueText, {color: theme.colors.text_dark}]}
                           href={`mailto:${contact.email}`}>{contact?.email}</A>
                    </View>
                </View>
            </View>
        </View>
    </View>
}
const styles = StyleSheet.create({
    headerText: {
        fontFamily: "BebasNeue_400Regular",
        fontSize: 30,
        lineHeight: 40,
    },
    valueText: {
        fontFamily: "OpenSans_400Regular",
        fontSize: 17,
        lineHeight: 27
    },
    valueContainer: {
        paddingTop: 15
    },
    iconContainer: {
        flexDirection: "row"
    }
})
