import React, {useRef, useState} from "react";
import {Image, Linking, StyleSheet, Text, TextInput, TouchableOpacity, View} from "react-native";
import {baseUrl, useLoginMutation} from '../../app/services/api/globalAdminAPI'
import {useSelector} from "react-redux";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import {USERNAME_LABEL_TAG} from "../../constants/strings";

export default function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [login, {isLoading}] = useLoginMutation();
    const theme = useSelector(state => state.theme);
    const passwordInput = useRef(null)
    const [errorState, setErrorState] = useState(null);
    const overwriteStyles = StyleSheet.create({
        container: {
            backgroundColor: theme.colors.background,
        },
        card: {
            backgroundColor: theme.colors.background_light,
            borderRadius: theme.borderRadius,
            padding: theme.padding
        },
        infoTextHeader: {
            color: theme.colors.text,
        },
        infoText: {
            color: theme.colors.text,
        },
        loginBtn: {
            backgroundColor: theme.colors.primary
        },
        loginText: {
            color: theme.colors.text_light
        },
        inputDescription: {
            color: theme.colors.text,
        }
    });
    if (isLoading === true) return <LoadingIndicator/>;
    const loginIntoApp = () => {
        login({username, password}).unwrap()
            .then((res) => console.log("Login response ", res))
            .catch(err => {
                setErrorState(err?.data?.message);
                console.error("got error: ", err)
            })
    }
    const InputGroup = (props) => {
        const {
            label,
            clearButtonMode,
            placeholder,
            onChangeText,
            secureTextEntry,
            value,
            textContentType,
            textInputProps
        } = props
        return <View style={styles.inputView}>
            <Text style={[styles.inputDescription, overwriteStyles.inputDescription]}>{label}:</Text>
            <TextInput
                style={styles.textInput}
                {...textInputProps}
                placeholderTextColor="#003f5c"
            />
        </View>
    };

    return <View
        style={[styles.container, overwriteStyles.container]}
        contentContainerStyle={{alignItems: "center"}}>
        <View style={[styles.card, overwriteStyles.card]}>
            <View style={styles.header}>
                <Image style={styles.logo} source={require("../../../assets/images/logo.png")}/>
                <Text style={[styles.infoTextHeader, overwriteStyles.infoTextHeader]}>
                    Herzlich Willkommen
                    in der KiKxxl-App!
                </Text>
                <Text style={[styles.infoText, overwriteStyles.infoText]}>
                    Um weitere Inhalte zu sehen, melde dich bitte mit deinen persönlichen Zugangsdaten an.
                </Text>
            </View>
            <View>
                {
                    InputGroup({
                        label: USERNAME_LABEL_TAG,
                        textInputProps: {
                            value: username,
                            textContentType: "username",
                            onSubmitEditing: () => passwordInput.current.focus(),
                            onChangeText: (email) => setUsername(email)
                        },
                    })
                }
                {
                    InputGroup({
                        textInputProps: {
                            ref: passwordInput,
                            returnKeyType: "done",
                            onSubmitEditing: loginIntoApp,
                            value: password,
                            textContentType: "password",
                            secureTextEntry: true,
                            onChangeText: (value) => setPassword(value)
                        },
                        label: "Passwort",
                    })
                }
                {
                    errorState && <Text style={[styles.loginErrorText, {color: theme.colors.error}]}>{errorState}</Text>
                }
            </View>
            <TouchableOpacity
                style={[styles.loginBtn, overwriteStyles.loginBtn]}
                onPress={loginIntoApp}>
                <Text style={[styles.loginText, overwriteStyles.loginText]}>Anmelden</Text>
            </TouchableOpacity>
            <TouchableOpacity style={styles.loginBtn} onPress={async () => {
                // const link = 'https://globaladmin-dev.de/resetting/request';
                //and remember kids: DO NOT HARDCODE!
                const link = `${baseUrl}resetting/request`;
                const supported = await Linking.canOpenURL(link);
                if (supported) await Linking.openURL(link)
                else console.error("not supported link", link)
            }}>
                <Text style={styles.loginText}>Passwort Vergessen?</Text>
            </TouchableOpacity>
        </View>
    </View>;
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        padding: 80,
    },
    loginErrorText: {
        fontFamily: "OpenSans_700Bold",
        fontSize: 18,
    },
    card: {
        width: '100%',
        maxWidth: 800,
        justifyContent: "center",
        alignItems: "center",
    },
    header: {
        textAlign: "center",
        alignItems: "center",
        justifyContent: "center",
    },
    infoTextHeader: {
        fontFamily: "OpenSans_700Bold",
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: "center",
        fontSize: 22,
    },
    infoText: {
        fontFamily: "OpenSans_300Light",
        fontSize: 22,
        textAlign: "center",
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 35,
        paddingBottom: 35,
    },
    logo: {
        height: 96,
        width: 212,
    },
    inputView: {
        paddingBottom: 30,
        alignItems: "center"
    },
    textInput: {
        borderBottomWidth: 1,
        width: 200,
        padding: 10,
        height: 40,
        fontFamily: "OpenSans_400Regular",
    },
    forgot_button: {
        height: 30,
        marginBottom: 30,
    },
    loginBtn: {
        width: "80%",
        borderRadius: 25,
        height: 50,
        alignItems: "center",
        justifyContent: "center",
        marginTop: 40,
    },
    loginText: {
        fontFamily: "OpenSans_300Light",
        fontSize: 18,
    },
    inputDescription: {
        paddingBottom: 10,
        fontFamily: "OpenSans_400Regular",
        fontSize: 18,
    }
});
