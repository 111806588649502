import {WebView as _WebView} from 'react-native-webview';
import {Platform, StyleSheet, View, Animated, Pressable, Keyboard, Dimensions} from "react-native";
import React, {useEffect} from "react";
import {useKeyboard} from "@react-native-community/hooks";

// Webview is not supported in Web
export default function WebView(props) {
    const {
        url,
        title,
        setBuiltInZoomControls = false,
        setDisplayZoomControls = false,
        scalesPageToFit = false,
        scrollEnabled = false
    } = props?.config?.options;
    const {keyboardShown, keyboardHeight} = useKeyboard()
    // let {width, height} = Dimensions.get("window")

    //todo: remove when web is fixed. web isn't mobile friend, so we apply a fix
    const chatBotRescaleFix = `const meta = document.createElement('meta'); meta.setAttribute('name', 'viewport'); meta.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=2, user-scalable=0'); document.getElementsByTagName('head')[0].appendChild(meta); `

    return <View style={styles.container}
        // onPress={()=>Keyboard.dismiss()}
    >
        {(Platform.OS === 'ios' || Platform.OS === 'android')
            ? <View
                style={Platform.OS === 'ios'
                    ? {
                        flex: 1,
                        marginBottom: keyboardShown ? (keyboardHeight - 95) : 0,
                    }
                    : {
                        flex: 1,
                        // borderColor: "red", borderStyle: "solid", borderWidth: 1,//for testing
                    }}
            >
                <_WebView
                    source={{uri: url}}
                    // does not prevent ios zoom
                    setBuiltInZoomControls={setBuiltInZoomControls}
                    setDisplayZoomControls={setDisplayZoomControls}
                    scalesPageToFit={scalesPageToFit}
                    injectedJavaScript={chatBotRescaleFix}
                    // injectedJavaScript={Platform.select({
                    //     ios: iOSChatBotRescale,
                    // })}
                    scrollEnabled={scrollEnabled} // set false for the ChatBot
                    // useWebKit={false}
                    // style={{ height: 500, width, resizeMode: 'cover', flex: 1 }}
                />
            </View>
            : React.createElement('iframe', {
                src: url,
                title: title,
                style: {height: '100%', border: 'none'}
            })
        }
    </View>;
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'stretch',
    },
});
