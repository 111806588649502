import DynamicForm from "./DynamicForm";
import {mockData} from "./exampleData";
import {View} from "react-native";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import React from "react";

export default function DynamicFormWrapper(props){
    const {id} = props;

    const {data, refetch, error, isLoading} = endpoints.getDynamicFormConfig.useQuery(id,{skip: !id});
    if(isLoading) return <LoadingIndicator/>
    if(!data) return;
    //we only support ONE page
    const formConfig = JSON.parse(data).pages[0]
    const fieldSets = Object.values(formConfig.fieldsets)
    const formLabel = formConfig.label;
    const formId = formConfig.id;
    const formText = formConfig.text;
    return <View>
        <DynamicForm {...props}  formName={JSON.parse(data)?.name} formId={id}  formText={formText} formLabel={formLabel} fieldSets={fieldSets} data={data}/>
    </View>
}
