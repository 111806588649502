import RadioGroup from 'react-native-radio-buttons-group';
import {useState} from "react";
export default function RadioButtonGroup(props){
    const {options,onPress} = props;

    const [radioButtons, setRadioButtons] = useState(options || []);

    function onPressRadioButton(radioButtonsArray) {
        setRadioButtons(radioButtonsArray);
        if(onPress) onPress(radioButtonsArray)
    }
    return (
        <RadioGroup
            {...props}
            containerStyle={{alignItems: "flex-start"}}
            radioButtons={radioButtons}
            onPress={onPressRadioButton}
        />
    );

}
