/**
 * Executes function after "wait" time. If function gets called again - wait time is reset.
 * @param func
 * @param wait
 * @param immediate
 */
export function debounceFunction(
    func: (e: any) => void,
    wait: number = 500,
    immediate?: boolean
) {
    let timeoutID: NodeJS.Timeout | null;

    return function (...args: any[]) {
        const context = this;

        const later = function () {
            timeoutID = null;
            if (!immediate) {
                func.apply(context, args);
            }
        };

        const callNow = immediate && !timeoutID;

        if (timeoutID) {
            clearTimeout(timeoutID);
        }

        timeoutID = setTimeout(later, wait);

        if (callNow) {
            func.apply(context, args);
        }
    };
}