import {createSlice, current} from '@reduxjs/toolkit'
import {CONTENT_TYPE} from "../../../constants/strings";
import {Platform} from "react-native";
//TODO work in progress
let globalAdminApi
export const injectglobalAdminApiIntoCache = _globalAdminApi => {
    globalAdminApi = _globalAdminApi
}

/**
 * data structure to track a single type's last sync time and the id's in sync
 */
type cacheTag = {
    type: string,
    lastSynced: number
    ids: number[]
}

/**
 * data structure for all tracked tags
 */
type SyncState = {
    // created: object
    // updated: object | null
    // deleted: object | null,
    // lastSync: string
    cacheTags: cacheTag[]
}
const initialState = {
    cacheTags: []
}

/**
 * keeps track of tags that were updated.
 * If tag array is empty - fills up with updated id's.
 * @param type
 * @param curState - native has mutable state, while web immutable, so we go on with immutable approach
 * @param args
 */
export const mutateCacheTags = (type, stateObj, args) => {
    let curState = Platform.OS === "web" ? current(stateObj) : stateObj;//for web we have a proxy object, so we take a snapshot
    type === CONTENT_TYPE.NEWS && console.log(CONTENT_TYPE.NEWS, "mutateCacheTags", args?.payload, args?.meta);
    const typeIndex = curState.cacheTags.findIndex(item => item.type === type)
    if (typeIndex !== -1) {
        //modify existing type.
        // (type == CONTENT_TYPE.NEWS) && console.log(Platform.OS, Platform.Version, "modified type object cacheTags for type ", type, curState.cacheTags?.find(c => c.type === type), args?.payload)

        return {
            ...curState,
            cacheTags: curState.cacheTags.map((obj, idx) => {
                return idx !== typeIndex
                    ? obj
                    : {
                        ...obj,
                        lastSynced: args.meta.fulfilledTimeStamp,
                        ids: Array.from(new Set([
                                ...obj.ids,
                                ...args.payload
                            ]
                        ))
                    }
            })
        };
    } else {
        //create a new type.
        // (type == CONTENT_TYPE.NEWS) && console.log(Platform.OS, Platform.Version, "create new cacheTags for type ", type, curState.cacheTags?.find(c => c.type === type), args?.payload)
        return {
            ...curState,
            cacheTags: [
                ...curState.cacheTags,
                {
                    type: type,
                    lastSynced: args.meta.fulfilledTimeStamp,
                    ids: args.payload || []
                }
            ]
        }
        // console.log("pushed type object into cacheTags",{
        //     type: type,
        //     lastSynced: args.meta.fulfilledTimeStamp,
        //     ids: args.payload || []
        // })
    }
}

/**
 * This slice stores modified/new ids to get from the API. User can decide when to invalidate the cache.
 */
const slice = createSlice({
    name: 'cache',
    initialState: initialState as SyncState,
    reducers: {
        resetCache: (state, action) => {
            state.cacheTags = [];
        },
        /**
         * Clears the invalidated cacheTags id-list for given type
         * @param state
         * @param payload
         */
        removeInvalidatedCacheTags: (state, {payload}) => {
            const type = payload;
            // console.log("removeInvalidatedCacheTags ",type)
            const typeIndex = state.cacheTags.findIndex(item => item.type === type);
            if (typeIndex !== -1) {
                state.cacheTags[typeIndex].ids = [];
            }
        }
    },
    extraReducers(builder) {
        builder
            .addMatcher(
                globalAdminApi.endpoints[`getUpdated${CONTENT_TYPE.NEWS}`].matchFulfilled,
                (state, args) => mutateCacheTags(CONTENT_TYPE.NEWS, state, args)
            )
            .addMatcher(
                globalAdminApi.endpoints[`getUpdated${CONTENT_TYPE.SHORTNEWS}`].matchFulfilled,
                (state, args) => mutateCacheTags(CONTENT_TYPE.SHORTNEWS, state, args)
            )
            .addMatcher(
                globalAdminApi.endpoints[`getUpdated${CONTENT_TYPE.COMMENT}`].matchFulfilled,
                (state, args) => mutateCacheTags(CONTENT_TYPE.COMMENT, state, args)
            )
            .addMatcher(
                globalAdminApi.endpoints[`getUpdated${CONTENT_TYPE.BENEFIT}`].matchFulfilled,
                (state, args) => mutateCacheTags(CONTENT_TYPE.BENEFIT, state, args)
            )
            .addMatcher(
                globalAdminApi.endpoints[`getUpdated${CONTENT_TYPE.GALLERY}`].matchFulfilled,
                (state, args) => mutateCacheTags(CONTENT_TYPE.GALLERY, state, args)
            )
            .addMatcher(
                globalAdminApi.endpoints[`getUpdated${CONTENT_TYPE.CALENDAREVENT}`].matchFulfilled,
                (state, args) => mutateCacheTags(CONTENT_TYPE.CALENDAREVENT, state, args)
            )
            .addMatcher(
                globalAdminApi.endpoints[`getUpdated${CONTENT_TYPE.QUICKLINK}`].matchFulfilled,
                (state, args) => mutateCacheTags(CONTENT_TYPE.QUICKLINK, state, args)
            )
            .addMatcher(
                globalAdminApi.endpoints[`getUpdated${CONTENT_TYPE.INFORMATION}`].matchFulfilled,
                (state, args) => mutateCacheTags(CONTENT_TYPE.INFORMATION, state, args)
            )
            .addMatcher(
                globalAdminApi.endpoints[`getUpdated${CONTENT_TYPE.THREAD}`].matchFulfilled,
                (state, args) => mutateCacheTags(CONTENT_TYPE.THREAD, state, args)
            )
            .addMatcher(
                globalAdminApi.endpoints["getUpcomingBirthDays"].matchFulfilled,
                (state, args) => mutateCacheTags(CONTENT_TYPE.BIRTHDAYS, state, args)
            )
            .addMatcher(globalAdminApi.endpoints["logout"].matchFulfilled,
                (state, args) => {
                    //todo: invalidate settings cache
                    // mutateCacheTags(CONTENT_TYPE.THREAD, state, args)
                }
            )
    }
})
export const {resetCache, removeInvalidatedCacheTags} = slice.actions
export default slice.reducer
