import React from "react";
import PublicText from "../PublicScreens/PublicText";
import CaptionBanner from "../CaptionBanner/CaptionBanner.native";
import {StyleSheet} from "react-native";
import {useSelector} from "react-redux";

/**
 * Loads & displays company locations
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CompanyLocations = (props) => {
    const theme = useSelector(state => state.theme);

    const style = StyleSheet.create({
        view: {
            backgroundColor: "#F2F2F2",
        }
    });

    return <>
        <CaptionBanner config={{
            options: {
                text: {
                    color: "white",
                    value: "Standorte",
                    // fontSize: 42,
                }
            }
        }}/>
        <PublicText apiCall={"getCompanyLocations"}
                    viewStyle={style.view}
                    tagsStyles={
                        {
                            h2: {
                                color: "#707070",
                                fontSize: 24,
                                fontFamily: "OpenSans_400Regular",
                                // lineHeight: 24,
                                // fontWeight: "1600",
                            },
                            p: {
                                color: "#707070",
                                fontFamily: "OpenSans_400Regular",
                                fontSize: 16,
                                lineHeight: 24,
                            },
                        }
                    }
        />
    </>
};


export default CompanyLocations;