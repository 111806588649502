import {Image, StyleSheet, Text, View,} from "react-native";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {ProfilePicture} from "../Profile/ProfilePicture";
import {Link} from "@react-navigation/native";
import WebIcon from "../Icon/WebIcon";
import {store} from "../../app/store";
import {useGetNotificationsForWebQuery, useHasNotificationsForWebQuery} from "../../app/services/api/globalAdminAPI";
import {NOTIFICATION_BELL_POLLING_INTERVAL} from "../../constants/numbers";
import {resetCache} from "../../app/services/state/cache";


const style = StyleSheet.create({
    container: {
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        paddingVertical: 16,
        paddingHorizontal: 80,
        backgroundColor: "#fff",
    },
    container_bpMargin: {
        paddingHorizontal: 40,
    },
    logoContainer: {
        flex: 1,
        justifyContent: "flex-start"
    },
    logoLink: {
        alignItems: "flex-start",
        justifyContent: "center",
    },
    logo: {
        height: 48,
        width: 106,
        resizeMode: 'contain',
    },
    dateContainer: {
        justifyContent: "center",
    },
    date: {
        textAlign: "center"
    },
    quickLinkContainer: {
        flex: 1,
        flexDirection: "row",
        justifyContent: "flex-end"
    },
    loggedInLink: {
        flexDirection: "row",
        justifyContent: "flex-end"
    },
    loggedInTextContainer: {
        alignItems: "flex-end",
        justifyContent: "center",
        marginRight: 16,
    },
    loggedInText: {
        textAlign: "right",
    },
    notificationLink: {
        flexShrink: 0,
        borderWidth: 1,
        borderRadius: "50%",
        height: 48,
        width: 48,
        marginLeft: 16,
        alignSelf: "center",
        justifyContent: 'center',
        alignItems: 'center'
    }
});

export default function HeaderBar(props) {
    const dispatch = useDispatch();

    const {config, breakpoint} = props;
    const {fullname} = store.getState()?.auth;

    const homeRoute = config.routes.find((route) => route.name === 'Home');
    const profileRoute = config.routes.find((route) => route.name === 'Profil');
    const notificationRoute = config.routes.find((route) => route.name === 'Notifications');
    const bp_margin = breakpoint < 3;
    const bp_shortDate = breakpoint < 2;
    const bp_hideDate = breakpoint < 1;

    const {data, error, isLoading} = useHasNotificationsForWebQuery({}, {
        pollingInterval: NOTIFICATION_BELL_POLLING_INTERVAL,
        notifyOnNetworkStatusChange: true
    });

    const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

    useEffect(() => {
        if (!isLoading) {
            setUnreadNotificationCount(data.unread_message_count);
        }
    }, [data]);

    const theme = useSelector(state => state.theme);
    const overwriteStyle = StyleSheet.create({
        container: {},
        date: {
            color: theme.colors.text_dark
        },
        loggedInText: {
            ...theme.typo.p_sm,
            color: theme.colors.text_dark
        },
        loggedInName: {
            ...theme.typo.p_sm_bold,
            color: theme.colors.text_dark
        },
        notificationLink: {
            borderColor: theme.colors.text_dark
        }
    });

    /**
     * we force some data to be invalidated / refreshed on click
     * @param e
     */
    const homeOnPress = (e) => {
        if (e?.type === 'click') {
            dispatch(resetCache());
            // console.log("homeOnPress")
        }
    }

    return <View style={[style.container, overwriteStyle.container, (bp_margin && style.container_bpMargin)]}>
        <View style={style.logoContainer}>
            <Link to={{screen: homeRoute?.name}} onPress={homeOnPress}>
                <View style={style.logoLink}>
                    <Image style={style.logo} source={require("../../../assets/images/kikxxl.svg")}/>
                </View>
            </Link>
        </View>

        {!bp_hideDate ? <View style={style.dateContainer}>
            <Text style={[style.date, overwriteStyle.date]}>
                {(bp_shortDate)
                    ? global.moment(Date.now()).format('dd, DD.MM.YYYY')
                    : global.moment(Date.now()).format('dddd,  DD. MMMM YYYY')}
            </Text>
        </View> : null}

        <View style={style.quickLinkContainer}>
            {profileRoute ? <Link to={{screen: profileRoute?.name}}>
                <View style={style.loggedInLink}>
                    {(breakpoint >= 4) ? <View style={style.loggedInTextContainer}>
                        <Text style={[style.loggedInText, overwriteStyle.loggedInText]}>
                            Eingeloggt als</Text>
                        <Text style={[style.loggedInText, overwriteStyle.loggedInText, overwriteStyle.loggedInName]}>
                            {fullname}</Text>
                    </View> : null}
                    <ProfilePicture/>
                </View>
            </Link> : null}
            {notificationRoute ? <Link to={{screen: notificationRoute?.name}}>
                <View style={[style.notificationLink, overwriteStyle.notificationLink]}>
                    {notificationRoute?.icon ? <WebIcon
                        icon={notificationRoute?.icon}
                        size={20}
                        color={theme.colors.text_dark}/> : null}
                    {
                        unreadNotificationCount > 0 && <View style={{
                            height: 16,
                            minWidth: 16,
                            paddingLeft: 3,
                            paddingRight: 3,
                            position: "absolute",
                            top: 5,
                            right: 5,
                            backgroundColor: "red",
                            borderRadius: 20,
                            font: "bold 11px / 16px \"Open Sans\"",
                            color: "#FFFFFF",
                            textAlign: "center"
                        }}>{unreadNotificationCount}</View>
                    }
                </View>
            </Link> : null}
        </View>
    </View>
}
