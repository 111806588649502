import React from 'react';
import TileLinks from "../TileLinks/TileLinks";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import {useValidLocations} from "../../utils/location";

export default function BenefitsOverview(props) {
    const locations = useValidLocations()
    const params = {};
    if (locations)
        params.locations = locations;

    const {data, refetch, error, isLoading} = endpoints.getBenefits.useQuery(params);

    if (isLoading) return <LoadingIndicator/>;

    return <TileLinks {...props} config={{
        options: {
            styles: {
                linkContainer: {backgroundColor: "#0093D1", borderWidth: 0,},
                linkText: {color: "#FFFFFF"}
            },
            useGroups: true,
            useSquares: true,
            links: [{
                items: data.entries?.map(link => {
                    return {
                        ...link,
                        id: link?.id,
                        title: link?.title,
                        label: link?.title,
                        route: "BenefitsDetail",
                        icon: {
                            type: 'external',
                            name: link?.icons?.icon?.reference?.url,
                            isImage: true
                        }
                    }
                })
            }]
        }
    }}/>
}
