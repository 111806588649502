import React from "react";
import {Platform, StyleSheet, TouchableOpacity, View} from "react-native";
import {Image} from "react-native-expo-image-cache";

const styles = StyleSheet.create({
    itemContainer: {
        alignItems: "center",
    },
    itemContainer_firstCol: {
        marginLeft: 0,
    },
    itemContainer_firstRow: {
        marginTop: 0
    },
    imageTouchable: {
        alignItems: "stretch",
        width: '100%',
    },
    galleryCategoryImage: {
        minHeight: Platform.select({native: 66, default: 202}),
        resizeMode: 'cover',
        borderRadius: 22,
    },
});
const GalleryViewItem = ({item, index, tiles, tileWidth, tileHeight, selectImg, margin = 24}) => {
    // console.log("renderItem", {tileWidth, tileHeight})
    const overwriteStyles = StyleSheet.create({
        itemContainer:{
            marginLeft: margin,
            marginTop: Platform.select({native: 0, default: margin}),
        }
    });

    return <View
        style={[styles.itemContainer, overwriteStyles.itemContainer, {width: tileWidth, height: tileHeight},
            (index < tiles && styles.itemContainer_firstRow),
            (index % tiles === 0 && styles.itemContainer_firstCol)
        ]}
    >
        <TouchableOpacity
            key={index}
            style={[styles.imageTouchable]}
            onPress={() => selectImg(index)}>
            <Image
                preview={{uri: item?.news_preview?.url}}
                uri={item?.news_big?.url}
                style={[styles.galleryCategoryImage, {width: tileWidth, height: tileHeight}]}
            />
        </TouchableOpacity>
    </View>
}

export default GalleryViewItem;