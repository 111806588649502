import {Skeleton as SkeletonComponent} from "@rneui/base";
import {useSelector} from "react-redux";

export default function Skeleton(props = {}){
    const theme = useSelector(root=>root.theme);
    const {style } = props;
    //TODO: try to change color & gradient to more light color
    return <SkeletonComponent
        // theme={{colors: ["black",], spacing: { xs: 2, sm: 4, md: 8, lg: 12, xl: 24}}}
        style={[style]} >
    </SkeletonComponent>
}
