import {StyleSheet, Text, View} from "react-native";
import {useSelector} from "react-redux";
import {Link} from "@react-navigation/native";
import WebIcon from "../Icon/WebIcon";

const styles = StyleSheet.create({
    container: {
        borderTopWidth: 1,
        flexDirection: "row",
        justifyContent: 'flex-start',
        alignItems: 'center',
        marginHorizontal: 80,
        paddingVertical: 16,
        paddingHorizontal: 32,
        height: 81,
    },
    container_bp4: {
        paddingHorizontal: 0,
        marginHorizontal: 40,
    },
    linkContainer: {
        marginRight: 64,
        flexDirection: "row",
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    linkContainer_bp4: {
        marginRight: 16,
    },
    linkText: {},
    copyRightContainer: {
        marginLeft: 'auto',
    },
    copyRightText: {
        textAlign: 'right',
    },
    icon: {
        marginRight: 16
    },
    icon_bp4: {
        marginRight: 8
    }
});

export const Footer = (props) => {
    const {config, breakpoint} = props;
    const imprintRoute = config.routes.find((route) => route.name === 'Impressum');
    const policyRoute = config.routes.find((route) => route.name === 'Datenschutz');
    const bp_margin = breakpoint < 3;

    const theme = useSelector(state => state.theme);
    const overwriteStyle = StyleSheet.create({
        container: {
            borderTopColor: theme.colors.border,
        },
        linkText: {
            ...theme.typo.p_sm,
            color: theme.colors.text_dark
        },
        copyRightText: {
            ...theme.typo.p_sm,
            color: theme.colors.text_unimportant
        }
    });

    return <View style={[styles.container, overwriteStyle.container,
        (bp_margin && styles.container_bp4)]}>

        {policyRoute ? <Link to={{screen: 'Datenschutz'}}>
            <View style={[styles.linkContainer, (bp_margin && styles.linkContainer_bp4)]}>
                {policyRoute?.icon ? <WebIcon
                    icon={policyRoute?.icon}
                    style={[styles.icon, (bp_margin && styles.icon_bp4)]}
                    color={theme.colors.text_dark}
                    size={20}/> : null}
                <Text style={styles.linkText}>Datenschutz</Text>
            </View>
        </Link> : null}

        {imprintRoute ? <Link to={{screen: 'Impressum'}}>
            <View style={[styles.linkContainer, (bp_margin && styles.linkContainer_bp4)]}>
                {imprintRoute?.icon ? <WebIcon
                    icon={imprintRoute?.icon}
                    style={[styles.icon, (bp_margin && styles.icon_bp4)]}
                    color={theme.colors.text_dark}
                    size={20}/> : null}
                <Text style={[styles.linkText, overwriteStyle.linkText]}>Impressum</Text>
            </View>
        </Link> : null}

        <View style={styles.copyRightContainer}>
            <Text style={[styles.copyRightText, overwriteStyle.copyRightText]}>© 2022 - Copyright - KiKxxl GmbH</Text>
        </View>
    </View>;
};
