import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import * as Notifications from "expo-notifications";
import {AndroidNotificationPriority} from "expo-notifications";
import {isDevelopment} from "../../../constants/booleanValues";

interface INotificationData {
    //entity that notification is linked to id, but since "we don't have time to refactor", we got news_id and thread_id as well
    id?: number,
    thread_id?: number,
    news_id?: number,
    type: number,
    notification_id: string,
}

/**
 * Notification data structure that is in Global admin
 */
export interface INotificationGlobalAdmin {
    createdAt: string,//Date
    data: INotificationData,
    description: string,
    title: string,
    isRead: boolean,
    silent: boolean,
    //same as data.notification_id
    id?: number;
}

// we use "seen" additionally to mark a message as "seen"
export type INotification = Notifications.Notification & { seen: boolean, data?: INotificationData };

type NotificationState = {
    seen: boolean,
    updatedAt: any,
    notifications: INotification[],
    //do we have notifications also loaded from the global admin?
    isLoaded: boolean,
}

/**
 * Maps global admin notification to push notification data structure
 * todo: extend INotification with notification_id
 * @param gNotification
 */
const buildNotification = (gNotification: INotificationGlobalAdmin): INotification => {
    // console.log("buildNotification from", gNotification.createdAt, " to ", global.moment(gNotification.createdAt).toDate().getTime())
    // console.log("buildNotification seen:", gNotification.isRead, gNotification.title,  gNotification.data.notification_id ?? gNotification.id)
    return {
        date: global.moment(gNotification.createdAt).toDate().getTime(),//important! as notifications received in microseconds separated by "."
        seen: gNotification.isRead,
        request: {
            content: {
                title: gNotification.title,
                subtitle: null,
                body: gNotification.description,
                data: {
                    id: gNotification.data.id ?? gNotification.data.thread_id ?? gNotification.data.news_id,
                    type: gNotification.data.type,
                    notification_id: gNotification.data.notification_id ?? gNotification.id
                },
                sound: "default",
                priority: AndroidNotificationPriority.DEFAULT,
            },
            identifier: Math.random().toString(),
            trigger: {
                // remoteMessage: {
                //     data: {
                //         id: gNotification?.data?.id?.toString() ?? "",
                //         type: gNotification?.data?.type?.toString() ?? "",
                //     },
                //     collapseKey: null,
                //     from: null,
                //     messageId: null,
                //     originalPriority: 1,
                //     priority: 1,
                //     sentTime: new Date(gNotification.createdAt).getMilliseconds(),
                //     ttl: 1,
                //     messageType: null,
                //     to: null,
                //     notification: null,
                // },
                type: "push"
            }
        }
    }
}

/**
 * compares notification with global admin notifiation
 * @param n
 * @param gNotification
 */
const compareNotifications = (n, gNotification: INotificationGlobalAdmin) => {
    const res = (gNotification.id !== undefined && (n?.request?.content?.data?.notification_id === gNotification.id)) //some edge case from messy data structure that has to be reviewed
        || (gNotification?.data?.notification_id !== undefined && n?.request?.content?.data?.notification_id === gNotification.data.notification_id)
    // console.log("compareNotifications", n?.request?.content?.data?.notification_id, " with ", gNotification?.data?.notification_id, res);
    return res;
}

export const notifications = createSlice({
    name: 'notifications',
    initialState: {updatedAt: null, notifications: [], seen: true, isLoaded: false} as NotificationState,
    reducers: {
        /**
         * Loads global admin notifications into our notification list
         * @param state
         * @param action
         */
        addLoadedData: (state, action: PayloadAction<INotificationGlobalAdmin[]>) => {
            if (!state.isLoaded) {
                // console.log("Loading Global Admin notifications into notification list", action.payload);
                action.payload?.forEach(gNotification => {
                    //if we don't have such notification - add to the list
                    if (state.notifications.findIndex(n => {
                            const res = compareNotifications(n, gNotification);
                            // console.log ("looking for ", gNotification.id, n?.request?.content?.data?.notification_id, res);
                            return res;
                        }
                    ) === -1) {
                        state.notifications = [
                            ...state.notifications,
                            buildNotification(gNotification)
                        ]
                    }
                });
                //sorting happens in GUI
                // state.notifications = state.notifications.sort((a,b) => a.date > b.date ? 1 : -1);
                state.isLoaded = true;
                // console.log("addLoadedData", state.notifications);
            }

        },
        addNotification: (state, action: PayloadAction<Notifications.Notification>) => {
            // const currentState = current(state);
            isDevelopment && console.log("addNotification", /*action.payload.date,*/ JSON.stringify(action.payload.request?.content?.data))
            const foundIndex = state.notifications.findIndex(n => n.request?.content?.data?.notification_id === action.payload?.request?.content?.data?.notification_id);
            if (foundIndex === -1) {
                state.notifications = [
                    ...state.notifications,
                    {
                        seen: false,
                        ...action.payload,
                        /**
                         * Notifications received from notification center have format
                         * 1695207686.9767652 of timestamp, while notifications loaded from globalAdminAPI
                         * 1695132088000 format.
                         * Since this code is called only from notification center, we multiply by 1000
                         * to match formats
                         */
                        date: action.payload.date * 1000
                    },
                ]
                // console.log("notification added", state.notifications.length, state.notifications[state.notifications.length-1])

                state.seen = false;
            }
        },
        sawNotification: (state, action) => {
            const notificationId = action.payload?.notification?.request?.content?.data?.notification_id ??
                action.payload?.request?.content?.data?.notification_id; //comes from Notifications.native clicking on notification
            //set seen status of notification with requestId
            state.notifications = state.notifications.map(notification => {
                // console.log("sawNotifications, comparing", notification.request?.content?.data?.notification_id, notificationId,
                //     notification.request?.content?.data?.notification_id === notificationId);

                if (notification.request?.content?.data?.notification_id === notificationId) {
                    // if (compareNotifications(notification, action.payload?.notification?.request)) {
                    // if (notification.request?.identifier === action.payload.request?.identifier) {
                    // console.log("sawNotifications, marking", notificationId);
                    return {
                        ...notification,
                        seen: true
                    }
                } else return notification
            });
            if (state.notifications.every(notification => notification.seen === true))
                state.seen = true
            return state;
        },
        sawNotifications: (state, action) => {
            state.seen = true;
            return state;
        },
        removeNotification: (state, action) => {
            state.notifications = state.notifications.filter(item => item.request?.identifier !== action.payload.request?.identifier);
            if (state.notifications.length < 1) state.seen = true;
            return state;
        },
        removeOldNotifications: (state, action) => {
            // console.log("state before removing old notifications", state)
            state.notifications = state.notifications.filter(item => {
                /*Investigate:
                something fishy is happening with date and the multiplying.
                we multiply date already at addNotification function, so we don't need to multiply any longer.
                 */
                const date = new Date(item.date);
                // console.log("item is less than 3 days old? ",!global.moment().subtract(3, 'days').isAfter(global.moment(new Date(date))));
                return !global.moment().subtract(3, 'days').isAfter(global.moment(new Date(date)))
            });
            // console.log("state after removing old notifications", state)
            return state;
        },
    },
})
// Action creators are generated for each case reducer function
export const {
    addLoadedData,
    addNotification,
    sawNotifications,
    sawNotification,
    removeNotification,
    removeOldNotifications
} = notifications.actions
export default notifications.reducer
