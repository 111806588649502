import {Dimensions, Platform, StyleSheet, Text} from "react-native";
import React from "react";
import {useSelector} from "react-redux";
import {launchImageLibrary, requestMediaLibraryPermissions} from "../../../utils/ImagePicker";
import Button from "../../Button/Button";
import ModalContainer from "../ModalContainer";

export function ImageOrLibraryDialog(props) {
    const {visible, setVisible, onMediaGalleryClicked, onCameraClicked, onImageDeleted} = props;
    const theme = useSelector(state => state.theme);
    const overwriteStyles = StyleSheet.create({
        title: {
            ...theme.typo.p_md_bold,
            color: theme.typo.text_dark,
            alignSelf: "self-start"

        }
    });
    const {width, height} = Dimensions.get("window");
    const style = StyleSheet.create({
        title: {
            marginBottom: 16,
        },
        centeredView: {
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            flex: 1,
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            top: 76 + (Platform.OS === 'ios' ? 44 : 0),
            //marginTop: 22,
        },
        modalView: {
            margin: 20,
            width: width * 0.8,
            backgroundColor: "white",
            // borderRadius: 20,
            padding: 35,
            alignItems: "center",
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 2
            },
            shadowOpacity: 0.25,
            shadowRadius: 4,
            elevation: 5
        },
        buttonClose: {
            position: "absolute",
            right: 15,
            top: 10,
        },
    });
    return <>
        <ModalContainer
            animationType="fade"
            transparent={true}
            style={{
                width: width,
            }}
            visible={visible}
            setVisible={setVisible}
            setEventIdVisible={() => {
            }}//??
        >
            <Text style={[style.title, overwriteStyles.title]}>Profilbild bearbeiten</Text>

            <Button style={{button: {borderRadius: 25, width: '100%'}}} text={"Medienbibliothek"} onClick={() => {
                launchImageLibrary().then((res) => {
                    if (res.canceled === true) return;
                    onMediaGalleryClicked(res)
                }).catch((e) => {
                    requestMediaLibraryPermissions().then(r => {
                    })
                })
            }}/>
            <Button
                style={{
                    button: {
                        marginTop: 15,//just to be below another button
                        width: '100%',
                        backgroundColor: theme.colors.error,
                        borderRadius: 25,
                        // orderRadius: width * 0.6,
                    }
                }}
                text={"Profilbild zurücksetzen"}
                onClick={() => {
                    onImageDeleted()
                }}/>
        </ModalContainer>

        {/*    <Pressable style={style.centeredView} onPress={()=>onDismissed()}>*/}
        {/*        <View style={style.modalView}>*/}
        {/*            <TouchableOpacity*/}
        {/*                style={[style.buttonClose]}*/}
        {/*                onPress={() => {*/}
        {/*                    onDismissed()*/}
        {/*                }}*/}
        {/*            >*/}
        {/*                <FontAwesome5*/}
        {/*                    size={25}*/}
        {/*                    color={theme.colors.text}*/}
        {/*                    name="times"*/}
        {/*                />*/}
        {/*            </TouchableOpacity>*/}
        {/*            <View>*/}
        {/*                <Button  style={{button: {borderRadius: 0,width: width * 0.6}}} text={"Kamera"} onClick={()=> {*/}
        {/*                    launchCamera().then((res)=>{*/}
        {/*                        if(res.canceled === true) return;*/}
        {/*                        onCameraClicked(res)*/}
        {/*                    }).catch((e)=>{*/}
        {/*                        requestCameraPermissions().then(r => {})*/}
        {/*                    })*/}
        {/*                }}/>*/}
        {/*                <Button style={{button: {borderRadius: 0,width: width * 0.6}}} text={"Medienbibliothek"} onClick={()=> {*/}
        {/*                    launchImageLibrary().then((res)=>{*/}
        {/*                        if(res.canceled === true) return;*/}
        {/*                        onMediaGalleryClicked(res)*/}
        {/*                    }).catch((e)=>{*/}
        {/*                        requestMediaLibraryPermissions().then(r => {})*/}
        {/*                    })*/}
        {/*                }}/>*/}
        {/*            </View>*/}
        {/*        </View>*/}
        {/*    </Pressable>*/}
    </>
}
