import {ActivityIndicator, StyleSheet, View} from "react-native";
import React from "react";
import {useSelector} from "react-redux";

export default function LoadingIndicator(props) {
    const {size = "large", style = {}, noStyles = false} = props;
    const theme = useSelector(state => state.theme);
    if(noStyles === true){
        return <ActivityIndicator size={size} color={theme.colors.primary}/>
    }
    return <View style={[styles.container,style.container]}>
        <ActivityIndicator
            style={[styles.indicator,style.indicator]} size={size} color={theme.colors.primary}/>
    </View>
}
const styles = StyleSheet.create({
    container: {
        padding: 20,
        justifyContent: 'center',
        alignItems: 'center',
        flex: 1
    },
    indicator: {
        alignSelf: "center"
    }
})
