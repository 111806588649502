import {StyleSheet, TouchableOpacity} from "react-native";
import React, {useState} from "react";
import {FontAwesome5, Ionicons} from '@expo/vector-icons';
import {useSelector} from "react-redux";
import {Dropdown} from "react-native-element-dropdown";

export default function SelectBox(props) {
    const {style: propStyles = {}} = props;
    const theme = useSelector(root=>root.theme);
    const {labelField = "name",valueField = "id",onValueChanged, value, items,placeholder = "Auswählen...",
        useClearIcon = true,
        onClear,
    } = props;
    return <Dropdown
        style={[styles.dropdown,{...propStyles.dropdown}]}
        placeholderStyle={[styles.placeholderStyle,{color: theme.colors.text},{...propStyles.placeholderStyle}]}
        selectedTextStyle={[styles.selectedTextStyle,{color: theme.colors.text},{...propStyles.selectedTextStyle}]}
        inputSearchStyle={[styles.inputSearchStyle,{color: theme.colors.text},{...propStyles.inputSearchStyle}]}
        itemTextStyle={[styles.itemTextStyle,{color: theme.colors.text},{...propStyles.itemTextStyle}]}
        iconStyle={styles.iconStyle}
        data={items}
        search
        maxHeight={300}
        labelField={labelField}
        valueField={valueField}
        placeholder={placeholder}
        searchPlaceholder="Suchen..."
        value={value}
        onChange={onValueChanged}
        renderLeftIcon={() => {
            if(useClearIcon === true){
                return  <TouchableOpacity style={{alignItems:"center",height: 20, width: 20,marginRight:5 }} onPress={()=> {
                    if(typeof onClear === "function") onClear()
                    else console.warn("onClear not defined : SelectBox")
                }}>
                    <FontAwesome5
                        color={theme.colors.primary}
                        size={20}
                        name={"times-circle"}
                    />
                </TouchableOpacity>
            }
        }}
    />
}
const styles = StyleSheet.create({
    dropdown: {
        margin: 16,
        fontFamily: "OpenSans_600SemiBold",
        height: 50,
        borderColor: 'gray',
        borderWidth: 0.5,
        borderRadius: 15,
        padding: 8,
        backgroundColor: "white"
    },
    icon: {
        marginRight: 5,
    },
    itemTextStyle:{
        fontFamily: "OpenSans_600SemiBold",
        fontSize: 16,
    },
    placeholderStyle: {
        fontFamily: "OpenSans_600SemiBold",
        fontSize: 16,
    },
    selectedTextStyle: {
        fontFamily: "OpenSans_600SemiBold",
        fontSize: 16,
    },
    iconStyle: {
        width: 20,
        height: 20,
    },
    inputSearchStyle: {
        height: 40,
        fontSize: 16,
    },
});
