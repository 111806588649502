import {StyleSheet, Text, View} from 'react-native';
import React from "react";
import {Image} from "react-native-expo-image-cache";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import {useSelector} from "react-redux";
import useGrid from "../../utils/useGrid";
import {Link} from "@react-navigation/native";
import useInfiniteScroll from "../../utils/hooks/useInfiniteScroll";
import LoadMoreButton from "../Button/LoadMoreButton";

const PAGE_SIZE = 10;
export default function FotoGalleries(props) {
    const {ref, tiles, tileDim} = useGrid(202, 24, 6);
    //const {refetch, data, error, isLoading} = endpoints.getGalleries.useQuery();
    const {
        combinedData: data,
        readMore,
        refresh,
        isLoading,
        isFetching,
        refetch,
        canFetchMore
    } = useInfiniteScroll(endpoints.getGalleriesPaginated.useQuery,
        {
            size: PAGE_SIZE,
            notifyOnNetworkStatusChange: true,
        });
    const theme = useSelector(state => state.theme);

    return <View>
        {isLoading
            ? <LoadingIndicator/>
            : <>
                <View ref={ref} style={[styles.container]}>
                    {data?.map((item, idx) => (
                        <Link
                            key={idx}
                            to={{screen: 'Fotogalerie', params: {galleryId: item.id, name: item.name}}}>
                            <View style={[styles.itemContainer,
                                (idx < tiles && styles.itemContainer_firstRow),
                                (idx % tiles === 0 && styles.itemContainer_firstCol)]}>
                                <Image
                                    preview={{uri: item.cover?.url}}
                                    uri={item.cover?.url}
                                    style={[styles.galleryCategoryImage, {width: tileDim, height: tileDim}]}/>
                                <Text style={styles.labelText}>
                                    {item.name}</Text>
                            </View>
                        </Link>
                    ))}
                </View>
                {
                    canFetchMore && <View style={styles.loadMoreButton}>
                        <LoadMoreButton onPress={readMore} disabled={!canFetchMore}/>
                    </View>
                }
            </>}
    </View>
}

const styles = StyleSheet.create({
    loadMoreButton: {
        alignSelf: "center",
        marginTop: 20
    },
    container: {
        flex: 1,
        flexDirection: "row",
        flexWrap: "wrap",
    },
    itemContainer: {
        alignItems: "center",
        marginLeft: 24,
        marginTop: 24,
    },
    itemContainer_firstCol: {
        marginLeft: 0,
    },
    itemContainer_firstRow: {
        marginTop: 0
    },
    galleryCategoryImage: {
        height: 160,
        width: 160,
        borderRadius: 22,
    },
    labelText: {
        fontFamily: "OpenSans_300Light",
        fontSize: 13,
        marginTop: 24,
    }
});
