import {Pressable, StyleSheet, Text, View} from 'react-native';
import CaptionBanner from "../CaptionBanner/CaptionBanner";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import React from "react";
import {useSelector} from "react-redux";
import {replaceHtml} from "../../utils/strings";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {NO_DATA_MESAGE,} from "../../constants/strings";

const styles = StyleSheet.create({
    categoryContainer: {
        flexShrink: 1,
        padding: 24,
        borderWidth: 1,
        marginTop: 16
    },
    categoryContainer_first: {
        marginTop: 0
    },
    categoryHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingBottom: 15
    },
    categoryLabel: {
        fontSize: 17,
    },
    infoLabel: {}
});

export default function ForumActivity(props) {
    let {days} = props?.route?.params;
    const theme = useSelector(state => state.theme);
    const {
        data: threadActivityData,
        refetchThreadActivity,
        error: threadActivityDataError,
        isLoading: threadActivityDataLoading
    } = endpoints.getThreadActivity.useQuery(days);
    if (threadActivityDataLoading) return <LoadingIndicator/>;

    const overwriteStyles = StyleSheet.create({
        categoryContainer: {
            backgroundColor: theme.colors.background_light,
            borderColor: theme.colors.border,
            borderRadius: theme.borderRadius,
        },
        categoryLabel: {
            ...theme.typo.p_md_bold,
            color: theme.colors.text_dark
        },
        infoLabel: {
            ...theme.typo.p_sm
        }
    });
    return <View>
        <CaptionBanner config={{
            headline: `Forumaktivitäten der letzen ${days} Tage`
        }}/>
        <View>
            {threadActivityData?.threads?.map((thread, idx) => (
                <Pressable
                    key={idx}
                    style={[styles.categoryContainer, overwriteStyles.categoryContainer, (idx === 0 && styles.categoryContainer_first)]}
                    onPress={() => props.navigation.navigate("ForumPage", {
                        id: thread.id,
                        commentId: thread.last_comment?.id
                    })}>
                    <View style={[styles.categoryHeader]}>
                        <Text style={[styles.categoryLabel, overwriteStyles.categoryLabel]}>{thread.title}</Text>
                        <FontAwesome5 name={'link'} size={20} color={theme.colors.text_dark}/>
                    </View>
                    <View>
                        <Text style={[styles.infoLabel, overwriteStyles.infoLabel]}>
                            {`Letzer Eintrag: ${global.moment(new Date(thread?.last_comment?.created_at)).format("DD.MM.YY - LT [Uhr]")}`}</Text>
                        <Text style={[styles.infoLabel, overwriteStyles.infoLabel]}>
                            {`${replaceHtml(thread.last_comment?.text)}`}</Text>
                    </View>
                </Pressable>
            ))}
            {
                Array.isArray(threadActivityData?.threads) && threadActivityData?.threads.length === 0 &&
                <Text>{NO_DATA_MESAGE}</Text>
            }
        </View>
    </View>

}
