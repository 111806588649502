import { HTMLElementModel, HTMLContentModel } from 'react-native-render-html';

/**
 * We receive sometimes from global admin <font> tag, such as: https://www.w3schools.com/tags/tag_font.asp
 * so we handle it to avoid warnings
 *
 * to enable Font attributes on html5, which isn't part of html5 standard, just so that we don't see complaints as warnings in console
 * Based on https://k--taeyang-tistory-com.translate.goog/37?_x_tr_sl=auto&_x_tr_tl=de&_x_tr_hl=de&_x_tr_pto=wapp
 * usage:
 *
 * <RenderHTML
 *               source={htmlSource}
 *               contentWidth={width}
 *               customHTMLElementModels={customHTMLElementModels}
 *             />
 * todo: 1) test out function parameters and transit from getUADerivedStyleFromAttributes to getMixedUAStyles
 */
const fontElementModel = HTMLElementModel.fromCustomModel({
    tagName: 'font',
    contentModel: HTMLContentModel.mixed,
    //based on https://meliorence.github.io/react-native-render-html/blog/2021/10/23/6-2-updates
    getMixedUAStyles(tnode, element){
        // console.log("getMixedUAStyles", tnode, element)
        //todo: we could handle font size, color, face here and return it
        return {
        }
    },
    //@deprecated:
    // getUADerivedStyleFromAttributes({ face, color, size }) {
    //     console.log("getUADerivedStyleFromAttributes", {face, color, size})
    //     let style = {};
    //     if (face) {
    //         style.fontFamily = face;
    //     }
    //     if (color) {
    //         style.color = color;
    //     }
    //     if (size) {
    //         // handle size such as specified in the HTML4 standard. This value
    //         // IS NOT in pixels. It can be absolute (1 to 7) or relative (-7, +7):
    //         // https://www.w3.org/TR/html4/present/graphics.html#edef-FONT
    //         // implement your solution here
    //     }
    //
    //     return style;
    // },
});
export const customHTMLElementModels = {
    font: fontElementModel,
};