/**
 * Groups list by the key generated using keyGetter
 * @param list
 * @param keyGetter
 * @returns {Map<any, any>|*}
 */
export function groupBy(list, keyGetter) {
    if (!Array.isArray(list)) return list;
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        if (!map.has(key)) {
            map.set(key, [item]);
        } else {
            map.get(key).push(item);
        }
    });
    return map;
}
