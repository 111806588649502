import {useSelector} from "react-redux";
import {StyleSheet, Text} from "react-native";
import React from "react";

const NewsArticlePreviewDate = (props) => {
    const {date} = props;
    const theme = useSelector(state => state.theme);

    const style = StyleSheet.create({
        date: {
            ...theme.typo.p_md_light,
            color: theme.colors.text_dark,
            padding: theme.wrapperPadding,
            paddingBottom: 0,
            fontFamily: "OpenSans_300Light_Italic",
        }
    });

    return date
        ? <Text style={style.date}>{global.moment(new Date(date)).format('DD.MM.YYYY')}</Text>
        : null;

}

export default NewsArticlePreviewDate;