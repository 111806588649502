import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import {
    Dimensions,
    Image,
    Image as ImageReact,
    PanResponder,
    ScrollView,
    StyleSheet,
    Text,
    TouchableOpacity,
    View
} from "react-native";
import {useSelector} from "react-redux";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";

const styles = StyleSheet.create({
    container: {
        width: '100%',
        maxHeight: 600,
    },
    scroll: {
        alignSelf: "center",
        width: '100%',
        height: '100%',
        scrollSnapType: "x mandatory",
        cursor: 'grab'
    },
    scrollContentContainer: {
        flex: 1,
        alignItems: 'stretch',
        justifyContent: 'flex-start',
    },
    pressable: {
        height: '100%',
        backgroundColor: '#fff'
    },
    image: {
        width: '100%',
        height: '100%',
        resizeMode: "cover",
    },
    textContainer: {
        position: 'absolute',
        bottom: 40,
        left: 80,
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start'
    },
    navigationArrow: {
        position: 'absolute',
        top: '48%'
    },
    navigationArrow_left: {
        alignSelf: 'center',
        left: 80,
    },
    navigationArrow_right: {
        right: 80,
    },
    pagination: {
        flexDirection: "row",
        position: 'absolute',
        alignSelf: "center",
        alignItems: 'center',
        bottom: 10,
        height: 40,
    },
    pagingText: {
        color: "white",
        margin: 3
    },
    pagingActiveText: {
        margin: 3
    },
    headlineText: {
        paddingHorizontal: 8,
        paddingTop: 4,
        fontSize: 30
    },
    newsTitle: {
        fontFamily: "BebasNeue_400Regular",
        fontSize: 43,
        marginRight: 12,
        paddingTop: 2,
    },
    newsTitleTouchable: {
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: 16,
        paddingHorizontal: 12,
        paddingTop: 2,
    }
});

export default function ImageSlider(props) {
    let {width, height} = Dimensions.get("window")
    height = width * 0.2;
    const {
        onImageChanged,
        images = [], fullScreen, initialImage, onPress, featuredNews,
        isSwipeEnabled = true,
    } = props;
    const [active, setActive] = useState(0);
    const theme = useSelector(state => state.theme);
    const [dim, setDim] = useState(Dimensions.get("window"));
    const activeSlide = useRef(0);
    useEffect(() => {
        const onResize = () => setDim(Dimensions.get("window"));
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, []);
    const panResponder = React.useRef(
        isSwipeEnabled
            ? PanResponder.create({
                onStartShouldSetPanResponder: (evt, gestureState) => true,
                onStartShouldSetPanResponderCapture: (evt, gestureState) => true,
                onMoveShouldSetPanResponder: (evt, gestureState) => true,
                onMoveShouldSetPanResponderCapture: (evt, gestureState) => true,
                onPanResponderMove: (evt, gestureState) => {
                    if (gestureState.dx > 200) {
                        scrollRef?.current?.scrollTo({x: (activeSlide.current - 1) * dim.width, y: 0, animated: true})
                    } else if (gestureState.dx < -200) {
                        scrollRef?.current?.scrollTo({x: (activeSlide.current + 1) * dim.width, y: 0, animated: true})
                    }
                },
                onPanResponderTerminationRequest: (evt, gestureState) => true,
                onShouldBlockNativeResponder: (evt, gestureState) => true,
            })
            : null
    ).current;

    function change(event) {
        const slide = Math.round(event.nativeEvent.contentOffset?.x / event.nativeEvent.layoutMeasurement?.width);
        if (slide !== active) {
            setActive(slide);
            activeSlide.current = slide;
        }
        if (onImageChanged && typeof onImageChanged === 'function') onImageChanged(slide)
    }

    let scrollRef = useRef(null);
    const overwriteStyles = StyleSheet.create({
        container: {
            backgroundColor: theme.backgroundColor,
            height: (fullScreen ? "100%" : (dim.height * .7)),
        },
        pagingActiveText: {
            color: theme.colors.primary,
        },
        headlineText: {
            ...theme.typo.button,
            fontSize: 43
        },
        newsTitle: {
            color: theme.colors.primary,
        },
        newsTitleTouchable: {
            backgroundColor: theme.colors.background_light,
        }
    });

    useEffect(() => {
        scrollRef?.current?.scrollTo({
            x: (initialImage ? (initialImage + 1 - 1) : (active - 1)) * dim.width,
            y: 0,
            animated: true
        })
    }, [initialImage])

    const ref = useRef(null);
    const [imgWidth, setImgWidth] = useState(0);
    useLayoutEffect(() => {
        const el = ref.current;
        if (!el) return;
        const ro = new ResizeObserver((e) => {
            const rect = el.getBoundingClientRect();
            setImgWidth(rect.width);
        });
        ro.observe(el);
        return () => ro.disconnect();
    }, [ref.current]);


    const SliderArrow = ({dir}) => {
        const lastItemIdx = (dir === 'left' ? 0 : images.length - 1);
        const nextItemIdx = active + (dir === 'left' ? -1 : 1);
        return <>
            {(active !== lastItemIdx) && <TouchableOpacity
                style={[styles.navigationArrow, styles[`navigationArrow_${dir}`]]}
                onPress={() => {
                    scrollRef?.current?.scrollTo({x: nextItemIdx * dim.width, y: 0, animated: true})
                }}>
                <FontAwesome5 color={"#fff"} size={30} solid name={`chevron-circle-${dir}`}/>
            </TouchableOpacity>}
        </>;
    };

    return <View style={[styles.container, overwriteStyles.container]} ref={ref}>
        <ScrollView
            ref={scrollRef}
            onStartShouldSetResponder={() => true}
            //pagingEnabled
            horizontal
            showsHorizontalScrollIndicator={false}
            style={{...styles.scroll}}
            contentContainerStyle={styles.scrollContentContainer}
            onScroll={change}
            scrollEventThrottle={20}
            {...(isSwipeEnabled ? panResponder.panHandlers : {})}
        >
            {featuredNews?.map((item, index) => (
                <View
                    key={index}
                    onPress={() => onPress(item)}
                    style={[styles.pressable, {width: imgWidth, scrollSnapAlign: "start"}]}>
                    {item.imageUrl ? <Image
                        source={item.imageUrl}
                        preview={{uri: item.previewUrl}}
                        style={styles.image}/> : <ImageReact resizeMode={"cover"} style={styles.image}
                                                             source={require("../../../assets/images/placeholder-web.jpg")}/>}
                    <View style={styles.textContainer}>
                        <Text style={[styles.headlineText, overwriteStyles.headlineText]}>Top Nachrichten</Text>
                        <TouchableOpacity
                            onPress={() => onPress(item)}
                            style={[styles.newsTitleTouchable, overwriteStyles.newsTitleTouchable]}>
                            <Text style={[styles.newsTitle, overwriteStyles.newsTitle]}>{item.title}</Text>
                            <FontAwesome5 name={'chevron-right'} color={theme.colors.primary} size={30}/>
                        </TouchableOpacity>
                    </View>
                </View>
            ))}
        </ScrollView>
        <SliderArrow dir={'left'}/>
        <SliderArrow dir={'right'}/>
        <View style={[styles.pagination]}>
            {featuredNews?.map((news, idx) => (
                <TouchableOpacity
                    key={idx}
                    onPress={() => scrollRef?.current?.scrollTo({x: idx * dim.width, y: 0, animated: true})}>
                    <Text
                        key={idx}
                        style={(idx === active) ? [styles.pagingActiveText, overwriteStyles.pagingActiveText] : styles.pagingText}>
                        ⬤
                    </Text>
                </TouchableOpacity>
            ))}
        </View>
    </View>
}
