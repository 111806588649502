import {StyleSheet, Text} from 'react-native';
import {useSelector} from "react-redux";

export default function CalendarNavigation(props) {
    const {navigation, label, style} = props;

    const theme = useSelector(state => state.theme);
    const overwriteStyles = StyleSheet.create({
        text: {
            color: theme.colors.primary,
        }
    });

    return <Text style={[styles.text, overwriteStyles.text, {...style?.label}]}>{label}</Text>
}

const styles = StyleSheet.create({
    text: {
        fontFamily: "BebasNeue_400Regular",
        fontSize: 30,
        paddingTop: 20,
        paddingBottom: 20,
    }
});
