import React, {useCallback} from 'react'
import {Platform, SafeAreaView} from "react-native";
import {useFonts} from "@expo-google-fonts/bebas-neue";
import * as SplashScreen from 'expo-splash-screen';
import "./src/utils/initIconLibrary";
import {Provider} from 'react-redux'
import {store} from "./src/app/store"
import {EntryPoint} from "./src/components/EntryPoint/EntryPoint";
import {SystemFonts} from "./src/constants/SystemFonts";
import * as Sentry from 'sentry-expo';
import Constants from "expo-constants";
import {isDevelopment} from "./src/constants/booleanValues";

SplashScreen.preventAutoHideAsync();
/**
 * Entry point for both iOS and web build
 * @constructor
 */
const App = () => {
    try {
        //TODO : Fonts: move to separateFile
        let [fontsLoaded] = useFonts(SystemFonts);
        const onLayoutRootView = useCallback(async () => {
            if (fontsLoaded) {
                /**
                 * We wait for fonts to get loaded and hide the SplashScreen after.
                 */
                // This tells the splash screen to hide immediately! If we call this after
                // `setAppIsReady`, then we may see a blank screen while the app is
                // loading its initial state and rendering its first pixels. So instead,
                // we hide the splash screen once we know the root view has already
                // performed layout.
                /**
                 * Sentry logs can be added like this
                 */
                // Sentry.addBreadcrumb({
                //     category: "auth",
                //     message: "Authenticated user " + user.email,
                //     level: "info",
                // });
                await SplashScreen.hideAsync();

                /**
                 * Sentry is a logger for production errors
                 * https://iscope.sentry.io/projects/kikxxl-mitarbeiter-app/?project=4504876784812032 : Login with Google Account used for Expo
                 */
                Sentry.init({
                    enableNative: !isDevelopment,
                    dsn: "https://eb3fd04343bc4c6d89389511166801ee@o4504876767117312.ingest.sentry.io/4504876784812032",
                    enableInExpoDevelopment: false,
                    debug: true, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
                });

                ["android", "ios"].includes(Platform.OS) && Sentry.Native.addBreadcrumb({
                    category: "build",
                    message: JSON.stringify({Constants, env: process.env}),
                    level: "info",
                });
            }
        }, [fontsLoaded]);

        if (!fontsLoaded) return null;

        return <SafeAreaView style={{flex: 1}} onLayout={onLayoutRootView}>
            <Provider store={store}>
                <EntryPoint/>
            </Provider>
        </SafeAreaView>;
    } catch (e) {
        console.error(e)
        /**
         * Catching Error with Sentry would look like this
         */
        Sentry.Native.captureException(e);
    }
}
export default App
