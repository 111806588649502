import {useSelector} from "react-redux";
import {StyleSheet, Text, View} from "react-native";
import {DEFAULT_ERROR, ERROR_TEXT_USER_OFFLINE, FETCH_ERROR} from "../../constants/strings";
import {useNetInfo} from "@react-native-community/netinfo";

export default function ErrorText(props) {
    const {
        text,
        error
    } = props;
    let errorText = "";
    const theme = useSelector(root => root.theme);
    const netInfo = useNetInfo();
    const style = StyleSheet.create({
        container: {
            padding: theme.wrapperPadding
        },
        text: {
            ...theme.text.paragraph,
            color: theme.colors.error
        }
    });
    if (text)
        errorText = text; // custom Text via prop
    else {
        if (error && error.status === FETCH_ERROR) {
            //we got a fetch error, lets see if we are online
            if (netInfo.isConnected) {
                errorText = error.status;
            } else {
                errorText = ERROR_TEXT_USER_OFFLINE;
            }
        } else {
            errorText = DEFAULT_ERROR;
        }
    }
    return <View style={style.container}>
        <Text style={style.text}>
            {errorText}
            {error ? JSON.stringify(error) : ""}
        </Text>
    </View>
}
