import {ActivityIndicator, FlatList, StyleSheet, Text, TouchableOpacity, useWindowDimensions, View} from 'react-native';
import React, {useEffect, useRef, useState} from "react";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import {useSelector} from "react-redux";
import {Image} from "react-native-expo-image-cache";
import CaptionBanner from "../CaptionBanner/CaptionBanner";
import useGrid from "../../utils/useGrid";
import BackNavigationBanner from "../BackNavigationBanner/BackNavigationBanner";
import LightboxModal from "../Modals/LightboxModal/LightboxModal";
import useInfiniteScroll from "../../utils/hooks/useInfiniteScroll";
import useTitle from "../../utils/hooks/useTitle";

//TODO: uninstall react-native-anchor-carousel react-native-image-slider-box
const PAGE_SIZE = 20;
export default function GalleryDetailView(props) {
    const {route, navigation, maxTiles = 6} = props;

    const {ref, tiles, tileDim} = useGrid(202, 24, maxTiles);
    const {galleryId, name} = route.params;
    console.log("GalleryDetailView", {galleryId, name});

    const {
        combinedData: data = [],
        maxPages,
        localPage,
        readMore,
        refresh,
        isLoading,
        isFetching,
        refetch,
        canFetchMore,
        remoteTotal,
        remotePage,
        remoteSize,
        extraData
    } = useInfiniteScroll(
        endpoints.getGalleryPaginated.useQuery,
        {
            size: PAGE_SIZE,
            galleryId,
        },
        {
            entries: "media",
            page: "pagination.page",
            total: "pagination.total",
            size: "pagination.per_page"
        },
        ["name"]
    );
    const [modalVisible, setModalVisible] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const theme = useSelector(state => state.theme);
    const {height} = useWindowDimensions();
    useTitle(navigation, isLoading, name ?? extraData.name);

    // console.log("GalleryDetailView", id, data)

    const overwriteStyle = StyleSheet.create({
        headline: {
            color: theme.colors.text_dark
        }
    });

    function _renderInfiteScrollLoading(props) {
        if (!isFetching) return undefined;
        return (
            <View style={{
                flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                paddingVertical: 10
            }}>
                <ActivityIndicator animating size={"large"} color={theme.colors.primary}/>
            </View>
        );
    }

    const selectImg = (idx) => {
        setModalVisible(true)
        setCurrentImage(idx)
    }

    // if LoadingIndicator is used like below, we get a bug with FlatList onEndReached : Can be tested via refreshing the view - onEndReached wont be fired
    if (isLoading) return <LoadingIndicator/>;

    return <View style={styles.container} ref={ref}>
        <BackNavigationBanner
            {...props}
            onPress={() => navigation.navigate('Fotogalerien')}
            label={"Fotogalerien"}
            iconBefore={"chevron-left"}
        />
        <CaptionBanner config={{
            headline: (name ?? extraData.name ?? "Unbenannte Galerie")
        }}/>
        <FlatList
            style={[styles.flatlist]}
            contentContainerStyle={{
                paddingBottom: 40,
                height: height - 460 //TODO: calculate real components height and substract
            }}
            data={data}
            keyExtractor={item => item.gallery_media}
            key={tiles} // forced refresh on column-change
            numColumns={tiles}
            // Performance settings
            removeClippedSubviews={true} // Unmount components when outside of window
            initialNumToRender={10} // Reduce initial render amount
            maxToRenderPerBatch={10} // Reduce number in each render batch
            updateCellsBatchingPeriod={100} // Increase time between renders
            windowSize={7} // Reduce the window size
            onEndReachedThreshold={0.1}
            onEndReached={() => {
                readMore();
            }}
            refreshing={isFetching || isLoading}
            ListFooterComponent={_renderInfiteScrollLoading}
            renderItem={({item, index}) => (
                <View
                    style={[styles.itemContainer, {width: tileDim},
                        (index < tiles && styles.itemContainer_firstRow),
                        (index % tiles === 0 && styles.itemContainer_firstCol)
                    ]}
                >
                    <TouchableOpacity
                        key={item.id}
                        style={[styles.imageTouchable]}
                        onPress={() => selectImg(index)}>
                        <Image
                            preview={{uri: item?.urls?.reference}}
                            uri={item?.urls?.reference}
                            style={[styles.galleryCategoryImage, {width: tileDim, height: tileDim}]}
                        />
                    </TouchableOpacity>
                    <Text style={styles.imageLabel}>{item.name}</Text>
                </View>
            )}
        />
        <LightboxModal
            visible={modalVisible}
            setVisible={setModalVisible}
            index={currentImage}
            setIndex={setCurrentImage}
            remoteSize={remoteSize}
            usePagination={true}
            loadMore={readMore}
            remotePage={remotePage}
            remoteTotal={remoteTotal}
            imageUrls={data?.map(item => ({url: item.urls?.reference}))}/>
    </View>
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    itemContainer: {
        alignItems: "center",
        marginLeft: 24,
        marginTop: 24,
    },
    itemContainer_firstCol: {
        marginLeft: 0,
    },
    itemContainer_firstRow: {
        marginTop: 0
    },
    imageTouchable: {
        alignItems: "stretch",
        width: '100%',
    },
    galleryCategoryImage: {
        minHeight: 202,
        resizeMode: 'cover',
        borderRadius: 22,
    },
    imageLabel: {
        textAlign: "center",
        fontFamily: "OpenSans_300Light",
        fontSize: 13,
        marginTop: 6,
        flexWrap: "wrap",
    },
    // list
    headline: {
        color: '#000',
        fontFamily: "OpenSans_700Bold",
        fontSize: 30,
        marginTop: 20,
        marginLeft: 20,
        marginBottom: 20
    },
    flatlist: {
        flex: 1,
    },
    header: {
        zIndex: 1005,
        width: '100%',
        alignItems: 'flex-end',
    },
    crossContainer: {
        padding: 40,
        justifyContent: 'center',
        alignItems: 'center'
    },
    // todo: make size responsive
    btn: {
        width: 300,
        height: 300,
        padding: 40,
        justifyContent: 'center',
        alignItems: 'center',
    },
    btn_right: {
        alignItems: 'flex-end',
    },
    btn_left: {
        alignItems: 'flex-start',
    }
});
