import {Pressable, StyleSheet, Text, TextInput, View} from "react-native";
import ModalContainer from "../ModalContainer";
import {useSelector} from "react-redux";
import React, {useState} from "react";
import {usePostThreadMutation} from "../../../app/services/api/globalAdminAPI";

const styles = StyleSheet.create({
    container: {
        width: '100%',
        alignItems: 'flex-start'
    },
    title: {
        marginBottom: 16,
    },
    inputGroup: {
        width: '100%',
        marginTop: 24,
    },
    inputGroup_first: {
        marginTop: 0,
    },
    input: {
        borderWidth: 1,
        padding: 8,
    },
    input_multiline: {
        height: 160
    },
    label: {
        marginBottom: 4
    },
    createBtn: {
        paddingHorizontal: 20,
        paddingVertical: 12,
        marginTop: 24,
        borderRadius: 100,
    },
    errText: {
        marginTop: 4,
    }
});

const CreateForumThreadModal = (props) => {
    const {visible, setVisible, forumId, onAdd} = props;
    // const [category, setCategory] = useState('category1');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    // errors
    const [categoryErr, setCategoryErr] = useState(false);
    const [titleErr, setTitleErr] = useState(false);
    const [contentErr, setContentErr] = useState(false);
    const [postThread, {isLoading: postThreadIsLoading}] = usePostThreadMutation();

    const theme = useSelector(state => state.theme);
    const overwriteStyles = StyleSheet.create({
        title: {
            ...theme.typo.p_md_bold,
            color: theme.typo.text_dark
        },
        input: {
            borderColor: theme.colors.border
        },
        label: {
            ...theme.typo.p_sm,
        },
        createBtn: {
            backgroundColor: theme.colors.primary,
        },
        createBtnText: {
            ...theme.typo.p_sm,
            color: theme.colors.text_light,
        },
        errText: {
            ...theme.typo.p_sm,
            color: theme.colors.error
        }
    });

    const onSubmit = () => {
        const req = {
            forumId: !!forumId,
            title: title?.length >= 10,
            content: content?.length >= 10
        };
        if (!req.forumId) setCategoryErr(true);
        if (!req.title) setTitleErr(true);
        if (!req.content) setContentErr(true);
        if (!req.forumId || !req.title || !req.content) return;

        postThread({forumId: forumId, title: title, comment: content})
            .then(res => {
                setVisible(false);
                if (typeof onAdd === "function") {
                    onAdd({forumId: forumId, title: title, comment: content})
                }
            })
            .catch(err => console.error(err));
    };

    return <ModalContainer
        drawerWidth={props.drawerWidth}
        visible={visible}
        setVisible={setVisible}
        setEventIdVisible={() => {
        }}//??
    >
        <View style={styles.container}>
            <Text style={[styles.title, overwriteStyles.title]}>Neuen Thread erstellen</Text>
            <View style={[styles.inputGroup, styles.inputGroup_first]}>
                <Text style={[styles.label, overwriteStyles.label]}>Title</Text>
                <TextInput
                    value={title}
                    onChangeText={(v) => {
                        setTitle(v);
                        if (titleErr && v?.length > 10) setTitleErr(false);
                    }}
                    style={[styles.input, overwriteStyles.input]}
                />
                {titleErr && <Text style={[styles.errText, overwriteStyles.errText]}>
                    Der Titel muss mindestens 10 Zeichen lang sein.</Text>}
            </View>
            <View style={[styles.inputGroup]}>
                <Text style={[styles.label, overwriteStyles.label]}>Text</Text>
                <TextInput
                    value={content}
                    onChangeText={(v) => {
                        setContent(v);
                        if (contentErr && v?.length > 10) setContentErr(false);
                    }}
                    multiline={true}
                    style={[styles.input, styles.input_multiline, overwriteStyles.input]}
                />
                {contentErr && <Text style={[styles.errText, overwriteStyles.errText]}>
                    Der Text muss mindestens 10 Zeichen lang sein.</Text>}
            </View>
            <Pressable
                disabled={postThreadIsLoading}
                style={[styles.createBtn, overwriteStyles.createBtn]}
                onPress={onSubmit}>
                <Text style={overwriteStyles.createBtnText}>Erstellen</Text>
            </Pressable>
        </View>
    </ModalContainer>;
}

export default CreateForumThreadModal;
