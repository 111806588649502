import {Modal, Pressable, StyleSheet, TouchableOpacity, View} from "react-native";
import React from "react";
import {useSelector} from "react-redux";
import {FontAwesome5} from "@expo/vector-icons";

const styles = StyleSheet.create({
    modalContainer: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        padding: 80,
        backgroundColor: 'rgba(0, 0, 0, .6)',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        flex: 1,
    },
    modal: {
        width: '100%',
        maxWidth: 800,
        borderRadius: 30,
        backgroundColor: "#fff",
        // paddingHorizontal: 56,
        // paddingVertical: 20,
        padding: 30,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 2
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5
    },
    buttonClose: {
        position: "absolute",
        right: 30,
        top: 30,
    },
});

export default function ModalContainer(props) {
    const {drawerWidth, visible, setVisible, children, setEventIdVisible} = props;

    const theme = useSelector(state => state.theme);
    const overwriteStyles = StyleSheet.create({
        modal: {
            borderRadius: theme.borderRadius,
            padding: theme.padding,
        }
    });

    return <Modal
        animationType="fade"
        transparent={true}
        visible={visible}
        onRequestClose={() => {
            // console.log("ModalContainer requested closing")
            setVisible(false)
            setEventIdVisible && setEventIdVisible(null);
        }}>
        <View style={[styles.modalContainer]}>
            <Pressable
                style={[styles.overlay]}
                onPress={() => {
                    setVisible(false)
                    setEventIdVisible && setEventIdVisible(null);
                }}/>
            {children && <View style={[styles.modal, overwriteStyles.modal]}>
                {children}
                <TouchableOpacity
                    style={styles.buttonClose}
                    onPress={() => {
                        setVisible(false)
                        setEventIdVisible && setEventIdVisible(null);
                    }}>
                    <FontAwesome5
                        name="times"
                        size={25}
                        color={theme.colors.text}/>
                </TouchableOpacity>
            </View>}
        </View>
    </Modal>;
}
