import {
    ActivityIndicator,
    FlatList,
    Platform, RefreshControl,
    StyleSheet,
    Text,
    TouchableOpacity,
    useWindowDimensions,
    View
} from "react-native";
import React, {useEffect, useState} from "react";
import UserImage from "../UserImage/UserImage.native";
import {useSelector} from "react-redux";
import {Card} from "../Card/Card";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import usePullToRefresh from "../../utils/usePullToRefresh";
import {routeToForumComment as routeToForumComment} from "../Forum/forumUtil";
import {routeToNewsComment} from "../NewsArticle/newsUtil";
import {useLinkTo} from "@react-navigation/native";
import {CONTENT_TYPE} from "../../constants/strings";

const listItemMargin = 15;
const imageWidth = 60;
const withTimeout = (promise, millis = 10000) => {
    const timeout = new Promise((resolve, reject) =>
        setTimeout(
            () => reject(`Timed out after ${millis} ms.`),
            millis));
    return Promise.race([
        promise,
        timeout
    ]);
};

/**
 * Comments feed for the Notifications
 * This is for native and web
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function CommentsFeed(props = {}) {
    const {
        feedCount = Platform.select({
            "web": 5,
            default: 10
        })
    } = props;
    const {cache, theme} = useSelector(state => ({theme: state.theme, cache: state.cache}));
    const cachedComments = cache?.cacheTags.find(item => item.type === CONTENT_TYPE.COMMENT);
    const {data, refetch, error, isLoading} = endpoints.getCommentFeed.useQuery({feedCount});
    const [layoutWidth, setLayoutWidth] = useState(undefined);
    const {width} = useWindowDimensions();
    const [isRefreshing, setIsRefreshing] = useState(false);

    /**
     * after navigating home, the cache is reset, so we refetch
     */
    useEffect(() => {
        if (!isLoading && !cachedComments) {
            // isDevelopment && console.log("CommentsFeed useEffect", {data, error, isLoading}, cachedNews);
            refetch();
        }
    }, [cachedComments]);

    Platform.select({
        ios: usePullToRefresh(props, refetch),
        default: undefined
    })

    const linkTo = useLinkTo();

    if (isLoading) return <LoadingIndicator/>

    const clonedData = JSON.parse(data ? JSON.stringify(data) : "{}");

    const content = <View style={[styles.container, {paddingHorizontal: theme.wrapperPadding}]}>
        <FlatList
            style={[styles.flatlist]}
            data={clonedData?.sort((a, b) => {
                return new Date(b.date) - new Date(a.date);
            }) ?? []}
            keyExtractor={item => item.id}
            numColumns={1}
            horizontal={false}
            removeClippedSubviews={false}
            initialNumToRender={10}
            maxToRenderPerBatch={2}
            updateCellsBatchingPeriod={100}
            onLayout={(event) => {
                setLayoutWidth(event.nativeEvent.layout.width)
            }}
            windowSize={10}
            refreshControl={<RefreshControl
                progressBackgroundColor={"#fff"}
                title={"Aktualisiere Inhalte..."}
                backgroundColor={theme.colors.newContent}
                style={{backgroundColor: isRefreshing ? theme.colors.newContent : "transparent"}}
                titleColor={theme.colors.newContentText}
                colors={[theme.colors.newContentText]} //android
                tintColor={theme.colors.newContentText}
                refreshing={isRefreshing}
                onRefresh={() => {
                    setIsRefreshing(true);
                    return new withTimeout(refetch).then(() => setIsRefreshing(false)).catch(() => setIsRefreshing(false))
                }}
            />}
            ItemSeparatorComponent={() => <View
                style={[styles.itemSeperator, {borderBottomColor: theme.colors.border,}]}></View>}
            renderItem={({item, index}) => (
                <View style={[styles.listItem]} key={index}>
                    <TouchableOpacity style={styles.listItemImageContainer}>
                        <UserImage
                            {...props}
                            imageUrl={item?.imageUrl} uri={item.imageUrl} user={item.user} style={styles.userImage}
                            size={imageWidth}
                        />
                    </TouchableOpacity>
                    <TouchableOpacity
                        style={[styles.listItemTextContainer, {width: layoutWidth ? layoutWidth * 0.8 : undefined}]}
                        onPress={() => {
                            item.type === "forum" ? routeToForumComment(item.entityId, item.commentId, linkTo) : routeToNewsComment(item.entityId, item.commentId, linkTo)
                        }}>
                        <Text numberOfLines={4} style={[styles.descriptionTextContainer, {
                            width:
                                Platform.select({
                                    native: width - (2 * theme.wrapperPadding) - listItemMargin - imageWidth,
                                    web: layoutWidth * 0.75
                                })
                        }]}>
                            <Text style={[styles.primaryText, {color: theme.colors.primary}]}>{item.fullName}</Text>
                            <Text style={[styles.darkText, {color: theme.colors.text}]}> hat </Text>
                            <Text style={[styles.primaryText, {color: theme.colors.primary}]}>{item.title + " "}</Text>
                            <Text style={[styles.darkText, {color: theme.colors.text}]}>kommentiert</Text>
                        </Text>
                        <Text style={[styles.dateLabel, {
                            color: Platform.select({
                                native: theme.colors.text_black,
                                web: theme.colors.text_unimportant
                            })
                        }]}>
                            {global.moment(item.date).fromNow()}
                        </Text>
                    </TouchableOpacity>
                </View>
            )}
        />
    </View>
    return Platform.select({
        native: content,
        default: <Card title={`Die neusten Kommentare`}>{content}</Card>
    })
}
const styles = StyleSheet.create(
    Platform.select({
        web: {
            primaryText: {
                fontFamily: "OpenSans_600SemiBold",
                fontSize: 16,
                flexWrap: "wrap",
                lineHeight: 22,
            },
            darkText: {
                fontFamily: "OpenSans_300Light",
                fontSize: 16,
                lineHeight: 22,
            },
            descriptionTextContainer: {
                width: "100%",
                flexDirection: "row",
                flexWrap: "wrap"
            },
            listItemImageContainer: {
                alignItems: "center",
                justifyContent: "center"
            },
            listItemTextContainer: {
                flexWrap: "wrap",
                marginLeft: listItemMargin,
                justifyContent: "center"
            },
            dateLabel: {
                fontFamily: "OpenSans_300Light_Italic",
                fontSize: 16,
                lineHeight: 22,
                marginTop: 4
            },
            container: {
                flex: 1,
                backgroundColor: "#fff",
                marginVertical: 35,
                marginLeft: 20
            },
            flatlist: {},
            itemSeperator: {
                borderBottomWidth: 0,
            },
            listItem: {
                flexDirection: "row",
                paddingTop: 20,
                paddingBottom: 20
            }
        },
        native: {
            primaryText: {
                fontFamily: "OpenSans_600SemiBold",
                fontSize: 14,
                flexWrap: "wrap",
                lineHeight: 19,
            },
            darkText: {
                fontFamily: "OpenSans_300Light",
                fontSize: 14,
                lineHeight: 19,
            },
            descriptionTextContainer: {
                flexDirection: "row",
                flexWrap: "wrap"
            },
            listItemImageContainer: {
                alignItems: "center",
                justifyContent: "center"
            },
            listItemTextContainer: {
                marginLeft: listItemMargin,
                justifyContent: "center"
            },
            dateLabel: {
                fontFamily: "OpenSans_300Light_Italic",
                fontSize: 13,
                lineHeight: 14,
                marginTop: 4
            },
            container: {
                flex: 1,
                backgroundColor: "#fff"
            },
            flatlist: {
                backgroundColor: "#fff"
            },
            itemSeperator: {
                borderBottomWidth: 1,
            },
            listItem: {
                backgroundColor: "white",
                flexDirection: "row",
                paddingTop: 20,
                paddingBottom: 20
            }
        }
    })
);
