import {StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import {useSelector} from "react-redux";
import WebIcon from "../Icon/WebIcon";
import React from "react";

const styles = StyleSheet.create({
    container: {
        width: '100%',
        flexDirection: 'column',
        justifyContent: "flex-start",
        alignItems: 'flex-start',
        paddingBottom: 40
    },
    headlineContainer: {
        width: '100%',
        alignSelf: 'stretch',
        flexDirection: 'row',
    },
    customComponent: {
        flexGrow: 1,
        justifyContent: 'center',
        alignItems: 'flex-end',
    },
    headline: {
        flexShrink: 1,
        maxWidth: 1000,
        width: "90%",
    },
    subHeadline: {
        maxWidth: 1000,
        width: "90%",
    }
});

export default function CaptionBanner(props) {
    const {navigation} = props;
    let {
        headline,
        subHeadline,
        headlineStyle,
        subHeadlineStyle,
        containerStyle,
        customComponent,
        includeBackButton,
        backRouteName,
        numberOfLines = undefined
    } = props.config;
    const theme = useSelector(state => state.theme);

    const overwriteStyle = StyleSheet.create({
        container: {
            ...containerStyle
        },
        headline: {
            ...theme.typo.headline,
            ...headlineStyle
        },
        subHeadline: {
            ...theme.typo.subHeadline,
            ...subHeadlineStyle
        },
    });

    return <View style={[styles.container, overwriteStyle.container]}>
        <View style={[styles.headlineContainer]}>
            {
                includeBackButton &&
                <TouchableOpacity
                    style={{alignSelf: "center"}}
                    onPress={() => {
                        navigation.canGoBack() ? navigation.goBack() : navigation.navigate(backRouteName)
                    }}>
                    <View style={{
                        marginRight: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        <WebIcon
                            icon={'chevron-circle-left'}
                            regular
                            size={35}
                            color={theme.colors.primary}/>
                    </View>
                </TouchableOpacity>
            }
            <Text numberOfLines={numberOfLines} style={[styles.headline, overwriteStyle.headline]}>
                {headline}
            </Text>
            {customComponent && <View style={[styles.customComponent]}>
                {customComponent}
            </View>}
        </View>
        {subHeadline && <Text numberOfLines={numberOfLines} style={[styles.subHeadline, overwriteStyle.subHeadline]}>
            {subHeadline}
        </Text>}
    </View>
}
