import {StyleSheet, TouchableOpacity} from "react-native";
import React from "react";
import {Image} from "react-native-expo-image-cache";
import {getDefaultImage} from "../../utils/avatar";
import {useLinkTo} from "@react-navigation/native";

export default function UserImage(props) {
    const linkTo = useLinkTo();
    const {navigation, imageUrl, gender, size = 50, style, userId, user} = props;
    const styles = StyleSheet.create({
        userImage: {
            height: size,
            width: size,
            borderRadius: size,
            flexShrink: 0,
            ...style
        },
    })
    let image = null;
    if (user?.imageUrl || imageUrl) image = <Image uri={user?.imageUrl ?? imageUrl}
                                                   preview={{uri: imageUrl ?? user?.imageUrl}}
                                                   style={styles.userImage}/>
    else image = getDefaultImage(user?.gender, styles.userImage)

    return <TouchableOpacity onPress={() => {
        if (user.id) {
            //navigation.navigate('Profil', {id: user.id})
            linkTo({
                screen: "Profil", params:{id: user.id}
            })
        }
    }}>
        {image}
    </TouchableOpacity>
}
