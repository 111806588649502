import {Dimensions, StyleSheet, View} from "react-native";
import {NativeRouter} from "react-router-native";
import WebDrawer from "./WebDrawer";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import ContainerRenderer from "../../components/ContainerRenderer/ContainerRenderer";
import {createStackNavigator} from "@react-navigation/stack";
import React, {useEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import WebIcon from "../../components/Icon/WebIcon";
import NewContentAvailableNotification
    from "../../components/NewContentAvaibleNotification/NewContentAvailableNotification";
import ContainerWrapper from "../../components/ContainerRenderer/ContainerRendererWrapper";

const style = StyleSheet.create({
    container: {
        position: 'absolute', // to prevent chrome-bug: content is ~1px taller than the screen
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        flexDirection: "row",
        alignItems: "stretch",
        justifyContent: "flex-start",
        overflow: "hidden",
    },
    content: {
        flex: 1,
        alignItems: 'stretch',
    },
    scrollContainer: {
        flexGrow: 1,
        flexShrink: 1,
    },
    scroll: {
        height: '100%',
    },
    fullScreen: {
        flex: 1,
    },
    bgIcon: {
        position: 'absolute',
        top: 0,
        right: -100,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center'
    }
});

const Stack = createStackNavigator();
const WebNavigator = (props) => {
    const [drawerWidth, setDrawerWidth] = useState(0);
    const [breakpoint, setBreakPoint] = useState(4);

    useEffect(() => {
        const onResize = () => {
            let bpSet = false;
            // 0: >0, 1: >576, 2: >768, 3: >992, 4: >1200, 5: >1400, 6: >1600, 7: >1800, 8: >2000
            const BREAKPOINTS = [0, 576, 768, 992, 1200, 1400, 1600, 1800, 2000];
            BREAKPOINTS.reverse().forEach((bp, bpIdx) => {
                if (!bpSet && Dimensions.get('window').width > bp) {
                    setBreakPoint(BREAKPOINTS.length - 1 - bpIdx);
                    bpSet = true;
                }
            });
        }
        onResize();
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, []);
    const theme = useSelector(state => state.theme);

    /**
     * builds of unique content types to be tracked out of the given view`s container
     * @param view
     * @returns {*[]}
     */
    const getContentTypes = (view) => {
        const contentTypes = [];
        view?.container?.forEach(outerContainer => {
            outerContainer.container.forEach(innerContainer => {
                if (Array.isArray(innerContainer.contentTypes)) {
                    innerContainer.contentTypes.forEach(type => {
                        if (!contentTypes.includes(type))//make unique
                            contentTypes.push(...innerContainer.contentTypes)
                    })
                }
            })
        })
        return contentTypes;
    }
    return <NativeRouter>
        <View style={[style.container, {backgroundColor: theme.colors.background}]}>
            <WebDrawer {...props} {...{setDrawerWidth, breakpoint}}/>
            <View style={style.content}>
                <HeaderBar {...props} {...{breakpoint}} />
                <View style={style.scrollContainer}>
                    <Stack.Navigator screenOptions={{headerShown: false}}>
                        {props.config.routes.map((route, i) => (
                            <Stack.Screen key={i} name={route.name} options={{title: route.title}}>
                                {(navigatorProps) => (
                                    <>
                                        {(route.bgIcon && breakpoint > 7) ? <View style={style.bgIcon}>
                                            <WebIcon
                                                icon={route.bgIcon}
                                                color={theme.colors.border}
                                                size={700}/>
                                        </View> : null}
                                        {/* Should be inside of scrollingView, but wont stay fixed there...*/}
                                        <NewContentAvailableNotification types={getContentTypes(route)}/>
                                        <ContainerWrapper isFullScreen={route.fullScreen} breakpoint={breakpoint}  {...props}>
                                            <ContainerRenderer
                                                {...props} {...navigatorProps} {...{breakpoint}}
                                                view={route} drawerWidth={drawerWidth}/>
                                        </ContainerWrapper>
                                        {/*{route.fullScreen*/}
                                        {/*    ? <View style={style.fullScreen}>*/}
                                        {/*        <ContainerRenderer*/}
                                        {/*            {...props} {...navigatorProps} {...{breakpoint}}*/}
                                        {/*            view={route} drawerWidth={drawerWidth}/>*/}
                                        {/*    </View>*/}
                                        {/*    : <ContainerWrapper view={route} {...navigatorProps}>*/}
                                        {/*        <View>*/}
                                        {/*            <ContainerRenderer*/}
                                        {/*                {...props} {...navigatorProps} {...{breakpoint}}*/}
                                        {/*                view={route} drawerWidth={drawerWidth}/>*/}
                                        {/*        </View>*/}
                                        {/*        <Footer {...props} {...{breakpoint}} />*/}
                                        {/*    </ContainerWrapper>*/}
                                        {/*}*/}
                                    </>
                                )}
                            </Stack.Screen>
                        ))}
                    </Stack.Navigator>
                </View>
            </View>
        </View>
    </NativeRouter>;
};

export default WebNavigator;
