import {Text, TouchableOpacity, View} from "react-native";
import React from "react";
import {useSelector} from "react-redux";

export default function NewsArticlePreviewReadMore(props) {
    const {rightContent, onArticlePress} = props;
    const theme = useSelector(state => state.theme);


    return <View style={{flex: 1, alignItems: "center", flexDirection: "row", paddingBottom: 24}}>
        <View style={{height: 5, width: 16, backgroundColor: theme.colors.primary,}}>
        </View>
        <TouchableOpacity
            onPress={onArticlePress}
            style={{
                height: 40,
                width: 120,
                backgroundColor: theme.colors.primary,
                alignItems: "center",
                justifyContent: "center"
            }}>
            <Text style={{...theme.text.header, fontSize: 25}}>
                Mehr lesen
            </Text>
        </TouchableOpacity>
        {rightContent}
    </View>
}