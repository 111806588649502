import {StyleSheet, Text, View} from 'react-native';
import {useSelector} from "react-redux";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import React from "react";

const styles = StyleSheet.create({
    container: {
        width: "100%",
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingBottom: 24,
    },
    text: {},
    icon: {}
});

function Caption(props) {
    let {config} = props;

    const theme = useSelector(state => state.theme);
    const overwriteStyles = StyleSheet.create({
        container: {},
        text: {
            ...theme.typo.subHeadline,
            ...config?.options?.text.style,
        },
        icon: {}
    });

    return <View style={[styles.container, overwriteStyles.container]}>
        <Text style={overwriteStyles.text}>{config?.options?.text?.value}</Text>
        {config?.options?.icon && <FontAwesome5
            color={config?.options?.text?.style?.color ?? theme.typo.subHeadline.color}
            style={{paddingLeft: 20}}
            size={(config?.options?.text?.style?.fontSize ?? theme.typo.subHeadline.fontSize) * .8}
            name={config?.options?.icon?.name}
        />}
    </View>;
}

export default Caption;
