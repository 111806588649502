import {Image, Pressable, StyleSheet, Text, useWindowDimensions, View} from "react-native";
import {useSelector} from "react-redux";
import CaptionBanner from "../CaptionBanner/CaptionBanner";
import useGrid from "../../utils/useGrid";
import React, {useEffect, useState} from "react";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import ContactDetail from "./ContactDetail";
import {FontAwesome5} from "@expo/vector-icons";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";

const styles = StyleSheet.create({
    groupContainer: {
        borderTopWidth: 1,
        marginTop: 64,
        paddingTop: 40,
    },
    groupContainer_first: {
        borderTopWidth: 0,
        marginTop: 0,
        paddingTop: 0,
    },
    group: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    itemContainer: {
        marginLeft: 24,
        marginTop: 24,
    },
    itemContainer_firstCol: {
        marginLeft: 0,
    },
    itemContainer_firstRow: {
        marginTop: 0
    },
    item: {
        borderRadius: 10,
        borderWidth: 1,
        width: 220,
        height: 120,
        padding: 24,
        justifyContent: 'space-between',
        alignItems: 'stretch',
        shadowColor: "#000",
        shadowOffset: {
            width: 2,
            height: 2
        },
        shadowOpacity: 0.16,
        shadowRadius: 6,
        elevation: 5
    },
    itemIconContainer: {},
    itemIcon: {
        width: 100,
        height: 100,
        resizeMode: 'contain'
    },
    itemLabel: {
        fontFamily: 'BebasNeue_400Regular',
        fontSize: 25,
        marginTop: 'auto'
    },
    tabContainer: {
        marginTop: 80,
        paddingTop: 80,
        borderTopWidth: 1
    },
    item_selected: {
        opacity: .5
    },
    selectContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    selectLabel: {
        fontFamily: 'BebasNeue_400Regular',
        fontSize: 18,
    },
    selectIcon: {
        marginLeft: 8,
    }
});

export default function Contacts(props) {
    let {config, navigation,scrollViewRef} = props;
    // console.log("Contacts", scrollViewRef);
    const {data, refetch, error, isLoading} = endpoints.getContacts.useQuery();
    const theme = useSelector(state => state.theme);
    const {height} = useWindowDimensions()
    const {ref, tiles, tileDim} = useGrid(202, 24);
    const [tab, setTab] = useState(data?.[0]);

    useEffect(() => {
        if (!tab) setTab(data?.[0]);
    }, [data, tab]);

    const overwriteStyles = StyleSheet.create({
        groupContainer: {
            borderColor: theme.colors.border
        },
        item: {
            borderColor: theme.colors.text,
            width: tileDim,
            height: config?.options?.useSquares ? tileDim : 120,
            ...config?.options?.styles?.linkContainer
        },
        itemLabel: {
            color: theme.colors.text,
            ...config?.options?.styles?.linkText
        },
        selectLabel: {
            color: theme.colors.text_light,
        },
        tabContainer: {
            borderTopColor: theme.colors.border
        },
    });

    return <View ref={ref}>
        <CaptionBanner config={{
            headline: "Kontakte",
            subHeadline: "Triff immer die richtige Leitung für dein Anliegen"
        }}/>
        {isLoading
            ? <LoadingIndicator/>
            : <>
                <View style={[styles.groupContainer, overwriteStyles.groupContainer, (styles.groupContainer_first)]}>
                    <View style={styles.group}>
                        {data?.map((item, idx) => (
                            <View
                                key={idx}
                                style={[styles.itemContainer,
                                    (idx < tiles && styles.itemContainer_firstRow),
                                    ((idx % tiles) === 0 && styles.itemContainer_firstCol)]}>
                                <Pressable onPress={() => {
                                    setTab(item)
                                    scrollViewRef?.current?.scrollTo({x: 0, y: height, animated: true});
                                }}>
                                    <View key={idx}
                                          style={[styles.item, overwriteStyles.item, (item.name === tab?.name && styles.item_selected)]}>
                                        {(item.name === tab?.name) && <View style={styles.selectContainer}>
                                            <Text
                                                style={[styles.selectLabel, overwriteStyles.selectLabel]}>Ausgewählt</Text>
                                            <FontAwesome5
                                                style={styles.selectIcon}
                                                color={theme.colors.text_light}
                                                size={16}
                                                name={"chevron-down"}
                                            />
                                            {/*<Image style={styles.selectIcon}*/}
                                            {/*       source={require('../../../assets/images/adaptive-icon.png')}/>*/}
                                        </View>}
                                        {item.icon && <View style={styles.itemIconContainer}>
                                            <Image style={styles.itemIcon}
                                                   source={require('../../../assets/images/adaptive-icon.png')}/>
                                        </View>}
                                        <Text style={[styles.itemLabel, overwriteStyles.itemLabel]}>{item.name}</Text>
                                    </View>
                                </Pressable>
                            </View>
                        ))}
                    </View>
                </View>
                {!!tab && <View style={[styles.tabContainer, overwriteStyles.tabContainer]}>
                    <ContactDetail item={tab}/>
                </View>}
            </>}
    </View>
}
