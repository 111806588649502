import ModalContainer from "../ModalContainer";
import {Pressable, StyleSheet, Text, View} from "react-native";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import {useSelector} from "react-redux";

const styles = StyleSheet.create({
    container: {
        width: '100%',
        alignItems: 'flex-start'
    },
    title: {
        marginBottom: 16,
    },
    saveBtn: {
        paddingHorizontal: 20,
        paddingVertical: 12,
        marginTop: 24,
        borderRadius: 100,
    },
    checkbox: {
        marginTop: 8,
    },
    checkboxText: {
        textDecorationLine: "none"
    }
});

const ShowBirthdayModal = (props) => {
    const {visible} = props;
    const theme = useSelector(state => state.theme);
    const overwriteStyles = StyleSheet.create({
        checkboxText: {
            ...theme.typo.p_sm
        },
        title: {
            ...theme.typo.p_md_bold,
            color: theme.typo.text_dark
        },
        saveBtn: {
            backgroundColor: theme.colors.primary,
        },
        saveBtnText: {
            ...theme.typo.p_sm,
            color: theme.colors.text_light,
        },
    });

    const CustomCheckbox = (props) => {
        return <BouncyCheckbox
            style={styles.checkbox}
            text={"Geburtstag anzeigen"}
            textContainerStyle={{textDecoration: "none", marginLeft: 8}}
            textStyle={[styles.checkboxText, overwriteStyles.checkboxText]}
            fillColor={theme.colors?.primary}
            unfillColor={"transparent"}
            iconStyle={{borderColor: theme.colors?.primary}}
            innerIconStyle={{borderWidth: 2}}
            {...props}
        />
    };

    return <ModalContainer
        visible={visible}
    >
        <View style={styles.container}>
            <Text style={[styles.title, overwriteStyles.title]}>Dürfen dein Geburtstag und Jubiläum anderen Nutzern
                angezeigt werden?</Text>
            {/*<Text>Dürfen dein Geburtstag und Jubiläum anderen Nutzern angezeigt werden?</Text>*/}
            <CustomCheckbox
                text={"Geburtstag anzeigen"}
            />
            <CustomCheckbox
                text={"Jubiläum anzeigen"}
            />
            <Pressable
                style={[styles.saveBtn, overwriteStyles.saveBtn]}
                // disabled={submitIsLoading}
                // onPress={onSubmit}
            >
                <Text style={overwriteStyles.saveBtnText}>Speichern</Text>
            </Pressable>
        </View>
    </ModalContainer>;
};

export default ShowBirthdayModal;
