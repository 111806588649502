import React, {useEffect, useState} from "react";
import {Text, View,} from "react-native";
import ImageSlider from "../ImageSlider/ImageSlider";
import NewsArticlePreview from "../NewsArticlePreview/NewsArticlePreview";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import usePullToRefresh from "../../utils/usePullToRefresh";
import {useSelector} from "react-redux";
import {useValidLocations} from "../../utils/location";
import ErrorText from "../Error/ErrorText";

export default function NewsPage(props) {
    const {route, bottomNavigation, navigation} = props;
    const [activeArticle, setActiveArticle] = useState(null);
    const theme = useSelector(state => state.theme);
    const locations = useValidLocations()
    const params = {};
    if (locations) params.locations = locations;
    const {data, error, isLoading, refetch, isFetching} = endpoints.getFeaturedNews.useQuery(params);
    if (!activeArticle && data?.length > 0) setActiveArticle(data[0])
    useEffect(() => {
        //set new active article after refreshing
        if (isFetching === false && activeArticle && data) {
            setActiveArticle(data[0])
        }
    }, [isFetching])
    usePullToRefresh(props, refetch)

    if (isLoading === true)
        return <LoadingIndicator/>;

    if (error) return <ErrorText error={error}/>
    if (!data) return <Text>Keine Daten vorhanden.</Text>
    // console.log("NewsPage", activeArticle?.advanced_information?.created_at, activeArticle?.created_at)
    return <View>
        <View style={{flex: 1}}>
            {
                data?.length < 1
                    ? <Text style={[theme.text.paragraph, {padding: 20,}]}>Es ist momentan kein Datensatz für Ihren
                        Standort verfügbar.</Text>
                    : <>
                        <ImageSlider
                            onPress={() => requestAnimationFrame(() => {
                                navigateToView({
                                    navigation, viewName: "NewsArticle", params: {
                                        id: activeArticle.id,
                                        comments: activeArticle.comments,
                                        title: activeArticle.title,
                                        you_like_it: activeArticle.you_like_it,
                                        commentsAmount: activeArticle.number_of_comments,
                                        likesAmount: activeArticle.numberOfLikes,
                                        imageUrl: activeArticle.imageUrl,
                                        content: activeArticle.content,
                                        stackNavigation: props?.stackNavigation,
                                        created_at: activeArticle.created_at,
                                    }
                                })
                            })

                            }
                            onImageChanged={(index) => {
                                setActiveArticle(data[index])
                            }} images={data?.map(item => {
                            return {
                                imageUrl: item.imageUrl,
                                // previewUrl: item.preview
                            }
                        })}/>
                        <NewsArticlePreview
                            stackNavigation={props?.stackNavigation}
                            key={activeArticle?.id}
                            navigation={navigation}
                            {...activeArticle}
                            useImage={false}
                        />
                    </>
            }
        </View>
    </View>
}
const navigateToView = ({navigation, params, viewName}) => {
    navigation.navigate(viewName, {
        ...params
    })
}
