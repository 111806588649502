import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import { StyleSheet, Text, useWindowDimensions, View} from "react-native";
import {
    FIELD_TYPE_CHECKBOX,
    FIELD_TYPE_DATE, FIELD_TYPE_STATIC_TEXT,
} from "./fieldTypes";
import Button from "../Button/Button";
import React from "react";
import {renderRowFieldType} from "./FormEditors/renderRowFieldType";
import {transformFormDataToRequestDefinition} from "./transformFormDataToRequestDefinition";
import {usePostDynamicFormMutation} from "../../app/services/api/globalAdminAPI";
export default function DynamicForm(props){
    const {data: formData,fieldSets,formLabel ,formText,formId,formName} = props;
    const auth = useSelector(root=>root.auth);
    const theme = useSelector(state => state.theme);
    const [postDynamicForm, {
        isError,
        error,
        isLoading,
        isSuccess,
        isUninitialized,
        originalArgs,
        reset,
        status
    }] = usePostDynamicFormMutation();
    const dispatch = useDispatch();
    const {width} = useWindowDimensions();
    let defaultValues = {};
    fieldSets.forEach(fieldSet=>{
        Object.values(fieldSet.rows).forEach(fieldSetRow=>{
            Object.values(fieldSetRow.fields).forEach(rowField=>{
                if(rowField.type === FIELD_TYPE_DATE) defaultValues[rowField.id] = new Date();
                else if(rowField.type === FIELD_TYPE_STATIC_TEXT){
                    //do nothing
                }
                else if(rowField.type === FIELD_TYPE_CHECKBOX)  defaultValues[rowField.id] = false;
                else defaultValues[rowField.id] = undefined;
            })
        })
    })
    const {getValues, control,setFocus, handleSubmit, setValue,setError,formState: {errors}} = useForm({
        defaultValues: defaultValues
    });
    const onSubmit = handleSubmit((data)     => {
        postDynamicForm({
            requestData: transformFormDataToRequestDefinition(getValues(),JSON.parse(formData)),
            formId,
            userId: auth.userId,
            username: auth.username,
            formName
        }).then(res=>{
            console.log(res);
        }).catch(error=>{
            console.log(error);
        })
    });
    return <View style={{
        width: "100%",
        maxWidth: 650,
        flex: 1,
    }}>
        {!!formLabel&&<Text style={[style.personalFieldHeaderText]}>{formLabel}</Text>}
        {!!formText&&<Text style={[theme.text.p_md]}>{formText}</Text>}
        <View>
            {
                fieldSets.map((fieldSet,fieldSetIdx)=>{
                    const fieldSetId = fieldSet.id;
                    const fieldSetLabel = fieldSet.label;
                    const fieldSetText = fieldSet.text;
                    const fieldSetRows = Object.values(fieldSet.rows)
                    return <View key={fieldSetIdx} >
                        {!!fieldSetLabel && <Text style={[style.personalFieldHeaderText]}>{fieldSetLabel}</Text>}
                        {!!fieldSetText && <Text style={[style.rowFieldHelpText]}>{fieldSetText}</Text>}
                        {
                            fieldSetRows?.map((row,rowIdx)=>{
                                const rowHelpText = row.help;
                                const rowId = row.id
                                const rowLabel = row.label;
                                const rowAttr = row.attr;
                                const rowText = row.text;
                                const rowFields = Object.values(row.fields);
                                return <View key={rowIdx}  style={{marginTop : 7}}>
                                    {!!rowLabel && <Text style={[style.personalFieldHeaderText]}>{rowLabel}</Text>}
                                    {!!rowHelpText && <Text style={[style.rowFieldHelpText]}>{rowHelpText}</Text>}
                                    {
                                        rowFields.map((rowField,rowFieldIdx)=>{
                                            const rowFieldLabel = rowField.label;
                                            const rowFieldName = rowField.name;
                                            const rowFieldType = rowField.type;
                                            const rowFieldValidators = rowField.validator;
                                            const rowFieldHelpText = rowField.help;
                                            const rowFieldId = rowField.id;
                                            return <View key={rowFieldIdx}>
                                                <Controller
                                                    control={ control }
                                                    rules={{
                                                        maxLength: 100,
                                                        required:  rowField.validators?.hasOwnProperty("required"),
                                                        pattern: rowField.validators?.hasOwnProperty("email") ? {
                                                            value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                                            message: "Invalide Email Adresse"
                                                        } : undefined
                                                    }}
                                                    render={({field: {onChange, onBlur, value}}) => renderRowFieldType({
                                                        setError,value,theme,rowFieldType,rowFieldLabel,rowField, onChange,errors,getValues
                                                    })}
                                                    name={rowFieldName}
                                                />
                                            </View>
                                        })
                                    }
                                </View>
                            })
                        }
                    </View>
                })

            }
            <View style={style.buttonContainer}>
                <Button text={"Absenden"} onClick={onSubmit}></Button>
            </View>
        </View>
    </View>
}
const style = StyleSheet.create({

    textArea: {
        height: 80,
        padding: 10,
        textAlignVertical: 'top'
    },
    buttonContainer: {
        marginTop: 10

    },
    container: {
        backgroundColor:"grey"
    },
    selectBoxContainer: {

    },
    valueContainer: {
        paddingTop: 7.5,
        paddingBottom: 7.5,
        flexDirection:"column"
    },
    personalFieldKey: {
        fontFamily: "OpenSans_600SemiBold",
        fontSize: 14,
    },
    rowFieldHelpText: {
        fontSize: 14,
        fontFamily: "OpenSans_300Light_Italic",
        paddingBottom: 5
    },
    personalFieldHeaderText: {
        fontSize: 18,
        marginBottom: 10,
        fontFamily: "OpenSans_700Bold",
    },
    input: {

        height: 40,
        borderWidth: 1,
        padding: 10,
        borderColor: "rgba(0,0,0,0.3)",
        fontSize: 13,
    },
});
