import {Dimensions, Platform, ScrollView, StatusBar, StyleSheet, Text, View} from "react-native";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import RenderHtml from "react-native-render-html";
import {SystemFontKeys, SystemFonts} from "../../constants/SystemFonts";
import {replaceLinkStyles} from "../../utils/strings";
import {HEADERHEIGHT} from "../../constants/numbers";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import LoadingIndicator from "../../components/LoadingIndicator/LoadingIndicator";

export default function ShortNewsArticle(props) {
    let {navigation, bottomNavigation, route} = props;
    let {content = "not defined", title = "not defined", date = "not defined", id} = route.params;
    const [news, setNews] = useState({content, title, date});

    const {data, error, isLoading: isLoadingData, refetch} = endpoints.getShortNewsArticle.useQuery(id, {skip: !id});

    const isLoading = Number.isInteger(id) && isLoadingData;

    useEffect(() => {
        if (!isLoading && data) {
            setNews({content: data.description, title: data.title, date: data.created_at})
        }
    }, [isLoadingData, data])

    let ScreenHeight = Dimensions.get("window").height;
    const theme = useSelector(state => state.theme);
    const style = StyleSheet.create({
        title: {
            fontFamily: "OpenSans_700Bold",
            fontSize: 16,
            color: "#fff"
        },
        text: {
            fontFamily: Platform.select({
                native: "OpenSans_400Regular",
                web: "OpenSans"
            }),
            fontSize: 15,
            color: "#fff",
        },
        container: {
            flex: 1,
            flexGrow: 1,
            padding: 16,
            backgroundColor: theme.colors.primary,
        },
        closeIcon: {
            marginLeft: "auto", marginRight: 8
        },
        horizontalLine: {
            marginTop: 10,
            borderBottomColor: 'white',
            borderBottomWidth: StyleSheet.hairlineWidth,
        }
    });
    // console.log("ShortNewsArticle", news, data, {isLoadingData, isLoading, id});

    if (isLoading)
        return <LoadingIndicator/>
    else {
        // const _content = replaceLinkStyles(content);
        return <View style={style.container}>
            <ScrollView style={{flex: 1, flexGrow: 1}} contentContainerStyle={{flexGrow: 1}}>
                <Text style={style.title}>{news.title}</Text>
                <Text style={style.text}>{news.date}</Text>
                <View
                    style={style.horizontalLine}
                />
                <RenderHtml
                    baseStyle={{
                        fontFamily: "OpenSans",
                        fontWeight: "300",
                        fontSize: 15,
                        color: "#fff",
                    }}
                    tagsStyles={{
                        a: {
                            color: "white",
                            textDecoration: "underline",
                            textDecorationColor: "white",
                            fontStyle: "italic"
                        }
                    }}
                    contentWidth={Dimensions.get("window").width}
                    source={{
                        html: news.content
                    }}
                    systemFonts={SystemFontKeys}
                />
                {/*<Text style={style.text}>{content}</Text>*/}
            </ScrollView>
        </View>
    }
}
