import React from "react";
import {StyleSheet, Text, View,} from "react-native";
import {useSelector} from "react-redux";
import NotificationCounter from "../NotificationCounter/NotificationCounter";
import RelativeTimeFormat from "relative-time-format"
import {endpoints} from "../../app/services/api/globalAdminAPI";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import {getDaysDifference} from "../../utils/timeFunctions";
import CaptionBanner from "../CaptionBanner/CaptionBanner";
import {Link} from "@react-navigation/native";

const styles = StyleSheet.create({
    listContainer: {
        flex: 1,
    },
    categoryContainer: {
        borderWidth: 1,
        borderRadius: 100,
        padding: 30,
        display: 'flex',
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: 'flex-start'
    },
    categoryLabel: {
        flex: 1,
        borderRightWidth: 1,
        paddingRight: 40,
        marginRight: 40,
    },
    categoryLabel_bpMargin: {
        paddingRight: 20,
        marginRight: 20,
    }
});

export default function ForumOverview(props) {
    const {route, bottomNavigation, navigation, breakpoint} = props;
    const bp_margin = breakpoint < 4;

    const rtf1 = new RelativeTimeFormat("de", {numeric: "auto"});
    const {refetch, data, error, isLoading} = endpoints.getForumOverview.useQuery();
    const theme = useSelector(state => state.theme);

    // if (isLoading) return <LoadingIndicator/>;
    const forumCategories = data?.entries?.map(forumCategory => ({
        id: forumCategory.id,
        title: forumCategory.title,
        commentsCount: forumCategory.comments,
        number_of_threads: forumCategory.number_of_threads,
        notificationsAmount: 0,
        lastEntryDate: forumCategory.last_user_info?.last_update ? rtf1.format(getDaysDifference(forumCategory.last_user_info?.last_update, new Date()), 'day') : "",
        lastEntry: forumCategory.last_user_info?.last_user?.full_name,
        threads: forumCategory.threads_data
    }));

    const overwriteStyle = StyleSheet.create({
        categoryContainer: {
            backgroundColor: theme.colors.background_light,
            borderColor: theme.colors.border,
            padding: theme.padding
        },
        categoryLabel: {
            ...theme.typo.p_sm_bold,
            color: theme.colors.text_dark,
            borderRightColor: theme.colors.border,
        },
        infoLabel: {
            ...theme.typo.p_sm,
            borderRightColor: theme.colors.border
        },
    });

    return <View>
        <CaptionBanner config={{
            headline: 'Forum',
        }}/>
        {isLoading
            ? <LoadingIndicator/>
            : <View style={styles.listContainer}>
                {forumCategories.map((category, idx) => (
                    <Link
                        key={idx}
                        to={{screen: 'ForumCategory', params: {id: category.id, title: category.title}}}
                        style={[styles.categoryContainer, overwriteStyle.categoryContainer, (idx !== 0 && {marginTop: 8})]}>
                        <Text style={[styles.categoryLabel,
                            (bp_margin && styles.categoryLabel_bpMargin), overwriteStyle.categoryLabel]}>{category.title}</Text>
                        <Text
                            style={[styles.categoryLabel, (bp_margin && styles.categoryLabel_bpMargin), overwriteStyle.infoLabel]}>{`Letzer Eintrag: ${category.lastEntryDate}`}</Text>
                        <Text
                            style={[styles.categoryLabel, (bp_margin && styles.categoryLabel_bpMargin), overwriteStyle.infoLabel]}>{`von: ${category.lastEntry}`}</Text>
                        <NotificationCounter value={category.number_of_threads}/>
                    </Link>
                ))}
            </View>}
    </View>
}
