import CaptionBanner from "../components/CaptionBanner/CaptionBanner";
import TileLinks from "../components/TileLinks/TileLinks";
import {Profile} from "../components/Profile/Profile";
import {BlogPage} from "../components/BlogPage/BlogPage";
import ImageSlider from "../components/ImageSlider/ImageSlider";
import NewsPage from "../components/NewsPage/NewsPage";
import ShortNews from "../components/ShortNews/ShortNews";
import Caption from "../components/Caption/Caption";
import NewsSelector from "../components/NewsSelector/NewsSelector";
import ShortNewsArticle from "../components/ShortNews/ShortNewsArticle";
import NewsArticle from "../components/NewsArticle/NewsArticle";
import WebView from "../components/WebView/WebView";
import ForumOverview from "../components/Forum/ForumOverview";
import ForumCategory from "../components/Forum/ForumCategory";
import ForumPage from "../components/Forum/ForumPage";
import FotoGalleries from "../components/Fotogalerien/FotoGalleries";
import BirthDays from "../components/Birthdays/BirthDays";
import Calendar from "../components/Calendar/Calendar";
import Notifications from "../components/Notifications/Notifications";
import CalendarRegistrations from "../components/Calendar/CalendarRegistrations";
import GalleryDetailView from "../components/Fotogalerien/GalleryDetailView";
import ForumActivity from "../components/Forum/ForumActivity";
import Contacts from "../components/Contacts/Contacts"; // .native might cause an error on web
import ContactDetail from "../components/Contacts/ContactDetail.native"; // ~
import BenefitsOverview from "../components/Benefits/BenefitsOverview";
import CanteenMenu from '../components/CanteenMenu/CanteenMenu';
import CanteenMenuPreview from "../components/CanteenMenu/CanteenMenuPreview";
import ChatBotPreview from '../components/ChatBotPreview/ChatBotPreview';
import NewsFeatured from "../components/NewsFeatured/NewsFeatured";
import Imprint from "../components/Imprint/Imprint";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";
import Settings from "../components/Settings/Settings";
import MealPlan from "../components/MealPlan/MealPlan.native";
import PublicText from "../components/PublicScreens/PublicText";
import ImageBlock from "../components/Image/ImageBlock";
import InternalLink from "../components/Link/InternalLink";
import MarginSpace from "../components/MarginSpace/MarginSpace";
import Paragraph from "../components/Paragraph/Paragraph";
import InformationLinks from "../components/InformationLinks/InformationLinks";
import {InformationDetail} from "../components/InformationLinks/InformationDetail";
import TabView from "../components/TabView/TabView";
import CommentsFeed from "src/components/CommentsFeed/CommentsFeed";
import WelcomeScreen from "../components/PublicScreens/WelcomeScreen";
import Login from "../components/Login/Login.native";
import MealPlanPublic from "../components/PublicScreens/MealPlanPublic";
import Blog from "../components/PublicScreens/Blog";
import BlogDetails from "../components/PublicScreens/BlogDetails";
import CompanyLocations from "../components/PublicScreens/CompanyLocations";

/***
 *
 *  swapped to function because else native Module wont load when loaded from config
 * @returns {{ShortNews: (function(*): *), ForumOverview: (function(*): *), BirthDays: (function(*): *), ShortNewsArticle: (function(*): *), Locations2: (function(): *), Profile: (function(*): *), Book: (function(): *), BlogPage: (function(*): *), WebView: (function(*): *), ForumCategory: (function(*): *), ForumPage: (function(*): *), Home: (function(*): *), CalendarRegistrations: (function(*): *), NewsPage: (function(*=): *), GalleryDetailView: (function(*): *), TileLinks: (function(*)), ImageSlider: (function(*): *), Calendar: (function(*): *), Locations: (function(): *), CaptionBanner: (function(*)), MyRewards: (function(): *), Caption: (function(*)), NewsSelector: (function(*=): *), ContactDetail: (function(*): *), NewsArticle: (function(*): *), Contacts: (function(*): *), ScreenB: (function({navigation: *}): *), ForumActivity: (function(*): *), ScreenC: (function(): *), FotoGalleries: (function(*): *), Notifications: (function(*): *), ScreenA: (function(): *)}}
 */
export const ComponentResolver = () => {
    return {
        "BlogPage": BlogPage,
        "Contacts": Contacts,
        "ContactDetail": ContactDetail,
        "CaptionBanner": CaptionBanner,
        "TileLinks": TileLinks,
        "Profile": Profile,
        "ImageSlider": ImageSlider,
        "NewsPage": NewsPage,
        "ShortNews": ShortNews,
        "Caption": Caption,
        "NewsSelector": NewsSelector,
        "ShortNewsArticle": ShortNewsArticle,
        "NewsArticle": NewsArticle,
        "WebView": WebView,
        "ForumOverview": ForumOverview,
        "ForumCategory": ForumCategory,
        "ForumPage": ForumPage,
        "FotoGalleries": FotoGalleries,
        "BirthDays": BirthDays,
        "Calendar": Calendar,
        "Notifications": Notifications,
        "CalendarRegistrations": CalendarRegistrations,
        "GalleryDetailView": GalleryDetailView,
        "ForumActivity": ForumActivity,
        "BenefitsOverview": BenefitsOverview,
        "CanteenMenu": CanteenMenu,
        "CanteenMenuPreview": CanteenMenuPreview,
        "ChatBotPreview": ChatBotPreview,
        "NewsFeatured": NewsFeatured,
        "Imprint": Imprint,
        "Settings": Settings,
        "MealPlan": MealPlan,
        "ImageBlock": ImageBlock,
        "InternalLink": InternalLink,
        "MarginSpace": MarginSpace,
        "Paragraph": Paragraph,
        "InformationLinks": InformationLinks,
        "InformationDetail": InformationDetail,
        "TabView":TabView,
        "CommentsFeed":CommentsFeed,
        //shared logged in and logged out components:
        "Impressum": PublicText,
        "PrivacyPolicy": PrivacyPolicy,

        //logged out navigation: welcome screen
        "WelcomeScreen": WelcomeScreen,
        "MealPlanPublic": MealPlanPublic,
        "Login": Login,
        "Blog": Blog,
        "BlogDetails": BlogDetails,
        "CompanyLocations": CompanyLocations,
    }
}
