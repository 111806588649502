import {StyleSheet, View} from 'react-native';
import React from "react";
import {useSelector} from "react-redux";
import {Image} from "react-native-expo-image-cache";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import {store} from "../../app/store";
import {getDefaultImage} from "../../utils/avatar";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";


export const ProfilePicture = (props) => {
    const theme = useSelector(state => state.theme);
    const {userId, expoToken} = store.getState()?.auth;
    const {
        data: userObj,
        refetch,
        error: userObjError,
        isLoading: userObjLoading
    } = endpoints.getUserObj.useQuery({userId}, {skip: !userId});
    const {
        data: userImage,
        error: userImageError,
        isLoading: userImageLoading
    } = endpoints.getImage.useQuery({imageId: userObj?.picture?.id}, {skip: !userId || !userObj?.picture?.id});
    const overwriteStyles = StyleSheet.create({
        userImageContainer: {
            ...props.style
        },
        userImage: {},
    });

    return <View style={[styles.userImageContainer, overwriteStyles.userImageContainer]}>
        {(userObjLoading || userImageLoading)
            ? <LoadingIndicator/>
            : userImage?.formats?.find(item => item.name === "default_preview")?.url
                ? <Image uri={userImage?.formats?.find(item => item.name === "default_big")?.url}
                         preview={{uri: userImage?.formats?.find(item => item.name === "default_preview")?.url}}
                         style={[styles.userImage, overwriteStyles.userImage]}/>
                : getDefaultImage(userObj?.gender, [styles.userImage, overwriteStyles.userImage])
        }
    </View>;
};

const styles = StyleSheet.create({
    userImageContainer: {
        backgroundColor: '#eee',
        flexShrink: 0,
        height: 48,
        width: 48,
        borderRadius: 50,
        overflow: 'hidden',
    },
    userImage: {
        resizeMode: 'contain',
        height: 48,
        width: 48,
    }
});
