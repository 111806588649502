import React, {useEffect, useRef, useState} from "react";
import {StyleSheet, Text, TouchableOpacity, View,} from "react-native";
import {useSelector} from "react-redux";
import NewsArticlePreview from "../NewsArticlePreview/NewsArticlePreview";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import useInfiniteScroll from "../../utils/hooks/useInfiniteScroll";
import SelectBox from "../Selectbox/SelectBox";
import {Card} from '../Card/Card';
import {useValidLocations} from "../../utils/location";
import LoadMoreButton from "../Button/LoadMoreButton";
import {Skeleton} from "@rneui/base";
import NewsArticlePreviewSkeleton from "../NewsArticlePreview/NewsArticlePreviewSkeleton";
import {CONTENT_TYPE} from "../../constants/strings";
import {isDevelopment} from "../../constants/booleanValues";

/**
 * Main news feed with images, etc. together with NewsSelector
 * Renders a selectbox to filter by news Tags
 * Renders all the news below the selectbox
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function NewsSelector(props) {
    const {navigation, breakpoint} = props;
    // const theme = useSelector(state => state.theme);
    const {cache, theme} = useSelector(state => ({theme: state.theme, cache: state.cache}));
    const cachedNews = cache?.cacheTags.find(item => item.type === CONTENT_TYPE.NEWS);

    const {
        refetch: tagRefetch,
        data: tagData,
        error: tagError,
        isLoading: tagIsLoading
    } = endpoints.getNewsTags.useQuery();


    const overwriteStyles = StyleSheet.create({
        loadMoreButton: {
            backgroundColor: theme.colors.primary
        }
    });

    const [selectedNews, setSelectedNews] = useState("Alle Neuigkeiten anzeigen");

    // responsive NewsArticlePreview
    const ref = useRef();
    // const [viewWidth, setViewWidth] = useState(0);
    // useEffect(() => {
    //     const el = ref.current;
    //     if (!el) return;
    //     const ro = new ResizeObserver((e) => {
    //         const w = el?.getBoundingClientRect().width;
    //         setViewWidth(w);
    //     });
    //     ro.observe(el);
    //     return () => ro.disconnect();
    // }, [ref.current]);
    const locations = useValidLocations()
    const params = {
        size: 3,
        filter: selectedNews === "Alle Neuigkeiten anzeigen" ? null : selectedNews,
        ...(locations ? {locations} : {})
    }

    const {
        combinedData: data,
        maxPages,
        localPage,
        readMore,
        refresh,
        isLoading,
        isFetching,
        canFetchMore,
        refetch
    } = useInfiniteScroll(
        endpoints.getRegularNewsPaginated.useQuery,
        params
    );

    // console.log("NewsSelector", params);


    /**
     * after navigating home, the cache is reset, so we refetch
     */
    useEffect(() => {
        // isDevelopment && console.log("NewsSelector useEffect", {data, tagError, isLoading, params}, cachedNews)
            if (!isLoading && !cachedNews) {
                // isDevelopment && console.log("NewsSelector useEffect2", {data, tagError, isLoading}, cachedNews);
                refetch();
            }
    }, [cachedNews]);
    const onFilterValueChanged = (value) => setSelectedNews(value);

    if (isLoading || tagIsLoading) return <LoadingIndicator/>;

    return <Card
        title={'Weitere Neuigkeiten'}
        headerChildren={(
            <SelectBox
                useNativeAndroidPickerStyle={false}
                onValueChanged={onFilterValueChanged}
                value={selectedNews}
                items={tagData?.entries?.map(item => ({label: item.name, value: item.id})
                )}
            />
        )}>
        <View ref={ref} style={{flex: 1}}>
            <View style={{marginHorizontal: theme.padding, marginBottom: 32, alignItems: 'stretch'}}>
                {/* TODO : Filter und tags aus dem GA */}
                {Array.isArray(data) && data.map((item, idx) => (
                    <NewsArticlePreview isSmall={(breakpoint < 2)} navigation={navigation} key={item.id} {...item} />
                ))}
                {
                    isFetching && <NewsArticlePreviewSkeleton/>
                }
            </View>
            {canFetchMore && <View style={[style.loadMoreContainer, {backgroundColor: theme.backgroundColorAccent}]}>
                <LoadMoreButton onPress={() => readMore()} disabled={!canFetchMore}/>
            </View>}

        </View>
    </Card>;
}

const style = StyleSheet.create({
    header: {
        height: 56,
        alignItems: "center",
        justifyContent: "center",
    },
    loadMoreContainer: {
        flex: 1,
        alignItems: "center",
        paddingBottom: 24,
        paddingTop: 24,
    },
    loadMoreButton: {
        height: 40,
        width: 140,
        borderRadius: 25,
        alignItems: "center",
        justifyContent: "center"
    }
});

