import {useWindowDimensions} from "react-native";

/**
 * Calculates ImageDimension - in React Native we need to define the proper dimension of the image, it cannot scale automatically
 * @param imageWidth
 * @param imageHeight
 * @param maxWidth
 * @param maxHeight
 * @returns
 */
export const calculateImageDimension = (imageWidth: number, imageHeight: number | undefined, maxWidth: number, maxHeight = 300): { width: number, height: number } => {
    const ratio = imageWidth / imageHeight;
    imageWidth = maxWidth;
    imageHeight = Math.ceil(maxWidth / ratio);
    if (imageHeight > maxHeight) {
        imageHeight = maxHeight;
        imageWidth = Math.ceil(maxHeight * ratio);
    }
    return {
        width: imageWidth,
        height: imageHeight
    }
}
/**
 * Calculates ImageDimension based on ViewPort - in React Native we need to define the proper dimension of the image, it cannot scale automatically
 * @param imageWidth
 * @param imageHeight
 * @param maxWidth
 * @param maxHeight
 * @returns
 */
export const useCalculateImageDimension = (imageWidth: number, maxWidth: number, maxHeight = 300): { width: number, height: number } => {
    const {width,height} = useWindowDimensions();
    const aspectRatio = height / width;
    const imageHeight = (height * .65) /aspectRatio;
    return calculateImageDimension(imageWidth,imageHeight,maxWidth,maxHeight);
}
