import {StyleSheet, Text, View} from "react-native";
import React from "react";
import {useSelector} from "react-redux";

export default function NotificationCounter(props) {
    const {size = 1, value = 0, style} = props;
    const theme = useSelector(state => state.theme);
    let styles = StyleSheet.create({
        notification: {
            backgroundColor: theme.colors.primary,
            height: (size * 24),
            width: (size * 48),
            borderRadius: (size * 12),
            justifyContent: "center",
            alignItems: "center",
            ...style?.notification
        },
        text: {
            fontFamily: "OpenSans_600SemiBold",
            color: "#fff",
            fontSize: 16,
        }
    })
    return <View style={styles.notification}>
        <Text style={styles.text}>{value}</Text>
    </View>
}
