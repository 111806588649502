import RenderHtml, {
    TBlock,
    CustomBlockRenderer,
    TNodeChildrenRenderer
} from 'react-native-render-html';
import React from "react";
import {Text} from "react-native";

/**
 * A custom paragraph renderer which renders actually a text element with ellipsis enabled
 * based on:
 * https://github.com/meliorence/react-native-render-html/issues/243
 * https://github.com/meliorence/react-native-render-html/pull/349
 * @param TDefaultRenderer
 * @param tnode
 * @param type
 * @param props
 * @constructor
 */
export const ParagraphRenderer: CustomBlockRenderer = function ParagraphRenderer(
    {
        TDefaultRenderer,
        tnode,
        type,
        ...props
    }) {
    // console.log("paragraph render called")
    return (
        <TDefaultRenderer tnode={tnode} {...props}>
            <TNodeChildrenRenderer
                tnode={tnode}
                parentMarkers={props.markers}
                renderChild={({childTnode, childElement}) => {
                    // console.log({type, childElement})
                    return <Text key={childElement.key} numberOfLines={3}>{childElement}</Text>;
                    // return type === "block"
                    //     ? childElement
                    //     : <Text numberOfLines={3}>{childElement}</Text>
                }
                }
            />
        </TDefaultRenderer>
    );
};