import {Platform, Text, View} from "react-native";
import Button from "../Button/Button";
import {isDevelopment} from "../../constants/booleanValues";

export const routeToCalendarEvent = (registration) => (global.drawerNavigation.navigate("Termine", {event: registration}))
export const getEventDurationString = (event) => {
    if (!event.begin || !event.end) return "";
    const dateOptions = {year: '2-digit', month: '2-digit', day: '2-digit'};
    const timeOptions = {hour: '2-digit', minute: '2-digit'}
    const beginDate = new Date(event?.begin).toLocaleDateString('de-DE', dateOptions);
    const beginTime = new Date(event?.begin).toLocaleTimeString([], timeOptions);
    const endDate = new Date(event?.end).toLocaleDateString('de-DE', dateOptions);
    const endTime = new Date(event?.end).toLocaleTimeString([], timeOptions);
    if (beginDate === endDate) return `${beginDate} ${beginTime} - ${endTime} Uhr`;
    return `${beginDate} ${beginTime} Uhr - ${endDate} ${endTime} Uhr`;
}

/**
 * Some transformation from Bob codes made reusable for notifications
 * @param participants
 * @param event
 * @param username
 * @returns {{marked: boolean, description, eventDurationString: string, short_name: *, end, isRegistered: *, id, title, begin, selected: boolean}}
 */
export const transformEventData = (event, username) => {
    const {id, short_name, description, title, begin, end, participants} = event ?? {};
    isDevelopment && console.log("transformEventData", JSON.stringify({
        id,
        short_name,
        description,
        title,
        begin,
        end,
        participants: participants?.length ?? "-"
    }));
    // console.log("transformEventData", Object.keys(event ?? {}).join(","));
    return {
        id,
        selected: false,
        marked: true,
        short_name,
        description,
        title,
        begin,
        end,
        eventDurationString: getEventDurationString(event),
        isRegistered: participants?.find(participant => participant.username === username)
    }
}

export const prepareCalendarDataSource = (data, username) => {
    let eventDates = {};
    data.entries.forEach(event => {
        const eventObj = transformEventData(event, username);

        let date = new Date(event.begin);
        let endDate = new Date(event.end);
        date.setHours(0, 0, 0);
        endDate.setHours(0, 0, 0);

        // loop through each event day
        while (date <= endDate) {
            date.setDate(date.getDate() + 1); // increase by 1 day
            const dateString = date.toISOString().substring(0, 10);
            // save event on new day
            if (eventDates[dateString]) eventDates[dateString].push(eventObj);
            else eventDates[dateString] = [eventObj]
        }
    });
    return eventDates;
}
export const renderEventListItems = (e, theme, navigation, style = {container: {padding: theme.wrapperPadding}}) => {
    const index = e.index;
    const item = e.item;
    const styles = Platform.select({
        default: {
            innerContainer: {
                flexDirection: "row", alignItems: "center"
            },
            buttonContainer: {
                flex: 2, flexGrow: 6,
            },
            button: {
                button: {borderRadius: 80, margin: 0, maxWidth: 350, width: "100%", marginLeft: "auto",}
            }
        },
        native: {
            innerContainer: {
                flexDirection: "column"
            },
            buttonContainer: {
                flex: 2, flexGrow: 6,
            },
            button: {
                button: {borderRadius: 80, margin: 0, marginTop: 15, minWidth: 170}
            }
        }
    });
    return <View key={item.id} style={style?.container}>
        <View style={{borderColor: "grey", borderBottomWidth: 1, marginBottom: 10, paddingBottom: 25}}>
            <View style={styles.innerContainer}>
                <View style={{flex: 8, flexShrink: 1}}>
                    <Text style={{fontFamily: "OpenSans_700Bold", fontSize: 16, marginBottom: 14}}>
                        {item.title}
                    </Text>
                    <Text style={{fontFamily: "OpenSans_700Bold", marginBottom: 14}}>
                        {global.moment(new Date(item.begin)).format("DD.MM.YYYY - HH:mm") + " Uhr"}
                    </Text>

                    <Text style={{fontFamily: "OpenSans_400Regular", color: theme.colors.text}}>
                        {item.description}
                    </Text>
                </View>
                <View style={styles.buttonContainer}>
                    <Button style={styles.button} text={"Zum Event"}
                            onClick={() => {
                                console.log('renderEventListItems', Object.keys(item).join(","))

                                console.log("renderEventListItems isRegistered", item.isRegistered ? "yes" : "no");
                                navigation.setParams({event: item/*{id: item.id}*/})
                            }}/>
                </View>
            </View>
        </View>
    </View>
}
