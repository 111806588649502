import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import React, {useEffect} from "react";
import CaptionBanner from "../CaptionBanner/CaptionBanner";
import FontAwesome5 from 'react-native-vector-icons/FontAwesome5';
import {useDispatch, useSelector} from "react-redux";
import {NOTIFICATION_TYPES} from "./notificationTypes";
import {useLinkTo} from "@react-navigation/native";
import {
    endpoints,
    useDeleteWebNotificationsMutation, useGetNotificationsForWebQuery,
    useSetNotificationsAsReadMutation
} from "../../app/services/api/globalAdminAPI";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import {NOTIFICATION_POLLING_INTERVAL} from "../../constants/numbers";
import {isDevelopment} from "../../constants/booleanValues";


/**
 * Notifications list accessed from bell icon
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function Notifications(props) {
    const linkTo = useLinkTo();
    const theme = useSelector(state => state.theme);
    const dispatch = useDispatch()
    // const {data, error, isLoading} = endpoints.getNotificationsForWeb.useQuery({
    //     pollingInterval: 5,
    //     notifyOnNetworkStatusChange: true
    // });
    const {data, error, isLoading, refetch} = useGetNotificationsForWebQuery({}, {
        pollingInterval: NOTIFICATION_POLLING_INTERVAL,
        notifyOnNetworkStatusChange: true
    });

    const [setNotificationsAsRead, {
        isError,
        error: updateError,
        isLoading: hasSeenMutationLoading,
        isSuccess,
        isUninitialized,
        originalArgs,
        reset,
        status
    }] = useSetNotificationsAsReadMutation();
    const [deleteWebNotifications, {
        isError: deleteWebNotificationIsError,
        error: deleteError,
        isLoading: deleteMutationLoading
    }] = useDeleteWebNotificationsMutation();
    // console.log({data})
    const notifications = data?.flatMap(notification => {
        const notificationType = NOTIFICATION_TYPES.find(item => {
            return item.type === notification?.data?.type
        })
        if (notification?.data?.type === undefined || !notificationType) return [];
        return {
            id: notification.id, //it's data.notification_id, but on the other level :D
            type: notificationType?.type,
            icon: notificationType?.icon,
            route: typeof notificationType?.route === "function" ? notificationType?.route(notification) : notificationType?.route,
            timestamp: notification.createdAt,
            seen: notification.isRead,
            description: notification.description,
            title: notification.title,
            anchorId: notification.data?.anchor_id,
            entityId: notification.data?.id ?? notification.data?.thread_id ?? notification.data?.news_id,
            obj: notificationType,
            notification,
        }
    });

    // useEffect(() => {
    //     // if(notifications?.length > 0){
    //     //     setNotifcationsAsRead({ids: notifications?.filter(notifcation => notifcation.seen === false).map(item=>item.id) })
    //     // }
    //     //TODO(?): when visiting this view, alert the backend that we have seen all notifications.
    // }, [isLoading]);

    const overwriteStyles = StyleSheet.create({
        notificationContainer: {
            backgroundColor: theme.colors.background_light,
            borderColor: theme.colors.border
        },
        notificationIcon: {
            borderColor: theme.colors.text_dark
        },
        notificationIcon_seen: {
            borderColor: theme.colors.text
        },
        notificationText: {
            flex: 1,
            ...theme.typo.p_sm_bold,
            color: theme.colors.text_dark
        },
        notificationText_seen: {
            ...theme.typo.p_sm,
        },
        buttonDelete: {},
        buttonDeleteText: {
            ...theme.typo.p_sm
        }
    });
    if (isLoading) return <LoadingIndicator/>
    // const test_notifications = [{seen: false, route: 'Home'}, {seen: true, route: 'Home'}];
    //TODO: Styling von Delete buttons noch offen
    return <View style={styles.container}>
        <CaptionBanner config={{
            headline: `Benachrichtigungen (${notifications?.length})`,
        }}/>

        <TouchableOpacity
            style={[styles.buttonDelete, overwriteStyles.buttonDelete]}
            onPress={() => deleteWebNotifications({ids: notifications.map(item => item.id).toString()})}>
            <Text style={[styles.buttonDeleteText, overwriteStyles.buttonDeleteText]}>Alle löschen</Text>
        </TouchableOpacity>

        {/*<Button text={"Alle löschen"}*/}
        {/*        disabled={notifications.length < 1}*/}
        {/*        style={{*/}
        {/*            button: {*/}
        {/*                backgroundColor: theme.colors.error*/}
        {/*            }*/}
        {/*        }}*/}
        {/*        onClick={() => {*/}
        {/*            deleteWebNotifcations({ids: notifications.map(item => item.id).toString()})*/}
        {/*        }}/>*/}

        {notifications?.map((item, idx) => (
            <View key={idx} style={[styles.notificationContainer, overwriteStyles.notificationContainer,
                (idx === 0 && styles.notificationContainer_first)]}>
                <TouchableOpacity
                    style={styles.notificationTouchable}
                    onPress={() => {
                        isDevelopment && console.log("marking as read:", item.route?.web ?? item.route, item)
                        try {
                            setNotificationsAsRead({ids: [item.id]})
                            props.navigation.navigate(item.route?.web ?? item.route, {id: item.entityId, commentId: item.anchorId, notificationType: item.type})
                        } catch (e) {
                            console.error(e)
                        }
                    }}>
                    <View style={{
                        width: 60,
                        height: 60,
                        marginRight: theme.wrapperPadding,
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: 50,
                        borderWidth: 1,
                        borderColor: item.seen !== true ? theme.colors.text_dark : theme.colors.border
                    }}>
                        <FontAwesome5 name={item.icon}
                                      color={item.seen !== true ? theme.colors.text_dark : theme.colors.text}
                                      size={25}/>
                        {!item.seen && <View style={{
                            backgroundColor: theme.colors.error,
                            height: 12,
                            width: 12,
                            position: "absolute",
                            top: "-2%",
                            right: "-15%",
                            borderRadius: 20
                        }}/>}

                    </View>
                    <View style={{flexShrink: 1, width: "100%", paddingRight: 10}}>
                        <View style={{flexDirection: 'row'}}>
                            <Text style={[{flex: 1}, item.seen !== true ? {
                                fontFamily: "OpenSans_700Bold",
                                color: theme.colors.text_dark
                            } : {fontFamily: "OpenSans_400Regular", color: theme.colors.text}]}>
                                {global.moment(item.timestamp).format('dddd, DD. MMMM HH:mm:ss')}
                            </Text>
                        </View>
                        <View style={{flexDirection: 'row'}}>
                            <Text style={[{
                                flexDirection: 'row',
                                overflow: "hidden",
                                display: "-webkit-box",
                                WebkitBoxOrient: 'vertical',
                                WebkitLineClamp: 1,
                                textOverflow: 'ellipsis',
                            }, item.seen !== true ? {
                                fontFamily: "OpenSans_700Bold",
                                color: theme.colors.text_dark
                            } : {fontFamily: "OpenSans_400Regular", color: theme.colors.text}]}>
                                {item.title}
                            </Text>
                        </View>
                        <View style={{
                            flexDirection: 'row',
                            overflow: "hidden",
                            display: "-webkit-box",
                            WebkitBoxOrient: 'vertical',
                            WebkitLineClamp: 1,
                            textOverflow: 'ellipsis',
                        }}>
                            <Text style={[{flex: 1}, item.seen !== true ? {
                                fontFamily: "OpenSans_700Bold",
                                color: theme.colors.text_dark
                            } : {fontFamily: "OpenSans_400Regular", color: theme.colors.text}]}>
                                {item.description}
                            </Text>
                        </View>

                    </View>
                    <TouchableOpacity onPress={() => {
                        deleteWebNotifications({ids: [item.id]})
                    }}>
                        <FontAwesome5
                            style={[styles.icon, {marginRight: 32}]}
                            name={'trash'}
                            color={theme.colors[item.seen ? 'text_unimportant' : 'text_dark']}
                            size={20}/>
                    </TouchableOpacity>
                    <FontAwesome5
                        style={styles.icon}
                        name={'chevron-right'}
                        color={theme.colors[item.seen ? 'text_unimportant' : 'text_dark']}
                        size={20}
                    />

                    {/*<View style={[styles.notificationIcon, overwriteStyles.notificationIcon,*/}
                    {/*    (item.seen && overwriteStyles.notificationIcon_seen)]}>*/}
                    {/*    <FontAwesome5*/}
                    {/*        name={'calendar'}*/}
                    {/*        color={theme.colors[item.seen ? 'text' : 'text_dark']}*/}
                    {/*        size={20}/>*/}
                    {/*    {!item.seen && <View style={styles.marker}/>}*/}
                    {/*</View>*/}
                    {/*<Text style={[overwriteStyles.notificationText, (item.seen && overwriteStyles.notificationText_seen)]}>notification*/}
                    {/*    text layout...</Text>*/}
                    {/*<FontAwesome5*/}
                    {/*    style={styles.icon}*/}
                    {/*    name={'chevron-right'}*/}
                    {/*    color={theme.colors[item.seen ? 'text_unimportant' : 'text_dark']}*/}
                    {/*    size={20}/>*/}
                </TouchableOpacity>
            </View>
        ))}
    </View>
}

const styles = StyleSheet.create({
    container: {},
    notificationContainer: {
        backgroundColor: '#fff',
        borderWidth: 1,
        borderRadius: 43,
        padding: 16,
        maxHeight: 86,
        display: 'flex',
        flexDirection: "row",
        alignItems: 'center',
        justifyContent: 'flex-start',
        marginTop: 10,
    },
    notificationContainer_first: {
        marginTop: 0,
    },
    notificationTouchable: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'center'
    },
    notificationIcon: {
        justifyContent: 'center',
        alignItems: 'center',
        height: 54,
        width: 54,
        marginRight: 20,
        borderWidth: 1,
        borderRadius: 100,
    },
    marker: {
        position: 'absolute',
        top: 2,
        right: 2,
        width: 12,
        height: 12,
        borderRadius: 10,
        backgroundColor: '#E62222'
    },
    icon: {
        marginRight: 16
    },
    buttonDelete: {
        alignSelf: 'flex-end',
        // borderWidth: 1,
        borderRadius: 100,
        marginBottom: 16,
        marginRight: 54,
    },
    buttonDeleteText: {
        textAlign: 'center',
        textDecorationLine: 'underline'
    },
});
