import Comment from "./Comment.native";
import CommentWeb from "./Comment";
import React, {useRef} from "react";
import {Platform, StyleSheet} from "react-native";
import {FlatList, InteractionManager} from "react-native-web";
import {isDevelopment} from "../../constants/booleanValues";
import {useSelector} from "react-redux";


/**
 * Maps comments to some weird extra structure.
 * Kept for legacy reasons. Should be refactored in the future probably
 * @param comments
 * @returns {*|*[]}
 */
export const mapComments = (comments) => {
    if (Array.isArray(comments)) {
        return comments.filter(c => !c?.deleted_at).flatMap(comment => {
            if (comment) {
                return {
                    id: comment.id,
                    createdAt: new Date(comment.created_at),
                    content: comment.text,
                    blocked: comment.blocked,
                    replies: mapComments(comment.children),
                    youLikeIt: comment.you_like_it,
                    likesAmount: comment.likes,
                    user: {
                        id: comment.author?.id,
                        imageUrl: comment.author?.picture?.formats.find(item => item.name === "default_small")?.url,
                        name: comment.author?.full_name,
                        gender: comment.author?.gender
                    }
                }
            } else return []
        }).sort((a, b) => a.createdAt < b.createdAt);
        //sort((a, b) => new Date(a?.createdAt) < new Date(b?.createdAt)) //maybe needed for web version? copied from ForumPage
    } else return [];
}

const dateSorter = (a, b) => new Date(a?.createdAt) < new Date(b?.createdAt);

/**
 * displays comments as flat mapped
 * todo: needs to be fully refactored to support news. Currently supported on Forum only
 * @param props
 * @returns {*}
 */
export const flatMapComments = (props) => {
    const {
        comments,
        commentId,
        commentLoadSize,
        setReplyOptions,
        setHighlightPosition,
        focusInput //used in web only
    } = props;
    // const sortedComments = comments?.sort(dateSorter);//todo: observer if this sorting is obsolete
    const sortedComments = comments;
    const res = sortedComments.flatMap((comment, idx) => {
        // console.log("flatMap", comment);
        const replies = comment.replies;//?.sort(dateSorter);//todo: observe if this sorting is absolete
        // if it contains highlighted comment, render all the sub-comments up until that index
        //we search for our comment. If it's in replies then we display some replies too
        const elementPos = replies?.map(el => el.id).indexOf(commentId);
        //replies?.find(item => Number(item.id) === commentId)
        if (elementPos >= 0 || idx <= commentLoadSize - 1) {
            const replyLoadCount = Platform.select({
                native: elementPos === -1 ? 0 : elementPos + 1,// maybe rather replies.length?
                default: replies?.length ?? 0,//web displays all comments
            });

            const isChildHighlighted = elementPos !== -1;
            const isParentHighlighted = Number(comment.id) === commentId;

            const CommentComponent = Platform.select({native: Comment, default: CommentWeb});
            // (isChildHighlighted || isParentHighlighted) && console.log("reply", JSON.stringify({
            //     id: comment.id, isReplyTo: comment.parentId,
            //     isChildHighlighted, isParentHighlighted
            // }));
            return <CommentComponent //Comment
                key={comment.id}
                id={comment.id}
                index={idx}
                isReplyTo={comment.parentId}
                canLike={false}
                user={comment.user}
                createdAt={global.moment(comment.createdAt).format(Platform.select({
                    native: "DD.MM.YYYY - HH:mm:ss", default: 'DD.MM.YY - LT [Uhr]'
                }))}
                highlighted={isParentHighlighted}
                setHighlightPosition={isParentHighlighted || isChildHighlighted ? setHighlightPosition : undefined}
                comment={{content: comment.content, id: comment.id}}
                onReply={(id) => {
                    focusInput && focusInput();//used in web only
                    let foundComment = comments.find(item => item.id === id)
                    setReplyOptions && setReplyOptions({
                        ...foundComment, fullName: foundComment.user?.name, commentId: foundComment.id
                    })
                }}

                /* other */
                replyLoadCount={replyLoadCount}
                backgroundColor={Platform.select({native: "#fff", default: "transparent"})}
                replies={replies}
                newCommentId={commentId}
            />
        } else return []
    });
    return res;
}

// /**
//  * Wrapper for the highlight position set up to include our FlatView ref and index to scroll to
//  * @param index
//  * @returns {function(*, *): *}
//  */
const highlightWorkedAround = (index, scrollView) => (params, isParent) => {
    // setHighlightPosition(
    //     {
    //         // ...params,
    //         // scrollView,
    //         // index
    //         x: 50,
    //         y: 50,
    //     }
    //     , isParent);
    InteractionManager.runAfterInteractions(() => {
        console.log("highlightWorkedAround", index);
        scrollView.current.scrollToIndex({index});
    });
}

/**
 * Obsolete, continue refactoring till flatMapComments works fully
 * todo: refactor news to support flatMapComments instead
 * Should depend on [commentId, comments].
 * @param comments
 * @param scrollView
 * @param styles
 * @param overwriteStyles
 * @param commandId
 * @returns {JSX.Element}
 * @constructor
 */
export const FlatListComments = (props) => {
    const {comments, commentId, setHighlightPosition, focusInput, setReplyOptions, ...propsRest} = props;
    const scrollView = useRef(null);
    const theme = useSelector(state => state.theme);

    const styles = StyleSheet.create({
        commentContainer: {
            borderTopWidth: 1,
            borderTopColor: theme.colors.border
        },
        noComments: {
            ...theme.typo.p_sm
        }
    });

    return <FlatList data={comments}
                     ref={scrollView}
                     keyExtractor={(item) => item.id}
                     nestedScrollEnabled={true}

                     style={[styles.commentContainer]}
                     renderItem={({item, index}) => {
                         // console.log("FlatList.renderItem", props)
                         const comment = item;
                         const isParentHighlighted = Number(comment.id) === commentId;
                         const elementPos = comment.replies?.map(el => el.id).indexOf(commentId);
                         const isChildHighlighted = elementPos !== -1;


                         // console.log("renderItem", comment, commentId)
                         return <>
                             <CommentWeb
                                 {...propsRest}
                                 key={comment.id}
                                 id={comment.id}
                                 isReplyTo={comment.parentId}
                                 canLike={false}
                                 user={comment.user}
                                 createdAt={global.moment(comment.createdAt)?.format(Platform.select({
                                     native: "DD.MM.YYYY - HH:mm:ss", default: 'DD.MM.YY - LT [Uhr]'
                                 }))}
                                 highlighted={isParentHighlighted}
                                 // setHighlightPosition={isHighlighted ? setHighlightPosition : undefined}
                                 // setHighlightPosition={isParentHighlighted ? highlightWorkedAround(index, scrollView) : undefined}
                                 setHighlightPosition={isParentHighlighted || isChildHighlighted ? setHighlightPosition : undefined}
                                 comment={{content: comment.content, id: comment.id}}
                                 onReply={(id) => {
                                     focusInput && focusInput();//used in web only
                                     let foundComment = comments.find(item => item.id === id)
                                     setReplyOptions && setReplyOptions({
                                         ...foundComment, fullName: foundComment.user?.name, commentId: foundComment.id
                                     })
                                 }}
                                 /* other */
                                 // stackNavigation={stackNavigation}
                             />
                             {comment.replies?.map((reply, idx) => {
                                 // isDevelopment && console.log(reply)
                                 return <CommentWeb
                                     key={reply.id}
                                     id={reply.id}
                                     // stackNavigation={stackNavigation}
                                     highlighted={Number(reply.id) === Number(commentId)}
                                     isReplyTo={comment.id}
                                     // onReply={(id)=>{
                                     //     messageSubmitRef?.current?.focusInput();
                                     //     let foundComment = comments.find(item=>item.id === id)
                                     //     messageSubmitRef.current.setReplyOptions({
                                     //         ...foundComment,
                                     //         fullName:foundComment.user?.name,
                                     //         commentId: foundComment.id
                                     //     })
                                     // }}
                                     canLike={false}
                                     canReply={false}
                                     user={reply.user}
                                     createdAt={global.moment(reply.createdAt)?.format('DD.MM.YY - LT [Uhr]')}
                                     comment={{content: reply.content}}/>
                             })}
                         </>
                     }}
    />
};