import {useSelector} from "react-redux";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import {NEW_CONTENT_POLLING_INTERVAL_DEFAULT} from "../../constants/numbers";
import {CONTENT_TYPE} from "../../constants/strings";
import {useFocusEffect} from "@react-navigation/native";
import {useCallback, useState} from "react";
import {isDevelopment} from "../../constants/booleanValues";

/**
 * Hook that gets content types to request for by polling in defined interval
 * @param types
 * @returns {{visible: boolean}}
 */
export default function useNewContentNotification({types = []}) {
    const cache = useSelector(state => state.cache);
    const [skipAll, setSkipAll] = useState(true)

    if (!Array.isArray(types)) {
        throw new Error("Types must be array.")
    }
    /**
     * Since React Navigation doesn't unmount our components, this focusEffect is a workaround for this issue.
     * When Component is not in view - we skip all data loading hooks.
     */
    useFocusEffect(
        useCallback(() => {
            isDevelopment && console.log("useNewContentNotification useFocusEffect for types", types.join(","));
            setSkipAll(false);
            return () => {
                isDevelopment && console.log("useNewContentNotification useFocusEffect closed");
                setSkipAll(true);
            };
        }, [])
    );
    /**
     * Define all the endpoints we need in our app
     */
    endpoints[`getUpdated${CONTENT_TYPE.NEWS}`].useQuery({}, {
        pollingInterval: NEW_CONTENT_POLLING_INTERVAL_DEFAULT,
        skip: !types.includes(CONTENT_TYPE.NEWS) || skipAll
    });
    endpoints[`getUpdated${CONTENT_TYPE.SHORTNEWS}`].useQuery({}, {
        pollingInterval: NEW_CONTENT_POLLING_INTERVAL_DEFAULT,
        skip: !types.includes(CONTENT_TYPE.SHORTNEWS) || skipAll
    });
    endpoints[`getUpdated${CONTENT_TYPE.QUICKLINK}`].useQuery({}, {
        pollingInterval: NEW_CONTENT_POLLING_INTERVAL_DEFAULT,
        skip: !types.includes(CONTENT_TYPE.QUICKLINK) || skipAll
    });
    endpoints[`getUpdated${CONTENT_TYPE.BENEFIT}`].useQuery({}, {
        pollingInterval: NEW_CONTENT_POLLING_INTERVAL_DEFAULT,
        skip: !types.includes(CONTENT_TYPE.BENEFIT) || skipAll
    });
    endpoints[`getUpdated${CONTENT_TYPE.INFORMATION}`].useQuery({}, {
        pollingInterval: NEW_CONTENT_POLLING_INTERVAL_DEFAULT,
        skip: !types.includes(CONTENT_TYPE.INFORMATION) || skipAll
    });
    endpoints[`getUpdated${CONTENT_TYPE.CALENDAREVENT}`].useQuery({}, {
        pollingInterval: NEW_CONTENT_POLLING_INTERVAL_DEFAULT,
        skip: !types.includes(CONTENT_TYPE.CALENDAREVENT) || skipAll
    });
    endpoints[`getUpdated${CONTENT_TYPE.GALLERY}`].useQuery({}, {
        pollingInterval: NEW_CONTENT_POLLING_INTERVAL_DEFAULT,
        skip: !types.includes(CONTENT_TYPE.GALLERY) || skipAll
    });
    endpoints[`getUpdated${CONTENT_TYPE.THREAD}`].useQuery({}, {
        pollingInterval: NEW_CONTENT_POLLING_INTERVAL_DEFAULT,
        skip: !types.includes(CONTENT_TYPE.THREAD) || skipAll
    });
    endpoints[`getUpdated${CONTENT_TYPE.COMMENT}`].useQuery({}, {
        pollingInterval: NEW_CONTENT_POLLING_INTERVAL_DEFAULT,
        skip: !types.includes(CONTENT_TYPE.COMMENT) || skipAll
    });

    let visible = false;

    types.forEach(typeSpec => {
        const cacheType = cache.cacheTags.find(item => item.type === typeSpec);
        if (Array.isArray(cacheType?.ids) && cacheType.ids.length > 0) {
            visible = true;
        }
    });

    // console.log("useNewContentNotification, visible=", visible, cache.cacheTags)

    return {
        visible
    }
}
