import {Platform, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {useSelector} from "react-redux";
import InternalLink from "../Link/InternalLink";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import NoDataText from "../Text/NoDataText";
import {DEFAULT_ERROR} from "../../constants/strings";
import {Image} from "react-native-expo-image-cache";
import React from "react";
import CaptionBanner from "../CaptionBanner/CaptionBanner";
import ViewBullet from "../../components/ViewBullet/ViewBullet";

export default function InformationLinks(props) {
    const {navigation} = props;
    const theme = useSelector(state => state.theme);
    const {
        data: overViewDescriptionData,
        error: overViewDescriptionError,
        isLoading: overViewDescriptionLoading
    } = endpoints.getInformationOverviewDescription.useQuery();
    const {data, error, isLoading} = endpoints.getInformationOverview.useQuery();
    if (overViewDescriptionLoading || isLoading || error) return <LoadingIndicator/>
    if (error || overViewDescriptionError) {
        return <Text>{DEFAULT_ERROR}</Text>
    }
    const renderLinks = () => {
        return data.entries.map((item, idx) => {
            const routeParams = Platform.OS !== 'web' ? {
                label: "Informationen",
                description: item.description,
                picture_data: item.picture_data
            } : {}
            return <ViewBullet listContainer={{marginBottom: 25, alignItems: "center"}}>
                <TouchableOpacity key={idx} /*style={styles.link}*/>
                    <InternalLink
                        navigation={navigation}
                        config={{
                            options: {
                                label: item.title || "Kein Titel",
                                routeTo: "InformationenDetail",
                                routeParams: {
                                    id: item.id,
                                    ...routeParams
                                }
                            }
                        }}/>
                </TouchableOpacity>
            </ViewBullet>
        });
    }
    return <View style={[{padding: theme.wrapperPadding}]}>
        <CaptionBanner config={{
            headline: "Informationen",
            subHeadline: "Triff immer die richtige Leitung für dein Anliegen"
        }}/>
        {/*<Image*/}
        {/*    style={styles.newsImage}*/}
        {/*    resizeMode={"cover"}*/}
        {/*    defaultSource={require("../../../assets/images/placeholder-web.jpg")}*/}
        {/*    {*/}
        {/*        ...(overViewDescriptionData?.banner_picture_data?.reference?.url*/}
        {/*            ? {*/}
        {/*                source: overViewDescriptionData?.banner_picture_data?.reference?.url,*/}
        {/*                uri: overViewDescriptionData?.banner_picture_data?.reference?.url*/}
        {/*            }*/}
        {/*            : {})*/}
        {/*    }*/}
        {/*/>*/}
        {/*<Text style={[theme.text.paragraph, styles.introParagraph]}>{overViewDescriptionData?.teaser}</Text>*/}
        {
            (data.entries && Array.isArray(data.entries) && data.entries.length > 0) ? renderLinks() : <NoDataText/>
        }
    </View>
}
const styles = StyleSheet.create({
    introParagraph: {
        marginBottom: 20
    },
    newsImage: {
        borderRadius: 30,
        marginBottom: 32,
        height: 480,
        resizeMode: "cover",
    },
    link: {
        marginBottom: 25
    }
})
