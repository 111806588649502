import {StyleSheet, Text, View,} from 'react-native';
import React, {useState} from "react";
import {ComponentResolver} from "src/constants/constants";
import FirstLoginModal from "src/components/Modals/FirstLoginModal/FirstLoginModal";
import {useSelector} from "react-redux";
import {style as stylesFullScreen} from "../ContainerRenderer/ContainerRendererWrapper";

const styles = StyleSheet.create({
    outerContainer: {
        flexDirection: 'row',
    },
    outerContainer_bpColumn: {
        flexDirection: 'column',
        alignItems: 'stretch',
    },
    outerContainer_hasMargin: {
        marginHorizontal: 80,
        marginBottom: 80,
        marginTop: 64,
    },
    outerContainer_hasMargin_bpMargin: {
        marginHorizontal: 40,
        marginBottom: 40,
        marginTop: 32,
    },
    innerContainer: {
        flex: 1,
    },
    innerContainer_hasMargin: {
        marginLeft: 80,
    },
    innerContainer_hasMargin_bpColumn: {
        marginLeft: 0,
        marginTop: 80,
    },
    innerContainer_hasMargin_bpColumn_bpMargin: {
        marginLeft: 0,
        marginTop: 40,
    },
    component: {},
    component_hasMargin: {
        marginTop: 80
    },
    component_hasMargin_bpMargin: {
        marginTop: 40
    }
});
export default function ContainerRenderer(props) {
    const {view, breakpoint} = props;
    // console.log("ContainerRenderer", props, view.fullScreen)

    const auth = useSelector(state => state.auth);
    // console.log("ContainerRenderer", auth)

    const [isFirstLoginModalVisible, setFirstLoginModalVisible] = useState(auth.loginCount < 1);
    const bp_column = (!view.fullScreen && breakpoint < 6);
    const bp_margin = breakpoint < 3
    return <View style={view.fullScreen ? stylesFullScreen.fullScreen : undefined}>
        {
            auth?.first_login &&
            <FirstLoginModal
                visible={isFirstLoginModalVisible}
                onDismissed={() => {
                    setFirstLoginModalVisible(false)
                }}
            />
        }
        {view.container.map((outerContainer, i) => (
            <View
                key={i}
                style={[styles.outerContainer, outerContainer.style,
                    (bp_column && styles.outerContainer_bpColumn),
                    (view.fullScreen && stylesFullScreen.fullScreen),
                    (outerContainer.options?.hasMargin && styles.outerContainer_hasMargin),
                    (outerContainer.options?.hasMargin && bp_margin && styles.outerContainer_hasMargin_bpMargin),
                ]}>
                {outerContainer.container.map((innerContainer, j) => (
                    <View
                        key={j}
                        style={[
                            styles.innerContainer, innerContainer.style,
                            (view.fullScreen && stylesFullScreen.fullScreen),
                            (innerContainer.options?.hasMargin && j !== 0 && styles.innerContainer_hasMargin),
                            (innerContainer.options?.hasMargin && j !== 0 && bp_column && styles.innerContainer_hasMargin_bpColumn),
                            (innerContainer.options?.hasMargin && j !== 0 && bp_column && bp_margin && styles.innerContainer_hasMargin_bpColumn_bpMargin),
                            (bp_column && {flex: null})
                        ]}>
                        {innerContainer.components.map((component, k) => (
                            <View key={k} style={[styles.component,
                                (view.fullScreen && stylesFullScreen.fullScreen),
                                ((component.options?.hasMargin && k !== 0) && styles.component_hasMargin),
                                ((component.options?.hasMargin && k !== 0 && bp_margin) && styles.component_hasMargin_bpMargin)
                            ]}>
                                {ComponentResolver()[component.name]
                                    ? React.createElement(ComponentResolver()[component.name],
                                        {...props, config: component})
                                    : <Text>Error ContainerRenderer: Component "{component.name}" not found</Text>}
                            </View>
                        ))}

                    </View>
                ))}

            </View>
        ))}
    </View>;
}
