import {Platform} from "react-native";

/**
 * calendar event was created with included participants
 * @type {number}
 */
export const NOTIFICATION_TYPE_CALENDAR_EVENT_NEW = 0;
/**
 * calendar event was edited with included participant
 * @type {number}
 */
export const NOTIFICATION_TYPE_CALENDAR_EVENT_CHANGED = 1;

/**
 * reply to original thread in forum (which is a first comment), created the the user
 * @type {number}
 */
export const NOTIFICATION_TYPE_FORUM_NEW_REPLY_TO_OWN_THREAD = 2;
/**
 * replies to users comments either from forum or from news
 * if it's in news - look out for news_id property, if it's in forum: thread_id
 * @type {number}
 */
export const NOTIFICATION_TYPE_FORUM_NEW_REPLY_TO_OWN_COMMENT = 3;

export const NOTIFICATION_TYPE_NEW_GALLERY = 4;

export const NOTIFICATION_TYPE_NEWS_NEW_ENTRY = 5;

export const NOTIFICATION_TYPE_BIRTHDAY = 6;

/**
 * short news
 * @type {number}
 */
export const NOTIFICATION_TYPE_NEWS_NEW_NOTICE = 7;
export const NOTIFICATION_TYPES = [
    {
        type: NOTIFICATION_TYPE_CALENDAR_EVENT_NEW,
        label: "Neues Kalenderevent",
        icon: "calendar-plus",
        route: {
            web: "Termine",
        },
        isConfigurable: true,
    },
    {
        type: NOTIFICATION_TYPE_CALENDAR_EVENT_CHANGED,
        label: "Kalenderevent hat sich geändert",
        icon: "calendar-plus",
        route: {
            web: "Termine",
        },
        isConfigurable: true,
    },
    {
        type: NOTIFICATION_TYPE_FORUM_NEW_REPLY_TO_OWN_THREAD,
        label: "Antwort auf eigenen Thread",
        icon: "comments",
        route: {
            web: "ForumPage"
        },
        isConfigurable: true,
    },
    {
        type: NOTIFICATION_TYPE_FORUM_NEW_REPLY_TO_OWN_COMMENT,
        label: "Antwort auf eigenen Kommentar",
        icon: "comments",
        // route: "ForumCategory",
        route: (notification) => {
            return Platform.OS === "web"
                ? (notification?.data?.hasOwnProperty("news_id") ? "NewsArticle" : "ForumPage")
                : "ForumCategory";
        },
        isConfigurable: true,
    },
    {
        type: NOTIFICATION_TYPE_NEW_GALLERY,
        label: "Neue Gallerie",
        icon: "images",
        route: {web: "Fotogalerie"},
        isConfigurable: true,
    },
    {
        type: NOTIFICATION_TYPE_NEWS_NEW_ENTRY,
        label: "Neue Nachrichten",
        icon: "newspaper",
        route: {
            web: "NewsArticle"
        },
        isConfigurable: true,
    },
    {
        type: NOTIFICATION_TYPE_BIRTHDAY,
        label: "Benutzer hat Geburtstag",
        icon: "birthday-cake",
        route: {
            web: "/"
        },
        isConfigurable: false,
    },
    {
        type: NOTIFICATION_TYPE_NEWS_NEW_NOTICE,
        label: "Neue Kurznachrichten",
        icon: "newspaper",
        route: {
            web: "Home"
        },
        isConfigurable: true,
    },
]
