import React, {useEffect, useRef} from "react";
import {useIsFocused} from "@react-navigation/native";
import {InteractionManager} from "react-native-web";
import {ScrollView, StyleSheet, View} from "react-native";
import {Footer} from "../../components/Footer/Footer";


export const style = StyleSheet.create({
    fullScreen: {
        flex: 1,
        alignSelf: 'stretch',
    },
});

/**
 * A wrapper for container renderer.
 * A full screen style logic is moved inside, so that we could pass down a scrollViewRef
 *
 * Previous implementation had a scrollViewRef assigned globally and made my eyes cry seeing the code
 * The wrapping with Views still should be reviewed - maybe there's too many wrappings still
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ContainerWrapper = (props) => {
    const {children, isFullScreen, breakpoint} = props;
    const scrollRef = useRef(null);
    const isFocused = useIsFocused();

    // resets scroll of the current page on route change
    useEffect(() => {
        // https://stackoverflow.com/questions/33208477/react-native-android-scrollview-scrollto-not-working
        // InteractionManager.runAfterInteractions(() => {
        //     scrollRef.current?.scrollTo({x: 0, y: 0, animated: false});
        //
        // });
        console.log("ContainerWrapper.useEffect", Object.keys(props).join(","))
    }, [scrollRef.current, isFocused]);

    const renderChildren = () => {
        return React.Children.map(children, (child) => {
            return React.cloneElement(child, {
                scrollViewRef: scrollRef,
            });
        });
    };

    return isFullScreen
        ? <View style={style.fullScreen}>
            {renderChildren()}
        </View>
        : <ScrollView
            ref={scrollRef}
            nestedScrollEnabled={true}
            onScroll={(event) => {
                console.log("ContainerWrapper.onScroll", event.nativeEvent)
            }}
            // onMomentumScrollEnd={(event) => {
            //     console.log("WebNavigator.onMomentumScrollEnd", event.nativeEvent)
            // }}
            style={style.scroll}
            contentContainerStyle={{minHeight: '100%', justifyContent: 'space-between'}}>
            {/*{props.children}*/}
            <View>
                {renderChildren()}
            </View>
            <Footer {...props} {...{breakpoint}} />
        </ScrollView>;
};


export default ContainerWrapper;