import {useLayoutEffect, useRef, useState} from "react";
import {Dimensions, Platform} from "react-native";

/**
 * a custom hook that calculates the amount and width per tiles to fill a given width.
 * only works in web.
 *
 * @param {Number} minTileWidth min. width per tile
 * @param {Number} marginWidth margin between tiles
 * @param {Number} maxTiles max. amount of tiles per row. the tiles will be scaled up to fill the width
 * @returns {{tiles: number, ref: React.MutableRefObject<null>, tileDim: number}}
 * ref needs to be assigned to the container the tiles are supposed to fill.
 * tiles is the amount of tiles per row.
 * tileDim is the with of each tile, excluding the margin.
 */
const useGrid = (minTileWidth, marginWidth, maxTiles = Number.POSITIVE_INFINITY, onLayout) => {
    const ref = useRef(null);
    const [tiles, setTiles] = useState(0);
    const [tileDim, setTileDim] = useState(0);

    const calcDimensions = (width) => {
        const _tiles = Math.min(maxTiles, Math.max(1, Math.floor(width / minTileWidth)));
        const _tileDim = (width - (marginWidth * (_tiles - 1))) / _tiles;
        console.log("got width:", width, "calculated dimention:", _tileDim, "minTileWidth", minTileWidth)

        setTiles(_tiles);
        setTileDim(_tileDim);
    }

    const calculateDimensionsEl = (el) => {
        const rect = el.getBoundingClientRect();
        calcDimensions(rect.width);
    }

    /**
     * this hook is only for web
     */
    if (Platform.OS === 'web') {
        useLayoutEffect(() => {
            const el = ref.current;
            if (!el) return;
            //web only
            const ro = new ResizeObserver((e) => {
                calculateDimensionsEl(el);
            });
            ro.observe(el);
            return () => ro.disconnect();
        }, [ref.current, minTileWidth, setTiles, setTileDim]);
    }


    /**
     * instead of hook, onlayout fires up for native devices
     * @param event
     */
    onLayout = (event) => {
        calcDimensions(event.nativeEvent.layout.width);
    };

    return {ref, tiles, tileDim, onLayout};
};

export default useGrid;
