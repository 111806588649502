import {CHAT_BOT_URL, CONFIGURATION_NAMES, CONTENT_TYPE} from "../../../../constants/strings";

const nativeRoutesLoggedIn = {
    "version": {
        "name": CONFIGURATION_NAMES.NATIVE_AFTER_LOGIN_DEFAULT
    },
    "styles": {
        "theme": "kikxxl",
        "text": {
            "fontFamily": "BebasNeueRegular"
        }
    },
    "routes": [
        /**
         * Tab Routes
         */
        {
            "name": "HomeTab",
            "description": "This is the entry point"
        },
        {
            "name": "HomeStack",
            "focusedRoute": "HomeStack",
            "title": "Home",
            "showInTab": true,
            "showInDrawer": true,
            "icon": "home"
        },
        {
            "name": "Home",
            "focusedRoute": "HomeStack",
            "title": "Home",
            "isTabRoute": true,
            "container": [
                {
                    "contentTypes": [
                        CONTENT_TYPE.NEWS,
                        CONTENT_TYPE.SHORTNEWS
                    ],
                    "components": [
                        {
                            "name": "NewsPage",
                        },
                        {
                            "name": "ShortNews",
                            "options": {
                                showBorders: true
                            }
                        },
                        {
                            "name": "NewsSelector",
                            "options": {
                                showBorders: true
                            }
                        }]
                }
            ],
            "showInWebDrawer": true,
            "webContainer": [],
            "showInDrawer": false,
            "icon": "home"
        },
        {
            "name": "BenefitsTab",
            "focusedRoute": "BenefitsTab",
            "title": "Benefits",
            "showInTab": true,
            "showInDrawer": false,
            "icon": "benefits"
        },
        {
            "name": "Benefits",
            "focusedRoute": "BenefitsTab",
            "container": [
                {
                    "contentTypes": [
                        CONTENT_TYPE.BENEFIT
                    ],
                    "components": [
                        {
                            "name": "CaptionBanner",
                            "styles": {},
                            "options": {
                                "text": {
                                    "value": "Benefits",
                                    "fontFamily": "BebasNeueRegular",
                                    "color": "white"
                                },
                                color: "white",
                                "icon": {name: "benefits", type: "fontello_custom"},
                                "justifyItems": "center",
                                "alignItems": "center",
                                "backgroundColor": "#0093D1"
                            }
                        },
                        {
                            "name": "BenefitsOverview",
                        }
                    ]
                }
            ],
            "showInDrawer": false,
            "isTabRoute": true,
        },
        {
            "name": "InternTab",
            "focusedRoute": "InternTab",
            "title": "Intern",
            "showInTab": true,
            "showInDrawer": false,
            "iconType": "svg",
            "icon": "intern"
        },
        {
            "name": "Intern",
            "focusedRoute": "InternTab",
            "isTabRoute": true,
            "showInDrawer": false,
            "container": [
                {
                    "contentTypes": [
                        CONTENT_TYPE.QUICKLINK
                    ],
                    "components": [
                        {
                            "name": "CaptionBanner",
                            "styles": {},
                            "options": {
                                "text": {
                                    "value": "INTERN",
                                    "fontFamily": "BebasNeueRegular",
                                    "color": "white"
                                },
                                "icon": {name: "intern", type: "fontello_custom"},
                                "justifyItems": "center",
                                "alignItems": "center",
                            }
                        },
                        {
                            "name": "TileLinks",
                            "options": {
                                "endpointName": "getQuickLinks",
                                "styles": {
                                    "container": {
                                        "flexDirection": "row",
                                        "flexWrap": "wrap"
                                    },
                                    "linkContainer": {
                                        backgroundColor: "transparent",
                                        borderColor: "#707070",
                                        borderWidth: 1
                                    },
                                    "linkText": {
                                        color: "#707070"
                                    }
                                },
                                "useGroups": true,
                                itemsPerRow: 2,
                                itemHeight: 0.7,
                            }
                        }
                    ]
                }
            ],
            "icon": "phone"
        },
        {
            "name": "ProfileTab",
            "focusedRoute": "ProfileTab",
            "title": "Profil",
            "showInTab": true,
            "showInDrawer": false,
            "icon": "profile"
        },
        {
            "name": "Profile",
            "focusedRoute": "ProfileTab",
            "isTabRoute": true,
            "container": [
                {
                    "components": [
                        {
                            "name": "CaptionBanner",
                            "styles": {},
                            "options": {
                                "text": {
                                    "value": "DEIN PROFIL",
                                    "fontFamily": "BebasNeueRegular",
                                    "color": "white"
                                },
                                "icon": {name: "profile", type: "fontello_custom"},
                                "justifyItems": "center",
                                "alignItems": "center",
                                "backgroundColor": "#0093D1"
                            }
                        },
                        {
                            "name": "Profile"
                        }
                    ]
                }
            ],
            "icon": "phone"
        },
        {
            "name": "ProfileOther",
            "focusedRoute": "Home",
            "isTabRoute": false,
            "container": [
                {
                    "components": [
                        {
                            "name": "Profile"
                        }
                    ]
                }
            ],
            "icon": "phone"
        },
        {
            "name": "BenefitsDetail",
            "focusedRoute": "Benefits",
            "title": "BenefitsDetail",
            "showInDrawer": false,
            "showInTab": false,
            "container": [
                {
                    "components": [
                        {
                            "name": "BlogPage"
                        }
                    ]
                }
            ]
        },
        /**
         * Drawer Routes
         */
        {
            "name": "FotogalerienStack",
            "focusedRoute": "FotogalerienStack",
            "title": "Fotogalerien",
            "showInTab": false,
            "showInDrawer": true,
            "icon": "images"
        },
        {
            "name": "FotoOverView",
            "focusedRoute": "FotogalerienStack",
            "title": "Forum",
            "showInTab": false,
            "showInDrawer": false,
            "container": [
                {
                    "contentTypes": [
                        CONTENT_TYPE.GALLERY
                    ],
                    "components": [
                        {
                            "name": "CaptionBanner",
                            "styles": {},
                            "options": {
                                "text": {
                                    "color": "white",
                                    "value": "Fotogalerien",
                                },
                                "backgroundColor": "#0093D1"
                            }
                        },
                        {
                            "name": "FotoGalleries"
                        }
                    ]
                }
            ]
        },

        {
            "name": "FotogalerienDetail",
            "focusedRoute": "FotoOverView",
            "title": "Fotogalerie Detail",
            "showInTab": false,
            "showInDrawer": false,
            "container": [
                {
                    "scrollView": false,
                    "components": [
                        {
                            "name": "GalleryDetailView"
                        }
                    ]
                }
            ]
        },
        /**
         * needed to access short news from notification
         */
        {
            "name": "ShortNewsArticle",
            "focusedRoute": "FotoOverView",
            "title": "Kurznachrichten",
            "showInTab": false,
            "showInDrawer": false,
            "container": [
                {
                    "scrollView": false,
                    "components": [
                        {
                            "name": "ShortNewsArticle"
                        }
                    ]
                }
            ]
        },
        {
            "name": "ForumOverviewStack",
            "focusedRoute": "ForumOverviewStack",
            "title": "Forum",
            "showInTab": false,
            "showInDrawer": true,
            "icon": "comments"
        },
        {
            "name": "ForumOverview",
            "focusedRoute": "ForumOverviewStack",
            "title": "Forum",
            "showInTab": false,
            "showInDrawer": false,
            "container": [
                {
                    "components": [
                        {
                            "name": "CaptionBanner",
                            "styles": {},
                            "options": {
                                "text": {
                                    "color": "white",
                                    "value": "FORUM",
                                },
                                "backgroundColor": "#0093D1"
                            }
                        },
                        {
                            "name": "ForumOverview"
                        }
                    ]
                }
            ]
        },
        {
            "name": "ForumCategory",
            "focusedRoute": "ForumOverview",
            "showInTab": false,
            "showInDrawer": false,
            "container": [
                {
                    "contentTypes": [
                        CONTENT_TYPE.THREAD
                    ],
                    "components": [
                        {
                            "name": "ForumCategory"
                        }
                    ]
                }
            ]
        },
        {
            "name": "ForumPage",
            "focusedRoute": "ForumCategory",
            "connectedRoutes": ["ProfileOther"],
            "showInTab": false,
            "showInDrawer": false,
            "container": [
                {
                    // scrollView: false,
                    keyboardAvoidingView: true,
                    contentTypes: [
                        CONTENT_TYPE.COMMENT
                    ],
                    "components": [
                        {
                            "name": "ForumPage"
                        }
                    ]
                }
            ]
        },
        {
            "name": "TermineStack",
            "focusedRoute": "TermineStack",
            "title": "Termine",
            "showInTab": false,
            "showInDrawer": true,
            "icon": "calendar",
        },
        {
            "name": "Termine",
            "focusedRoute": "TermineStack",
            "title": "Termine",
            "showInTab": false,
            "showInDrawer": false,
            "container": [
                {
                    scrollView: false,
                    contentTypes: [
                        CONTENT_TYPE.CALENDAREVENT
                    ],
                    "components": [
                        {
                            "name": "CaptionBanner",
                            "styles": {},
                            "options": {
                                "text": {
                                    color: "white",
                                    "value": "Termine",
                                },
                            }
                        },
                        {
                            "name": "Calendar",
                            "options": {}
                        }
                    ]
                }
            ]
        },
        {
            "name": "SpeiseplanStack",
            "focusedRoute": "SpeiseplanStack",
            "title": "Speiseplan",
            "showInTab": false,
            "showInDrawer": true,
            "icon": "utensils"
        },
        {
            "name": "Speiseplan",
            "focusedRoute": "SpeiseplanStack",
            "title": "Speiseplan für Osnabrück",
            "showInTab": false,
            "showInDrawer": false,
            "container": [
                {
                    "components": [
                        {
                            "name": "CaptionBanner",
                            "styles": {},
                            "options": {
                                "text": {
                                    numberOfLines: 1,
                                    color: "white",
                                    "value": "Speiseplan für Osnabrück",
                                },
                            }
                        },
                        {
                            "name": "MealPlan",
                        }
                    ]
                }
            ]
        },
        // {
        //     "name": "NotificationsStack",
        //     "focusedRoute": "NotificationsStack",
        //     "showInTab": false,
        //     "showInDrawer": true,
        //     "title":"Benachrichtigungen",
        //     "icon": "utensils"
        // },
        {
            "name": "Notifications",
            "focusedRoute": "Home",
            "connectedRoutes": ["Termine", "ForumPage", "FotogalerienDetail", /*"NewsArticle",*/ "ShortNewsArticle"],
            "title": "Benachrichtigungen",
            "showInTab": false,
            "showInDrawer": false,
            "container": [
                {
                    scrollView: false,
                    "components": [
                        {
                            "name": "TabView",
                            "options": {
                                "tabs": [
                                    {
                                        title: "Aktivitäten",
                                        component: "CommentsFeed"
                                    },
                                    {
                                        title: "Benachrichtigungen",
                                        component: "Notifications",
                                        isNotificationRoute: true // shows red icon
                                    }
                                ]
                            }
                        }
                    ]
                }
            ]
        },
        {
            "name": "GeburtstageStack",
            "focusedRoute": "GeburtstageStack",
            "title": "Geburtstage & Jubilare",
            "showInTab": false,
            "showInDrawer": true,
            "icon": "birthday-cake"
        },
        {
            "name": "Birthdays",
            "focusedRoute": "GeburtstageStack",
            "connectedRoutes": ["ProfileOther"],
            "title": "Forum",
            "showInTab": false,
            "showInDrawer": false,
            "container": [
                {
                    "components": [
                        {
                            "name": "CaptionBanner",
                            "styles": {},
                            "options": {
                                "text": {
                                    numberOfLines: 1,
                                    color: "white",
                                    "value": "Geburtstage & Jubilare",
                                },
                                "backgroundColor": "#0093D1"
                            }
                        },
                        {
                            "name": "BirthDays"
                        }
                    ]
                }
            ]
        },
        {
            "name": "KontaktStack",
            "focusedRoute": "KontaktStack",
            "title": "Kontakte",
            "showInTab": false,
            "showInDrawer": true,
            "icon": "phone"
        },
        {
            "name": "Kontakte",
            "focusedRoute": "KontaktStack",
            "title": "Kontakte",
            "showInTab": false,
            "showInDrawer": false,
            "icon": "phone",
            "container": [
                {
                    "components": [
                        {
                            "name": "CaptionBanner",
                            "options": {
                                "text": {
                                    color: "white",
                                    "value": "Kontakte",
                                },
                                "backgroundColor": "#0093D1"
                            }
                        },
                        {
                            name: "Contacts"
                        }
                    ]
                }
            ]
        },
        {
            "name": "ContactDetail",
            "focusedRoute": "Kontakte",
            "title": "ContactDetail",
            "showInTab": false,
            "showInDrawer": false,
            "icon": "phone",
            "container": [
                {
                    "components": [
                        {
                            name: "ContactDetail"
                        }
                    ]
                }
            ]
        },
        {
            "name": "InformationStack",
            "focusedRoute": "InformationStack",
            "title": "Informationen",
            "showInTab": false,
            "showInDrawer": true,
            "icon": "info"
        },
        {
            "name": "Informationen",
            "focusedRoute": "InformationStack",
            "title": "Informationen",
            "showInTab": false,
            "showInDrawer": false,
            "container": [
                {
                    contentTypes: [
                        CONTENT_TYPE.INFORMATION
                    ],
                    "components": [
                        {
                            "name": "InformationLinks"
                        }
                        // {
                        //     "name": "MarginSpace",
                        //     "options":{
                        //     }
                        // },
                        // {
                        //     "name": "Paragraph",
                        //     "options":{
                        //     }
                        // },
                        // {
                        //     "name": "MarginSpace",
                        //     "options":{
                        //     }
                        // },
                        // {
                        //     "name": "InternalLink",
                        //     "options":{
                        //         "routeTo":"test"
                        //     }
                        // },
                        // {
                        //     "name": "MarginSpace",
                        //     "options":{
                        //     }
                        // },
                        // {
                        //     "name": "InternalLink",
                        //     "options":{
                        //         "routeTo":"test"
                        //     }
                        // },
                    ]
                }
            ]
        },
        {
            "name": "InformationenDetail",
            "focusedRoute": "Informationen",
            "title": "test",
            "showInTab": false,
            "showInDrawer": false,
            "container": [
                {
                    "components": [
                        {
                            "name": "BlogPage",
                            "options": {}
                        }
                    ]
                }
            ]
        },
        {
            "name": "EinstellungenStack",
            "focusedRoute": "EinstellungenStack",
            "title": "Einstellungen",
            "showInTab": false,
            "showInDrawer": true,
            "icon": "cogs"
        },
        {
            "name": "Einstellungen",
            "focusedRoute": "EinstellungenStack",
            "title": "Einstellungen",
            "showInTab": false,
            "showInDrawer": false,
            "container": [
                {
                    "components": [
                        {
                            "name": "CaptionBanner",
                            "styles": {},
                            "options": {
                                "text": {
                                    color: "white",
                                    "value": "Einstellungen",
                                },
                            }
                        },
                        {
                            "name": "Settings",
                        }
                    ]
                }
            ]
        },
        {
            "name": "ImpressumStack",
            "focusedRoute": "ImpressumStack",
            "title": "Impressum",
            "showInTab": false,
            "showInDrawer": true,
            "icon": "file"
        },
        {
            "name": "Impressum",
            "focusedRoute": "ImpressumStack",
            "title": "Impressum",
            "showInTab": false,
            "showInDrawer": false,
            "container": [
                {
                    "components": [
                        {
                            "name": "CaptionBanner",
                            "styles": {},
                            "options": {
                                "text": {
                                    color: "white",
                                    "value": "Impressum",
                                },
                            }
                        },
                        {
                            "name": "Impressum",
                        }
                    ]
                }
            ]
        },
        {
            "name": "DatenschutzStack",
            "focusedRoute": "DatenschutzStack",
            "title": "Datenschutz",
            "showInTab": false,
            "showInDrawer": true,
            "icon": "user-secret"
        },
        {
            "name": "Datenschutz",
            "focusedRoute": "DatenschutzStack",
            "title": "Datenschutz",
            "showInTab": false,
            "showInDrawer": false,
            "container": [
                {
                    "components": [
                        {
                            "name": "CaptionBanner",
                            "styles": {},
                            "options": {
                                "text": {
                                    color: "white",
                                    "value": "Datenschutz",
                                },
                            }
                        },
                        {
                            "name": "PrivacyPolicy",
                        }
                    ]
                }
            ]
        },
        /**
         * Stack Routes
         */
        {
            "name": "ChatBot",
            "focusedRoute": "Home",
            "title": "ChatBot",
            "showInTab": false,
            "showInDrawer": false,
            "container": [
                {
                    "components": [
                        {
                            keyboardAvoidingView: false,

                            "name": "WebView",
                            "options": {
                                "url": CHAT_BOT_URL
                            }
                        }
                    ]
                }
            ]
        },
        {
            "name": "ShortNews",
            "focusedRoute": "Home",
            "tabBarVisible": false,
            "title": "ShortNews",
            "showInDrawer": false,
            "showInTab": false,
            "container": [{
                scrollView: false,
                "components": [{
                    "name": "ShortNewsArticle"
                }]
            }]
        },
        {
            "name": "NewsArticle",
            "focusedRoute": "Home",
            "tabBarVisible": false,
            "title": "NewsArticle",
            "showInDrawer": false,
            "showInTab": false,
            "container": [{
                keyboardAvoidingView: true,
                "components": [{
                    "name": "NewsArticle"
                }]
            }]
        },
        {
            "name": "Freizeichen",
            "focusedRoute": "Benefits",
            "tabBarVisible": false,
            "title": "Freizeichen",
            "showInDrawer": false,
            "showInTab": false,
            "container": [{
                "components": [{
                    "name": "WebView"
                }]
            }]
        },
        {
            "name": "ForumActivity",
            "focusedRoute": "Profile",
            "connectedRoutes": ["ForumPage"],
            "tabBarVisible": false,
            "title": "Forumaktivitäten",
            "showInDrawer": false,
            "showInTab": false,
            "container": [{
                "components": [{
                    "name": "ForumActivity"
                }]
            }]
        },
        {
            "name": "CalendarRegistrations",
            "focusedRoute": "Profile",
            "connectedRoutes": ["Termine"],
            "tabBarVisible": false,
            "title": "Kalenderanmeldungen",
            "showInDrawer": false,
            "showInTab": false,
            "container": [{
                "components": [{
                    "name": "CalendarRegistrations"
                }]
            }]
        },
    ],
};

export default nativeRoutesLoggedIn;