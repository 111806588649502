import {Image} from "react-native-expo-image-cache";
import {Image as ImageReact, Pressable, StyleSheet, useWindowDimensions} from "react-native";
import CaptionBanner from "../CaptionBanner/CaptionBanner.native";
import React from "react";

const NewsArticlePreviewHeader = (props) => {
    const {onArticlePress = undefined, uri, isImageEnabled, title, newsImageStyle = {}, text = {}} = props;
    const {width, height} = useWindowDimensions();

    const style = StyleSheet.create({
        newsImage: {
            width: null,
            height: width / 25 * 12,
            // height: width * .6,
            ...newsImageStyle
        },
    });

    return <Pressable onPress={onArticlePress}>
        {isImageEnabled && (uri
            ? <Image style={style.newsImage} {...{preview: {uri: uri}, uri}} />
            : <ImageReact resizeMode={"contain"} style={style.newsImage}
                          source={require("../../../assets/images/placeholder.jpg")}/>)}
        <CaptionBanner config={{
            options: {
                text: {
                    color: "white",
                    value: title,
                    fontSize: 42,
                    ...text,
                }
            }
        }}/>
    </Pressable>
}

export default NewsArticlePreviewHeader;