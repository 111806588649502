import {
    Image, Linking,
    StyleSheet,
    Text,
    TouchableOpacity,
    useWindowDimensions,
    View
} from "react-native";
import Button from "../Button/Button";
import {useSelector} from "react-redux";
import {StatusBar} from "expo-status-bar";
import {KeyboardAwareScrollView} from "react-native-keyboard-aware-scrollview";
import {useCalculateImageDimension} from "../../utils/imageUtils";

const reservedHeightForImpressum = 120;
import Constants from 'expo-constants';
import React from "react";

const statusBarHeight = Constants.statusBarHeight;
import welcomeImage from "../../../assets/images/welcomeImage.jpg"
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {LINKS} from "../../constants/strings";

export default function WelcomeScreen(props) {
    const {stackNavigation} = props;
    const theme = useSelector(root => root.theme);
    const {height, width} = useWindowDimensions()
    const {width: imageWidth, height: imageHeight} = useCalculateImageDimension(width, width, 800)
    // const containerHeight = height - 50;
    const screenHeightThatFitsAll = 900;
    const factor = ((height / screenHeightThatFitsAll) > 1) ? 1 : (height / screenHeightThatFitsAll);

    // console.log("WelcomeScreen", {
    //     factor, height,
    //     // props
    // });


    const overWriteStyles = StyleSheet.create({
        image: {
            width: imageWidth,
            height: imageHeight * factor
        },
        websiteButton: {
            button: {
                borderRadius: 60 * factor,
                height: 60 * factor,
                width: "74%",
                maxWidth: 400,
                borderColor: theme.colors.primary,
                backgroundColor: "white",
                borderWidth: 1
            },
            text: {
                color: theme.colors.primary,
                fontFamily: "OpenSans_400Regular",
                fontSize: 18 * factor
            }
        },
        linkText: {
            color: theme.colors.primary,
            fontFamily: "OpenSans_400Regular",
            fontSize: 16,
        },
        caption: {
            color: theme.colors.primary,
            fontSize: 33 * factor,
            fontFamily: "BebasNeue_400Regular",
            marginBottom: 15,
            textAlign: "center",
            width: "100%",
            // maxWidth: 375
        },
        innerContainer: {
            paddingTop: theme.wrapperPadding,
            justifyContent: 'space-between',
            // height: height - (imageHeight /* * factor*/) - statusBarHeight - (reservedHeightForImpressum * factor)
        },
        textContainer: {
            alignItems: "flex-start",
            paddingHorizontal: theme.wrapperPadding,
        },
        linkContainer: {
            // width:width,alignItems:"center",
            flexDirection: "row",
            justifyContent: "center",
            paddingBottom: 30,
        },
        socialMedia: {
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 20,
            marginBottom: 40,
        },
        buttonContainer: {
            display: "flex",
            alignItems: "center",
            marginTop: 15,
            marginBottom: 50,
            width: width,
        },
        TextAndButtonContainer: {
            justifyContent: "space-evenly",
            height: "100%",
        },
        container: {
            flex: 1,
            alignItems: "center",
            backgroundColor: "white",
        },
        bottom: {
            display: "flex",
            flex: 1,
            justifyContent: 'flex-end',
            // marginBottom: 36
        },
        welcomeText: {
            fontSize: 16 * factor,
            textAlign: "center",
            fontFamily: "OpenSans_300Light",
            // maxWidth: 586,
            width: "100%",
            marginBottom: 30,
        },
        loginButton: {
            button: {
                borderRadius: 60 * factor,
                height: 60 * factor,
                width: "74%",
                marginBottom: 10 * factor,
                maxWidth: 400,
            },
            text: {fontFamily: "OpenSans_400Regular", fontSize: 18 * factor, color: "white"}
        },
    })

    const getButtons = () => {
        return <View style={[overWriteStyles.buttonContainer]}>
            <Button text={"Zum Login"}
                    onClick={() => stackNavigation.navigate('Login')}
                    style={overWriteStyles.loginButton}
            />
            {/*<Button text={"Zur KiKxxl Webseite"}*/}
            {/*        onClick={()=>props?.navigation.navigate('Website', {name: 'Website'})}*/}
            {/*        style={overWriteStyles.websiteButton}*/}
            {/*/>*/}
            <Button text={"Zum \"freizeichen\" Speiseplan"}
                    onClick={() => stackNavigation.navigate('MealPlanPublic')}
                    style={overWriteStyles.websiteButton}
            />
        </View>
    }

    const getSocialMediaLinks = () => {
        return <View style={[overWriteStyles.linkContainer, overWriteStyles.socialMedia]}>
            <TouchableOpacity onPress={() => Linking.openURL(LINKS.INSTAGRAM)} style={{marginRight: 50}}>
                <FontAwesome5
                    color={"#000"}
                    size={35}
                    name={"instagram"}
                />
            </TouchableOpacity>
            <TouchableOpacity onPress={() => Linking.openURL(LINKS.FACEBOOK)}>
                <FontAwesome5
                    color={"#000"}
                    size={30}
                    name={"facebook-f"}
                />
            </TouchableOpacity>
        </View>
    }

    return <View contentContainerStyle={[overWriteStyles.container/*, {height: containerHeight}*/]}>
        <StatusBar style="dark"/>
        <Image style={overWriteStyles.image}
               source={welcomeImage}
        />
        <View style={[overWriteStyles.innerContainer]}>
            {/*<View style={[overWriteStyles.TextAndButtonContainer]}>*/}
            <View style={overWriteStyles.textContainer}>
                <Text style={[overWriteStyles.caption]}>
                    Herzlich Willkommen in der
                    KiKxxl Mitarbeiter*innen App!
                </Text>
                <Text style={[overWriteStyles.welcomeText, {color: theme.colors.primary}]}>
                    In dieser App findest Du alle aktuellen
                    Informationen für KiKxxl Mitarbeiter*innen,
                    wie die neuesten KiKxxl-News, interessante
                    Forenbeiträge, Party-Fotoalben oder auch der
                    Speiseplan unseres Osnabrücker Freizeichen!
                </Text>

            </View>
            {getButtons()}
            {/*</View>*/}
        </View>
        {/*{getSocialMediaLinks()}*/}
        <View style={overWriteStyles.linkContainer}>
            <TouchableOpacity onPress={() => stackNavigation.navigate('Impressum')}>
                <Text style={[overWriteStyles.linkText]}>Impressum </Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => stackNavigation.navigate('Datenschutz')}>
                <Text style={[overWriteStyles.linkText]}>| Datenschutz </Text>
            </TouchableOpacity>
        </View>
    </View>
}
