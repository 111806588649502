export function getDaysDifference(date1, date2) {

    let _date1 = new Date(date1);
    let _date2 = new Date(date2);
    let timeDiff = _date1.getTime() - _date2.getTime();

    return Math.round(timeDiff / (1000 * 60 * 60 * 24));
}

export const dateOptions = {year: 'numeric', month: '2-digit', day: '2-digit'};
export const timeOptions = {hour: '2-digit', minute: '2-digit'};
export const dateTimeOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
}

export function differenceInMinutes(dt2, dt1) {

    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60;
    return Math.abs(Math.round(diff));

}
