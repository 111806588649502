import {Platform} from "react-native";
export function routeToForumComment(threadId, anchorId, linkTo){
    linkTo({
        screen: 'ForumPage', params: {
            id: threadId,
            commentId: anchorId
        }
    })
}
export function routeToThread(threadId,linkTo){
    linkTo({
        screen: 'ForumPage', params: {
            id: threadId,
        }
    })
}

