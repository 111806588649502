import {createSlice} from '@reduxjs/toolkit'
import {isDevelopment} from "../../../constants/booleanValues";

let globalAdminApi;
export const injectglobalAdminApi = (_globalAdminApi) => {
    globalAdminApi = _globalAdminApi;
}

/**
 * Default initial state
 * @type {{refresh_token: null, firstname: null, expo_token: null, gender: null, locationId: null, locations: *[], fullname: null, userId: null, first_login: null, token: null, username: null, lastname: null}}
 */
const initialState = {
    first_login: null,//is used when user logs in the first time to change his password and select some settings
    loginCount: 0,
    token: null, //JWT bearer authentication token
    expo_token: null, // push token to be used to send push notifications to this device.
    refresh_token: null, //JWT bearer authentication refresh token
    username: null,
    userId: null,
    firstname: null,
    lastname: null,
    fullname: null,
    gender: null,
    locationId: null, //main location id of the user. Content seen in app might get filtered by location
    locations: [] //multiple other locations that are set on login.
}

const slice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        resetAuth: (state) => {
            state = initialState;
            // isDevelopment && console.log("::resetAuth");
            return state;
        },
        refreshToken: (state, {payload}) => {
            state = {
                ...state,
                token: payload?.token,
                refresh_token: payload?.refresh_token
            }
            // isDevelopment && console.log("::refreshToken");
            return state;
        },
        setIsFirstLogin: (state, {payload}) => {
            state = {
                ...state,
                first_login: payload
            }
            // isDevelopment && console.log("::setIsFirstLogin", JSON.stringify(state));
            return state;
        },
        setExpoToken: (state, args) => {
            let token;
            if (args?.payload) {
                token = args?.payload?.data?.replace("ExponentPushToken[", "");
                token = token.replace("]", "")
            }
            state.expo_token = token;
            // isDevelopment && console.log("::setExpoToken", JSON.stringify(state));
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                globalAdminApi.endpoints.login.matchFulfilled,
                (state, {payload}) => {
                    //todo: still not fixed on server side, so worked around
                    //also this function affects first_login: setIsFirstLogin
                    state.first_login = payload.logins < 1;
                    state.loginCount = payload.logins;
                    state.token = payload.token;
                    state.refresh_token = payload.refresh_token;
                    state.username = payload.username;
                    state.userId = payload.id;
                    state.firstname = payload.first_name;
                    state.lastname = payload.last_name;
                    state.fullname = payload.full_name;
                    state.gender = payload.gender;
                    state.locationId = payload.location;
                    state.locations = payload.locations || [];
                    // isDevelopment && console.log("::addMatcher login", JSON.stringify(state), payload.logins < 1);
                    return state;
                }
            )
            .addMatcher(
                globalAdminApi.endpoints.logout.matchFulfilled,
                (state, {payload}) => {
                    // isDevelopment && console.log("::addMatcher logout");
                    return initialState;
                }
            )
            //when user object is loaded, we update it's locations if it's our user object
            .addMatcher(
                globalAdminApi.endpoints.getUserObj.matchFulfilled,
                (state, {payload}) => {
                    if (state.userId === payload.id) {
                        // console.log("got userObj", payload)
                        if (state.locationId !== payload?.parameters?.location?.id)
                            state.locationId = payload?.parameters?.location?.id
                        if (JSON.stringify(state.locations) !== JSON.stringify(payload?.parameters?.locations))
                            state.locations = payload?.parameters?.locations || [];
                        // isDevelopment && console.log("::addMatcher getUserObj", JSON.stringify(state));
                    }
                    return state;
                }
            );
        /**
         * Needs extra investigation
         */
        builder.addMatcher(
            globalAdminApi.endpoints.updateProfile.matchFulfilled,
            (state, {payload, meta}) => {
                const args = meta?.arg?.originalArgs
                // let curState = Platform.OS === "web" ? current(state) : state;//for web we have a proxy object, so we take a snapshot

                if (args && args.formData) {
                    if (args.formData.plainPassword) {
                        /*
                        When user gets first login modal and changes password, keepLoggedIn is sent,
                        but we set state to first_login "false" as navigating to user profile afterwards would display
                        first modal again.
                         */
                        if (args.keepLoggedIn) {
                            state.first_login = false;
                        } else {
                            //password was changed, log user out.
                            return initialState;
                            // isDevelopment && console.log("::addMatcher updateProfile resetState");
                        }
                    }
                    //we have a simple updateProfile call -> oops, we don't take profile from state, we get it from data
                    // else {
                    //     let result = {
                    //         ...curState,
                    //         ...args.formData
                    //     };
                    //     //yeah, this is BS, but i'd have to refactor profile
                    //     result.about_me = args.formData.aboutMe;
                    //     // delete result.aboutMe;
                    //     isDevelopment && console.log("::addMatcher updateProfile update profile", JSON.stringify(result));
                    //
                    //     return result;
                    // }
                }
                // isDevelopment && console.log("::addMatcher updateProfile keeping same state", JSON.stringify(state), JSON.stringify(args));
                return state;
            }
        );
        builder.addMatcher(
            globalAdminApi.endpoints.updateWebSettings.matchFulfilled,
            (state, {payload, meta}) => {
                const args = meta?.arg?.originalArgs
                /**
                 * After users first login modal password is being changed, updateWebSettings is called.
                 * This call passes "performLogout" parameter and it resets the login state
                 */
                if (args && args.performLogout) {
                    // isDevelopment && console.log("::addMatcher updateWebSettings, performing log out", JSON.stringify(state));
                    return initialState;
                }
                return state;
            }
        );
    },
})
const {actions, reducer} = slice
// Extract and export each action creator by name
export const {setExpoToken, refreshToken, resetAuth, setIsFirstLogin} = slice.actions
// Export the reducer, either as a default or named export
export default slice.reducer
