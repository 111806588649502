import {UseQuery,} from '@reduxjs/toolkit/dist/query/react/buildHooks';
import {useCallback, useEffect, useMemo, useState} from 'react';

const calculateMaxPages = (total: number, size: number) => {
    return Math.ceil(total / size);
};

export const isValidNotEmptyArray = (array: any[]): boolean => {
    return !!(array && array?.length && array?.length > 0)
};

export interface IListQueryResponse {
    items: any[];
    total: number;
    page: number;
    size: number;
}

//TODO: https://github.com/facebook/react-native/issues/32859  ITS UNSAFE but on android its causing error when optional is used
function checkForDotNotationAndReturnResult(arg, baseObj) {
    // console.log("checkForDotNotationAndReturnResult", arg, baseObj)
    if (!baseObj) {
        return undefined;
    }
    if (arg.includes(".")) {
        //console.log("baseObj" + "." +  arg.split(".").join("."))
        return eval("baseObj" + "." + arg.split(".").join("."))
    } else {
        return baseObj[arg];
    }
}

/**
 *
 * @param useGetDataListQuery
 * @param size
 * @param queryParameters
 * @param recievedPaginationConfig
 * @param returnExtraData - keys of which data should be extra returned from the object?
 */
const useInfiniteScroll = (useGetDataListQuery: UseQuery<any>,
                           {
                               size = 10,
                               ...queryParameters
                           },
                           recievedPaginationConfig = {
                               entries: "entries",
                               page: "page",
                               total: "total",
                               size: "size"
                           },
                           returnExtraData = [],
) => {
    const [isFirstRun, setIsFirstRun] = useState(true)
    const [localPage, setLocalPage] = useState(1);
    const [combinedData, setCombinedData] = useState([]);
    //we store extra user desired data to be fetched
    const [extraData, setExtraData] = useState({});
    const queryResponse = useGetDataListQuery({
        page: localPage,
        size,
        ...queryParameters
    });
    const {refetch, currentData, data} = queryResponse ?? {}
    //this doesnt work when pagination.total is passed - therefore refactored with function checkForDotNotationAndReturnResult
    // const {
    // 	//[recievedPaginationConfig.entries ]: fetchData = [],
    // 	//[recievedPaginationConfig.page]: remotePage = 1,
    // 	// [recievedPaginationConfig.total]: remoteTotal = 0,
    // 	//[recievedPaginationConfig.size]: remoteSize = size
    // } = queryResponse?.currentData as IListQueryResponse || {}
    // console.log("useInfiniteScroll", queryResponse);
    let fetchData = checkForDotNotationAndReturnResult(recievedPaginationConfig.entries, currentData) || [];
    let remotePage = checkForDotNotationAndReturnResult(recievedPaginationConfig.page, currentData) || 1;
    let remoteTotal = checkForDotNotationAndReturnResult(recievedPaginationConfig.total, currentData) ?? (checkForDotNotationAndReturnResult(recievedPaginationConfig.total, data) || 0);
    let remoteSize = checkForDotNotationAndReturnResult(recievedPaginationConfig.size, currentData) || size;

    /**
     * We might want extra data from currently returned data, which we can define additionally
     */
    const buildExtraDataFromResult = () => {
        let extraDataTmp = {}
        returnExtraData.forEach(key => {
            extraDataTmp[key] = checkForDotNotationAndReturnResult(key, currentData);
        })
        if (Object.keys(extraDataTmp).length > 0){
            setExtraData(extraDataTmp);
        }
    }

    useEffect(() => {
        if (isValidNotEmptyArray(fetchData)) {
            if (localPage === 1) {
                //console.log("set fetch data as combined")
                setCombinedData(fetchData);
            } else if (localPage === remotePage) {
                //console.log("concat arrays")
                setCombinedData((previousData) => [...previousData, ...fetchData])
            }
            buildExtraDataFromResult();
        }
    }, [fetchData, queryParameters.filter])
    useEffect(() => {
        if (!isFirstRun) {
            setIsFirstRun(true)
        } else {
            setCombinedData(fetchData);
            setLocalPage(1)
        }
    }, [queryParameters.filter])

    const maxPages = useMemo<number>(() => {
        return calculateMaxPages(remoteTotal, remoteSize);
    }, [remoteTotal, remoteSize, queryParameters.filter]);

    const refresh = useCallback(() => {
        setLocalPage(1);
    }, []);

    const readMore = () => {
        if (localPage < maxPages && localPage === remotePage) {
            setLocalPage((page) => page + 1);
        }
    };
    // const test = {...queryResponse}
    // delete test.currentData;
    // delete test.data;
    // console.warn({test})
    // if(queryResponse?.isFetching){
    // 	console.warn("ISFETCHING")
    // }
    // if(queryResponse?.isLoading){
    // 	console.warn("isLoading")
    // }
    return {
        combinedData,
        localPage,
        maxPages,
        readMore,
        refresh,
        refetch,
        remoteTotal,
        remotePage,
        remoteSize,
        isLoading: queryResponse?.isLoading,
        isFetching: queryResponse?.isFetching,
        error: queryResponse?.error,
        canFetchMore: localPage < maxPages,
        extraData
    };
};

export default useInfiniteScroll;
