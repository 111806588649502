import {Linking, Pressable, StyleSheet, Text, View} from "react-native";
import CaptionBanner from "../CaptionBanner/CaptionBanner";
import React from "react";
import {useSelector} from "react-redux";
import {FontAwesome5} from "@expo/vector-icons";
import {A} from '@expo/html-elements';

const styles = StyleSheet.create({
    container: {},
    contactContainer: {
        marginTop: 64,
    },
    contactContainer_first: {
        marginTop: 0,
    },
    a: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    underline: {
        textDecorationLine: 'underline'
    },
    caption: {
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 16
    },
    captionText: {
        // marginLeft: 12
    },
    icon: {
        marginRight: 12,
    }
});

export default function ContactDetail(props) {
    const {item} = props;
    const {contact} = item;
    const theme = useSelector(state => state.theme);
    const overwriteStyles = StyleSheet.create({
        container: {},
        text: {
            ...theme.typo.p_sm
        },
        captionText: {
            ...theme.typo.p_lg_bold
        }
    });

    return <View style={[styles.container, overwriteStyles.container]}>
        <CaptionBanner config={{
            headline: item?.name
        }}/>

        {/*<ContactGroup style={styles.contactContainer_first} title={'Anschrift'}>*/}
        {/*    <Pressable*/}
        {/*        style={styles.a}*/}
        {/*        onPress={async () => Linking.openURL(`https://maps.google.com/?q=${contact?.street} ${contact?.zip} ${contact.city}`)}*/}
        {/*    >*/}
        {/*        <View>*/}
        {/*            <Text style={overwriteStyles.text}>{contact?.street}</Text>*/}
        {/*            <Text style={overwriteStyles.text}>{contact?.zip} {contact?.city}</Text>*/}
        {/*        </View>*/}
        {/*    </Pressable>*/}
        {/*</ContactGroup>*/}

        <ContactGroup title={'Telefon'} icon={'phone'}>
            <A style={[styles.a, styles.underline]} href={`tel:${contact?.tel}`}>
                <Text style={overwriteStyles.text}>{contact?.tel}</Text>
            </A>
            <A style={[styles.a, styles.underline]} href={`tel:${contact?.mobile}`}>
                <Text style={overwriteStyles.text}>{contact?.mobile}</Text>
            </A>
        </ContactGroup>

        <ContactGroup title={'E-Mail'} icon={'envelope'}>
            <A style={[styles.a, styles.underline]} href={`mailto:${contact?.email}`}>
                <Text style={overwriteStyles.text}>{contact?.email}</Text>
            </A>
        </ContactGroup>
    </View>
}

const ContactGroup = (props) => {
    const {children, style, icon, title} = props;
    const theme = useSelector(state => state.theme);
    const overwriteStyles = StyleSheet.create({
        captionText: {
            ...theme.typo.p_lg_bold
        }
    });

    return <View style={[styles.contactContainer, style]}>
        <View style={styles.caption}>
            {icon && <FontAwesome5 name={icon} solid size={20} color={theme.colors.text_dark} style={styles.icon}/>}
            <Text style={[styles.captionText, overwriteStyles.captionText]}>{title}</Text>
        </View>
        {children}
    </View>
};
