import CaptionBanner from "../CaptionBanner/CaptionBanner";
import {StyleSheet, Text} from "react-native";
import RenderHtml from "react-native-render-html";
import {SystemFontKeys, SystemFonts} from "../../constants/SystemFonts";
import React from "react";
import {useSelector} from "react-redux";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import ErrorText from "../Error/ErrorText";

export default function PrivacyPolicy() {
    const {data, error, isLoading, refetch} = endpoints.getPrivacy.useQuery();
    const theme = useSelector(state => state.theme);
    const overwriteStyles = StyleSheet.create({
        text: {
            ...theme.typo.p_sm,
            ...theme.typo.web,
        }
    });
    const htmlStyles = React.useMemo(
        () => ({
            ...overwriteStyles.text
        }),
        [theme],
    );
    if (isLoading) return <LoadingIndicator/>;
    else if (error) return <ErrorText error={error}/>;
    return <>
        <CaptionBanner config={{
            headline: "Datenschutz"
        }}/>
        <Text style={overwriteStyles.text}>
            <RenderHtml
                baseStyle={htmlStyles}
                source={{html: data?.description}}
                systemFonts={SystemFontKeys}
            />
        </Text>
    </>;
}
