import * as SecureStore from 'expo-secure-store';
import 'react-native-get-random-values';
import {v4 as uuidv4} from 'uuid';
import {isDevelopment} from "src/constants/booleanValues";
import {Platform} from "react-native";

/**
 * Persists a generated id on the device to stay after app is reinstalled and fetches it back to the app
 */
const getUuid = async () => {
    const uuidKey = "secure_deviceid";

    let uuid = await SecureStore.getItemAsync(uuidKey);
    //if user has already signed up prior
    if (!uuid) {

        uuid = Platform.OS === "ios" ? uuidv4().replaceAll('"', '') : uuidv4();
        await SecureStore.setItemAsync(uuidKey, uuid as string);
    }

    // isDevelopment && console.log("uuid", uuid);
    return uuid;
}

export default getUuid;