import {createSlice} from '@reduxjs/toolkit'
import {CHAT_BOT_URL, CONTENT_TYPE} from "../../../constants/strings";

const initialState = {
    "styles": {
        "theme": "kikxxl", "text": {
            "fontFamily": "BebasNeueRegular"
        }
    }, "routes": [{
        "name": "Home",
        "path": "",
        "title": "Home",
        "icon": "home",
        "showInDrawer": false,
        "drawerGroup": 0,
        "container": [
            {
                "container": [{
                    contentTypes: [
                        CONTENT_TYPE.NEWS
                    ],
                    "components": [{
                        "name": "NewsFeatured",
                    }], "options": {}
                }], "options": {
                    hasMargin: false
                }
            },
            {
                "container": [
                    {
                        contentTypes: [CONTENT_TYPE.NEWS],
                        "components": [
                            {"name": "NewsSelector", "options": {hasMargin: true}},
                            {"name": "ChatBotPreview", "options": {hasMargin: true}}
                        ],
                        "options": {hasMargin: true,},
                        "style": {flex: 2}
                    },
                    {
                        contentTypes: [CONTENT_TYPE.SHORTNEWS],
                        "components": [
                            {"name": "ShortNews", "options": {hasMargin: true}},
                            {"name": "CanteenMenuPreview", "options": {hasMargin: true,}},
                            {"name": "BirthDays", "options": {hasMargin: true}},
                            {"name": "CommentsFeed", "options": {hasMargin: true}}
                        ],
                        "options": {hasMargin: true},
                        "style": {flex: 2}
                    }
                ],
                "options": {hasMargin: true}
            }
        ],
    }, {
        "name": "Benefits",
        "title": "Benefits",
        "icon": {name: "benefits", type: "fontello_custom"},
        "bgIcon": {name: "benefits", type: "fontello_custom"},
        "showInDrawer": true,
        "drawerGroup": 0,
        "container": [{
            "container": [{
                "components": [{
                    "name": "CaptionBanner", "headline": "Benefits"
                }, {
                    "name": "BenefitsOverview",
                },
                    //     {
                    //     "name": "TileLinks", "options": {
                    //         "styles": {
                    //             "linkContainer": {
                    //                 backgroundColor: "#0093D1", borderWidth: 0,
                    //             },
                    //             "linkText": {
                    //                 color: "#FFFFFF"
                    //             }
                    //         }, "useGroups": true, "useSquares": true, "links": [{
                    //             group: "Vorteile", items: [{
                    //                 "route": "/", "label": "Zeiterfassung",
                    //                 "icon": {type: "local", name: "kikxxl_kikbav.png"}
                    //             }, {
                    //                 "route": "/", "label": "Raumbuchung",
                    //                 "icon": {type: "local", name: "kikxxl_mwm.png"}
                    //             }, {
                    //                 "route": "/", "label": "Stellenmarkt",
                    //                 "icon": {type: "local", name: "kikxxs_betreuung.png"}
                    //             }, {
                    //                 "route": "detailPage2.2", "label": "KikXXL Wiki", "icon": ""
                    //             }, {
                    //                 "route": "MWM", "label": "Dokumente", "icon": ""
                    //             }, {
                    //                 "route": "Freizeichen", "label": "Web Intranet", "icon": ""
                    //             },]
                    //         }, {
                    //             group: "Verpflegung", items: [{
                    //                 "route": "MWM", "label": "Zeiterfassung", "icon": ""
                    //             }]
                    //         }, {
                    //             group: "Finanziell", items: [{
                    //                 "route": "KiKBu", "label": "KIKBAV", "icon": ""
                    //             }, {
                    //                 "route": "MWM", "label": "Dein MWM", "icon": ""
                    //             }]
                    //         }]
                    //     }
                    // }
                ], "style": {
                    maxWidth: 1000
                }
            }], "options": {
                hasMargin: true,
            }
        }]
    }, {
        "name": "Intern",
        "title": "Intern",
        "icon": {name: "intern", type: "fontello_custom"},
        "bgIcon": {name: "intern", type: "fontello_custom"},
        "showInDrawer": true, "drawerGroup": 0, "container": [{
            "container": [{
                "components": [{
                    "name": "CaptionBanner", "headline": "Intern"
                }, {
                    "name": "TileLinks", "options": {
                        "styles": {}, "useGroups": false,
                        "endpointName": "getQuickLinks",
                        // "links": [{
                        //     group: "Quick Links", icon: "external-link-alt", items: [
                        //         {
                        //             type: "external",
                        //             "route": "http://kikagent.kikxxl.corp/",
                        //             "label": "KiKAgent"
                        //         },
                        //         {
                        //             type: "external",
                        //             "route": "http://startseite.kikxxl.corp/",
                        //             "label": "KiKqknow"
                        //         },
                        //         {
                        //             type: "external",
                        //             "route": "https://bluespice.kikxxl.corp/wiki/Hauptseite",
                        //             "label": "KiKxxl-Wiki"
                        //         },
                        //         {
                        //             type: "external",
                        //             "route": "https://kikxxl.de/interne_stellen/",
                        //             "label": "Stellenmarkt"
                        //         }]
                        // }]
                    }
                }], "style": {
                    maxWidth: 1000
                }
            }], "options": {
                hasMargin: true
            }
        }]
    }, {
        "name": "Info",
        "title": "Informationen",
        "icon": {name: "info-circle", type: "fontawesome", fa5style: "regular"},
        "bgIcon": {name: "info-circle", type: "fontawesome", fa5style: "regular"},
        "showInDrawer": true,
        "drawerGroup": 0,
        "container": [{
            "container": [{
                "components": [
                    //     {
                    //     "name": "CaptionBanner",
                    //     "headline": "Informationen",
                    //     "subHeadline": "Hier findest du eine Übersicht aller relevanten KiKxxl-Informationen, wie Betriebsvereinbarungen usw."
                    // },
                    {
                        "name": "InformationLinks",
                    }], "style": {
                    maxWidth: 1000
                }
            }], "options": {
                hasMargin: true
            }
        }]
    }, {
        "name": "Fotogalerien",
        "title": "Fotogalerien",
        "icon": {name: "images", type: "fontawesome", fa5style: "regular"},
        "showInDrawer": true,
        "drawerGroup": 1,
        "container": [{
            "container": [{
                "components": [{
                    "name": "CaptionBanner", "headline": "Fotogalerien",
                }, {
                    "name": "FotoGalleries"
                }], "style": {}
            }], "options": {
                hasMargin: true
            }
        }]
    }, {
        "name": "Fotogalerie",
        "title": "FotogalerienDetail",
        "icon": "",
        "showInDrawer": false,
        "drawerGroup": 0,
        "container": [{
            "container": [{
                "components": [{
                    "name": "GalleryDetailView"
                }], "style": {
                    // maxWidth: 1000
                }
            }], "options": {
                hasMargin: true
            }
        }]
    }, {
        "name": "Forum",
        "title": "Forum",
        "icon": {name: "comments", type: "fontawesome", fa5style: "regular"},
        "showInDrawer": true,
        "drawerGroup": 1,
        "container": [{
            "container": [{
                "components": [{
                    "name": "ForumOverview"
                }]
            }], "options": {
                hasMargin: true
            }
        }]
    }, {
        "name": "ForumCategory",
        "title": "ForumCategory",
        "icon": "",
        "showInDrawer": false,
        "drawerGroup": 0,
        "container": [{
            "container": [{
                "components": [{
                    "name": "ForumCategory"
                }]
            }], "options": {
                hasMargin: true
            }
        }]
    }, {
        "name": "ForumPage",
        "title": "ForumPage",
        "icon": "",
        "bgIcon": "comments",
        "showInDrawer": false,
        "drawerGroup": 0,
        "container": [{
            "container": [{
                "components": [{
                    "name": "ForumPage"
                }], "style": {
                    maxWidth: 1000
                }
            }], "options": {
                hasMargin: true
            }
        }]
    }, {
        "name": "Forumaktivität",
        "title": "Deine Forumaktivitäten",
        "icon": "comments",
        "showInDrawer": false,
        "drawerGroup": 0,
        "container": [{
            "container": [{
                "components": [{
                    "name": "ForumActivity"
                }], "style": {
                    maxWidth: 1000
                }
            }], "options": {
                hasMargin: true
            }
        }]
    }, {
        "name": "Termine",
        "title": "Termine",
        "icon": {name: "calendar", type: "fontawesome", fa5style: "regular"},
        "showInDrawer": true,
        "drawerGroup": 1,
        "container": [{
            "container": [{
                "components": [{
                    "name": "Calendar"
                }], "options": {
                    hasMargin: true
                }
            }], "options": {
                hasMargin: true
            }
        }]
    }, {
        "name": "Anmeldungen",
        "title": "Deine Anmeldungen",
        "icon": "comments",
        "showInDrawer": false,
        "drawerGroup": 0,
        "container": [{
            "container": [{
                "components": [{
                    "name": "CalendarRegistrations"
                }], "style": {
                    maxWidth: 1000
                }, "options": {
                    hasMargin: false
                }
            }], "options": {
                hasMargin: true
            }
        }]
    }, {
        "name": "Speiseplan",
        "title": "Speiseplan",
        "icon": "utensils",
        "bgIcon": "utensils",
        "showInDrawer": true,
        "drawerGroup": 1,
        "container": [{
            "container": [{
                "components": [{
                    "name": "CanteenMenu"
                }], "style": {
                    maxWidth: 1000
                }, "options": {
                    hasMargin: false
                }
            }], "options": {
                hasMargin: true
            }
        }]
    }, {
        "name": "Kontakt",
        "title": "Kontakte",
        "screens": ["Personalabteilung", "Lohnbuchhaltung", "PEP-Outbound", "PEP-Inbound", "Betriebsrat"],
        "icon": "phone",
        "bgIcon": "phone",
        "showInDrawer": true,
        "drawerGroup": 1,
        "container": [{
            "container": [{
                "components": [{
                    "name": "Contacts",
                    "options": {
                        "styles": {
                            "container": {
                                "flexDirection": "row", "flexWrap": "wrap"
                            }, "linkContainer": {
                                backgroundColor: "#0093D1", borderWidth: 0,
                            }, "linkText": {
                                color: "#FFFFFF"
                            }
                        }, "useSquares": true, "useGroups": false,
                    }
                }], "style": {
                    maxWidth: 1000
                }
            }], "options": {
                hasMargin: true
            }
        }]
    }, {
        "name": "Profil",
        "title": "Profil",
        "icon": "",
        "bgIcon": "user",
        "showInDrawer": false,
        "drawerGroup": 0,
        "container": [{
            "container": [{
                "components": [{
                    "name": "Profile"
                }]
            }], "options": {
                hasMargin: true,
            }, "style": {
                maxWidth: 1000
            }
        }]
    }, {
        "name": "Notifications",
        "title": "Notifications",
        "icon": {name: "bell", type: "fontawesome", fa5style: "solid"},
        "showInDrawer": false,
        "drawerGroup": 0,
        "container": [{
            "container": [{
                "components": [{
                    "name": "Notifications"
                }]
            }], "options": {
                hasMargin: true
            }
        }]
    }, {
        "name": "ChatBot",
        "title": "ChatBot",
        "icon": "",
        "showInDrawer": false,
        "drawerGroup": 0,
        "fullScreen": true,
        "container": [{
            "container": [{
                "components": [{
                    "name": "WebView", "options": {
                        "url": CHAT_BOT_URL,
                        "title": "Toni - Chatbot"
                    }
                }]
            }], "options": {
                hasMargin: false,
            }
        }]
    }, {
        "name": "NewsArticle",
        "title": "NewsArticle",
        "icon": "",
        "bgIcon": "newspaper",
        "showInDrawer": false,
        "drawerGroup": 0,
        "container": [{
            "container": [{
                "components": [{
                    "name": "NewsArticle"
                }], "style": {
                    maxWidth: 1000
                }
            }], "options": {
                hasMargin: true
            }
        }]
    }, {
        "name": "Impressum",
        "title": "Impressum",
        "icon": {name: "file", type: "fontawesome", fa5style: "regular"},
        "showInDrawer": false, "drawerGroup": 0, "container": [{
            "container": [{
                "components": [{
                    "name": "Imprint",
                }], "style": {
                    maxWidth: 1000
                }
            }], "options": {
                hasMargin: true
            }
        }]
    }, {
        "name": "Datenschutz",
        "title": "Datenschutz",
        "icon": {name: "lock", type: "fontawesome", fa5style: "regular"},
        "bgIcon": {name: "lock", type: "fontawesome", fa5style: "regular"},
        "showInDrawer": false,
        "drawerGroup": 0,
        "container": [{
            "container": [{
                "components": [{
                    "name": "PrivacyPolicy"
                }], "style": {
                    maxWidth: 1000
                }
            }], "options": {
                hasMargin: true
            }
        }]
    }, {
        "name": "Einstellungen",
        "title": "Einstellungen",
        "icon": {name: "cogs", type: "fontawesome"},
        "showInDrawer": false,
        "drawerGroup": 0,
        "container": [{
            "container": [{
                "components": [{
                    "name": "Settings"
                }], "style": {
                    maxWidth: 1000
                }
            }], "options": {
                hasMargin: true
            }
        }]
    }, {
        "name": "BenefitsDetail",
        "title": "Benefit",
        "icon": {name: "benefits", type: "fontello_custom"},
        "bgIcon": {name: "benefits", type: "fontello_custom"},
        "showInDrawer": false,
        "drawerGroup": 0,
        "container": [{
            "container": [{
                "components": [{
                    "name": "BlogPage"
                }], "style": {
                    maxWidth: 1000
                }
            }], "options": {
                hasMargin: true
            }
        }]
    },
        {
            "name": "InformationenDetail",
            "title": "Informationen",
            "showInDrawer": false,
            "drawerGroup": 0,
            "container": [{
                "container": [{
                    "components": [{
                        "name": "InformationDetail"
                    }], "style": {
                        maxWidth: 1000
                    }
                }], "options": {
                    hasMargin: true
                }
            }]
        }
//         {
//         "name": "KiKBu",
//         "title": "KiKBu",
//         "icon": "",
//         "showInDrawer": false,
//         "drawerGroup": 0,
//         "container": [{
//             "container": [{
//                 "components": [{
//                     "name": "BlogPage",
//                     "options": {
//                         "title": "KIKBU",
//                         "imageUrl": "https://t3.ftcdn.net/jpg/00/92/53/56/240_F_92535664_IvFsQeHjBzfE6sD4VHdO8u5OHUSc6yHF.jpg",
//                         "icon": {name: "benefits", type: "fontello_custom"},
//                         "teaser": "KiKbu – betriebliche Berufsunfähigkeitsversicherung",
//                         "html": `
// Die private Berufsunfähigkeitsversicherung ist eine der wichtigsten Absicherungen für fast jeden.
// Sie zahlt, wenn aufgrund schwerer Krankheit oder eines Unfalls der versicherte Beruf nicht mehr ausgeübt werden kann. Daher sollte eine BU so früh wie möglich abgeschlossen werden.
//
// Aber warum über den Betrieb abschließen?
//
// <p style='font-family: "OpenSans_700Bold",serif;' >Das leistest du:</p>
// Du wandelst Teile deines Bruttogehalts in die betriebliche Berufsunfähigkeitsversicherung (KiKbu) um und der Staat honoriert dies mit Steuer und Sozialversicherungsersparnissen.
//
// <p style='font-family: "OpenSans_700Bold",serif;' >Das leistet Kikxxl:</p>
// Zusätzlich beteiligt sich Kikxxl mit 20% am Aufbau deiner Berufsunfähigkeitsrente. So teilst du deinen Berufsunfähigkeitsbeitrag mit dem Staat und Kikkxl. Da wird aus einer Eigenleistung von ca. 36 Euro netto schnell ein Sparbetrag für deine Berufsunfähigkeitsrente von monatlich ca. 80 Euro.
//
// <p style='font-family: "OpenSans_700Bold",serif;' >So funktioniert's:</p>
// • Wir schließen für euch eine Direktversicherung über
//    Berufsunfähigkeit ab
// • Teile des Entgelts werden in Beiträge für eine
//    Berufsunfähigkeits-Versicherung umgewandelt
// • Die Beiträge sind steuer- und sozialversicherungsfrei
// • Die Abbuchung und die Abrechnung erfolgen direkt
//   durch KiKxxl im Rahmen der Gehaltsabrechnung
//
// <p style='font-family: "OpenSans_700Bold",serif;'>Beispiel:</p>
// Garantierente bei Berufsunfähigkeit gewünscht in Höhe von 1000€
// Arbeitslohn brutto: 1700€
// Einzahlung BU Rente monatlich in Höhe von 80,72€
// Entgeltumwandlung durch KiKbu -> Eigenaufwand monatlich 36,29€
//
// -> Euer Vorteil aus der betrieblichen Altersvorsorge beträgt 44,43€
//
// <p style='font-family: "OpenSans_700Bold",serif;' >Service:</p>
// Unser Ansprechpartner prüft kostenlos und unverbindlich schon bestehende Berufsunfähigkeitsversicherungen um evtl. Versorgungslücken durch KiKbu nachzuversichern.`
//                     }
//                 }], "style": {
//                     maxWidth: 1000
//                 }
//             }], "options": {
//                 hasMargin: true
//             }
//         }]
//     }
    ],
}

export const webViewConfiguration = createSlice({
    name: 'config', initialState, reducers: {
        overwrite: (state, action) => {
            state.config = action.payload.config;
        },
    },
})

// Action creators are generated for each case reducer function
export const {overwrite} = webViewConfiguration.actions

export default webViewConfiguration.reducer
