import RenderHtml from "react-native-render-html";
import {SystemFontKeys} from "../../constants/SystemFonts";
import {customHTMLElementModels} from "../../utils/fontElementModel";
import React from "react";
import {useSelector} from "react-redux";
import {useWindowDimensions} from "react-native";

/**
 * used just to specially render german symbols
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const NewsArticlePreviewText = (props) => {
    const {id, text, noText = "Keinen Teaser gefunden."} = props;
    const theme = useSelector(state => state.theme);
    const {width, height} = useWindowDimensions();


    const htmlStyles = React.useMemo(
        () => ({
            ...theme.typo.p_md_light,
            ...theme.typo.web,
            color: theme.colors.text_dark,
            padding: theme.wrapperPadding,
            paddingTop: 0,
        }),
        [theme],
    );

    return <RenderHtml
        key={id}
        baseStyle={htmlStyles}
        tagsStyles={{
            a: {
                color: theme.colors.primary,
                textDecoration: "underline",
                textDecorationColor: theme.colors.primary
            }
        }}
        contentWidth={width}
        source={{html: text ?? noText}}
        systemFonts={SystemFontKeys}
        customHTMLElementModels={customHTMLElementModels}//to handle <font> tag
    />
}

export default NewsArticlePreviewText;