import {StyleSheet, Text, TextInput, TouchableOpacity, View} from "react-native";
import UserImage from "../UserImage/UserImage";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import React, {useImperativeHandle, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import {store} from "../../app/store";
import Comment from "../Comment/Comment";

const MessageSubmitter = React.forwardRef((props, ref) => {
    const {onSubmit} = props;
    const theme = useSelector(state => state.theme);
    let inputRef = useRef()
    const [height, setHeight] = useState(42)
    const [value, _setValue] = useState('');
    const [replyOptions, _setReplyOptions] = useState(null);
    const userId = store.getState()?.auth?.userId;
    const fullName = store.getState()?.auth?.fullname
    const {
        data: userObj,
        error: userObjError,
        isLoading: userObjLoading
    } = endpoints.getUserObj.useQuery({userId}, {skip: !userId});
    const {
        data: userImage,
        error: userImageError,
        isLoading: userImageLoading
    } = endpoints.getImage.useQuery({imageId: userObj?.picture?.id}, {skip: !userId || !userObj});
    const {style, onFocus, setValue} = props;

    const overwriteStyles = StyleSheet.create({
        replyToCommentContainer: {
            backgroundColor: theme.colors.background_light2,
        },
        input: {
            backgroundColor: theme.colors.background_light,
        },
        commentSubmitContainer: {
            backgroundColor: theme.colors.primary,
        },
        submitButton: {
            backgroundColor: theme.colors.background_light,
        },
        replyHeaderText: {
            ...theme.typo.p_md_bold,
            color: theme.colors.text_dark
        }
    });

    useImperativeHandle(ref, () => ({
        setReplyOptions(args) {
            _setReplyOptions({...args})
        },
        focusInput() {
            inputRef?.current?.focus();
        },
        setValue(value) {
            _setValue(value)
        }
    }));

    return <View style={[styles.replyToCommentContainer, overwriteStyles.replyToCommentContainer]}>
        {replyOptions?.user && <View style={{padding: 15}}>
            <View style={styles.replyHeader}>
                <Text style={[styles.replyHeaderText, overwriteStyles.replyHeaderText]}>Antworten</Text>
                <TouchableOpacity onPress={() => _setReplyOptions(null)}>
                    <FontAwesome5
                        color={theme.colors.text_dark}
                        size={25}
                        solid
                        name="times-circle"/>
                </TouchableOpacity>
            </View>
            <Comment
                id={replyOptions.id}
                padding={15}
                canLike={false}
                canReply={false}
                user={replyOptions.user}
                createdAt={global.moment(replyOptions.createdAt)?.format('DD.MM.YY - LT [Uhr]')}
                comment={{content: replyOptions.content}}
            />
        </View>}
        <View style={[styles.commentSubmitContainer, overwriteStyles.commentSubmitContainer]}>
            <UserImage size={40} user={userObj}
                       imageUrl={userImage?.formats?.find(item => item.name === "default_big")?.url}/>
            <TextInput
                // multiline
                onFocus={onFocus}
                keyboardType="default"
                returnKeyType="done"
                blurOnSubmit={true}
                onSubmitEditing={() => {
                    onSubmit({
                        id: Math.random(),
                        createdAt: new Date(),
                        content: value,
                        user: {
                            imageUrl: userImage?.formats?.find(item => item.name === "default_big")?.url,
                            name: fullName
                        },
                        replyOptions: replyOptions
                    })
                    _setValue("");
                }}
                onChangeText={(value) => {
                    _setValue(value)
                }}
                onContentSizeChange={(e) => setHeight(e.nativeEvent.contentSize.height)}
                ref={inputRef}
                value={value}
                style={[styles.input, overwriteStyles.input, {height: height}]}
            />
            <TouchableOpacity
                style={[styles.submitButton, overwriteStyles.submitButton]}
                onPress={() => {
                    if (value) {
                        onSubmit({
                            id: Math.random(),
                            createdAt: new Date(),
                            content: value,
                            user: {
                                imageUrl: userImage?.formats?.find(item => item.name === "default_big")?.url,
                                name: fullName
                            },
                            replyOptions: replyOptions
                        });
                        _setValue("")
                        _setReplyOptions(null)
                    }
                }}>
                <FontAwesome5
                    color={"black"}
                    size={20}
                    solid
                    style={{marginLeft: -2}}
                    name="paper-plane"
                />
            </TouchableOpacity>
        </View>
    </View>
});
export default MessageSubmitter;

const styles = StyleSheet.create({
    input: {
        margin: 12,
        borderWidth: 0,
        paddingLeft: 32,
        minHeight: 42,
        textAlignVertical: 'center',
        borderRadius: 20,
        flex: 10
    },
    commentSubmitContainer: {
        borderRadius: 100,
        paddingLeft: 16,
        paddingRight: 16,
        flexDirection: "row",
        minHeight: 65,
        alignItems: "center",
        justifyContent: "space-between"
    },
    submitButton: {
        height: 40,
        width: 40,
        borderRadius: 40,
        alignItems: "center",
        justifyContent: "center"
    },
    replyToCommentContainer: {
        width: '100%',
        borderRadius: 30,
        marginTop: 48,
    },
    replyHeader: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    replyHeaderText: {}
});
