import React, {useState} from "react";
import {useSelector} from "react-redux";
import {
    Image as ImageReact,
    Pressable,
    StyleSheet,
    Text,
    TouchableOpacity,
    useWindowDimensions,
    View,
} from "react-native";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {Image} from "react-native-expo-image-cache";
import {endpoints, useLikeArticleMutation, useUnlikeArticleMutation} from "../../app/services/api/globalAdminAPI";
import {Transition, Transitioning,} from 'react-native-reanimated';
import {useLinkTo} from "@react-navigation/native";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import {POLLING_INTERVALL_LIKES_COMMENTS} from "../../constants/numbers";
import {SystemFontKeys} from "../..//constants/SystemFonts";
import {customHTMLElementModels} from "../../utils/fontElementModel";
import RenderHtml from "react-native-render-html";
import {ParagraphRenderer} from "../../utils/customHtmlRenderers";
import {isDevelopment} from "../../constants/booleanValues";

// todo: link article pages (to={'/'})
export default React.memo(function NewsArticlePreview(props) {
    // isDevelopment && console.log("NewsArticlePreview.web", props);
    const {
        isSmall,
        id,
        navigation,
        title = "",
        comments,
        useImage = true,
        number_of_comments = 0,
        number_of_likes = 0,
        createdAt,
        datetime,
        you_like_it,
        picture_info,
        imageUrl = undefined,
        teaser = "",//"Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.",
    } = props;
    const theme = useSelector(state => state.theme);
    const [likeArticle] = useLikeArticleMutation();
    const [unLikeArticle] = useUnlikeArticleMutation();
    const {
        data: likeData,
        refetch,
        error,
        isLoading: likesAndCommentsLoading,
        isFetching: likesAndCommentsFetching,
        isUninitialized: likesAndCommentsIsUninitialized
    } = endpoints.getLikesAndCommentsCountForNewsArticles.useQuery([id], {pollingInterval: POLLING_INTERVALL_LIKES_COMMENTS});
    const [liked, setLiked] = useState(you_like_it);
    const [numberOfLikes, setNumberOfLikes] = useState(number_of_likes)
    const ref = React.useRef(null);
    const {width, height} = useWindowDimensions();


    const uri = imageUrl ?? picture_info?.reference?.url;
    const likeDataSingle = likeData?.find(item => Number(item.id) === id);
    const commentsCount = likeDataSingle?.number_of_comments;
    const likesCount = likeDataSingle?.likes;
    const likedByUser = likeDataSingle?.you_like_it;

    const linkTo = useLinkTo();
    const navigateToArticle = () => linkTo({
        screen: 'NewsArticle', params: {
            id,
        }
    });

    return <View style={[styles.container, (isSmall && styles.container_sm)]}>
        <Pressable
            style={[{flex: 1}, (isSmall && {flex: null})]}
            onPress={navigateToArticle}>
            {useImage && (uri ? <Image
                    style={[styles.newsImage, (isSmall && styles.newsImage_sm)]}
                    {...{preview: {uri: uri}, uri}} /> :
                <ImageReact resizeMode={"cover"} style={[styles.newsImage, (isSmall && styles.newsImage_sm)]}
                            source={require("../../../assets/images/placeholder-web.jpg")}/>)
            }
        </Pressable>
        <View style={[styles.textContainer, (isSmall && styles.textContainer_sm)]}>
            <Text style={[styles.newsTitle, theme.typo.p_lg]}>{title}</Text>
            <Text style={[styles.newsDate, theme.typo.date]}>
                {global.moment(datetime ?? createdAt)?.format('DD.MM.YY')}
                {/*{global.moment(datetime ?? createdAt)?.format('DD.MM.YY - LT [Uhr]')}*/}
            </Text>
            {/*<Text style={[styles.newsParagraph, theme.typo.p_sm]} numberOfLines={3}>*/}
            {/*    {teaser ? teaser.replace(/<[^>]+>/g, '') : 'Keinen Teaser gefunden.'}*/}
            {/*</Text>*/}
            <RenderHtml
                contentWidth={width}
                baseStyle={{...styles.newsParagraph, ...theme.typo.p_sm}}
                tagsStyles={{
                    a: {
                        color: theme.colors.primary,
                        textDecoration: "underline",
                        textDecorationColor: theme.colors.primary
                    },
                    //ellipsis doesn't seem to work / react
                    // div: {
                    //     // color: "green", //to this one it reacts
                    //     maxLines: 1,
                    //     flexDirection: 'row',
                    //     overflow: "hidden",
                    //     display: "-webkit-box",
                    //     WebkitBoxOrient: 'vertical',
                    //     WebkitLineClamp: 1,
                    //     textOverflow: 'ellipsis',
                    // },
                    // 'body': {
                    //     // color: "green",
                    //     display: "-webkit-box",
                    //     WebkitBoxOrient: 'vertical',
                    //     WebkitLineClamp: 1,
                    //     textOverflow: 'ellipsis',
                    // }
                }}
                //overflow: hidden; display: -webkit-box; WebkitBoxOrient: vertical, WebkitLineClamp: 1; textOverflow: ellipsis;
                source={{
                    html: teaser
                        ? `<p>` + teaser.replace(/<[^>]+>/g, '') + `</p>`
                        : 'Keinen Teaser gefunden.'
                }}
                systemFonts={SystemFontKeys}
                customHTMLElementModels={customHTMLElementModels}//to handle <font> tag
                //for limiting our paragraph to 3 lines with ellipsis
                renderers={{
                    p: ParagraphRenderer
                }}
            />

            {/* interaction */}
            <View style={styles.interactionContainer}>
                {/* read more */}
                <Pressable
                    onPress={navigateToArticle}
                    style={[styles.button_readMore, {backgroundColor: theme.colors.primary}]}>
                    <Text style={[theme.typo.button]}>Mehr lesen</Text>
                </Pressable>

                {/* likes */}
                <View style={styles.interactionGroup}>
                    <TouchableOpacity onPress={() => {
                        if (likedByUser) unLikeArticle({articleId: id});
                        else likeArticle({articleId: id});
                        ref.current?.animateNextTransition();
                    }}>
                        <Transitioning.View ref={ref} transition={transition}>
                            <FontAwesome5
                                color={theme.colors.primary}
                                size={24}
                                solid={!!likedByUser}
                                name={"thumbs-up"}/>
                        </Transitioning.View>
                    </TouchableOpacity>
                    {(likesAndCommentsLoading || (likesAndCommentsIsUninitialized && likesAndCommentsFetching)) ?
                        <LoadingIndicator noStyles={true} size={"small"}/> :
                        <Text style={[styles.interactionCount, theme.text.paragraph]}>{likesCount}</Text>}
                </View>

                {/* comments */}
                <View style={styles.interactionGroup}>
                    <TouchableOpacity onPress={navigateToArticle}>
                        <FontAwesome5
                            color={theme.colors.primary}
                            name={"comment"}
                            size={24}/>
                    </TouchableOpacity>
                    {(likesAndCommentsLoading || (likesAndCommentsIsUninitialized && likesAndCommentsFetching)) ?
                        <LoadingIndicator noStyles={true} size={"small"}/> :
                        <Text style={[styles.interactionCount, theme.text.paragraph]}>{commentsCount}</Text>}
                </View>
            </View>

        </View>
    </View>
});

const styles = StyleSheet.create({
    container: {
        marginTop: 32,
        flexDirection: 'row',
        height: 280,
        alignItems: 'stretch'
    },
    container_sm: {
        height: 'unset',
        flexDirection: 'column',
    },
    newsImage: {
        flex: 1,
        borderRadius: 10,
    },
    newsImage_sm: {
        flex: null,
        minHeight: 300,
    },
    textContainer: {
        flex: 1,
        marginLeft: 32,
        minWidth: 300,
        justifyContent: 'center'
    },
    textContainer_sm: {
        flex: null,
        marginLeft: 0,
        marginTop: 16,
    },
    newsTitle: {
        marginBottom: 4,
    },
    newsDate: {
        marginBottom: 16,
    },
    newsParagraph: {
        flexShrink: 1,
    },
    interactionContainer: {
        alignItems: "center",
        flexDirection: "row",
        marginTop: 16
    },
    interactionGroup: {
        flexDirection: 'row',
        alignItems: 'center',
        marginLeft: 32
    },
    interactionCount: {
        marginLeft: 8
    },
    button_readMore: {
        height: 40,
        width: 120,
        alignItems: "center",
        justifyContent: "center"
    }
});

const navigateToView = ({navigation, params, viewName}) => {
    navigation.navigate(viewName, {
        ...params
    })
}

const transition = (
    <Transition.Together>
        <Transition.Out type="scale" durationMs={100}/>
        <Transition.Change interpolation="easeInOut"/>
        <Transition.In type="scale" durationMs={100} delayMs={50} value={50}/>
    </Transition.Together>
);
