import {useCallback, useEffect, useRef, useState} from "react";
import {Keyboard, Platform} from "react-native";
import {renderToast} from "../../utils/renderToast";
import {isDevelopment} from "../../constants/booleanValues";
import {
    endpoints,
    usePostThreadCommentMutation,
    useReplyToCommentMutation
} from "../../app/services/api/globalAdminAPI";
import {DATA_POLLING_INTERVAL} from "../../constants/numbers";
import {debounceFunction} from "../../utils/debaunce";
import {InteractionManager} from "react-native-web";

/**
 * Handles data fetching, highlighting comments and scrolling to them
 * awaitForParent - false if comments don't have parent comments (like on web)
 * @param props
 * @returns {{replyToCommentError: *, threadData: *, threadDataError: *, onCommentSubmit: onCommentSubmit, highlightedCommentId: unknown, threadDataIsLoading: *, isHighLightPending: boolean, commentId: unknown, highlightPosition: unknown, refetch: *, setPosition: setPosition}}
 */
const useCommentSubmit = (props) => {
    const {id, commentId, scrollView/*: initialScrollView*/} = props;
    const isCommentFromNotification = !!commentId;

    //since scrollView on the web initially isn't known,
    // it can update it's reference after it loads CommentSection.jsx
    //otherwise initialScrollView would be sufficient, and useRef would be unnecessary
    // const scrollView = useRef(initialScrollView?.current);
    // const index = useRef();

    const [recentCommentId, setRecentCommentId] = useState(undefined);
    const [highlightPosition, _setHighlightPosition] = useState(undefined);

    const [isHighLightPending, setIsHighLightPending] = useState(isCommentFromNotification);

    const [postThreadComment, {postThreadCommentError, postThreadCommentIsLoading}] = usePostThreadCommentMutation();
    const [replyToComment, {
        error: replyToCommentError,
        isLoading: replyToCommentIsLoading
    }] = useReplyToCommentMutation();

    const {
        refetch,
        data: threadData,
        error: threadDataError,
        isLoading: threadDataIsLoading
    } = endpoints.getThreadDetail.useQuery({threadId: id}, {pollingInterval: DATA_POLLING_INTERVAL});

    /**
     * Resets all the values related to highlight back to undefined
     * It gets ready the content to be highlighted again
     */
    const resetHighLight = useCallback(() => {
        // setIsHighLightPending(false); // this one is controlled separately
        _setHighlightPosition(undefined);
        setRecentCommentId(undefined);
    }, []);

    const isDeboucedFnSet = useRef(null);
    const debouncedFnRef = useRef(null);

    function debounceHighlight() {
        if (!isDeboucedFnSet.current || !debouncedFnRef.current) {
            debouncedFnRef.current = debounceFunction((e) => {
                resetHighLight();
            }, 5000);
            isDeboucedFnSet.current = true;
        }
        debouncedFnRef.current();
    }

    /**
     * on reply, we scroll to the new comment
     */
    useEffect(() => {
        isDevelopment && console.log("useCommentSubmit.useEffect", {
            isHighLightPending, highlightPosition, recentCommentId, scrollView, /*index: index.current*/
        })
        if (isHighLightPending && highlightPosition) {
            if (scrollView.current?.hasOwnProperty("scrollToPosition")) {
                //keyboardaware scrollview
                scrollView.current?.scrollToPosition(highlightPosition.x, highlightPosition.y)
            } else if (scrollView.current?.hasOwnProperty("scrollTo")) {
                //react-native scrollview
                InteractionManager.runAfterInteractions(() => {
                    // scrollView.current.scrollTo({x: 0, y: 300, animated: false});
                    scrollView.current?.scrollTo({x: highlightPosition.x, y: highlightPosition.y, animated: false});
                    isDevelopment && console.log("scrollTo", {
                        x: highlightPosition.x,
                        y: highlightPosition.y,
                        animated: false
                    })
                });
            // } else if (Number.isInteger(index.current)) {
                //https://stackoverflow.com/questions/67440618/react-native-flatlist-scrolltoindex-is-not-working-when-screen-loaded-for-the
                // setTimeout(() => {
                //     scrollView.current.scrollToIndex({index: index.current, animated: false});
                //     isDevelopment && console.log("scrollToIndex", {index: index.current})
                //     index.current = undefined;
                // }, 500);
            } else {
                console.log("scrollView failed with", scrollView.current, "and", Number.isInteger(index.current))
            }
            setIsHighLightPending(false); //because during timeout we might interact with interface our self
            //removes temporary highlight, resets state
            !isCommentFromNotification &&
            debounceHighlight()
        }
    }, [JSON.stringify(highlightPosition)]);

    /**
     * returns highlighted comment id:
     * either from props, which is coming from events
     * or from recently user created comment id
     * @returns {unknown}
     */
    const highlightedCommentId = isCommentFromNotification ? Number(commentId) : recentCommentId;

    const setPosition = (val, isParent) => {
        // const {scrollView: newScrollView, index: newIndex, ...val} = parameters;
        // /**
        //  * web reports position with additional information about index to highlight and scrollView to be used
        //  */
        // if (Platform.OS === "web" && newScrollView?.current && newIndex) {
        //     console.log("useCommandSubmit.setPosition", {newIndex, newScrollView});
        //     scrollView.current = newScrollView.current;
        //     index.current = newIndex;
        //     setIsHighLightPending(true);
        // }
        // console.log('setHighlightPosition', val, isParent)
        _setHighlightPosition(prev => {
            const res = prev === undefined
                ? val
                : {//we add parents y with child y, and we add the parents height
                    ...prev,
                    y: prev.y + val.y + (isParent ? val.height : prev.height),
                };
            console.log('setPosition', JSON.stringify(val), isParent, "res", JSON.stringify(res));
            return res;
        })
    }

    const onCommentSubmit = (comment) => {
        const formData = new FormData();
        formData.append("comment", comment?.content);
        if (Platform.OS !== "web")
            Keyboard.dismiss();
        if (comment.replyOptions?.commentId) {
            replyToComment({commentId: comment.replyOptions?.commentId, formData}).then((res) => {
                refetch({threadId: id});
                resetHighLight();
                if ('error' in res) {
                    renderToast("Kommentar nicht gesendet!", "error");
                } else {
                    //well, well... it appears posting a reply to comment has different structure than posting a threat reply
                    if (res?.data?.hasOwnProperty("id")) {
                        // console.log("reply to comment,res.data.id =", res.id);
                        setRecentCommentId(res.data.id);
                    } else if (isDevelopment) {
                        console.log("missing data", res);
                    }
                    renderToast("Kommentar erfolgreich gesendet!");
                    setIsHighLightPending(true);
                }
            }).catch((error) => {
                renderToast("Kommentar nicht gesendet!", "error");
            })
        } else {
            postThreadComment({threadId: id, formData}).then(res => {
                refetch({threadId: id});
                resetHighLight();
                if ('error' in res) {
                    renderToast("Kommentar nicht gesendet!", "error");
                } else {
                    //now this stuff is some ugly reply, to which we assume that our recent comment is the last
                    //we mark it to be highlighted as set the highlight mark too
                    //and data structure differs from News comment one
                    if (res.data?.hasOwnProperty("comments_info") && Array.isArray(res.data.comments_info)) {
                        // console.log(res.data.comments[res.data.comments.length-1]);
                        setRecentCommentId(res.data.comments_info[res.data.comments_info.length - 1].id);
                        // }else{
                        //     console.log(Object.keys(res.data).join(", "));
                    } else if (isDevelopment) {
                        console.log("missing data", res);
                    }
                    renderToast("Kommentar erfolgreich gesendet!");
                    setIsHighLightPending(true);
                }
            }).catch(e => {
                console.error("rejected postNewsComment", e)
                renderToast("Kommentar nicht gesendet!", "error");
            })
        }
    };

    return {
        refetch, threadData, threadDataError, threadDataIsLoading,
        replyToCommentError,
        onCommentSubmit,
        setPosition,
        isHighLightPending, highlightedCommentId,
        commentId: isHighLightPending || highlightPosition ? highlightedCommentId : undefined,
        highlightPosition,
    }
}

export default useCommentSubmit;