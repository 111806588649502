import {createSlice} from '@reduxjs/toolkit'
import {CHAT_BOT_URL, CONFIGURATION_NAMES, CONTENT_TYPE} from "../../../constants/strings";
import nativeRoutesLoggedIn from "../../../app/services/state/nativeViewConfigurationRoutes/nativeRoutesLoggedIn";

let store;

/**
 * This is used only to combat require cycle
 * @param _store
 */
export const injectStore = _store => {
    store = _store
}

export const nativeViewConfiguration = createSlice({
    name: 'config',
    initialState: nativeRoutesLoggedIn,
    reducers: {
        overwrite: (state, action) => {
            if (state.version?.name !== action.payload?.version?.name) {
                // state = action.payload;
                return action.payload;
            }
        },
    },
})
export const {overwrite} = nativeViewConfiguration.actions

export default nativeViewConfiguration.reducer
