import {
    Dimensions, KeyboardAvoidingView, Modal, NativeModules, Platform, ScrollView, StyleSheet, Text, TextInput, View
} from "react-native";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import Button from "../../Button/Button";
import {setIsFirstLogin} from "../../../app/services/state/auth";
import {useUpdateProfileMutation, useUpdateWebSettingsMutation} from "../../../app/services/api/globalAdminAPI";
import React, {useMemo, useState} from "react";


const styles = StyleSheet.create({
    container: {
        width: '100%', alignItems: 'flex-start'
    }, title: {
        marginBottom: 16,
    }, inputGroup: {
        flexDirection: 'row', alignItems: 'center', width: '100%', marginTop: 8,
        maxWidth: 400,
    }, inputGroup_first: {
        marginTop: 0,
    }, input: {
        flex: 2, borderWidth: 1, padding: 8,
    }, input_multiline: {
        height: 160
    }, label: {
        width: 140, marginBottom: 4
    }, createBtn: {
        paddingHorizontal: 20, paddingVertical: 12, marginTop: 24, borderRadius: 100,
    }, errText: {
        marginTop: 4,
    }
});

const style = StyleSheet.create({
    headerText: {
        marginBottom: 20
    }, checkbox: {
        paddingBottom: 10,
        maxWidth: 400,
    }, valueContainer: {
        paddingTop: 7.5, paddingBottom: 7.5
    }, personalFieldKey: {
        fontFamily: "OpenSans_600SemiBold", fontSize: 14,
    }, personalFieldHeaderText: {
        fontSize: 18, marginBottom: 10, fontFamily: "OpenSans_700Bold",
    }, modalView: {
        backgroundColor: "white", padding: 15, paddingBottom: 100,
    }, input: {
        height: 40, borderWidth: 1, padding: 10, borderColor: "rgba(0,0,0,0.3)", // borderRadius: 6,
        fontSize: 13, // textAlign: 'center',
    }, buttonClose: {
        padding: 10, position: "absolute", right: 20, zIndex: 100, top: 10,
    }, textArea: {
        height: 80, padding: 10, textAlignVertical: 'top'
    }
});

export default function FirstLoginModal(props) {
    const {visible, onDismissed} = props;
    const theme = useSelector(state => state.theme);
    const dispatch = useDispatch()
    const auth = useSelector(state => state.auth);

    const {width, height} = Dimensions.get("window");
    const [serverErrors, setServerErrors] = useState([]);
    const [passwordValidationError, setPasswordValidationError] = useState("")
    const [loading, setLoading] = useState(false)
    const {StatusBarManager} = NativeModules;
    const STATUSBAR_HEIGHT = Platform.OS === 'ios' ? 40 : ((StatusBarManager?.HEIGHT - 20) ?? 0);
    const {getValues, control, setValue, handleSubmit, formState: {errors}, watch} = useForm({
        defaultValues: {
            anniversary: false, birthday: false, plainPassword: "", passwordValidation: ""
        }
    });

    // console.log("FirstLoginModal rerendered", props, getValues())
    const [updateWebSettings, {
        isErrorGeneralSettings, error: updateErrorGeneralSettings,
    }] = useUpdateWebSettingsMutation();
    const [updateProfile, {}] = useUpdateProfileMutation();
    const overwriteStyles = StyleSheet.create({
        title: {
            ...theme.typo.p_md_bold, color: theme.typo.text_dark
        }, input: {
            borderColor: theme.colors.border
        }, label: {
            ...theme.typo.p_sm,
        }, createBtn: {
            backgroundColor: theme.colors.primary,
        }, createBtnText: {
            ...theme.typo.p_sm, color: theme.colors.text_light,
        }, errText: {
            ...theme.typo.p_sm, color: theme.colors.error
        }
    });
    const InputGroup = ({label, propName, numberOfLines, customInput, inputOptions}) => {
        // <TextInput
        //     autoCapitalize={'none'}
        //     autoCorrect={false}
        //     ref={passwordInput}
        //     style={styles.TextInput}
        //     placeholderTextColor="#003f5c"
        //     secureTextEntry={true}
        //     value={password}
        //     onChangeText={(password) => setPassword(password)}
        //     returnKeyType="send"
        //     onSubmitEditing={loginIntoApp}
        // />
        return <Controller
            control={control}
            name={propName}
            render={({field: {onChange, onBlur, value}}) => (
                <View style={[styles.inputGroup]}>
                    <Text style={[styles.label, overwriteStyles.label]}>{label}</Text>
                    {customInput
                        ? customInput({value, onChange})
                        : <TextInput
                            textContentType={"password"}
                            defaultValue={value}
                            onChangeText={onChange}
                            style={[styles.input, overwriteStyles.input]}
                            multiline={!!numberOfLines}
                            numberOfLines={numberOfLines}
                            {...inputOptions} // causes keyboard to disappear as field rerenders
                        />}
                </View>
            )}
        />;
    };
    const onSubmit = data => {
        const passwordMatches = getValues().plainPassword === getValues().passwordValidation;
        if (getValues().plainPassword === "") setPasswordValidationError("Neues Passwort wird benötigt."); else if (passwordMatches && !loading) {
            setPasswordValidationError("");
            setLoading(true)
            updateProfile({
                userId: auth?.userId,
                formData: {
                    plainPassword: getValues().plainPassword,
                },
                keepLoggedIn: true,//todo: refactor logic from scratch: logout
            }).then(passwordRes => {
                if (passwordRes.error) {
                    setServerErrors(passwordRes.error?.data?.errors?.errors ?? [])
                    setLoading(false)
                } else {
                    dispatch(setIsFirstLogin(false))
                    updateWebSettings({
                        userId: auth?.userId,
                        formData: {
                            dateOfEmploymentPublic: getValues().anniversary, birthdayPublic: getValues().birthday
                        },
                        performLogout: true,
                    }).then(res => {
                        //console.log("updateWebSettings respoonse: ",res)
                        onDismissed();
                        setLoading(false)
                    }).catch(error => {
                        setLoading(false)
                        console.error(error)
                    })
                }
            }).catch(e => {
                console.error(e)
                setLoading(false)
            })
        } else {
            setPasswordValidationError("Die eingegebenen Passwörter stimmen nicht überein.")
        }
    };

    /**
     * without memo we get rerenders that loose focus
     * alternative way is to not use InputGroup but use Controller directly as passing parameters to it reloads modal
     * @type {unknown}
     */
    const pwdBoxesMemorized = useMemo(() => {
        return <>
            <InputGroup label={'Neues Passwort'} propName={'plainPassword'}
                        inputOptions={{secureTextEntry: true}}/>
            <InputGroup label={'Neues Passwort wiederholen'} propName={'passwordValidation'}
                        inputOptions={{secureTextEntry: true}}/>
        </>
    }, [])
    return <Modal
        animationType="fade"
        visible={visible}
        statusBarTranslucent={true}
        presentationStyle={"overFullScreen"}
        propagateSwipe={true}>
        <KeyboardAvoidingView behavior={"height"}>
            {/*<ScrollView*/}
            {/*contentContainerStyle={[style.modalView, {top: STATUSBAR_HEIGHT - 5, width: width, marginTop: 20}]}>*/}
            <View
                style={[style.modalView, {top: STATUSBAR_HEIGHT - 5, width: width, marginTop: 20}]}>
                {<View>
                    <Text style={[style.personalFieldHeaderText, {marginTop: 20}]}>Willkommen in der KiKxxl
                        Mitarbeiter App! Bitte wähle aus, welche deiner Daten für andere Benutzer sichtbar sind: </Text>
                    <Text style={[theme.text.paragraph, {marginBottom: 20}]}>Diese Einstellungen können auch
                        nachträglich angepasst werden.</Text>
                    <Controller
                        name="anniversary"
                        control={control}
                        render={({field: {onChange, onBlur, value}}) => (<BouncyCheckbox
                            style={style.checkbox}
                            size={25}
                            isChecked={value}
                            fillColor={theme.colors?.primary}
                            unfillColor="transparent"
                            text={"Jubiläum"}
                            iconStyle={{borderColor: theme.colors?.primary}}
                            innerIconStyle={{borderWidth: 2}}
                            textContainerStyle={{textDecoration: "none"}}
                            textStyle={{fontFamily: "OpenSans_400Regular", textDecorationLine: "none"}}
                            onPress={(val) => {
                                setValue("anniversary", val);
                            }}
                        />)}
                    />
                    <Controller
                        control={control}
                        name="birthday"
                        render={({field: {onChange, onBlur, value}}) => (<BouncyCheckbox
                            style={style.checkbox}
                            size={25}
                            isChecked={value}
                            fillColor={theme.colors?.primary}
                            unfillColor="transparent"
                            text={"Geburtstag"}
                            iconStyle={{borderColor: theme.colors?.primary}}
                            innerIconStyle={{borderWidth: 2}}
                            textContainerStyle={{textDecoration: "none"}}
                            textStyle={{fontFamily: "OpenSans_400Regular", textDecorationLine: "none"}}
                            onPress={(val) => {
                                setValue("birthday", val);
                            }}
                        />)}
                    />

                    <Text style={[theme.text.paragraph, {marginBottom: 20, marginTop: 30}]}>Bitte gebe ein neues
                        Passwort für den Zugang zur App ein.</Text>
                    {pwdBoxesMemorized}
                    {passwordValidationError &&
                        <Text style={[styles.errText, overwriteStyles.errText]}>{passwordValidationError}</Text>}
                    {serverErrors.length > 0 &&
                        <Text style={[styles.errText, overwriteStyles.errText]}>{serverErrors.join(",")}</Text>}
                    <View style={{marginTop: 30, maxWidth: 450}}>
                        <Button text={"Einstellungen bestätigen"} disabled={loading} onClick={onSubmit}/>
                    </View>
                </View>}
            </View>
        </KeyboardAvoidingView>
    </Modal>
}