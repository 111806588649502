import CaptionBanner from "..//CaptionBanner/CaptionBanner";
import {StyleSheet, Text, View} from "react-native";
import MessageSubmitter from "../MessageSubmitter/MessageSubmitter";
import React, {useRef, useState} from "react";
import {useSelector} from "react-redux";
import {FlatListComments, flatMapComments} from "./commentsHelpers";

export default function CommentSection(props) {
    const {comments, commentId, setHighlightPosition, onCommentSubmit, headline} = props;

    let messageSubmitRef = useRef();
    const theme = useSelector(state => state.theme);

    // console.log("CommentSection", comments)

    /**
     * since we have a flatlist here, and onCommentSubmit controller in ForumPage
     * we use callback to report our scrollView reference
     */
        // useEffect(() => {
        //     setScrollView(scrollReference.current);
        //     return () => {
        //         setScrollView(null);
        //     }
        // }, [scrollReference.current])

    const overwriteStyles = StyleSheet.create({
            noComments: {
                ...theme.typo.p_sm
            }
        });


    const number_of_comments = comments.reduce((prev, comment) => prev + 1 + comment.replies.length, 0);


    return <View style={[styles.commentSection]}>
        <CaptionBanner config={{
            headline: `${headline ?? 'Kommentare'} (${number_of_comments})`,
        }}/>
        {comments.length > 0
            //todo: this is the future, just comment.replies aren't displayed yet
            // ? flatMapComments({
            //     comments,
            //     commentId,
            //     commentLoadSize: comments.length,
            //     setReplyOptions: (options) => messageSubmitRef.current?.setReplyOptions(options),
            //     setHighlightPosition,
            //     focusInput: () => messageSubmitRef?.current?.focusInput()
            // })
            //todo: refactor into flatMapComments
            ? <FlatListComments {...props}
                                comments={comments}
                                commentId={commentId}
                                setHighlightPosition={setHighlightPosition}
                                setReplyOptions={(options) => messageSubmitRef.current?.setReplyOptions(options)}
                                focusInput={() => messageSubmitRef?.current?.focusInput()}
            />
            : <Text style={[styles.noComments, overwriteStyles.noComments]}>Keine Kommentare</Text>}
        {/*{comments?.length > 0*/}
        {/*    ? <LoadMoreButton*/}
        {/*        style={{button: {width: 200}}}*/}
        {/*        disabled={comments?.length <= commentLoadSize}*/}
        {/*        onPress={() => setCommentLoadSize(commentLoadSize + loadMoreAmount)}/>*/}
        {/*    : null}*/}
        <MessageSubmitter
            ref={messageSubmitRef}
            onSubmit={onCommentSubmit}
        />
    </View>;
}

const styles = StyleSheet.create({
    commentSection: {
        maxWidth: 835,
        marginBottom: -50
    },
    noComments: {
        paddingTop: 48,
    }
});
