import {Pressable, StyleSheet, Text, TouchableOpacity} from 'react-native';
import CaptionBanner from "../CaptionBanner/CaptionBanner";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import {useSelector} from "react-redux";
import {upcomingDaysCalendarActivity} from "../../constants/numbers";
import {NO_DATA_MESAGE} from "../../constants/strings";

export default function CalendarRegistrations(props) {
    const theme = useSelector(state => state.theme);
    let {userId} = props?.route?.params;
    const {
        refetch,
        data: calendarRegistrations,
        error: calendarRegistrationsError,
        isLoading: calendarRegistrationsLoading
    } = endpoints.getCalendarActivity.useQuery(upcomingDaysCalendarActivity);
    const routeToEvent = (registration) => props.navigation.navigate("Termine", {event: registration});

    const overwriteStyles = StyleSheet.create({
        eventContainer: {
            borderTopColor: theme.colors.border
        },
        eventTitle: {
            ...theme.typo.p_md_bold,
            color: theme.colors.text_dark
        },
        eventDate: {
            ...theme.typo.date,
        },
        eventText: {
            ...theme.typo.p_sm,
        },
        btn: {
            backgroundColor: theme.colors.primary
        },
        btnText: {
            ...theme.typo.p_sm,
            color: theme.colors.text_light
        }
    });

    return <>
        <CaptionBanner config={{headline: `Event-Anmeldungen der nächsten ${upcomingDaysCalendarActivity} Tage`}}/>
        {calendarRegistrationsLoading
            ? <LoadingIndicator/>
            : calendarRegistrations?.events?.slice().sort((a, b) => (
                new Date(b?.begin) - new Date(a?.begin)
            )).map((registration, idx) => (
                <TouchableOpacity
                    onPress={() => routeToEvent(registration)}
                    key={idx}
                    style={[styles.eventContainer, overwriteStyles.eventContainer, (idx === 0 && styles.eventContainer_first)]}>
                    <Text style={[styles.eventTitle, overwriteStyles.eventTitle]}>
                        {registration.title}
                    </Text>
                    <Text style={[styles.eventDate, overwriteStyles.eventDate]}>
                        {global.moment(registration.begin).format("DD.MM.YY - LT [Uhr]")}
                    </Text>
                    <Text style={[styles.eventText, overwriteStyles.eventText]}>
                        {registration.description}
                    </Text>
                    <Pressable
                        onPress={() => routeToEvent(registration)}
                        style={[styles.btn, overwriteStyles.btn]}>
                        <Text style={[styles.btnText, overwriteStyles.btnText]}>
                            Zum Event
                        </Text>
                    </Pressable>
                </TouchableOpacity>
            ))}
        {
            Array.isArray(calendarRegistrations?.events) && calendarRegistrations?.events.length === 0 &&
            <Text>{NO_DATA_MESAGE}</Text>
        }
    </>;
}

const styles = StyleSheet.create({
    eventContainer: {
        borderTopWidth: 1,
        paddingTop: 64,
        marginTop: 64,
    },
    eventContainer_first: {
        borderTopWidth: 0,
        paddingTop: 0,
        marginTop: 0,
    },
    eventTitle: {},
    eventDate: {
        marginBottom: 16,
    },
    eventText: {},
    btn: {
        height: 40,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 50,
        marginTop: 32,
    },
    btnText: {}
});
