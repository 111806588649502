export function replaceHtml(text:string) {
    return text.replace(/<[^>]*>?/gm, '')
}
interface FormObject {
    type: "form";
    id: string;
}

/**
 * Parses HTML into array of form ["<someHtml>",{type: "form", id: 1}] while matching with regexp: [form:id]
 * which can be used in the view to render a React Native Form between HTML sections.
 * @param htmlString
 */
export function parseHTMLIntoSeperatedArray(htmlString: string): (string | FormObject)[] {
    const formRegExp = /\[form:(\w+)\]/g; // matches [form:<id>]

    let parts: (string | FormObject)[] = [];
    let lastIndex = 0;
    let match;

    while ((match = formRegExp.exec(htmlString))) {
        // Add string before the match as a plain string
        if (match.index > lastIndex) {
            parts.push(htmlString.substring(lastIndex, match.index));
        }

        // Add matched form as an object
        parts.push({ type: "form", id: match[1] });

        // Update lastIndex to be after the match
        lastIndex = match.index + match[0].length;
    }

    // Add remaining string after last match as a plain string
    if (lastIndex < htmlString.length) {
        parts.push(htmlString.substring(lastIndex));
    }

    return parts;
}
export function replaceLinkStyles(htmlString) {
    const linkRegex = /<a([^>]*)>/g;
    const styleRegex = /style="[^"]*"/g;
    const styleToAdd = 'style="color:white; font-style:italic!important; text-decoration:underline; text-decoration-color:white;"';
    const linkMatches = htmlString.match(linkRegex);
    if (linkMatches === null) {
        return htmlString;
    }
    for (let i = 0; i < linkMatches.length; i++) {
        const linkMatch = linkMatches[i];
        const styleMatch = linkMatch.match(styleRegex);
        if (styleMatch === null) {
            const replacedLink = linkMatch.replace('>', ` ${styleToAdd}>`);
            htmlString = htmlString.replace(linkMatch, replacedLink);
        } else {
            const replacedStyle = styleMatch[0].replace(/"/g, '') + ' ' + styleToAdd.slice(6, -1) + '"';
            const replacedLink = linkMatch.replace(styleRegex, replacedStyle);
            htmlString = htmlString.replace(linkMatch, replacedLink);
        }
    }
    return htmlString;
}
//
