import {Pressable, StyleSheet, Text, TouchableOpacity, View} from 'react-native';
import React, {useCallback, useMemo, useState} from "react";
import {useSelector} from "react-redux";
import {Image} from "react-native-expo-image-cache";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {
    endpoints, useDeleteImageMutation,
    useLogoutMutation,
    useSetUserImageMutation,
    useUpdateProfileMutation,
    useUploadImageMutation
} from "../../app/services/api/globalAdminAPI";
import {store} from "../../app/store";
import {renderToast} from "../../utils/renderToast";
import {getDefaultImage} from "../../utils/avatar";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import {Card} from "../Card/Card";
import {launchImageLibrary, requestMediaLibraryPermissions} from "../../utils/ImagePicker";
import EditProfileModal from "../Modals/EditProfileModal/EditProfileModal";
import CaptionBanner from "../CaptionBanner/CaptionBanner";
import {ImageOrLibraryDialog} from "../../components/Modals/ImageOrLibraryDialog/ImageOrLibraryDialog";

export const Profile = (props) => {
    const {breakpoint, route} = props;
    const id = route?.params?.id;
    const {userId: stateUserId, expoToken, token} = store.getState()?.auth;
    const userId = id ?? stateUserId;
    const isOwnProfile = userId === stateUserId;
    const threadActivityLastXDays = 90;
    const theme = useSelector(state => state.theme);
    const [personalModalVisible, setPersonalModalVisible] = useState(false);
    const [isImageMenuVisible, setIsImageMenuVisible] = useState(false);

    const {
        data: threadActivityData,
        refetch: refetchThreadActivity,
        error: threadActivityDataError,
        isLoading: threadActivityDataLoading
    } = endpoints.getThreadActivity.useQuery(threadActivityLastXDays);
    const {
        data: userObj,
        refetch: refetchData,
        error: userObjError,
        isLoading: userObjLoading
    } = endpoints.getUserObj.useQuery({userId}, {skip: !userId});
    const {
        data: calendarRegistrations,
        refetch: refetchCalendarRegistrations,
        error: calendarRegistrationsError,
        isLoading: calendarRegistrationsLoading
    } = endpoints.getCalendarRegistrationsForUser.useQuery(userId, {skip: !userId});
    const {
        data: userImage,
        refetch: refetchUserImage,
        error: userImageError,
        isLoading: userImageLoading
    } = endpoints.getImage.useQuery({imageId: userObj?.picture?.id}, {skip: !userId || !userObj?.picture?.id});
    const [uploadImage, {isLoading, error}] = useUploadImageMutation();
    const [setUserImage] = useSetUserImageMutation();
    const [logout, {logoutLoading}] = useLogoutMutation();
    const [deleteImage, {}] = useDeleteImageMutation();
    const [updateProfile, {
        isError,
        isLoading: profileMutationLoading,
        isSuccess,
        isUninitialized,
        originalArgs,
        reset,
        status
    }] = useUpdateProfileMutation();

    /**
     * our asynchronous update profile function after which we refetch data
     * @param data
     * @returns {Promise<void>}
     */
    const customUpdateProfile = async (data) => {
        updateProfile(data).then((res) => {
            refetchData();
        });
    }

    /**
     * when user object changes - we update the profile modal
     * @type {unknown}
     */
    const userEditPopup = useMemo(() => {
        return <EditProfileModal
            userObj={userObj}
            visible={personalModalVisible}
            updateProfile={customUpdateProfile}
            setVisible={setPersonalModalVisible}/>
    }, [userObj, personalModalVisible]);

    const userImagePreviewURL = userImage?.formats?.find(item => item.name === "default_preview")?.url;
    // useEffect(() => {
    //     props.onRefresh.current = [
    //         refetchData,
    //         refetchThreadActivity,
    //         refetchUserImage,
    //         refetchCalendarRegistrations
    //     ]
    // }, []);

    /**
     * Uploads profile image
     * @param res
     * @param token
     */
    const processSelectedImage = (token) => (res) => {
        uploadImage({formData: res, token}).unwrap().then((res) => {
            //console.log("Uploaded image : " , res);
            setUserImage({imageId: res.id, userId: userId}).unwrap().then((res) => {
                renderToast();
                setIsImageMenuVisible(false);
            }).catch(e => console.error("setUserImage : ", e));
        }).catch(e => console.error("uploadImage: ", e));
    };


    if (userObjLoading || userImageLoading) return <LoadingIndicator/>
    // console.log({calendarRegistrations,calendarRegistrationsError})
    // console.log({userObj})

    const bp_col = breakpoint < 2;

    const overwriteStyles = StyleSheet.create({
        profileContainer: {
            backgroundColor: theme.colors.background_light,
            borderRadius: theme.borderRadius,
        },
        container: {
            backgroundColor: theme.colors.primary,
        },
        personalContainer: {
            borderLeftColor: theme.colors.border
        },
        personalTitle: {
            ...theme.typo.p_md_bold,
            color: theme.colors.text_dark,
        },
        userNameText: {
            ...theme.typo.p_md_bold,
            color: theme.colors.text_light,
        },
        propertyKey: {
            ...theme.typo.p_sm_bold,
            color: theme.colors.text_light
        },
        propertyValue: {
            ...theme.typo.p_sm_light,
            color: theme.colors.text_light
        },
        personalPropertyGroup: {
            borderTopColor: theme.colors.border
        },
        personalPropertyKey: {
            ...theme.typo.p_sm_bold,
            color: theme.colors.text_dark
        },
        personalPropertyValue: {
            ...theme.typo.p_sm_light,
        },
        chapter: {
            borderColor: theme.colors.border,
        },
        chapterText: {
            color: theme.colors.text_dark,
        },
        notification: {
            backgroundColor: theme.colors.primary,
        },
        editButton: {
            backgroundColor: theme.colors.background_light,
        }
    });

    const PropertyGroup = (props) => {
        return <View style={[styles.propertyGroup, (bp_col && styles.propertyGroup_bpCol)]}>
            <Text
                style={[styles.propertyKey, (bp_col && styles.propertyKey_bpCol), overwriteStyles.propertyKey]}>{props.keyName}</Text>
            <Text
                style={[styles.propertyValue, overwriteStyles.propertyValue]}>{props.value || "nicht gesetzt"}</Text>
        </View>
    };

    const PersonalPropertyGroup = (props) => {
        return props.value
            ? <View
                style={[styles.personalPropertyGroup, (bp_col && styles.propertyGroup_bpCol), overwriteStyles.personalPropertyGroup, props.style]}>
                <Text style={[styles.propertyKey, overwriteStyles.personalPropertyKey]}>{props.keyName}</Text>
                <Text
                    style={[styles.propertyValue, overwriteStyles.personalPropertyValue]}>{props.value || "nicht gesetzt"}</Text>
            </View>
            : null
    };

    const ActivityItem = (props) => {
        const {onPress, icon, color, label} = props;
        return <TouchableOpacity
            style={[styles.chapter, overwriteStyles.chapter]}
            onPress={onPress}>
            <View style={styles.chapterInner}>
                <FontAwesome5
                    style={styles.chapterInnerIcon}
                    color={color ?? theme.colors.text_dark}
                    size={30}
                    name={icon}/>
                <Text
                    style={[styles.chapterText, overwriteStyles.chapterText, (color && {color: color})]}>{label}</Text>
            </View>
        </TouchableOpacity>
    }

    /**
     * Selects an image from a library
     * @returns {Promise<{canceled: boolean} | FormData>}
     */
    const changeImageAction = () => launchImageLibrary().then((res) => {
        if (res.canceled === true) return;
        //console.log("Uploading image : " , res);
        processSelectedImage(token)(res);
    }).catch((e) => {
        console.log('error');
        requestMediaLibraryPermissions().then(r => {
        })
    });

    const ChangeUserImageWrapper = (props) => {
        if (!isOwnProfile) return <>{props.children}</>
        // if we have image - we show dialog, if we don't - we select an image
        return <Pressable onPress={userImagePreviewURL ? () => setIsImageMenuVisible(true) : changeImageAction}>
            {props.children}
            <View style={[styles.editButton, overwriteStyles.editButton]}>
                <FontAwesome5
                    color={theme.colors.text_dark}
                    size={15}
                    name={"pen"}
                />
            </View>
        </Pressable>
    };

    const traits = ["date_of_birth", "city", "hobbies", "music", "movies", "quote", "about_me"];
    const hasTraits = Object.keys(userObj).findIndex(key => traits.includes(key)) !== -1;


    return <>
        {userEditPopup}
        <ImageOrLibraryDialog
            visible={isImageMenuVisible}
            setVisible={setIsImageMenuVisible}
            onMediaGalleryClicked={processSelectedImage(token)}
            onImageDeleted={() => {
                setUserImage({imageId: null, userId: userId}).unwrap().then((res) => {
                    deleteImage({imageId: userObj?.picture?.id}).unwrap().then(res => {
                        renderToast("Aktualisierung erfolgreich!")
                        setIsImageMenuVisible(false)
                    }).catch(error => {
                        setIsImageMenuVisible(false)
                        console.error(error)
                        renderToast("deleteImage Error", "error")
                    })
                }).catch(e => {
                    renderToast("setUserImage Error ", "error")
                    console.error(error)
                    setIsImageMenuVisible(false)
                })
            }}
        />
        <CaptionBanner
            config={{headline: isOwnProfile ? 'Dein Profil' : `Profil von ${userObj?.firstname} ${userObj?.lastname}`}}/>
        <View style={[styles.profileContainer, overwriteStyles.profileContainer]}>
            <View style={[styles.container, overwriteStyles.container]}>
                <View style={styles.userImageContainer}>
                    <ChangeUserImageWrapper>
                        {userImagePreviewURL
                            ? <Image uri={userImage?.formats?.find(item => item.name === "default_big")?.url}
                                     preview={{uri: userImagePreviewURL}}
                                     style={styles.userImage}/>
                            : getDefaultImage(userObj.gender, styles.userImage)}
                    </ChangeUserImageWrapper>
                </View>
                {/* user data */}
                <View style={{flexGrow: 1}}>
                    <Text style={[styles.userNameText, overwriteStyles.userNameText]}>
                        {`${userObj?.firstname} ${userObj?.lastname}`}</Text>
                    <PropertyGroup keyName={'Standort'} value={userObj?.parameters?.location?.name}/>
                    <PropertyGroup keyName={'Funktion'} value={userObj?.job}/>
                    <PropertyGroup keyName={'Bereich'} value={userObj?.area_of_work}/>
                    <PropertyGroup keyName={'Im Unternehmen'}
                                   value={userObj?.date_of_employment ? global.moment(userObj?.date_of_employment).format('DD. MMMM YYYY') : null}/>
                </View>
            </View>
            <View style={[styles.personalContainer, overwriteStyles.personalContainer]}>
                {/* todo (persnliche angaben) */}
                <View style={styles.personalHeader}>
                    <Text style={[styles.personalTitle, overwriteStyles.personalTitle]}>Persönliche Angaben</Text>
                    {isOwnProfile ? <Pressable onPress={() => setPersonalModalVisible(true)}>
                        <FontAwesome5
                            color={theme.colors.text_dark}
                            size={15}
                            name={"pen"}
                        />
                    </Pressable> : null}
                </View>
                {/*{console.log(userObj, global.moment(userObj?.date_of_birth).format('DD. MMMM YYYY'))}*/}
                <PersonalPropertyGroup
                    keyName={'Geburtstag'}
                    value={userObj?.date_of_birth ? global.moment(userObj?.date_of_birth).format('DD. MMMM YYYY') : null}
                    style={styles.personalPropertyGroup_first}/>
                <PersonalPropertyGroup keyName={'Wohnort'} value={userObj?.city}/>
                <PersonalPropertyGroup keyName={'Interessen'} value={userObj?.hobbies}/>
                <PersonalPropertyGroup keyName={'Musik'} value={userObj?.music}/>
                <PersonalPropertyGroup keyName={'Filme'} value={userObj?.movies}/>
                <PersonalPropertyGroup keyName={'Zitate'} value={userObj?.quote}/>
                <PersonalPropertyGroup keyName={'Über mich'} value={userObj?.about_me}/>
                {!hasTraits && <Text>Keine persönlichen Angaben verfügbar.</Text>}

            </View>
        </View>

        {isOwnProfile ? <Card title={"Deine Aktivitäten"} style={{marginTop: 64}}>
            <ActivityItem
                icon={'comments'}
                label={'Deine Forumaktivitäten'}
                onPress={() => props.navigation.navigate('Forumaktivität', {days: threadActivityLastXDays})}/>
            <ActivityItem
                icon={'comments'}
                label={'Deine Anmeldungen'}
                onPress={() => props.navigation.navigate('Anmeldungen', {
                    ...props, calendarRegistrations, userId
                })}/>
            <ActivityItem
                icon={'sign-out-alt'}
                label={'Ausloggen'}
                color={theme.colors.error}
                onPress={() => logout({token: expoToken}).unwrap()
                    .then((res) => console.log("Logout response ", res))
                    .catch((err) => console.error("got error: ", err))}/>
        </Card> : null}
    </>
};

const styles = StyleSheet.create({
    profileContainer: {
        backgroundColor: '#fff',
        shadowColor: '#000',
        shadowOffset: {
            width: 0,
            height: 3,
        },
        shadowOpacity: .1,
        shadowRadius: 6,
        elevation: 1,
    },
    personalContainer: {
        margin: 32,
        marginLeft: 32 + 150 - 1,
        paddingLeft: 32,
        borderLeftWidth: 1,
    },
    personalHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between'
    },
    personalTitle: {
        marginBottom: 10
    },
    container: {
        borderRadius: 20,
        flexDirection: "row",
        padding: 32,
        width: "100%",
    },
    userImageContainer: {
        marginRight: 32
    },
    userImage: {
        height: 150,
        width: 150,
        borderRadius: 150,
        borderWidth: 2,
        borderColor: '#ffffff'
    },
    commentFunctionContainer: {
        flexDirection: "row",
        alignItems: "center",
        paddingTop: 16,
    },
    commentFunctionIcon: {
        marginLeft: 32,
        marginRight: 8,
        // cursor:"pointer"
    },
    userNameText: {
        marginBottom: 10
    },
    propertyGroup: {
        flexDirection: "row",
        paddingTop: 10,
    },
    propertyGroup_bpCol: {
        flexDirection: "column",
    },
    personalPropertyGroup: {
        flexDirection: "row",
        paddingTop: 7,
        paddingBottom: 7,
        borderTopWidth: 1,
    },
    personalPropertyGroup_first: {
        borderTopWidth: 0,
    },
    propertyKey: {
        marginRight: 8,
        width: 140,
        flexWrap: 'wrap',
    },
    propertyKey_bpCol: {
        width: 'unset',
        marginRight: 0,
        flex: 1,
    },
    propertyValue: {
        flex: 1,
        flexWrap: 'wrap',
        flexShrink: 1,
    },
    chapter: {
        borderTopWidth: 1,
        borderBottomWidth: 1,
        justifyContent: "space-between",
        alignItems: "center",
        height: 70,
        flexDirection: "row"
    },
    chapterInner: {
        flexDirection: "row",
        marginLeft: 32,
        alignItems: "center"
    },
    chapterInnerIcon: {
        marginRight: 8,
    },
    chapterText: {
        fontFamily: "OpenSans_400Regular",
        fontSize: 16,
        paddingLeft: 10,
    },
    notification: {
        height: 24,
        width: 48,
        borderRadius: 10,
        justifyContent: "center",
        alignItems: "center",
        marginRight: 32
    },
    editButton: {
        height: 30,
        width: 30,
        borderRadius: 45,
        position: "absolute",
        top: 8,
        right: 8,
        flex: 1,
        justifyContent: "center",
        alignItems: "center"
    }
});
