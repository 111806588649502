import React from 'react';
import {Image, StyleSheet, useWindowDimensions, View} from 'react-native';
import RenderHtml from 'react-native-render-html';
import CaptionBanner from "../CaptionBanner/CaptionBanner";
import {SystemFontKeys, SystemFonts} from "../../constants/SystemFonts";
// import {Image} from "react-native-expo-image-cache";
import {useSelector} from "react-redux";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import {parseHTMLIntoSeperatedArray} from "../../utils/strings";
import DynamicFormWrapper from "../Form/DynamicFormWrapper";
import BackNavigationBanner from "../BackNavigationBanner/BackNavigationBanner";
import useTitle from "../../utils/hooks/useTitle";

// only works for benefits.
// content that is not returned from getBenefit({id}) will not show properly
export const BlogPage = (props) => {
    const {config, route, navigation} = props;

    const {params} = route;
    const {data, refetch, error, isLoading} = endpoints.getBenefit.useQuery({id: params.id});
    const {width} = useWindowDimensions();
    const theme = useSelector(state => state.theme);
    const htmlStyles = React.useMemo(
        () => ({
            ...theme.typo.p_md_light,
            ...theme.typo.web,
            color: theme.colors.text_dark,
            userSelect: "auto"
        }),
        [theme],
    );
    useTitle(navigation, isLoading, data?.title ?? params?.title);

    const resultArr = data?.description ? parseHTMLIntoSeperatedArray(data?.description) : [];
    return <View>
        <BackNavigationBanner
            {...props}
            onPress={() => props.navigation.navigate('Benefits')}
            label={"Benefits"}
            iconBefore={"chevron-left"}
        />
        <CaptionBanner config={{
            headline: data?.title ?? params?.title
        }}/>
        {isLoading
            ? <LoadingIndicator/>
            : <>
                {(data?.picture_data?.reference?.url) ? <Image
                    source={data?.picture_data?.reference?.url}
                    style={styles.newsImage}/> : <Image resizeMode={"cover"} style={styles.newsImage}
                                                             source={require("../../../assets/images/placeholder-web.jpg")}/>
                }
                <View style={styles.articleContainer}>
                    {
                        resultArr.map((item,index)=>{
                            if(typeof item === "object" && item.type === "form" && item.id){
                                return <DynamicFormWrapper key={index} id={ item.id}/>
                            }
                            else if(typeof item === "string" && item !== "" ) {
                                return <RenderHtml
                                    key={index}
                                    tagsStyles={{
                                        a: {
                                            color: theme.colors.primary,
                                            textDecoration:"underline",
                                            textDecorationColor:theme.colors.primary
                                        }
                                    }}
                                    contentWidth={width}
                                    baseStyle={htmlStyles}
                                    source={{
                                        html: item
                                    }}
                                    defaultTextProps={{selectable: true}}
                                    systemFonts={SystemFontKeys}
                                />
                            }
                        })
                    }
                </View>
            </>}
    </View>;
}
const styles = StyleSheet.create({
    articleContainer: {},
    newsImage: {
        borderRadius: 30,
        marginBottom: 32,
        height: 480,
        resizeMode: "cover",
    },
});
