import Toast, {BaseToast} from "react-native-toast-message";
import React from "react";
import {FontAwesome5} from "@expo/vector-icons";


export const toastConfig = {
    /*
      Overwrite 'success' type,
      by modifying the existing `BaseToast` component
    */
    success: (props) => (
        <BaseToast
            {...props}
            touchableContainerProps={{
                position: -10,
                style: {
                    paddingVertical: 16,
                    paddingHorizontal: 20,
                    flexDirection:"row",
                    alignItems:"center",
                    backgroundColor: "#EFFFE3",
                    borderRadius: 5,
                    justifyContent:"center",
                    shadowColor: "#000000",
                    shadowOffset: {
                        width: 0,
                        height: 11,
                    },
                    shadowOpacity:  0.23,
                    shadowRadius: 11.78,
                    elevation: 15
                }
            }}
            contentContainerProps={{
                style: {
                    backgroundColor: "#EFFFE3"
                }
            }}
            renderLeadingIcon={()=><FontAwesome5 size={18} style={{marginRight:10,color:"#039711"}} name={"check"}/>}
            text1Style={{
                fontSize: 14,
                fontFamily: "OpenSans_700Bold",
                color:"#039711"
            }}
        />
    ),
    error: (props) => (
        <BaseToast
            {...props}
            touchableContainerProps={{
                position: -10,
                style: {
                    paddingVertical: 16,
                    paddingHorizontal: 20,
                    flexDirection:"row",
                    alignItems:"center",
                    backgroundColor: "#FFE9E9",
                    borderRadius: 5,
                    justifyContent:"center",
                    shadowColor: "#000000",
                    shadowOffset: {
                        width: 0,
                        height: 12,
                    },
                    shadowOpacity:  0.23,
                    shadowRadius: 12.81,
                    elevation: 16
                }
            }}
            contentContainerProps={{
                style: {
                    backgroundColor: "#FFE9E9"
                }
            }}
            renderLeadingIcon={()=><FontAwesome5 size={18} style={{marginRight:10,color:"#B10000"}} name={"times"}/>}
            text1Style={{
                fontSize: 14,
                fontFamily: "OpenSans_700Bold",
                color:"#B10000"
            }}
        />
    ),
    /*
      Overwrite 'error' type,
      by modifying the existing `ErrorToast` component
    */
    /*
      Or create a completely new type - `tomatoToast`,
      building the layout from scratch.

      I can consume any custom `props` I want.
      They will be passed when calling the `show` method (see below)
    */
    // tomatoToast: ({ text1, props }) => (
    //     <View style={{ height: 60, width: '100%', backgroundColor: 'tomato' }}>
    //         <Text>{text1}</Text>
    //         <Text>{props.uuid}</Text>
    //     </View>
    // )
};

export function renderToast(message = 'Aktualisierung erfolgreich!', type = "success") {
    if (type === "error") Toast.show({
        type: 'error',
        text1: message,
        visibilityTime: 3000
    });
    else {
        Toast.show({
            type: 'success',
            text1: message,
            visibilityTime: 3000
        });
    }
}

export const renderToastFromQueryResult = (result, successMsg = 'Aktualisierung erfolgreich!', errorMsg = 'Ein Fehler ist aufgetreten.') => {
    if ('error' in result) {
        Toast.show({
            type: 'error',
            text1: errorMsg,
            visibilityTime: 3000
        });
    } else {
        Toast.show({
            type: 'success',
            text1: successMsg,
            visibilityTime: 3000
        });
    }
}
