import {configureStore} from '@reduxjs/toolkit'
import {globalAdminApi, injectStore} from './services/api/globalAdminAPI'
import authReducer from './services/state/auth'
import configReducer, {injectStore as injectStoreConfig} from './services/state/nativeViewConfiguration';
import themeReducer from './services/state/themeConfiguration';
import cacheReducer from "./services/state/cache";
import notificationReducer from "./services/state/notifications";
import webConfigReducer from "./services/state/webViewConfiguration";
import {injectStore as InjectStoreForHelper} from './services/api/queryHelpers';
// persist
import AsyncStorage from '@react-native-async-storage/async-storage';
import {FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE,} from 'redux-persist'
import {isDevelopment} from "../constants/booleanValues";

const persistConfig = {
    key: 'root',
    storage: AsyncStorage // Had this error on native, so exchanged  https://stackoverflow.com/questions/57781527/how-to-solve-console-error-redux-persist-failed-to-create-sync-storage-falli
};
const persistedAuthReducer = persistReducer(persistConfig, authReducer);

export const store = configureStore({
    reducer: {
        [globalAdminApi.reducerPath]: globalAdminApi.reducer,
        auth: persistedAuthReducer,
        cache: cacheReducer,
        config: configReducer,
        webConfig: webConfigReducer,
        notifications: notificationReducer,
        theme: themeReducer
    },
    devTools: process.env.NODE_ENV !== 'production',
    // middleware: [thunk],
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            ...(isDevelopment
                //to get less warnings while developing:
                //https://stackoverflow.com/questions/65217815/redux-handling-really-large-state-object
                ? {
                    immutableCheck: false,
                    serializableCheck: false,
                }
                : {
                    serializableCheck: {
                        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                    },
                }),
        })
            .concat(globalAdminApi.middleware),
})
injectStore(store);
injectStoreConfig(store);
InjectStoreForHelper(store);
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

