import {useSelector} from "react-redux";
import {StyleSheet, Text, View} from "react-native";
import {DEFAULT_ERROR, ERROR_TEXT_USER_OFFLINE, FETCH_ERROR} from "../../constants/strings";

export default function ValidationErrorText(props) {
    const {
        error,
        type,
    } = props;
    let errorText = "";
    const theme = useSelector(root => root.theme);
    const style = StyleSheet.create({
        container: {
            // padding: theme.wrapperPadding
        },
        text: {
            ...theme.text.paragraph,
            color: theme.colors.error
        }
    });
    if(error?.type === "pattern"){
        console.log("output email error",error)
        if(error.message) errorText = error.message
        else errorText = "Invalides Feld"
    } else if(error?.type === "required"){
        if(error.message) errorText = error.message
        else errorText = "Dieses Feld wird benötigt"
    }

    return <View style={style.container}>
        <Text style={style.text}>
            {
                errorText
            }
        </Text>
    </View>
}
