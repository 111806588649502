import {Linking, Pressable, StyleSheet, Text, View} from 'react-native';
import React from "react";
import {useSelector} from "react-redux";
import Caption from "../Caption/Caption";
import useGrid from "../../utils/useGrid";
import WebIcon from "../Icon/WebIcon";
import {endpoints} from "../../app/services/api/globalAdminAPI";

const styles = StyleSheet.create({
    groupContainer: {
        borderTopWidth: 1,
        marginTop: 64,
        paddingTop: 40,
    },
    groupContainer_first: {
        borderTopWidth: 0,
        marginTop: 0,
        paddingTop: 0,
    },
    group: {
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    itemContainer: {
        marginLeft: 24,
        marginTop: 24,
    },
    itemContainer_firstCol: {
        marginLeft: 0,
    },
    itemContainer_firstRow: {
        marginTop: 0
    },
    item: {
        borderRadius: 10,
        borderWidth: 1,
        width: 220,
        height: 120,
        padding: 24,
        justifyContent: 'space-between',
        alignItems: 'stretch',
        shadowColor: "#000",
        shadowOffset: {
            width: 2,
            height: 2
        },
        shadowOpacity: 0.16,
        shadowRadius: 6,
        elevation: 5
    },
    itemIconContainer: {},
    itemIcon: {
        width: 100,
        height: 100,
        resizeMode: 'contain'
    },
    itemLabel: {
        fontFamily: 'BebasNeue_400Regular',
        fontSize: 25,
        marginTop: 'auto'
    }
});

function TileLinks(props) {
    let {config, navigation} = props;
    const theme = useSelector(state => state.theme);
    const {ref, tiles, tileDim} = useGrid(202, 24);
    let endpointModeEnabled = !!config?.options.endpointName;
    const endPointArgs = config?.options?.endpointName ? endpoints[config?.options.endpointName].useQuery() : null;
    const dataSource = endpointModeEnabled ? [{
        group: "Quick Links", icon: "external-link-alt", //TODO: export to config
        items: endPointArgs.data?.entries
    }] : config?.options?.links;
    const overwriteStyles = StyleSheet.create({
        groupContainer: {
            borderColor: theme.colors.border
        },
        item: {
            borderColor: theme.colors.text,
            width: tileDim,
            height: config?.options?.useSquares ? tileDim : 120,
            ...config?.options?.styles?.linkContainer
        },
        itemLabel: {
            fontFamily: 'BebasNeue_400Regular',
            fontSize: 25,
            color: theme.colors.text,
            ...config?.options?.styles?.linkText
        }
    });
    return <View ref={ref}>
        {dataSource?.map((groupItem, idx) => (
            <View key={idx}
                  style={[styles.groupContainer, overwriteStyles.groupContainer, (idx === 0 && styles.groupContainer_first)]}>
                {/*<Text style={theme.typo.sectionHeadline_2}>{groupItem?.group}</Text>*/}
                {
                    <Caption config={{
                        options: {
                            text: {
                                value: groupItem?.group,
                                style: {},
                            },
                            icon: {
                                name: groupItem?.icon,
                            }
                        }
                    }}/>
                }
                <View style={styles.group}>
                    {groupItem?.items?.map((item, idx) => (
                        <View
                            key={idx}
                            style={[styles.itemContainer,
                                (idx < tiles && styles.itemContainer_firstRow),
                                ((idx % tiles) === 0 && styles.itemContainer_firstCol)]}>
                            <Pressable onPress={(item.type === 'external'
                                ? async () => {
                                    const supported = await Linking.canOpenURL(item.route);
                                    if (supported) await Linking.openURL(item.route);
                                    else console.error("not supported link", item.route)
                                }
                                : () => navigation.navigate(item.route, {id: item.id, title: item.title}))}>
                                <View key={idx} style={[styles.item, overwriteStyles.item]}>
                                    {item.icon ? <View style={styles.itemIconContainer}>
                                        <WebIcon
                                            icon={item.icon}
                                            color={theme.colors.text_light}
                                            size={((tileDim - 48 - 32) * .7)}
                                            style={{textAlign: 'center'}}/>
                                    </View> : null}
                                    <Text style={[styles.itemLabel, overwriteStyles.itemLabel]}>{item.label}</Text>
                                </View>
                            </Pressable>
                        </View>
                    ))}
                </View>

            </View>
        ))}
    </View>;
}

export default TileLinks


