import React, {useEffect, useState} from "react";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import {useSelector} from "react-redux";
import {CONTENT_TYPE} from "../../constants/strings";

const useCanteenMenu = (props) => {
    const {renderDays = 7} = props;
    const [dishesByDate, setDishesByDate] = useState([]);
    const {data, error, isLoading, refetch} = endpoints.getMealPlan.useQuery();

    const {cache} = useSelector(state => ({cache: state.cache}));
    const cachedMenu = cache?.cacheTags.find(item => item.type === CONTENT_TYPE.MENU_PREVIEW);

    /**
     * after navigating home, the cache is reset, so we refetch
     */
    useEffect(() => {
        if (!isLoading && !cachedMenu) {
            // isDevelopment && console.log("BirthDays useEffect", {data, error, isLoading}, cachedBirthdays);
            refetch();
        }
    }, [cachedMenu]);

    useEffect(() => {
        const getDishesByDate = () => {
            let days = [];
            for (let i = 0; i < renderDays; i++) {
                const date = global.moment(new Date()).add(i, 'days');
                const formattedDate = date.format('dddd, DD.MM.YYYY');
                const dishes = data?.filter(dish => dish.date.includes(date.format('YYYY-MM-DD'))).sort((a, b) => {
                    return parseFloat(a.employee_price.replace(',', '.')) - parseFloat(b.employee_price.replace(',', '.'));
                });
                if (dishes?.length > 0) days.push({date: formattedDate, dishes: dishes});
            }
            return days;
        }
        setDishesByDate(getDishesByDate());
    }, [data, renderDays]);

    return {dishesByDate, isLoading};
};

export default useCanteenMenu;
