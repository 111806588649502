import {BebasNeue_400Regular} from "@expo-google-fonts/bebas-neue";
import {
    OpenSans_300Light,
    OpenSans_300Light_Italic,
    OpenSans_400Regular,
    OpenSans_400Regular_Italic,
    OpenSans_600SemiBold,
    OpenSans_700Bold
} from "@expo-google-fonts/open-sans";

export const SystemFonts = {
    BebasNeue_400Regular,
    OpenSans_400Regular,
    OpenSans_400Regular_Italic,
    OpenSans_600SemiBold,
    OpenSans_700Bold,
    OpenSans_300Light,
    OpenSans_300Light_Italic,
    fontello_custom: require("../../assets/icons/custom_icons/font/fontello.ttf")
}
export const SystemFontKeys =  Object.keys(SystemFonts)
