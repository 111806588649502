import {Image, StyleSheet, Text, View} from "react-native";
import CaptionBanner from "../CaptionBanner/CaptionBanner";
import {useSelector} from "react-redux";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import React from "react";
import useCanteenMenu from "../../utils/hooks/useCanteenMenu";

export default function CanteenMenu(props) {
    const {dishesByDate, isLoading} = useCanteenMenu({renderDays: 7});

    const theme = useSelector(state => state.theme);
    const overwriteStyles = StyleSheet.create({
        dateText: {
            ...theme.typo.p_md_bold,
            color: theme.colors.secondary
        },
        dishContainer: {
            borderBottomColor: theme.colors.border
        },
        dishText: {
            ...theme.typo.p_sm,
            color: theme.colors.text_dark
        },
        dishPrice: {
            ...theme.typo.p_sm_bold,
            color: theme.colors.secondary
        }
    });

    return <View style={styles.container}>
        <CaptionBanner config={{headline: 'Speiseplan für Osnabrück'}}/>
        {isLoading
            ? <LoadingIndicator/>
            : dishesByDate.map((item, idx) => (
                <View key={idx} style={[styles.dateContainer, (idx === 0 && styles.dateContainer_first)]}>
                    <Text style={[styles.dateText, overwriteStyles.dateText]}>{item.date}</Text>
                    {item.dishes.map((dish, idx) => (
                        <View key={idx} style={[styles.dishContainer, overwriteStyles.dishContainer]}>
                            <View style={styles.dishIconContainer}>
                                {(dish.vegetarian || dish.contains_pork) && <Image
                                    style={styles.dishIcon}
                                    source={dish.vegetarian
                                        ? require("../../../assets/images/vegi.png")
                                        : require("../../../assets/images/schwein.png")}/>}
                            </View>
                            <View style={styles.dishTextContainer}>
                                <Text style={[styles.dishText, overwriteStyles.dishText]}>
                                    {dish.title}</Text>
                                <Text style={[styles.dishText, overwriteStyles.dishText]}>
                                    {dish.side_dish}</Text>
                            </View>
                            <View style={styles.dishPriceContainer}>
                                <Text style={[styles.dishPrice, overwriteStyles.dishPrice]}>
                                    {dish.employee_price} €</Text>
                                <Text style={[styles.dishPrice, overwriteStyles.dishPrice]}>
                                    Gäste-Preis: {dish.guest_price} €</Text>
                            </View>
                        </View>
                    ))}
                </View>
            ))}
    </View>;
}

const styles = StyleSheet.create({
    container: {},
    dateContainer: {
        marginTop: 80
    },
    dateContainer_first: {
        marginTop: 0
    },
    dateText: {
        marginBottom: 16,
    },
    dishContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 24,
        borderBottomWidth: 1,
    },
    dishIconContainer: {
        width: 32,
        marginRight: 24,
    },
    dishIcon: {
        width: 32,
        height: 32,
        resizeMode: 'contain',
    },
    dishTextContainer: {
        flex: 1,
        alignItems: 'flex-start',
        marginRight: 24,
    },
    dishText: {},
    dishPriceContainer: {
        flexShrink: 0,
        alignItems: 'flex-end'
    },
    dishPrice: {}
});
