import {Image, Text, View} from "react-native";
import React from "react";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import Caption from "../Caption/Caption";
import {useSelector} from "react-redux";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import usePullToRefresh from "../../utils/usePullToRefresh";
import ErrorText from "../Error/ErrorText";

const RENDER_X_NEXT_DAYS = 7;
export default function MealPlan(props) {
    const {isPublic = false} = props;
    // console.log("MealPlan", skipAuth);
    const theme = useSelector(state => state.theme);
    const {
        data,
        error,
        isLoading,
        refetch
    } = endpoints?.[isPublic ? "getMealPlanPublic" : "getMealPlan"].useQuery();
    // const priceTag = isPublic ? "guest_price" : "employee_price";
    const priceTag = "employee_price";
    usePullToRefresh(props, refetch);
    if (isLoading) return <LoadingIndicator/>
    if (error) return <ErrorText error={error}/>
    const renderDay = (day) => {
        //if there is no data for this day, we skip displaying it
        const skipDay = data?.find(item => {
            if (item.date?.includes(day.format('YYYY-MM-DD'))) {
                return true;
            }
        })
        if (!skipDay) return <></>
        return <>
            <Caption config={{
                options: {
                    text: {
                        value: day.format("dddd, DD.MM.YYYY"),
                        style: {
                            fontSize: 32,
                            paddingLeft: theme.wrapperPadding,
                            paddingRight: theme.wrapperPadding,
                        },
                    },
                    icon: {
                        fontSize: 32
                    }
                }
            }}/>
            {
                data?.filter(item => {
                    if (item.date?.includes(day.format('YYYY-MM-DD'))) {
                        return true;
                    }
                    //kikxxl wants sorting for price
                })?.sort((a, b) => {
                    return parseFloat(a?.[priceTag].replace(",", ".")) - parseFloat(b?.[priceTag].replace(",", "."))
                })?.map((item, idx) => {
                    let icon = null;
                    if (item?.vegetarian === true) {
                        icon = <Image resizeMode={"contain"} style={{
                            height: 35,
                            width: 35,
                        }} source={require("../../../assets/images/vegi.png")}/>;
                    } else if (item?.contains_pork === true) {
                        icon = <Image resizeMode={"contain"} style={{
                            height: 35,
                            width: 35,
                        }} source={require("../../../assets/images/schwein.png")}/>;
                    }
                    return <View key={idx} style={{
                        paddingLeft: theme.wrapperPadding,
                        flexDirection: "row",
                        borderBottomWidth: 1,
                        borderBottomColor: theme.colors?.border,
                        paddingTop: 15,
                        paddingBottom: 15
                    }}>
                        <View style={{height: 35, width: 35}}>
                            {icon}
                        </View>
                        <View style={{
                            flexDirection: "row",
                            flexShrink: 1,
                            paddingLeft: 10,
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                            <Text style={{fontFamily: "OpenSans_400Regular", fontSize: 14, flexShrink: 1}}>
                                {`${item?.title} ${item?.side_dish === "-" ? "" : item?.side_dish}`}
                            </Text>
                        </View>
                        <Text style={{
                            fontFamily: "OpenSans_700Bold",
                            fontSize: 16,
                            marginLeft: "auto",
                            paddingRight: theme.wrapperPadding,
                            alignSelf: "center"
                        }}>
                            {`${item?.[priceTag]}€`}
                        </Text>
                    </View>
                })
            }
        </>
    }
    let daysArr = [];
    for (let i = 0; i < RENDER_X_NEXT_DAYS; i++) {
        if (i === 0) daysArr.push(renderDay(global.moment(new Date())))
        else daysArr.push(renderDay(global.moment(new Date()).add(i, 'days')))
    }
    return <View>
        {
            daysArr.map((item, index) => <View key={index}>{item}</View>)
        }
    </View>
}
