import {StyleSheet, Text} from "react-native";
import CaptionBanner from "../CaptionBanner/CaptionBanner";
import React, {useEffect, useState} from "react";
import {NOTIFICATION_TYPES} from "../Notifications/notificationTypes";
import BouncyCheckbox from "react-native-bouncy-checkbox";
import Button from "../Button/Button";
import {useSelector} from "react-redux";
import {endpoints, useUpdateWebSettingsMutation} from "../../app/services/api/globalAdminAPI";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import {renderToastFromQueryResult} from "../../utils/renderToast";
import {isDevelopment} from "../../constants/booleanValues";

export default function Settings(props) {
    const theme = useSelector(state => state.theme);
    const auth = useSelector(state => state.auth);
    const [formData, setFormData] = useState({
        expoNotifications: [],
        privacy: {
            birthdayPublic: undefined,
            dateOfEmploymentPublic: undefined
        }
    });
    const {
        refetch,
        data,
        error,
        isLoading,
        isFetching,
        status: getUserStatus
    } = endpoints.getUserObj.useQuery({userId: auth?.userId});
    const [updateWebSettings, {
        isError,
        error: updateError,
        isLoading: profileMutationLoading,
        isSuccess,
        isUninitialized,
        originalArgs,
        reset,
        status
    }] = useUpdateWebSettingsMutation();
    useEffect(() => {
        //initialize formData with data from server
        if (getUserStatus === "fulfilled") {
            setFormData({
                ...formData,
                expoNotifications: data?.expo_notifications,
                privacy: {
                    dateOfEmploymentPublic: data?.date_of_employment_public,
                    birthdayPublic: data?.birthday_public
                }
            })
        }
    }, [getUserStatus]);
    const havePrivacySettingsChanged = () => {
        return formData.privacy.dateOfEmploymentPublic !== data?.date_of_employment_public ||
            formData.privacy.birthdayPublic !== data?.birthday_public;
    };
    const haveNotificationSettingsChanged = JSON.stringify(data?.expo_notifications?.slice().sort()) !== JSON.stringify(formData?.expoNotifications?.slice().sort());
    const filteredNotifications = NOTIFICATION_TYPES.filter(item => item.isConfigurable === true);
    const configurableNotifcations = filteredNotifications.map((item, idx) => {
        return <BouncyCheckbox
            key={idx}
            style={[styles.checkbox, ...(filteredNotifications.length - 1 === idx ? [styles.checkboxLast] : [])]}
            size={25}
            isChecked={formData?.expoNotifications?.includes(item.type)}
            fillColor={theme.colors?.primary}
            unfillColor="transparent"
            text={item.label}
            disableBuiltInState
            iconStyle={{borderColor: theme.colors?.primary}}
            innerIconStyle={{borderWidth: 2}}
            textContainerStyle={{textDecoration: "none"}}
            textStyle={{fontFamily: "OpenSans_400Regular", textDecorationLine: "none"}}
            onPress={() => {
                //isChecked: set target to isChecked
                const formDataStateBeforeUpdate = formData?.expoNotifications?.includes(item.type);
                let notificationArr = [];
                if (formData?.expoNotifications) notificationArr = [...formData.expoNotifications];
                if (formDataStateBeforeUpdate === true) {
                    notificationArr = notificationArr.filter(el => {
                        return el !== item.type
                    })
                } else {
                    notificationArr.push(item.type)
                }
                setFormData({
                    ...formData,
                    expoNotifications: notificationArr
                })
            }}
        />;
    });
    if (isLoading) return <LoadingIndicator/>
    const privacySettings = () => {
        return <>
            <Text style={[styles.headerText, theme.text?.caption]}>Privatsphäre</Text>
            <BouncyCheckbox
                style={styles.checkbox}
                size={25}
                isChecked={formData.privacy.dateOfEmploymentPublic}
                disableBuiltInState={true}
                fillColor={theme.colors?.primary}
                unfillColor="transparent"
                text={"Eigenes Jubiläum teilen"}
                iconStyle={{borderColor: theme.colors?.primary}}
                innerIconStyle={{borderWidth: 2}}
                textContainerStyle={{textDecoration: "none"}}
                textStyle={{fontFamily: "OpenSans_400Regular", textDecorationLine: "none"}}
                onPress={() => {
                    setFormData({
                        ...formData,
                        privacy: {
                            ...formData.privacy,
                            dateOfEmploymentPublic: !formData?.privacy.dateOfEmploymentPublic
                        }
                    })
                }}
            />
            <BouncyCheckbox
                style={styles.checkbox}
                size={25}
                isChecked={formData.privacy.birthdayPublic}
                disableBuiltInState={true}
                fillColor={theme.colors?.primary}
                unfillColor="transparent"
                text={"Eigenen Geburtstag teilen"}
                iconStyle={{borderColor: theme.colors?.primary}}
                innerIconStyle={{borderWidth: 2}}
                textContainerStyle={{textDecoration: "none"}}
                textStyle={{fontFamily: "OpenSans_400Regular", textDecorationLine: "none"}}
                onPress={() => {
                    setFormData({
                        ...formData,
                        privacy: {
                            ...formData.privacy,
                            birthdayPublic: !formData?.privacy.birthdayPublic
                        }
                    })
                }}
            />
        </>
    }
    return <>
        <CaptionBanner config={{
            headline: 'Einstellungen'
        }}/>
        <Text style={[styles.headerText, theme.text?.caption]}>Benachrichtigungen</Text>
        {
            configurableNotifcations
        }
        {
            privacySettings()
        }
        <Button
            disabled={!haveNotificationSettingsChanged && !havePrivacySettingsChanged()}
            text={"Einstellungen speichern"}
            style={{button: {borderRadius: 100, maxWidth: 400}}}
            onClick={() => {
                if (havePrivacySettingsChanged() || haveNotificationSettingsChanged) {
                    isDevelopment && console.log("updateWebSettings", {
                        ...formData.privacy,
                        expoNotifications: formData.expoNotifications
                    })
                    updateWebSettings({
                        userId: auth?.userId,
                        formData: {...formData.privacy, expoNotifications: formData.expoNotifications}
                    }).then(res => {
                        renderToastFromQueryResult(res, "Aktualisierung erfolgreich", "Ein Fehler ist aufgetreten.")
                        // isDevelopment && console.log("got respoonse: ", res)
                        setFormData({
                            ...formData,
                            privacy: {
                                dateOfEmploymentPublic: res?.data?.date_of_employment_public,
                                birthdayPublic: res?.data?.birthday_public
                            },
                            expoNotifications: res?.data?.expo_notifications
                        })
                    }).catch(error => console.error("updateWebSettings", error))
                }
            }}
        />
    </>
}
const styles = StyleSheet.create({
    headerText: {
        marginBottom: 20
    },
    checkbox: {
        paddingBottom: 10
    },
    checkboxLast: {
        marginBottom: 20
    }
})
