import {FlatList, Pressable, StyleSheet, Text, useWindowDimensions, View} from 'react-native';
import {Calendar as _Calendar} from 'react-native-calendars';
import CalendarNavigation from "../Calendar/CalendarNavigation";
import CalendarEventModal from "../Modals/CalendarEventModal/CalendarEventModal";
import {endpoints} from "../../app/services/api/globalAdminAPI";
import LoadingIndicator from "../LoadingIndicator/LoadingIndicator";
import {store} from "../../app/store";
import React, {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import moment from "moment";
import CaptionBanner from "../CaptionBanner/CaptionBanner";
import {calendarMonths, shortWeekDays, weekDays} from "./constants";
import {prepareCalendarDataSource, renderEventListItems} from "./calendarUtils";
import {
    NOTIFICATION_TYPE_CALENDAR_EVENT_CHANGED,
    NOTIFICATION_TYPE_CALENDAR_EVENT_NEW
} from "../../components/Notifications/notificationTypes";

// has to support markingType period; does not have to support markingType multi-period (mtebbe)
export default function Calendar(props) {
    const {config, route, breakpoint} = props;
    const theme = useSelector(state => state.theme);
    const {refetch, data, error, isLoading} = endpoints.getCalendarEvents.useQuery();
    const username = store.getState()?.auth?.username;
    const [visibleEvent, setVisibleEvent] = useState(null);
    const [currentMonth, setCurrentMonth] = useState(global.moment(new Date()).format('YYYY-MM-DD'));
    useEffect(() => {
        if ([NOTIFICATION_TYPE_CALENDAR_EVENT_NEW.toString(), NOTIFICATION_TYPE_CALENDAR_EVENT_CHANGED.toString()].includes(route?.params?.notificationType.toString())) {
            setVisibleEvent(route?.params?.id.toString())
            setCurrentMonth(global.moment(route?.params?.begin).format('YYYY-MM-DD'))
            props.navigation.setParams({id: route?.params?.id, event: undefined});
            global.scrollRef?.current?.scrollTo({x: 0, y: 0, animated: false});
        // }else{
        //     console.log("Calendar else", route?.params)
        }
    }, [JSON.stringify(route?.params)]);

    // responsive dayContainer-height
    const {width, height} = useWindowDimensions();
    const dayHeight = Math.max(80, (height - 80 - 80 - 160 - 100 - 130) / 6);

    if (isLoading) return <LoadingIndicator/>;

    //list dataSources
    const onlyNewEvents = data.entries.filter(event => new Date(event.begin) > new Date())
    const myEvents = [], notMyEvents = []
    onlyNewEvents.forEach(item => {
        if (item.participants.find(user => user.username === username))
            myEvents.push(item)
        else
            notMyEvents.push(item)
    })

    // console.log("Calendar", route?.params)


    let eventDates = prepareCalendarDataSource(data, username)

    const overwriteStyle = StyleSheet.create({
        weekContainer: {
            backgroundColor: theme.colors.primary,
            borderBottomColor: theme.colors.border,
            borderTopColor: theme.colors.border,
        },
        weekDayInner: {
            borderLeftColor: theme.colors.border
        },
        weekDayText: {
            color: theme.colors.text_light,
        },
        dayContainer: {
            borderBottomColor: theme.colors.border,
            borderLeftColor: theme.colors.border,
            borderRightColor: theme.colors.border,
            backgroundColor: theme.colors.background,
        },
        noDataText: {
            color: theme.colors.text_unimportant,
            marginBottom: 40,
        }
    });

    /**
     * @returns {JSX.Element} no events text
     */
    const getNoEventsText = () => {
        return <Text style={overwriteStyle.noDataText}>Zur Zeit keine Termine.</Text>
    }

    /**
     * Formats event lists
     * @returns {JSX.Element}
     */
    const displayEvents = (eventList) => {
        return eventList.length > 0
            ? <FlatList data={eventList}
                        keyExtractor={(item) => item.id}
                        renderItem={(e) => renderEventListItems(e, theme, props.navigation, null)}
            />
            : getNoEventsText()
    }

    return <>
        <CaptionBanner config={{headline: 'Termine'}}/>
        <_Calendar
            style={styles.calendar}
            // current={currentMonth}
            initialDate={currentMonth}
            theme={{weekVerticalMargin: 0}}
            hideExtraDays={false}
            enableSwipeMonths={true}
            hideArrows={false}
            firstDay={1}
            markingType={'period'}
            markedDates={eventDates}
            customHeader={(args) => (
                <View style={[styles.headerContainer]}>
                    <View style={styles.monthContainer}>
                        <Pressable
                            onPress={() => setCurrentMonth(global.moment(currentMonth).add(-1, "month").format('YYYY-MM-DD'))}
                            style={[styles.monthArrow, styles.monthArrow_left]}>
                            <FontAwesome5
                                color={theme.colors.primary}
                                size={30}
                                name={"chevron-left"}
                            />
                        </Pressable>
                        {args.renderHeader(args.month)}
                        <Pressable
                            onPress={() => setCurrentMonth(global.moment(currentMonth).add(1, "month").format('YYYY-MM-DD'))}
                            style={[styles.monthArrow, styles.monthArrow_right]}>
                            <FontAwesome5
                                color={theme.colors.primary}
                                size={30}
                                name={"chevron-right"}
                            />
                        </Pressable>
                    </View>
                    <View style={[styles.weekContainer, overwriteStyle.weekContainer]}>
                        {((breakpoint >= 3) ? weekDays : shortWeekDays).map((day, i) => (
                            <View key={i} style={styles.weekDayContainer}>
                                <View
                                    style={[styles.weekDayInner, overwriteStyle.weekDayInner, (i === 0 && styles.weekDayInnerFirst)]}>
                                    <Text style={[styles.weekDayText, overwriteStyle.weekDayText]}>{day}</Text>
                                </View>
                            </View>
                        ))}
                    </View>
                </View>
            )}
            dayComponent={({date, state, marking}) => {
                const day = moment(date.dateString).day();
                const myDate = eventDates[date.dateString] || date;
                return <View
                    style={[styles.dayContainer, overwriteStyle.dayContainer, {height: dayHeight}, (day === 1 && styles.dayContainerFirst)]}>
                    <CalendarEventModal
                        visible={visibleEvent}
                        date={myDate}
                        setVisibleEvent={setVisibleEvent}
                        pressDisabled={marking?.marked === true}
                        dateState={state}
                        eventDay={date.day}
                    />
                </View>
            }}
            renderHeader={date => {
                let dateObj = new Date(date);
                const year = dateObj.getFullYear();
                const month = dateObj.getMonth();
                return <CalendarNavigation label={`${calendarMonths[month]} ${year}`}/>
            }}
        />
        <View style={{flex: 1, marginTop: 10, marginBottom: 25}}>
            <CaptionBanner config={{numberOfLines: 1, headline: 'Meine Termine'}}/>
        </View>
        {displayEvents(myEvents)}
        <View style={{flex: 1, marginTop: 10, marginBottom: 25}}>
            <CaptionBanner config={{numberOfLines: 1, headline: 'Termine ohne Zusage'}}/>
        </View>
        {displayEvents(notMyEvents)}
    </>
}
const styles = StyleSheet.create({
    calendar: {
        // overwrite calendar styles
        paddingLeft: 0,
        paddingRight: 0,
        backgroundColor: 'none',
    },
    headerContainer: {
        flex: 1,
    },
    monthContainer: {
        alignItems: "center",
        alignSelf: "flex-start",
        flexDirection: "row",
        justifyContent: "space-between",
        minWidth: 300,
        paddingBottom: 16,
    },
    monthArrow: {},
    monthArrow_left: {
        paddingRight: 40,
    },
    monthArrow_right: {
        paddingLeft: 40,
    },
    weekContainer: {
        flex: 1,
        flexDirection: "row",
        alignItems: "stretch",
        justifyContent: "space-between",
        borderTopWidth: 1,
        borderBottomWidth: 1,
    },
    weekDayContainer: {
        flex: 1,
    },
    weekDayInner: {
        paddingVertical: 6,
        flex: 1,
        justifyContent: 'center',
        borderLeftWidth: 1,
    },
    weekDayInnerFirst: {
        borderLeftWidth: 0
    },
    weekDayText: {
        textAlign: 'center'
    },
    dayContainer: {
        width: '100%',
        height: 80,
        alignItems: "center",
        borderBottomWidth: 1,
        borderRightWidth: 1,
    },
    dayContainerFirst: {
        borderLeftWidth: 1,
    },
});
