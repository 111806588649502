import * as ImagePicker from 'expo-image-picker';
import {Platform,} from "react-native";

//Extract the MIME type from a base64 string using a regular expression.
function base64MimeType(encoded) {
    let result = null;

    if (typeof encoded !== 'string') {
        return result;
    }

    const mime = encoded.match(/data:(.*);base64/);

    if (mime && mime.length) {
        result = mime[1];
    }

    return result;
}

function prepareFormData(result) {
    let imageRes = undefined;
    if (Array.isArray(result.assets)) {
        imageRes = result.assets[0];
    } else {
        throw new Error("Didnt find assets in result.")
    }
    let type, fileName = undefined;
    if (Platform.OS === "web") {
        type = base64MimeType(imageRes.uri)
    } else {
        fileName = imageRes.uri.split('/').pop();
        let match = /\.(\w+)$/.exec(fileName);
        type = match ? `image/${match[1]}` : `image`;
    }
    //console.log({type,fileName})
    const fileExtension = type.split("/")[1];
    //console.log({fileExtension})
    fileName = `${new Date().getTime()}.${fileExtension}`
    //console.log({fileName})
    let formData = new FormData();
    formData.append("context", "default");
    formData.append('enabled', 1);
    formData.append('cdnIsFlushable', 0);
    formData.append('binaryContent', "data:" + type + ";base64," + imageRes.base64)
    formData.append("name", fileName);
    formData.append('contentType', type);
    //console.log("formData ", formData);
    return formData
}

export async function getCurrentCameraPermissions() {
    return await ImagePicker.getCameraPermissionsAsync()
}

export async function getCurrentMediaLibraryPermissions() {
    return await ImagePicker.getMediaLibraryPermissionsAsync()
}

export async function requestCameraPermissions() {
    return await ImagePicker.requestCameraPermissionsAsync()
}

export async function requestMediaLibraryPermissions() {
    return await ImagePicker.requestMediaLibraryPermissionsAsync()
}

export async function launchCamera() {
    let result = await ImagePicker.launchCameraAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        base64: true,
        exif: true,
        quality: 1,
        allowsEditing: true
    })
    // console.log(result);
    if (result.canceled) {
        return {canceled: true};
    }
    return prepareFormData(result)
}

export async function launchImageLibrary() {
    let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        base64: true,
        exif: true,
        quality: 1,
        allowsEditing: true
    });
    //console.log("got res" , result)
    if (result.canceled) {
        return {canceled: true};
    }
    return prepareFormData(result)
}
