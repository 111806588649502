import NewsArticlePreviewHeader from "../NewsArticlePreview/NewsArticlePreviewHeader";
import React from "react";
import {StyleSheet, View} from "react-native";
import NewsArticlePreviewText from "../NewsArticlePreview/NewsArticlePreviewText";
import NewsArticlePreviewDate from "../NewsArticlePreview/NewsArticlePreviewDate";

const BlogDetails = (props) => {
    const {route} = props;
    const {news} = route?.params ?? {};

    // console.log("BlogDetails", news)

    return <View>
        <NewsArticlePreviewHeader
            title={news?.title}
            isImageEnabled={true}
            uri={news?.picture}
            newsImageStyle={style.newsImage}
            text={{fontSize: 45}}
        />
        <NewsArticlePreviewDate date={news?.datetime ?? news?.created_at}/>
        <NewsArticlePreviewText text={news.description}/>
    </View>
}
const style = StyleSheet.create({
    newsImage: {
        height: 212,
        resizeMode: "cover"
    },
});
export default BlogDetails;