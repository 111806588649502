import {createSlice} from '@reduxjs/toolkit'

const initialState = {
    backgroundColor: "#707070",
    headerBorderColor: "#dedede",
    backgroundColorAccent: "#E5E5E5",
    wrapperPadding: 16,
    containerSpacing: 32,
    fontSize: 16,
    text: {
        bold: {
            fontFamily: "OpenSans_700Bold",
            fontSize: 16,
            color: "#707070",
        },
        link: {
            fontFamily: "OpenSans_700Bold",
            fontSize: 22,
            color: "#0093D1",
        },
        caption: {
            fontFamily: "OpenSans_600SemiBold",
            fontSize: 22,
            color: "#0093D1",
        },
        header: {
            fontFamily: "BebasNeue_400Regular",
            color: "#fff",
        },
        paragraph: {
            color: "#707070",
            fontSize: 15,
            fontFamily: "OpenSans_400Regular"
        },
        primaryColorText: {
            color: "#0093D1",
            fontSize: 16,
            fontFamily: "OpenSans_700Bold"
        }
    },
    borderRadius: 30,
    padding: 30,
    colors: {
        primary: '#0093D1',
        primary_light: '#6DBEE5', // borders on primary
        secondary: '#96BB32',
        error: '#a90000',
        // text
        text: '#707070',
        text_light: '#FFFFFF',
        text_dark: '#2A2A2A',
        text_black: "#010101",
        text_unimportant: '#9B9B9B',
        // background & borders
        background: '#F6F6F6',
        background_light: '#FFFFFF',
        comment_highlight: '#d7e4ff',
        newContent: "#D3E4EF",
        newContentText: "#006FB4",
        border: '#DBDBDB',
        background_light2: '#d9e3ff',
    },
    typo: {
        web: {
            lineHeight: "1.4em",
        },
        // paragraph
        p_sm: {
            fontFamily: "OpenSans_400Regular",
            fontSize: 14,
            color: '#707070'
        },
        p_sm_bold: {
            fontFamily: "OpenSans_700Bold",
            fontSize: 14,
            color: '#707070'
        },
        p_sm_light: {
            fontFamily: "OpenSans_300Light",
            fontSize: 14,
            color: '#707070'
        },
        p_md: {
            fontFamily: "OpenSans_400Regular",
            fontSize: 16,
            color: '#707070'
        },
        p_md_bold: {
            fontFamily: "OpenSans_700Bold",
            fontSize: 16,
            color: '#707070'
        },
        p_md_light: {
            fontFamily: "OpenSans_300Light",
            fontSize: 16,
            color: '#707070'
        },
        p_lg: {
            fontFamily: "OpenSans_400Regular",
            fontSize: 18,
            color: '#2A2A2A'
        },
        p_lg_bold: {
            fontFamily: "OpenSans_700Bold",
            fontSize: 18,
            color: '#2A2A2A'
        },
        p_lg_light: {
            fontFamily: "OpenSans_300Light",
            fontSize: 18,
            color: '#2A2A2A'
        },
        // headline
        headline: {
            fontFamily: "OpenSans_300Light",
            fontSize: 41,
            color: '#2A2A2A',
        },
        subHeadline: {
            fontFamily: "OpenSans_300Light",
            fontSize: 24,
            color: '#2A2A2A',
            // paddingBottom: 24,
        },
        // other
        button: {
            fontFamily: "BebasNeue_400Regular",
            fontSize: 23,
            color: '#fff',
            backgroundColor: '#0093D1',
        },
        date: {
            fontFamily: "OpenSans_400Regular_Italic",
            fontSize: 14,
            color: '#828282'
        },
    }
}

export const themeConfiguration = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        overwrite: (state, action) => {
            state = action.payload;
        }
    },
})
// Action creators are generated for each case reducer function
export const {overwrite} = themeConfiguration.actions
export default themeConfiguration.reducer
