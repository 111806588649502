/**
 * Workaround constant used for new content data ids (not yet in our rtk-cache)
 * @type {number}
 */
export const CACHE_ID_NEW_CONTENT = Number.MAX_VALUE;
/**
 * Default Intervall to get data
 * @type {number}
 */
export const NEW_CONTENT_POLLING_INTERVAL_DEFAULT = 15000;
/**
 * for the likes/comments count data to be refreshed
 * @type {number}
 */
export const POLLING_INTERVALL_LIKES_COMMENTS = 20000;
export const threadActivityLastXDays = 90;
export const upcomingDaysCalendarActivity = 90;
export const HEADERHEIGHT = 68;
export const TABBARHEIGHT = 66;

/**
 * max length of notifications for the native device before being cut
 * @type {number}
 */
export const NOTIFICATION_MAX_LENGTH = 125;
/**
 * How often to poll for bell notification status check
 * @type {number}
 */
export const NOTIFICATION_BELL_POLLING_INTERVAL = 5000;
/**
 * how often to fetch notifications in the notifications window
 * @type {number}
 */
export const NOTIFICATION_POLLING_INTERVAL = 8000;
/**
 * News and forum
 * @type {number}
 */
export const DATA_POLLING_INTERVAL = 10000;
