import React from "react";
import {useSelector} from "react-redux";

export default function SelectBox(props) {
    const {onValueChanged, value, items, placeholder, style} = props;
    const theme = useSelector(state => state.theme);

    return <select style={{
        borderWidth: 0,
        borderRadius: 0,
        height: 40,
        paddingHorizontal: 5,
        alignSelf: "stretch",
        ...theme.typo.p_sm,
        color: theme.colors.text_dark,
        ...style
    }}
                   onChange={(e) => onValueChanged(e.target.value)}
    >
        <option value={null}>Alle Neuigkeiten anzeigen</option>
        {items?.map((item, idx) => <option key={idx} value={item.value}>{item.label}</option>)}
    </select>;
}
