import FontAwesome5 from "react-native-vector-icons/FontAwesome5";
import {Image, Platform, StyleSheet} from "react-native";
import {FontelloIcon} from "./FontelloIcon";

export default function WebIcon(props) {
    const {icon, style, color, size} = props;
    const styleArr = [styles.icon, {width: size, height: size}, style];
    if (Platform.OS === 'web') {
        // fontawesome
        if (typeof icon === 'string' || icon?.type === 'fontawesome')
            return <FontAwesome5
                style={styleArr}
                name={icon?.name ?? icon}
                size={size ?? 30}
                regular={(icon?.fa5style === 'regular')}
                solid={(icon?.fa5style === 'solid')}
                color={color ?? '#000'}/>;
        // fontello
        if (icon.type === 'fontello_custom')
            return <FontelloIcon
                style={styleArr}
                name={icon?.name}
                color={color ?? "#000"}
                size={size ?? 30}/>;
        // external
        if (icon.type === 'external')
            return <Image
                style={styleArr}
                source={icon?.name}/>;
        // local
        if (icon.type === 'local')
            return <Image
                style={styleArr}
                source={require(`../../../assets/images/${icon?.name}`)}/>;
        // none
        console.warn("could not create icon:", icon);
    }
    return null;
};

const styles = StyleSheet.create({
    icon: {
        width: 30,
        height: 30,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        resizeMode: "contain",
    }
});
